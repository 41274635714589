import "./App.css";
import React, { lazy, Suspense, useEffect } from "react";
import Footer from "./Components/Footer/Footer";
import MyOffer from "./Pages/MyOffers/MyOffers";
import Login from "./Pages/LoginRegistration/Login";
import Registration from "./Pages/LoginRegistration/Registration";
import NewOffer from "./Pages/NewOffer/NewOffer";
// import Home from './Pages/Home/Home'
import Map from "./Pages/Map/Map";
import NotFound from "./Pages/NotFound/NotFound";
import { Route, Routes, useSearchParams } from "react-router-dom";
import UpdateOffer from "./Pages/UpdateOffer/NewOffer";
import OfferUploaded from "./Pages/OfferUploaded/OfferUploaded";
import MobileNavBar from "./Components/MobileNavBar/MobileNavBar";
import FavouriteOffers from "./Pages/FavouriteOffers/FavouriteOffers";
import { useDispatch, useSelector } from "react-redux";
import { setAdsType, setIsMobile } from "./redux/action";
import Morebar from "./Components/Morebar/Morebar";
import Success from "./Pages/Success/Success";
import { useLocation } from "react-router-dom";
import PaymentError from "./Pages/PaymentError/PaymentError";
import SearchPopUp from "./Components/SearchPopUp/SearchPopUp";
import HomeWithPremiumOffers from "./Pages/Home/HomeWithPremiumOffers";
import ChangeHeadTags from "./Components/ChangeHeadTags/ChangeHeadTags";
// import Blog from './Pages/Blog/Blog'
// import BlogById from './Pages/Blog/BlogById'
import Loading from "./Components/Loading/Loading";
const Home = lazy(() => import("./Pages/Home/Home"));

function App() {
  const dispatch = useDispatch();
  const isMobile = useSelector((state) => state.isMobile);
  const adsType = useSelector((state) => state.adsType);
  const showMorebar = useSelector((state) => state.showMorebar);
  const searchByUrlPopUp = useSelector((state) => state.searchByUrlPopUp);
  const [queryParameters] = useSearchParams();
  useEffect(() => {
    getWindowSize().innerWidth < 750
      ? dispatch(setIsMobile(true))
      : dispatch(setIsMobile(false));
    function handleWindowResize() {
      getWindowSize().innerWidth < 750
        ? dispatch(setIsMobile(true))
        : dispatch(setIsMobile(false));
    }
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (
      window.location.search.includes("?az") ||
      window.location.search.includes("az=") ||
      window.location.search.includes("?ru") ||
      window.location.search.includes("ru=")
      // window.location.search.includes('en=') ||
      // window.location.search.includes('?en') ||
    ) {
      if (
        window.location.search.includes("?ru") ||
        window.location.search.includes("ru=")
      ) {
        if (localStorage.getItem("language") !== "ru") {
          window.localStorage.setItem("language", "ru");
          window.location.reload();
        }
      }
      // else if (window.location.search.includes('?en') || window.location.search.includes('en=')) {
      //   if(localStorage.getItem('language') !== "en"){
      //     window.localStorage.setItem('language', 'en')
      //     window.location.reload()
      //   }
      // }
      else if (
        window.location.search.includes("?az") ||
        window.location.search.includes("az=")
      ) {
        if (localStorage.getItem("language") !== "az") {
          window.localStorage.setItem("language", "az");
          window.location.reload();
        }
      } else {
        if (localStorage.getItem("language") !== "az") {
          window.localStorage.setItem("language", "az");
          window.location.reload();
        }
      }
    }
    if (queryParameters.get("elan") !== null) {
      if (queryParameters.get("elan")?.includes("masin")) {
        dispatch(setAdsType("cars"));
      } else {
        dispatch(setAdsType("homes"));
      }
    }
  }, []);
  return (
    <div className="App">
      <Suspense fallback={<Loading />}>
        <ChangeHeadTags />
        <div className="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/profil" element={<MyOffer />} />
            <Route path="/elan-yerlesdir" element={<NewOffer />} />
            <Route path="/elan-paylasildi" element={<OfferUploaded />} />
            <Route path="/elani-yenile/*" element={<UpdateOffer />} />
            <Route path="/secilmis-elanlar" element={<FavouriteOffers />} />
            {/* <Route path='/map' element={<Map />} /> */}
            <Route path="/elan/*" element={<Home />} />
            <Route
              path="/butun-premium-elanlar"
              element={<HomeWithPremiumOffers />}
            />
            <Route path="/secilmis-elanlar/*" element={<FavouriteOffers />} />
            <Route path="/daxil-ol" element={<Login />} />
            <Route path="/qeydiyyat" element={<Registration />} />
            {/* <Route path='/bloglar' element={<Blog />} />
          <Route path='/blog/*' element={<BlogById />} /> */}
            <Route path="/success/*" element={<Success />} />
            <Route path="/payment-error/*" element={<PaymentError />} />
            <Route path="/xeta-404/*" element={<NotFound />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        {!isMobile ? (
          <Footer />
        ) : (
          <div
            style={{ height: "0px", width: "100%", backgroundColor: "inherit" }}
          ></div>
        )}
        {isMobile && <MobileNavBar />}
        {showMorebar ? <Morebar /> : <></>}
        {searchByUrlPopUp && <SearchPopUp />}
      </Suspense>
    </div>
  );
}
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default App;
