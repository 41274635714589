import React, { useState } from "react";
import "./Offers.css";
import Select from "react-select";
import OfferCard from "./OfferCard";
import Box from "@mui/material/Box";
import { Empty } from "antd";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { GridView, Map, Search } from "@mui/icons-material";
import MapComponent from "../Map/Map";
import Offer from "../../Pages/Offer/Offer";
import { useDispatch, useSelector } from "react-redux";
import {
  changeFilter,
  changeFlatType,
  changeSearchRow,
  getPage,
  setAdsType,
  setShouldRunSecondEffect,
  setShowMobileFilter,
  setShowModal,
  showOrCloseMapF,
} from "../../redux/action";
import Loading from "../Loading/Loading";
import LoadingMap from "../Loading/LoadingMap";
import { useNavigate } from "react-router-dom";
import { options } from "../../constants";
import { useEffect } from "react";
import { createBrowserHistory } from "history";
import InitialPageOffers from "../InitialPageOffers/InitialPageOffers";
import SearchRoundedIcon from "../images/searchSvg.svg";
import SettingsIcon from "../images/settingsIcon.svg";
import { getAllVipsAction } from "../../redux/action";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import Crown from "../images/Crown.svg";
import Loading2 from "../Loading/Loading2";
import FlatImage from "./FlatImage";
import CountryImage from "./CountryImage";
import LandImage from "./LandImage";
import ObyektImage from "./ObyektImage";
import OfisImage from "./OfisImage";
import GarageImage from "./GarageImage";
import gezzVertical from "../../images/project-about/gezzVertical.png";
// import gezzVertical from "../../images/project-about/gezzbanner1H.png";
import gezzHorizontal from "../../images/project-about/gezzHorizontal.png";
import bidVertical1 from "../../images/project-about/bidVertical1.png";
import bidVertical2 from "../../images/project-about/bidVertical2.png";
import bidVertical3 from "../../images/project-about/bidVertical3.png";
import bodHorizontal from "../../images/project-about/bidHorizontal.jpg";
import bodHorizontal2 from "../../images/project-about/bidHorizontal2.jpg";
import OfferCardAuto from "./OfferCardAuto";
import OfferAuto from "../../Pages/Offer/OfferAuto";
import CarImage from "./CarImage";

const Offers = () => {
  const initialOffersVips = useSelector((state) => state.initialOffersVips);
  const history = createBrowserHistory();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const filter = useSelector((state) => state.filter);
  const language = useSelector((state) => state.language);
  const defaultOffersCount = useSelector((state) => state.defaultOffersCount);
  const countOfPages = useSelector((state) => state.countOfPages);
  const page = useSelector((state) => state.page);
  const offersFoundOrNot = useSelector((state) => state.offersFoundOrNot);
  const searchTrueOrFalse = useSelector((state) => state.search);
  const lastSearchUrl = useSelector((state) => state.lastSearchUrl);
  const isMobile = useSelector((state) => state.isMobile);
  const handleChange = (event, value) => {
    dispatch(setShouldRunSecondEffect(false));
    dispatch(getPage(value));
  };
  const showModal = useSelector((state) => state.showOrCloseModal);
  const [value, setValue] = useState(0);
  const showMap = useSelector((state) => state.showOrCloseMap);
  const offers = useSelector((state) => state.defaultOffers);
  const offerType = useSelector((state) => state.offerType);
  const adsType = useSelector((state) => state.adsType);
  const BidVerticalBanners = [bidVertical1, bidVertical2, bidVertical3];
  const [selectedBanner, setSelectedBanner] = useState(
    Math.floor(Math.random() * BidVerticalBanners.length)
  );
  const defaultOffers =
    offerType !== "get" ? (
      Object.values(offers).map((offer, index) =>
        adsType === "homes" ? (
          <OfferCard key={index} offer={offer} />
        ) : (
          <OfferCardAuto key={index} offer={offer} />
        )
      )
    ) : (
      <InitialPageOffers />
    );
  const searchRow = useSelector((state) => state.searchRow);
  const closeModal = async (e) => {
    if (e.target.classList[0] === "offerdetailsModal") {
      dispatch(setShowModal(false));
      // window.scrollTo({ top: 0, behavior: 'smooth' });
      const path = window.location.pathname.split("/");
      try {
        const element = document.getElementById(path[path.length - 1]);
        element.scrollIntoView({ block: "center", inline: "nearest" });
      } catch {}
      if (lastSearchUrl !== "/" && lastSearchUrl !== undefined) {
        await navigate("/");
        navigate(`/${lastSearchUrl}`);
      } else {
        navigate("/");
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/butun-premium-elanlar"
    ) {
      dispatch(setShowModal(true));
    } else {
      dispatch(setShowModal(false));
    }
  }, [window.location.pathname]);
  const styles = {
    // #0D7CBA !important
    valueContainer: (base) => ({
      ...base,
      maxHeight: 35,
      overflowY: "auto",
      borderRadius: "7px",
      fontSize: "14px",
      color: "#888",
    }),
    control: (styles, state) => ({
      ...styles,
      maxHeight: 35,
      overflowY: "auto",
      borderRadius: "7px",
      cursor: "pointer",
      boxShadow: "none",
      color: "#888",
      border: "1px solid #bbb",
      "&:hover": {
        borderColor: "#bbb",
      },
    }),
  };
  const showAllVips = () => {
    dispatch(getAllVipsAction());
    navigate("/butun-premium-elanlar");
  };

  const showAllOffers = (offerType, flatType, flatTypeName) => {
    // dispatch(changeFlatType(flatTypeName));
    const url = new URL(window.location.href);
    url.searchParams.set("elan", flatType);
    window.location.href = url
      .toString()
      .replace("butun-premium-elanlar", "");
    // dispatch(setAdsType(flatType === "masin" ? "cars" : "homes"))
    localStorage.setItem("adsType", flatType === "masin" ? "cars" : "homes")
  };
  return (
    <div
      className="offers"
      style={{
        position: "relative",
        marginTop: offerType === "vips" ? "50px" : "0px",
      }}
    >
      {/* <div className="project-about1">
        <a
          href="https://gezz.az"
          target="_blank"
          rel="nofollow"
          style={{
            width: "100%",
            // height: "100%",
          }}
        >
          <div className="project-aboutinner1">
            <img src={gezzVertical} />
          </div>
        </a>
      </div> */}
      {/* <div className="project-about2">
        <a
          href="https://bid.az"
          target="_blank"
          rel="nofollow"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div className="project-aboutinner2">
            <img src={BidVerticalBanners[selectedBanner]} />
          </div>
        </a>
      </div> */}
      {isMobile ? (
        <>
          <div
            className="mobile-offers-content"
            style={{
              marginBottom: offerType === "vips" ? "20px" : "0px",
              backgroundColor: "white",
            }}
          >
            <div className="mobile-offers-content-top-part">
              <div
                className="mobile-offers-content-top-left"
                onClick={() => dispatch(setShowMobileFilter(true))}
              >
                <img
                  src={SearchRoundedIcon}
                  alt="search"
                  style={{ height: "20px", width: "20px" }}
                />
                <span style={{ fontSize: "16px" }}>
                  {options[language].searchName} :
                </span>
                <span
                  style={{
                    fontSize: "14px",
                    color: "#aaa",
                    fontWeight: "400",
                  }}
                >
                  {offerType === "get"
                    ? options[language].offersFilterSloqan
                    : searchRow}
                </span>
              </div>
              <div
                className="mobile-offers-content-top-right"
                onClick={() => dispatch(setShowMobileFilter(true))}
              >
                <img
                  src={SettingsIcon}
                  alt="search"
                  style={{ height: "100%", width: "100%" }}
                />
              </div>
            </div>
            <div className="mobile-offers-content-filter-part">
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "masin",
                    options[language].cars
                  )
                }
              >
                <CarImage
                  color={
                    window.location.href.includes("masin")
                      ? "#0089CF"
                      : "#222222"
                  }
                />
                <span
                  style={{
                    color: window.location.href.includes("masin")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].cars}
                  {window.location.href.includes("masin") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "menzil",
                    options[language].flat_type_options[0]
                  )
                }
              >
                <FlatImage
                  color={
                    window.location.href.includes("menzil")
                      ? "#0089CF"
                      : "#222222"
                  }
                />
                <span
                  style={{
                    color: window.location.href.includes("menzil")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].flat_type_options[0].label}
                  {window.location.href.includes("menzil") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "heyet-evi-bag-villa",
                    options[language].flat_type_options[1]
                  )
                }
              >
                <CountryImage
                  color={
                    window.location.href.includes("heyet-evi-bag-villa")
                      ? "#0089CF"
                      : "#222222"
                  }
                />
                <span
                  style={{
                    color: window.location.href.includes("heyet-evi-bag-villa")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].flat_type_options[1].label2}
                  {window.location.href.includes("heyet-evi-bag-villa") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "torpaq",
                    options[language].flat_type_options[4]
                  )
                }
              >
                <LandImage
                  color={
                    window.location.href.includes("torpaq")
                      ? "#0089CF"
                      : "#222222"
                  }
                />
                <span
                  style={{
                    color: window.location.href.includes("torpaq")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].flat_type_options[4].label}
                  {window.location.href.includes("torpaq") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "obyekt",
                    options[language].flat_type_options[5]
                  )
                }
              >
                <ObyektImage
                  color={
                    window.location.href.includes("obyekt")
                      ? "#0089CF"
                      : "#222222"
                  }
                />
                <span
                  style={{
                    color: window.location.href.includes("obyekt")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].flat_type_options[5].label}
                  {window.location.href.includes("obyekt") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "ofis",
                    options[language].flat_type_options[2]
                  )
                }
              >
                <OfisImage
                  color={
                    window.location.href.includes("ofis")
                      ? "#0089CF"
                      : "#222222"
                  }
                />
                <span
                  style={{
                    color: window.location.href.includes("ofis")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].flat_type_options[2].label}
                  {window.location.href.includes("ofis") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <div
                className="mobile-offers-content-filter"
                style={{
                  width: "calc(100% / 8 )",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                onClick={() =>
                  showAllOffers(
                    "s",
                    "qaraj",
                    options[language].flat_type_options[3]
                  )
                }
              >
                <GarageImage
                  color={
                    window.location.href.includes("qaraj")
                      ? "#0089CF"
                      : "#222222"
                  }
                />

                <span
                  style={{
                    color: window.location.href.includes("qaraj")
                      ? "#0089CF"
                      : "#444",
                    fontSize: "12px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {options[language].flat_type_options[3].label}
                  {window.location.href.includes("qaraj") ? (
                    <div
                      style={{
                        width: "100%",
                        height: "2px",
                        backgroundColor: "#0089CF",
                        borderRadius: "10px",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
            </div>
            {offerType !== "get" && offerType !== "vips" && (
              <div className="mobile-offers-content-bottom-part">
                <span
                  style={{
                    minWidth: "97px",
                    fontSize: "14px",
                    color: "#888",
                    fontFamily: "Oxygen",
                    fontWeight: "400",
                  }}
                >
                  {options[language].offersFound}: {defaultOffersCount}{" "}
                </span>
                <Select
                  styles={styles}
                  closeMenuOnSelect={true}
                  isMulti={false}
                  isSearchable={false}
                  options={options[language].filter_options}
                  onChange={(e) => {
                    dispatch(setShouldRunSecondEffect(false));
                    dispatch(changeFilter(e));
                  }}
                  value={filter}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          {offerType !== "get" && offerType !== "vips" && (
            <>
              <div className="offers-content">
                <div className="offers-content-first-row">
                  <span>
                    {searchRow}
                    <span style={{ marginLeft: "25px" }}>
                      {defaultOffersCount}{" "}
                      {defaultOffersCount !== "" &&
                        options[language].offersFound}
                    </span>
                  </span>
                  <div className="offers-content-first-row-right-side">
                    <Box sx={{ width: 140 }}>
                      <BottomNavigation
                        showLabels
                        sx={{ backgroundColor: "#f5f5f5" }}
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                        }}
                      >
                        {adsType === "homes" && (
                          <>
                            <BottomNavigationAction
                              label={options[language].listSmall}
                              icon={<GridView />}
                              onClick={(e) => {
                                dispatch(setShouldRunSecondEffect(false));
                                dispatch(showOrCloseMapF(false));
                              }}
                            />
                            <BottomNavigationAction
                              label={options[language].mapSmall}
                              icon={<Map />}
                              onClick={(e) => {
                                dispatch(setShouldRunSecondEffect(false));
                                dispatch(showOrCloseMapF(true));
                              }}
                            />
                          </>
                        )}
                      </BottomNavigation>
                    </Box>
                    <Select
                      styles={styles}
                      closeMenuOnSelect={true}
                      isMulti={false}
                      options={options[language].filter_options}
                      onChange={(e) => {
                        dispatch(setShouldRunSecondEffect(false));
                        dispatch(changeFilter(e));
                      }}
                      value={filter}
                    />
                  </div>
                </div>
              </div>
              {showMap && (
                <div className="offers-content-maps">
                  <MapComponent />
                </div>
              )}
            </>
          )}
        </>
      )}
      {initialOffersVips?.length !== 0 &&
        offerType === "get" &&
        !searchTrueOrFalse && (
          <div
            style={{
              width: "100vw",
              maxWidth: "1330px",
              borderRadius: isMobile ? "0px" : "20px",
              height: isMobile ? "285px" : "350px",
              backgroundColor: "#F1F1F1",
              position: "absolute",
              top: isMobile ? "135px" : "55px",
              zIndex: "-1",
              left: isMobile ? "50vw" : "calc(50vw - 5px)",
              transform: "translate(-50%, 0%)",
            }}
          ></div>
        )}
      {initialOffersVips?.length !== 0 &&
        offerType !== "get" &&
        offerType !== "vips" &&
        !showMap &&
        !searchTrueOrFalse &&
        offersFoundOrNot && (
          <div
            style={{
              width: "100vw",
              maxWidth: "1330px",
              borderRadius: isMobile ? "0px" : "20px",
              height: isMobile ? "265px" : "350px",
              backgroundColor: "#F1F1F1",
              position: "absolute",
              top: isMobile ? "185px" : "103px",
              zIndex: "-1",
              left: isMobile ? "50vw" : "calc(50vw - 5px)",
              transform: "translate(-50%, 0%)",
            }}
          ></div>
        )}
      {initialOffersVips?.length !== 0 &&
        offerType !== "get" &&
        offerType !== "vips" &&
        !showMap &&
        !searchTrueOrFalse &&
        offersFoundOrNot && (
          <div
            className="vips-on-offers"
            style={{ width: "70%", maxWidth: "1300px" }}
          >
            {/* <div style={{ width: '100%' }}> */}
            <div
              className="InitialPageOffers-content-row InitialPageOffers-content-vip"
              style={{ width: "100%" }}
            >
              <div
                className="InitialPageOffers-content-row-title"
                style={{
                  marginBottom: isMobile ? "0px" : "10px",
                  marginTop: isMobile ? "10px" : "0px",
                  // marginLeft: isMobile
                  //   ? "2.5%"
                  //   : window.innerWidth >= 1400
                  //   ? "10px"
                  //   : "0",
                  width: "100%",
                  maxWidth: "1300px",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "5px",
                  }}
                >
                  <img
                    style={{ width: isMobile ? "25px" : "30px" }}
                    src={Crown}
                    alt="crown"
                  />
                  {options[language].premiumOffers}
                </span>
                <span onClick={() => showAllVips()}>
                  {options[language].showAll}
                </span>
              </div>
              <Swiper
                style={{
                  zIndex: "0",
                  width: "100%",
                  // marginLeft: isMobile
                  //   ? "0"
                  //   : window.innerWidth >= 1400
                  //   ? "7px"
                  //   : "0",
                }}
                modules={[Autoplay]}
                speed={2000}
                autoplay={{
                  delay: 1500,
                  pauseOnMouseEnter: true,
                }}
                loop={true}
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  840: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1220: {
                    slidesPerView: 4,
                    spaceBetween: 31,
                  },
                }}
                scrollbar={{ draggable: true }}
              >
                {initialOffersVips?.map((offer, index) => (
                  <SwiperSlide
                    style={{
                      paddingTop: "10px",
                      paddingBottom: "10px",
                      paddingLeft: isMobile ? "2px" : "2px",
                      paddingRight: isMobile ? "2px" : "2px",
                      width: "42vw",
                      maxWidth: "305px",
                    }}
                    key={index}
                  >
                    {adsType === "homes" ? (
                      <OfferCard key={index} offer={offer} />
                    ) : (
                      <OfferCardAuto key={index} offer={offer} />
                    )}
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
            {/* </div> */}
          </div>
        )}
      <div className="offers-content-cards">
        {searchTrueOrFalse ? (
          showMap ? (
            <LoadingMap />
          ) : (
            <span
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                height: "calc(100vh - 100px)",
              }}
            >
              <Loading2 />
            </span>
          )
        ) : offersFoundOrNot ? (
          offerType === "get" ? (
            defaultOffers
          ) : (
            <>
              <hr
                className="InitialPageOffers-content-hr"
                style={{ marginTop: isMobile ? "0px" : "-10px" }}
              />
              <span
                style={{
                  width: "100%",
                  fontSize: "18px",
                  fontWeight: "500",
                  marginTop: isMobile ? "-10px" : "10px",
                  marginBottom: isMobile ? "0" : "20px",
                }}
              >
                {options[language].offersCommon}
              </span>
              {offerType !== "get" ? (
                <>
                  {isMobile ? (
                    <>
                      <div className="offers-content-cardsNew">
                        {Object.values(offers)
                          ?.slice(0, 10)
                          ?.map((offer, index) =>
                            adsType === "homes" ? (
                              <OfferCard key={index} offer={offer} />
                            ) : (
                              <OfferCardAuto key={index} offer={offer} />
                            )
                          )}
                      </div>

                      {/* <a
                        href="https://gezz.az"
                        target="_blank"
                        rel="nofollow"
                        style={{
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <div className="mobile-project-about2"></div>
                      </a> */}
                      <div className="offers-content-cardsNew">
                        {Object.values(offers)
                          ?.slice(10, 20)
                          ?.map((offer, index) =>
                            adsType === "homes" ? (
                              <OfferCard key={index} offer={offer} />
                            ) : (
                              <OfferCardAuto key={index} offer={offer} />
                            )
                          )}
                      </div>
                      {/* {Object.values(offers)?.length >= 16 && (
                        <a
                          href="https://bid.az"
                          target="_blank"
                          rel="nofollow"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                        >
                          <div className="mobile-project-about1"></div>
                        </a>
                      )} */}
                    </>
                  ) : (
                    <div className="offers-content-cardsNew">
                      {Object.values(offers)?.map((offer, index) =>
                        adsType === "homes" ? (
                          <OfferCard key={index} offer={offer} />
                        ) : (
                          <OfferCardAuto key={index} offer={offer} />
                        )
                      )}
                    </div>
                  )}
                </>
              ) : (
                <InitialPageOffers />
              )}
              {/* <div className="offers-content-cardsNew">{defaultOffers}</div>
              <span>salam</span>
              <div className="offers-content-cardsNew">{defaultOffers}</div> */}
            </>
          )
        ) : (
          <span
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            <Empty
              description={options[language].notFoundOffers}
              fontSize="40px"
            />
          </span>
        )}
      </div>
      {offerType !== "get" && offerType !== "vips" ? (
        offersFoundOrNot ? (
          !showMap ? (
            <Stack
              spacing={2}
              style={{ marginTop: "20px", paddingBottom: "70px" }}
            >
              <Pagination
                page={page}
                count={countOfPages}
                variant="outlined"
                shape="rounded"
                color="primary"
                onChange={handleChange}
              />
            </Stack>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      ) : (
        <></>
      )}

      {showModal && (
        <div className="offerdetailsModal" onClick={(e) => closeModal(e)}>
          {window.location.href?.includes("autosales") ||
          window.location.href?.includes("autorents") ? (
            <OfferAuto />
          ) : (
            <Offer />
          )}
        </div>
      )}
    </div>
  );
};

export default Offers;
