import React, { useState } from "react";
import "./Offers.css";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import whiteFon from "../../images/whitefon.jpg";
import { options } from "../../constants";
import HeartWhite from "../../images/blurHeart.svg";
import HeartRed from "../../images/FilledHeart.svg";
import { useEffect } from "react";
import locationIcon from "../../images/locationIcon.svg";
import { white } from "material-ui/styles/colors";
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from "@mui/icons-material";
import { saveLastSearchUrl } from "../../redux/action";
import CrownIcon from "../images/CrownCard.svg";

const OfferCardAuto = ({ offer }) => {
  const navigate = useNavigate();
  const isMobile = useSelector((state) => state.isMobile);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();
  const [url, setUrl] = useState(offer?.url?.split("/")[2]);
  const [changed, setChanged] = useState(false);
  const offerType = useSelector((state) => state.offerType);
  const [isFavorite, setIsFavorite] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  if (url === "dasinmaz-emlak") {
    setUrl("tap.az");
    setChanged(true);
  }
  useEffect(() => {
    const favoritesData = localStorage.getItem("MyFavourites");
    if (favoritesData) {
      const isObjectInFavorites =
        JSON.parse(favoritesData)?.findIndex(
          (item) => item.id === offer?.id
        ) !== -1;

      setIsFavorite(isObjectInFavorites);
    }
  }, [offer?.id]);
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MyFavourites");
    if (existingData) {
      const dataArray = JSON.parse(existingData);
      const existingIndex = dataArray.findIndex((item) => item.id === obj.id);
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1);
      } else {
        dataArray.push(obj);
      }
      localStorage.setItem("MyFavourites", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MyFavourites", JSON.stringify(newDataArray));
    }
    setIsFavorite(!isFavorite);
  }
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offer);
  };
  const getLeftImg = () => {
    if (imgIndex !== 0) {
      let index = imgIndex - 1;
      setImgIndex(index);
    } else {
      setImgIndex(JSON.parse(offer?.photos).length - 1);
    }
  };
  const getRightImg = () => {
    if (imgIndex < JSON.parse(offer?.photos).length - 1) {
      let index = imgIndex + 1;
      setImgIndex(index);
    } else {
      setImgIndex(0);
    }
  };
  const clickOnLink = () => {
    if (
      !(
        window.location.href.includes("/elan/") ||
        window.location.href.includes("/secilmis-elanlar/") ||
        window.location.href.includes("secilmis-elanlar")
      )
    ) {
      dispatch(
        saveLastSearchUrl(window.location.href.replace("https://ebaz.az/", ""))
      );
    }
  };
  return (
    <>
      {isMobile ? (
        <Card
          className="cardB"
          sx={{
            width: "calc(48vw - 8px)",
            height: "195px",
            maxWidth: 305,
            borderRadius: "10px",
            border:
              offer?.boostAds?.length !== 0
                ? "0px solid #ccc"
                : "0px solid #ccc",
            boxShadow:
              (offer?.boostAds?.length !== 0 || offer?.ads?.length !== 0) &&
              (offer?.boostAds?.[0]?.status === true ||
                offer?.ads?.[0]?.status === true)
                ? offer?.boostAds?.[0]?.color === 2 ||
                  offer?.ads?.[0]?.color === 2
                  ? "0 0 0 1.5px #FF2B00"
                  : offer?.boostAds?.[0]?.color === 1 ||
                    offer?.ads?.[0]?.color === 1
                  ? "0 0 0 1.5px #0089CF"
                  : "none"
                : "none",
          }}
        >
          <CardActionArea
            disableRipple
            className="card-action-area"
            sx={{ "&:hover": { backgroundColor: "transparent" } }}
          >
            <div
              className="additionalRow"
              style={{ marginTop: "-8px", marginLeft: "5px" }}
            >
              <div className="additionalRowLeft">
                {/* <FaRegHeart color='white' size={25} /> */}
              </div>
              <div className="additionalRowRight">
                {/* {offer?.ads?.length !== 0 && (
                  <img
                    style={{ width: "20px" }}
                    className="crown-on-image"
                    src={CrownIcon}
                    alt="heart red"
                  />
                )} */}
                <img
                  style={{ width: "20px" }}
                  src={
                    localStorage.getItem("MyFavourites")
                      ? JSON.parse(
                          localStorage.getItem("MyFavourites")
                        )?.findIndex((item) => item.id === offer?.id) !== -1
                        ? HeartRed
                        : HeartWhite
                      : HeartWhite
                  }
                  alt="heart red"
                  onClick={() => addInFavorite()}
                />
              </div>
            </div>

            <Link
              className={!imageLoaded ? "url-background-color" : ""}
              to={{
                pathname:
                  window.location.pathname === "/" ||
                  window.location.pathname.includes("/elan/") ||
                  window.location.pathname.includes("/butun-premium-elanlar")
                    ? `/elan/${offer?.type ? offer?.type : offerType}/${
                        offer?.id
                      }`
                    : `/secilmis-elanlar/${
                        offer?.type ? offer?.type : offerType
                      }/${offer?.id}`,
              }}
              state={{ offer }}
              onClick={() => clickOnLink()}
            >
              {(offer?.photos !== undefined) &
                (JSON.parse(offer?.photos)?.length > 1) && (
                <div className="middleRow">
                  <div
                    className="middleRow-left"
                    onClick={(e) => {
                      e.preventDefault();
                      getLeftImg();
                    }}
                  >
                    <ArrowBackIosRounded
                      className="middleRow-left-icon"
                      fontSize="medium"
                      sx={{ color: white }}
                    />
                  </div>
                  <div
                    className="middleRow-right"
                    onClick={(e) => {
                      e.preventDefault();
                      getRightImg();
                    }}
                  >
                    <ArrowForwardIosRounded
                      className="middleRow-right-icon"
                      fontSize="medium"
                      sx={{ color: white }}
                    />
                  </div>
                </div>
              )}
              {offer?.boostAds?.length !== 0 &&
              offer?.boostAds?.[0].status === true ? (
                offer?.boostAds?.[0]?.color === 2 ||
                offer?.ads?.[0]?.color === 2 ? (
                  <div
                    className="urgent-offer"
                    style={{ padding: "3px 8px", fontSize: "12px" }}
                  >
                    {options[language].urgentSmall}
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <CardMedia
                component="img"
                height={115}
                width={"100%"}
                image={
                  offer?.photos !== null &&
                  typeof JSON.parse(offer?.photos)[imgIndex] === "string" &&
                  JSON.parse(offer?.photos)[imgIndex]?.includes("https://")
                    ? JSON.parse(offer?.photos)[imgIndex]
                    : JSON.parse(offer?.photos) !== null &&
                      typeof JSON.parse(offer?.photos)[imgIndex] === "string"
                    ? JSON.parse(offer?.photos)[imgIndex]
                    : whiteFon
                }
                alt={offer.title ? offer.title : "elan"}
                onLoad={handleImageLoad}
                loading="lazy"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = whiteFon;
                }}
              />
            </Link>
            <Typography
              className="city-and-link-data city-and-link-data-url-part"
              variant="body2"
              color="text.secondary"
              component={"div"}
              sx={{
                fontWeight: 500,
                fontSize: "10px",
                position: "absolute",
                top: `96px`,
                color: "white",
                justifyContent: "flex-end",
                right: "0",
              }}
            >
              {changed && offer?.url !== null ? (
                <a
                  href={"https://tap.az" + offer?.url}
                  target="_blank"
                  className="offer-url"
                  rel="nofollow"
                >
                  {offer?.url?.split("/")[2]}
                </a>
              ) : offer?.url !== null ? (
                <a
                  href={offer?.url}
                  className="offer-url"
                  rel="nofollow"
                  target="_blank"
                >
                  {offer?.url?.split("/")[2]}
                </a>
              ) : (
                <></>
              )}{" "}
              {offer?.url === null && (
                <a
                  href={`https://ebaz.az/elan/${
                    offer?.type ? offer?.type : offerType
                  }/${offer?.id}`}
                  rel="nofollow"
                  target="_blank"
                  className="offer-url"
                >
                  ebaz.az
                </a>
              )}
            </Typography>
            <Link
              to={{
                pathname:
                  window.location.pathname === "/" ||
                  window.location.pathname.includes("/elan/") ||
                  window.location.pathname.includes("/butun-premium-elanlar")
                    ? `/elan/${offer?.type ? offer?.type : offerType}/${
                        offer?.id
                      }`
                    : `/secilmis-elanlar/${
                        offer?.type ? offer?.type : offerType
                      }/${offer?.id}`,
              }}
              state={{ offer }}
              onClick={() => clickOnLink()}
            >
              <CardContent
                sx={{ padding: "10px 7px", height: "76px", width: "100%" }}
              >
                {/* <hr
                  className='horizontal'
                  style={{
                    position: 'absolute',
                    bottom: '86px',
                    color: 'black'
                  }}
                /> */}
                <Typography
                  variant="body3"
                  color="text.secondary"
                  component={"div"}
                  className="typography-price-mobile"
                  sx={{
                    fontSize: "15px",
                    position: "absolute",
                    top: `106px`,
                    color: "black",
                  }}
                >
                  {offer?.price?.toLocaleString("ru")} AZN
                  {offer?.type?.includes("rent") &&
                  offer?.rentForMonthOrDay === false
                    ? options[language].perDays
                    : ""}
                </Typography>
                <Typography
                  className="city-and-link-data"
                  variant="body2"
                  color="text.secondary"
                  component={"div"}
                  sx={{
                    fontWeight: 500,
                    fontSize: "10px",
                    position: "absolute",
                    top: `122px`,
                    right: "8px",
                    color: "#888888",
                    justifyContent: "flex-end",
                  }}
                >
                  {offer?.post_date
                    ? `${
                        new Date(offer?.post_date).getDate() ===
                          new Date().getDate() &&
                        new Date(offer?.post_date).getMonth() ===
                          new Date().getMonth() &&
                        new Date(offer?.post_date).getFullYear() ===
                          new Date().getFullYear()
                          ? `${new Date(new Date(offer?.post_date).setHours(new Date(offer?.post_date).getHours() - 2))
                              .toLocaleString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                              })
                              .replace("24:", "00:")}`
                          : new Date(offer?.post_date).getDate() ===
                              new Date().getDate() - 1 &&
                            new Date(offer?.post_date).getMonth() ===
                              new Date().getMonth() &&
                            new Date(offer?.post_date).getFullYear() ===
                              new Date().getFullYear()
                          ? `${options[language].yesterdaySmall}`
                          : new Date(offer?.post_date)
                              .toLocaleString("ru-Ru", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })
                              .replace(/20\d{2}/, /\d{2}/)
                      }`
                    : ""}
                </Typography>
                {/* <hr
                  className='horizontal'
                  style={{
                    position: 'absolute',
                    bottom: '58px',
                    color: 'black'
                  }}
                /> */}
                <Typography
                  className="city-and-link-data"
                  variant="body2"
                  component={"div"}
                  color="text.secondary"
                  sx={{
                    fontWeight: 400,
                    width: "calc(100% + 0px)",
                    fontSize: "14px",
                    position: "absolute",
                    bottom: "26px",
                    color: "#212529",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "5px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "calc(100% + 0px)",
                      marginLeft: "1px",
                    }}
                  >
                    {offer?.brand !== null
                      ? options["az"].auto_type_options.find(
                          (el) => el.value === offer.brand.toString()
                        )?.label
                      : ""}
                    {offer?.model !== null && offer?.brand === null
                      ? "Auto"
                      : ""}
                    {offer?.model !== null
                      ? ", " +
                        options["az"].auto_model_options.find(
                          (el) => el.value === offer.model.toString()
                        )?.label
                      : ""}
                  </div>
                </Typography>
                {/* <hr
                  className='horizontal'
                  style={{
                    position: 'absolute',
                    bottom: '33px',
                    color: 'black'
                  }}
                /> */}
                <Typography
                  className="room-area-and-floor-data"
                  variant="body2"
                  component={"div"}
                  color="text.secondary"
                  sx={{
                    fontWeight: 500,
                    fontSize: "12px",
                    position: "absolute",
                    bottom: "2px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                    maxWidth: "calc(100% - 20px)",
                    color: "#000",
                  }}
                >
                  {offer?.year !== null && offer?.year}
                  {offer?.year !== null && offer?.enginel !== null && (
                    <hr className="vertical dot-vertical" />
                  )}
                  {offer?.enginel !== null && (
                    <>
                      {offer?.enginel} {options[language].l}
                    </>
                  )}
                  {offer?.mileage !== null && (
                    <hr className="vertical dot-vertical" />
                  )}
                  {offer?.mileage !== null && (
                    <>
                      {offer?.mileage?.toLocaleString("ru")}{" "}
                      {options[language].km}
                    </>
                  )}
                </Typography>
              </CardContent>
            </Link>
          </CardActionArea>
        </Card>
      ) : (
        <Card
          className="cardB"
          sx={{
            width: "17vw",
            position: "relative",
            height: "280px",
            maxWidth: 300,
            minWidth: 230,
            borderRadius: "10px",
            border:
              offer?.boostAds?.length !== 0
                ? "0px solid #ccc"
                : "0px solid #ccc",
            boxShadow:
              (offer?.boostAds?.length !== 0 || offer?.ads?.length !== 0) &&
              (offer?.boostAds?.[0]?.status === true ||
                offer?.ads?.[0]?.status === true)
                ? offer?.boostAds?.[0]?.color === 2 ||
                  offer?.ads?.[0]?.color === 2
                  ? "0 0 0 2px #FF2B00"
                  : offer?.boostAds?.[0]?.color === 1 ||
                    offer?.ads?.[0]?.color === 1
                  ? "0 0 0 2px #0089CF"
                  : "none"
                : "none",
            ":hover": {
              boxShadow:
                (offer?.boostAds?.length !== 0 || offer?.ads?.length !== 0) &&
                (offer?.boostAds?.[0]?.status === true ||
                  offer?.ads?.[0]?.status === true)
                  ? offer?.boostAds?.[0]?.color === 2 ||
                    offer?.ads?.[0]?.color === 2
                    ? "0 0 0 2px #FF2B00"
                    : offer?.boostAds?.[0]?.color === 1 ||
                      offer?.ads?.[0]?.color === 1
                    ? "0 0 0 2px #0089CF"
                    : "0 4px 4px rgba(0,0,0,.25)"
                  : "0 4px 4px rgba(0,0,0,.25)",
            },
          }}
        >
          <div className="card-action-area">
            <div className="additionalRow">
              <div className="additionalRowLeft"></div>
              <div className="additionalRowRight">
                {/* {offer?.ads?.length !== 0 && (
                  <img
                    className='crown-on-image'
                    src={CrownIcon}
                    alt='heart red'
                  />
                )} */}
                <img
                  src={
                    localStorage.getItem("MyFavourites")
                      ? JSON.parse(
                          localStorage.getItem("MyFavourites")
                        )?.findIndex((item) => item.id === offer?.id) !== -1
                        ? HeartRed
                        : HeartWhite
                      : HeartWhite
                  }
                  alt="heart red"
                  onClick={() => addInFavorite()}
                />
              </div>
            </div>

            <Link
              className={!imageLoaded ? "url-background-color" : ""}
              to={{
                pathname:
                  window.location.pathname === "/" ||
                  window.location.pathname.includes("/elan/") ||
                  window.location.pathname.includes("/butun-premium-elanlar")
                    ? `/elan/${offer?.type ? offer?.type : offerType}/${
                        offer?.id
                      }`
                    : `/secilmis-elanlar/${
                        offer?.type ? offer?.type : offerType
                      }/${offer?.id}`,
              }}
              state={{ offer }}
              onClick={() => clickOnLink()}
            >
              {offer?.photos !== undefined &&
                JSON.parse(offer?.photos)?.length > 1 && (
                  <div
                    className="middleRow"
                    style={{ top: "calc(30% - 20px)" }}
                  >
                    <div
                      className="middleRow-left"
                      onClick={(e) => {
                        e.preventDefault();
                        getLeftImg();
                      }}
                    >
                      <ArrowBackIosRounded
                        className="middleRow-left-icon"
                        fontSize="large"
                        sx={{ color: white }}
                      />
                    </div>
                    <div
                      className="middleRow-right"
                      onClick={(e) => {
                        e.preventDefault();
                        getRightImg();
                      }}
                    >
                      <ArrowForwardIosRounded
                        className="middleRow-right-icon"
                        fontSize="large"
                        sx={{ color: white }}
                      />
                    </div>
                  </div>
                )}
              {(offer?.boostAds?.length !== 0 || offer?.ads?.length !== 0) &&
              (offer?.boostAds?.[0]?.status === true ||
                offer?.ads?.[0]?.status === true) ? (
                offer?.boostAds?.[0]?.color === 2 ||
                offer?.ads?.[0]?.color === 2 ? (
                  <div className="urgent-offer">
                    {options[language].urgentSmall}
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              <CardMedia
                component="img"
                height={180}
                width={"100%"}
                image={
                  offer?.photos & (offer?.photos !== null) &&
                  typeof JSON.parse(offer?.photos)?.[imgIndex] === "string" &&
                  JSON.parse(offer?.photos)?.[imgIndex]?.includes("https://")
                    ? JSON.parse(offer?.photos)?.[imgIndex]
                    : JSON.parse(offer?.photos) !== null &&
                      typeof JSON.parse(offer?.photos)?.[imgIndex] === "string"
                    ? JSON.parse(offer?.photos)?.[imgIndex]
                    : whiteFon
                }
                alt={offer.title ? offer.title : "elan"}
                loading="lazy"
                onLoad={handleImageLoad}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = whiteFon;
                }}
              />
            </Link>
            <Link
              to={{
                pathname:
                  window.location.pathname === "/" ||
                  window.location.pathname.includes("/elan/") ||
                  window.location.pathname.includes("/butun-premium-elanlar")
                    ? `/elan/${offer?.type ? offer?.type : offerType}/${
                        offer?.id
                      }`
                    : `/secilmis-elanlar/${
                        offer?.type ? offer?.type : offerType
                      }/${offer?.id}`,
              }}
              state={{ offer }}
              onClick={() => clickOnLink()}
            >
              <CardContent
                sx={{ padding: "10px", height: "110px", width: "100%" }}
              >
                <hr
                  className="horizontal"
                  style={{ position: "absolute", top: "173px", color: "black" }}
                />
                <Typography
                  variant="body3"
                  color="text.secondary"
                  component={"div"}
                  className="typography-price"
                  sx={{
                    fontSize: "18px",
                    position: "absolute",
                    top: `${window.innerWidth >= 550 ? "170px" : "166px"}`,
                  }}
                >
                  {offer?.price?.toLocaleString("ru")} AZN
                  {offer?.type?.includes("rent") &&
                  offer?.rentForMonthOrDay === false
                    ? options[language].perDays
                    : ""}
                </Typography>
                <hr
                  className="horizontal"
                  style={{ position: "absolute", top: "207px", color: "black" }}
                />
                <Typography
                  className="city-and-link-data"
                  variant="body2"
                  component={"div"}
                  color="text.secondary"
                  sx={{
                    fontWeight: 500,
                    width: "calc(100% + 0px)",
                    fontSize: "16px",
                    position: "absolute",
                    top: "221px",
                    color: "#222222",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "5px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "calc(100% + 0px)",
                      marginLeft: "0px",
                    }}
                  >
                    {offer?.brand !== null
                      ? options["az"].auto_type_options.find(
                          (el) => el.value === offer.brand.toString()
                        )?.label
                      : ""}
                    {offer?.model !== null && offer?.brand === null
                      ? "Auto"
                      : ""}
                    {offer?.model !== null
                      ? ", " +
                        options["az"].auto_model_options.find(
                          (el) => el.value === offer.model.toString()
                        )?.label
                      : ""}
                  </div>
                </Typography>
                <Typography
                  className="room-area-and-floor-data"
                  variant="body2"
                  component={"div"}
                  color="text.secondary"
                  sx={{
                    fontWeight: 500,
                    fontSize: "14px",
                    position: "absolute",
                    top: "255px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    width: "100%",
                    maxWidth: "calc(100% - 20px)",
                    color: "#000",
                  }}
                >
                  {offer?.year && offer?.year}
                  {offer?.year && offer?.enginel && (
                    <hr className="vertical dot-vertical" />
                  )}
                  {offer?.enginel !== null && (
                    <>
                      {offer?.enginel} {options[language].l}
                    </>
                  )}
                  {offer?.mileage !== null && (
                    <hr className="vertical dot-vertical" />
                  )}
                  {offer?.mileage !== null && (
                    <>
                      {offer?.mileage?.toLocaleString("ru")}{" "}
                      {options[language].km}
                    </>
                  )}
                </Typography>
                <hr
                  className="horizontal"
                  style={{ position: "absolute", top: "245px", color: "black" }}
                />
              </CardContent>
            </Link>
            <Typography
              className="city-and-link-data"
              variant="body2"
              component={"div"}
              color="text.secondary"
              sx={{
                fontWeight: 500,
                fontSize: "14px",
                position: "absolute",
                top: "-20px",
                color: "black",
                marginLeft: "10px",
              }}
            >
              <Link
                to={{
                  pathname:
                    window.location.pathname === "/" ||
                    window.location.pathname.includes("/elan/")
                      ? `/elan/${offer?.type ? offer?.type : offerType}/${
                          offer?.id
                        }`
                      : `/secilmis-elanlar/${
                          offer?.type ? offer?.type : offerType
                        }/${offer?.id}`,
                }}
                onClick={() => clickOnLink()}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "207px",
                    right: "0",
                    color: "#888888",
                  }}
                >
                  {offer?.post_date
                    ? `${
                        new Date(offer?.post_date).getDate() ===
                          new Date().getDate() &&
                        new Date(offer?.post_date).getMonth() ===
                          new Date().getMonth() &&
                        new Date(offer?.post_date).getFullYear() ===
                          new Date().getFullYear()
                          ? `${new Date(new Date(offer?.post_date).setHours(new Date(offer?.post_date).getHours() - 2))
                              .toLocaleString('ru-RU', {
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                              })
                              .replace("24:", "00:")}`
                          : new Date(offer?.post_date).getDate() ===
                              new Date().getDate() - 1 &&
                            new Date(offer?.post_date).getMonth() ===
                              new Date().getMonth() &&
                            new Date(offer?.post_date).getFullYear() ===
                              new Date().getFullYear()
                          ? `${options[language].yesterdaySmall}`
                          : new Date(offer?.post_date)
                              .toLocaleString("ru-Ru", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "2-digit",
                              })
                              .replace(/20\d{2}/, /\d{2}/)
                      }`
                    : ""}
                </span>
              </Link>
              <Typography
                className="city-and-link-data-url-part"
                variant="body2"
                component={"div"}
                color="text.secondary"
                sx={{
                  fontWeight: 500,
                  fontSize: "14px",
                  position: "absolute",
                  top: `176px`,
                  color: "white",
                  justifyContent: "flex-start",
                  right: "-10px",
                }}
              >
                {changed && offer?.url !== null ? (
                  <a
                    style={{ zIndex: "5" }}
                    href={"https://tap.az" + offer?.url}
                    target="_blank"
                    rel="nofollow"
                    className="offer-url"
                  >
                    {offer?.url?.split("/")[2]}
                  </a>
                ) : offer?.url !== null ? (
                  <a
                    style={{ zIndex: "5" }}
                    href={offer?.url}
                    rel="nofollow"
                    className="offer-url"
                    target="_blank"
                  >
                    {offer?.url?.split("/")[2]}
                  </a>
                ) : (
                  <></>
                )}
                {offer?.url === null && (
                  <a
                    style={{ zIndex: "5", textDecoration: "underline" }}
                    href={`https://ebaz.az/elan/${
                      offer?.type ? offer?.type : offerType
                    }/${offer?.id}`}
                    target="_blank"
                    className="offer-url"
                  >
                    ebaz.az
                  </a>
                )}
              </Typography>
            </Typography>
          </div>
        </Card>
      )}
    </>
  );
};

export default OfferCardAuto;
