import { options } from "../constants";
const initialState = {
  filter:
    options[
      localStorage.getItem("language") ? localStorage.getItem("language") : "az"
    ].filter_options[0],
  token: "",
  language: localStorage.getItem("language")
    ? localStorage.getItem("language")
    : "az",
  defaultOffersCount: "",
  defaultOffers: [],
  initialOffersCar: [],
  initialOffersFlat: [],
  initialOffersCountry: [],
  initialOffersOffice: [],
  initialOffersGarage: [],
  initialOffersLand: [],
  initialOffersObyekt: [],
  defaultSimilarOffers: [],
  loadingUpdateProfilInfo: false,
  updateProfilInfoSuccess: "",
  defaultOffersWithLocations: [],
  myAllOffers: [],
  blogs: [],
  blog: [],
  countOfBlogPages: 1,
  blogsCount: 0,
  stationNames: [],
  loadingClickOnMakePremiumButton: false,
  loadingClickOnMakeBoostButton: false,
  page: 1,
  loadingUploadOffer: false,
  countOfPages: 1,
  siteRoad: "",
  search: true,
  searchRow: "/",
  lastSearchUrl: "/",
  offerType: window?.location?.search?.includes(["", "?ru", "?en", "?az"])
    ? "get"
    : "",
  flatType: {},
  autoType: "masin",
  initialPage: true,
  center: { lat: 40.3953, lng: 49.8822 }, //lat: 40.38015003228348, lng: 49.88494431481932
  zoom: 8,
  offersFoundOrNot: true,
  showOrCloseMap: false,
  offerDetail: [],
  userInfo: [],
  latLng: "",
  checkPhoneNum: false,
  isPasswordCorrect: true,
  isUserExist: true,
  isMobile: false,
  isMobilenumUsed: false,
  offerTypeForUpdate: "",
  showOrCloseModal: false,
  shouldRunSecondEffect: true,
  showMobileFilter: false,
  showMorebar: false,
  countOfOffers: "",
  openOTP: false,
  isOTPok: true,
  timer: 0,
  loadingOfCount: false,
  searchByUrlPopUp: false,
  searchByUrlData: [],
  initialOffersVips: [],
  loadingSearchByUrlData: false,
  boundsObject: {
    south: 0, //yuq
    west: 0, //zapad
    north: 0, //sever
    east: 0, //vostok
  },
  showSuccessUploadOfferPopUp: false,
  uploadedOffer: [],
  isFirstOfferOfUser: false,
  adsType: localStorage.getItem("adsType") ?? "homes",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_ADS_TYPES":
      return {
        ...state,
        adsType: action.payload.value,
      };
    case "SET_BLOGS":
      return {
        ...state,
        blogs: action.payload.value,
      };
    case "SET_BLOG":
      return {
        ...state,
        blog: action.payload.value,
      };
    case "SET_BLOGS_PAGE_COUNT":
      return {
        ...state,
        countOfBlogPages: action.payload.value,
      };
    case "SET_BLOGS_COUNT":
      return {
        ...state,
        blogsCount: action.payload.value,
      };
    case "SHOW_SUCCESS_UPLOAD_OFFER_POPUP":
      return {
        ...state,
        showSuccessUploadOfferPopUp: action.payload.value,
      };
    case "UPLOADED_OFFER":
      return {
        ...state,
        uploadedOffer: action.payload.value,
      };
    case "IS_FIRST_OFFER_OF_USER":
      return {
        ...state,
        isFirstOfferOfUser: action.payload.value,
      };
    case "ADD_TOKEN":
      return {
        ...state,
        token: action.payload.value,
      };
    case "SET_IS_MOBILE":
      return {
        ...state,
        isMobile: action.payload.value,
      };
    case "CHANGE_SITE_LANGUAGE":
      return {
        ...state,
        language: action.payload.value,
      };
    case "LOADING_SEARCH_BY_URL":
      return {
        ...state,
        loadingSearchByUrlData: action.payload.value,
      };
    case "CHANGE_FLAT_TYPE":
      return {
        ...state,
        flatType: action.payload.value,
      };
    case "CHANGE_AUTO_TYPE":
      return {
        ...state,
        autoType: action.payload.value,
      };

    case "LOADING_UPDATE_PROFIL_INFO":
      return {
        ...state,
        loadingUpdateProfilInfo: action.payload.value,
      };
    case "UPDATE_PROFIL_INFO_SUCCESS":
      return {
        ...state,
        updateProfilInfoSuccess: action.payload.value,
      };
    case "LOADING_UPLOAD_OFFER":
      return {
        ...state,
        loadingUploadOffer: action.payload.value,
      };
    case "GET_INITIAL_OFFERS":
      return {
        ...state,
        initialOffersVips: action.payload.value,
      };
    case "SET_TIMER":
      return {
        ...state,
        timer: action.payload.value,
      };
    case "SET_BY_URL_DATA":
      return {
        ...state,
        searchByUrlData: action.payload.value,
      };
    case "SET_OPEN_SEARCH_BY_URL_POPUP":
      return {
        ...state,
        searchByUrlPopUp: action.payload.value,
      };
    case "SET_LOADING_PREMIUM_BUTTON":
      return {
        ...state,
        loadingClickOnMakePremiumButton: action.payload.value,
      };
    case "SET_LOADING_BOOST_BUTTON":
      return {
        ...state,
        loadingClickOnMakeBoostButton: action.payload.value,
      };
    case "SET_SHOW_MOBILE_FILTER":
      return {
        ...state,
        showMobileFilter: action.payload.value,
      };
    case "SET_SHOW_MOREBAR":
      return {
        ...state,
        showMorebar: action.payload.value,
      };
    case "SET_IS_OTP_CORRECT":
      return {
        ...state,
        isOTPok: action.payload.value,
      };
    case "SET_OPEN_OTP_PART":
      return {
        ...state,
        openOTP: action.payload.value,
      };
    case "SET_LOADING_OF_COUNT":
      return {
        ...state,
        loadingOfCount: action.payload.value,
      };
    case "SET_COUNT_OF_OFFERS":
      return {
        ...state,
        countOfOffers: action.payload.value,
      };
    case "LOGIN":
      return {
        ...state,
        userInfo: action.payload.value,
      };
    case "SET_SHOULD_RUN_SECOND_EFFECT":
      return {
        ...state,
        shouldRunSecondEffect: action.payload.value,
      };
    case "SHOW_OR_CLOSE_MODAL":
      return {
        ...state,
        showOrCloseModal: action.payload.value,
      };
    case "CHANGE_FILTER":
      return {
        ...state,
        filter: action.payload.value,
      };
    case "CHANGE_SEARCH_ROW":
      return {
        ...state,
        searchRow: action.payload.value,
      };
    case "SAVE_LAST_SEARCH_URL":
      return {
        ...state,
        lastSearchUrl: action.payload.value,
      };
    case "CHANGE_BOUNDS":
      return {
        ...state,
        boundsObject: action.payload.value,
      };
    case "IS_PASSWORD_CORRECT":
      return {
        ...state,
        isPasswordCorrect: action.payload.value,
      };
    case "IS_USER_EXIST":
      return {
        ...state,
        isUserExist: action.payload.value,
      };
    case "IS_MOBILENUMBER_USED":
      return {
        ...state,
        isMobilenumUsed: action.payload.value,
      };
    case "OFFER_TYPE_FOR_UPDATE":
      return {
        ...state,
        offerTypeForUpdate: action.payload.value,
      };

    case "DELETE_TOKEN":
      localStorage.removeItem("token");
      return {
        ...state,
        token: action.payload.value,
      };

    case "GET_COUNT_OF_PAGES":
      return {
        ...state,
        countOfPages: action.payload.value,
      };
    case "SET_LAT_LNG":
      return {
        ...state,
        latLng: action.payload.value,
      };
    case "GET_PAGE":
      return {
        ...state,
        page: action.payload.value,
      };

    case "SET_SEARCH_TRUE":
      return {
        ...state,
        search: action.payload.value,
      };

    case "SHOW_OR_CLOSE_MAP":
      return {
        ...state,
        showOrCloseMap: action.payload.value,
      };

    case "GET_DEFAULT_OFFERS":
      return {
        ...state,
        defaultOffers: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_VIPS":
      return {
        ...state,
        initialOffersVips: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_CAR":
      return {
        ...state,
        initialOffersCar: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_FLAT":
      return {
        ...state,
        initialOffersFlat: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_COUNTRY":
      return {
        ...state,
        initialOffersCountry: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_OBYEKT":
      return {
        ...state,
        initialOffersObyekt: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_GARAGE":
      return {
        ...state,
        initialOffersGarage: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_OFFICE":
      return {
        ...state,
        initialOffersOffice: action.payload.value,
      };
    case "GET_INITIAL_OFFERS_LAND":
      return {
        ...state,
        initialOffersLand: action.payload.value,
      };
    case "GET_DEFAULT_SIMILAR_OFFERS":
      return {
        ...state,
        defaultSimilarOffers: action.payload.value,
      };

    case "GET_DEFAULT_OFFERS_WITH_LOCATIONS":
      return {
        ...state,
        defaultOffersWithLocations: action.payload.value,
      };

    case "OFFERS_FOUND_OR_NOT":
      return {
        ...state,
        offersFoundOrNot: action.payload.value,
      };
    case "GET_DEFAULT_OFFERS_COUNT":
      return {
        ...state,
        defaultOffersCount: action.payload.value,
      };

    case "CHANGE_OFFER_TYPE":
      return {
        ...state,
        offerType: action.payload.value,
      };

    case "GET_STATION_NAMES":
      return {
        ...state,
        stationNames: action.payload.value,
      };

    case "GET_MY_OFFERS":
      return {
        ...state,
        myAllOffers: action.payload.value,
      };

    case "GET_BY_ID":
      return {
        ...state,
        offerDetail: action.payload.value,
      };

    case "GET_SITE_ROAD":
      return {
        ...state,
        siteRoad: action.payload.value,
      };

    case "SET_CENTER":
      return {
        ...state,
        center: action.payload.value,
      };

    case "SET_ZOOM":
      return {
        ...state,
        zoom: action.payload.value,
      };

    default:
      return state;
  }
}
