import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";

const ChangeHeadTags = () => {
  const language = useSelector((state) => state.language);
  const tags = [
    {
      az: {
        title: "ebaz.az - Butun emlak elanlari bir saytda. Alqi-satqi, kiraye",
        description:
          "Butun ev elanlari bir saytda - ebaz.az. Daşinmaz emlak, alqi-satqi, kiraye menzil, obyekt, torpaq, villa, ofis.",
        keywords:
          "ev alqi satqisi,аренда квартир в баку,kiraye ev,binam az,ofis icare,arenda obyektler,kiraye ev yasamal,kiraye obyektler,kiraye villa,en son emlaklar,yeni ev elanlari,kiraye ev elanlari,dasinmaz emlak,yeniemlak,menzil,en son kiraye,ev elanlari,tap az kiraye ev",
      },
    },
    {
      en: {
        title:
          "ebaz.az - All property listings on one site. Purchase, sale, rent",
        description:
          "All house listing are on one site - ebaz.az. Real estate, buying and selling, apartment for rent, object, land, villa, office.",
        keywords:
          "house purchase and sale, apartment for rent in Baku, house for rent, building az, office for rent, objects for rent, house construction for rent, objects for rent, villa for rent, latest real estate, new house ads, house for rent ads, real estate, new house, apartment, latest rent, house ads, find low rent house",
      },
    },
    {
      ru: {
        title:
          "ebaz.az - Все объявления недвижимости на одном сайте. Покупка, продажа, аренда",
        description:
          "Все объявления о продаже домов находятся на одном сайте - ebaz.az. Недвижимость, покупка и продажа, аренда квартир, объектов, земельных участков, вилл, офисов.",
        keywords:
          "покупка и продажа дома, квартира в аренду в Баку, дом в аренду, binam AZ, офис в аренду, объекты в аренду, домостроение в аренду, объекты в аренду, вилла в аренду, новейшая недвижимость, объявления о новых домах, дом в аренду объявления, недвижимость, новый дом, квартира, последняя арендная плата, объявления о доме, найти недорогой дом",
      },
    },
  ];
  useLayoutEffect(() => {
    let index =
      language === "az" ? 0 : language === "en" ? 1 : language === "ru" ? 2 : 0;
    let ogTitle = document.getElementById("ogTitle");
    let ogDescription = document.getElementById("ogDescription");
    ogTitle.setAttribute(
      "content",
      tags[index][language]["title"]
    );
    ogDescription.setAttribute(
      "content",
      `${tags[index][language]["description"]}`
    );
    document.title = tags[index][language]?.title;

    let metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        tags[index][language]?.description
      );
    } else {
      metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = tags[index][language]?.description;
      document.head.appendChild(metaDescription);
    }

    let metaKeywords = document.querySelector('meta[name="keywords"]');
    if (metaKeywords) {
      metaKeywords.setAttribute("content", tags[index][language]?.keywords);
    } else {
      metaKeywords = document.createElement("meta");
      metaKeywords.name = "keywords";
      metaKeywords.content = tags[index][language]?.keywords;
      document.head.appendChild(metaKeywords);
    }
  }, []);
};

export default ChangeHeadTags;
