export const options = {
  az: {
    allFavouriteOffersTitle: "Ebaz.az - Butun secilmis elanlar",
    allPremiumOffersTitle: "Ebaz.az - Butun premium elanlar",
    loginTitle: "Ebaz.az - Daxil ol",
    registrationTitle: "Ebaz.az - Qeydiyyat",
    myOffersTitle: "Ebaz.az - Profil",
    newOffersTitle: "Ebaz.az - Yeni elan",
    notFoundTitle: "Ebaz.az - Xeta 404",
    paymentErrorTitle: "Ebaz.az - Ugursuz emeliyyat!",
    paymentSuccessTitle: "Ebaz.az - Ugurlu emeliyyat!",
    newOfferTitle: "Ebaz.az - Elani yenile",
    findOffer: "Elanı tap",
    favourites: "Seçilmişlər",
    profile: "Profil",
    signIn: "Giriş",
    uploadOffer: "Elan yerləşdir",
    sale: "Alış",
    rent: "Kirayə",
    day: "Günlük",
    clean: "Təmizlə",
    add: "Əlavə et",
    cityName: "Şəhər",
    flatTypeName: "Əmlakın növü",
    autoTypeName: "Marka",
    autoModelName: "Model",
    autoModelNameFirst: "İlk olaraq markanı qeyd edin",
    autoBodyType: "Ban növü",
    autoFuelType: "Yanacaq növü",
    autoTransmissionType: "Sürətlər qutusu",
    autoColorName: "Rəng",
    autoEngineVolume:"Həcm (sm",
    autoHorsePower:"Güc (a. g.)",
    autoDriveType: "Ötürücü",
    roomsCountName: "Otaq sayı",
    priceName: "Qiymət:",
    yearName: "İl:",
    mileageName: "Yürüş (km):",
    pricePerM2Name: "Kvadratmetr qiyməti:  AZN/m2",
    pricePerSotName: "Sot qiyməti:  AZN/sot",
    min: "min.",
    max: "maks.",
    areaName: "Sahə",
    sot: "sot",
    m2: "m²",
    l: "L",
    km:"km",
    regionName: "Rayon",
    residentName: "Qəsəbə",
    stationName: "Metro",
    targetName: "Nişangah",
    floorName: "Mərtəbə:",
    siteName: "Saytlar",
    siteNameEnter: "Elan saytlarını seçin",
    withGoodsName: "Əşyalı",
    withoutGoodsName: "Əşyasız",
    repairName: "Təmirli",
    withoutRepairName: "Təmirsiz",
    withDocumentsName: "Sənədli",
    withoutDocumentsName: "Sənədsiz",
    mortgageName: "İpoteka",
    showOffersName: "Elan göstər",
    searchName: "Axtar",
    historyName: "Əvvəlki axtarışlar",
    additionalsearchName: "Ətraflı axtarış",
    lastFiveSearchName: "Son 5 axtarış",
    allFloorNumberName: "Ümumi mərtəbə sayı",
    sale2: "Satış",
    clean2: "Sıfırla",
    sloqan: "Bütün əmlaklar bir ünvanda!",
    usersAgree: "İstifadəçi razılaşması",
    privicy: "Məxfilik siyasəti",
    services: "Xidmətlər",
    connect: "Əlaqə",
    analytics: "Analitika",
    predicted: "Gözlənilən",
    predictedHigh: "artım",
    predictedLow: "azalma",
    disclaimer:
      "*Saytın rəhbərliyi reklam bannerlərinin və yerləşdirilmiş elanların məzmununa görə məsuliyyət daşımır.",
    enterAddress: "Ünvanı qeyd edin",
    showAll: "Hamısını göstər",
    premiumOffers: "Premium Elanlar",
    saleCars: "Alqı-satqı maşınlar",
    saleFlats: "Alqı-satqı mənzillər",
    rentFlats: "Kirayə mənzillər",
    saleCountry: "Alqı-satqı həyat evi/bağ/villa",
    rentCountry: "Kirayə həyat evi/bağ/villa",
    saleOffice: "Alqı-satqı ofis",
    rentOffice: "Kirayə ofis",
    saleGarage: "Alqı-satqı qaraj",
    rentGarage: "Kirayə qaraj",
    login: "Daxil ol",
    saleLand: "Alqı-satqı torpaq",
    rentLand: "Kirayə torpaq",
    saleCommercial: "Alqı-satqı obyekt",
    rentCommercial: "Kirayə obyekt",
    searchOffersLoading: "Elan Axtarılır",
    logout: "Çıxış",
    offersCommon: "Elanlar",
    myOffersName: "Elanlarim",
    myOffersActiveName: "Aktiv",
    myOffersDeactiveName: "Deaktiv olundu",
    myOffersReductName: "Redaktə et",
    myOffersDeleteFromOffersName: "Elanlardan çıxart",
    myOffersRefreshOfferName: "Yenidən paylaş",
    myOffersDontHaveOffersName: "Sizin elanınız yoxdur.",
    myOffersDontHaveActiveOffersName: "Sizin aktiv elanınız yoxdur.",
    myOffersDontHaveDeactiveOffersName: "Sizin deaktiv elanınız yoxdur.",
    myOffersNameSurnameChangeName: "Ad Soyad Dəyişmək",
    myOffersNameChangeName: "Ad",
    myOffersLastnameChangeName: "Soyad",
    myOffersNameChangeRequareName: "*Zəhmət olmasa adınızı qeyd edin",
    myOffersLastnameChangeRequareName: "*Zəhmət olmasa soyadınızı qeyd edin",
    myOffersSetNameChangeName: "Adınızı qeyd edin",
    myOffersSetLastnameChangeName: "Soyadınızı qeyd edin",
    myOffersNameLastnameChangedSuccessfullyName: "Uğurla yeniləndi",
    myOffersNameLastnameChangeButtonName: "Dəyiş",
    residentsSmall: " qəs.",
    WithRoomsSmall: " otaqlı ",
    floorSmall: "mərtəbə",
    todaySmall: "Bugün",
    yesterdaySmall: "Dünən",
    searchSmall: "Axtarış",
    newOfferSmall: "Yeni elan",
    cabinetSmall: "Kabinet",
    moreSmall: "Daha çox",
    menuSmall: "Menyu",
    languageSmall: "Dil",
    languageBig: "Azərbaycan",
    languageAZ: "Azərbaycan",
    languageRU: "Русский",
    languageEN: "English",
    yesSmall: "Bəli",
    noSmall: "Xeyir",
    aboutOffer: "Əmlak haqqında",
    urgentSmall: "Təcili",
    offersFilterSloqan: "Şəhər, Əmlak, Qiymət, Sahə, Otaq...",
    offersFound: "Elan",
    listSmall: "Siyahı",
    mapSmall: "Xəritə",
    mapSmallShow: "Xəritəni göstər",
    mapSmallDontShow: "Xəritəni gizlət",
    notFoundOffers: "Təəssüf ki, axtarışa uyğun elan tapılmadı.",
    writeLinkForSearch: "Axtarmaq istdiyiniz elanın linkini yazın",
    supportedSitesSearch:
      "Dəstəklənən saytlar: tap.az, bina.az, lalafo.az, arenda.az, emlak.az, yeniemlak.az, rahatemlak.az, ucuztap.az",
    showOfferSearch: "Elanı göstər",
    favouriteOffersCount: "Seçilmiş elanlar",
    notFavouritesOffers: "Seçilmiş elan yoxdur.",
    seeAllOffers: "Bütün elanlara bax",
    phoneNumberName: "Mobil nömrə",
    writePhoneNumber: "*Zəhmət olmasa nömrənizi qeyd edin",
    sendSMStoPhone: "Sizin nömrənizə SMS-kod göndərildi",
    sendRepeatSMStoPhone: "SMS-kod yenidən göndərilsin",
    pleaseWriteSMSCode: "*Zəhmət olmasa SMS-kodu qeyd edin",
    pleaseWriteSMSCodeCorrect: "*Zəhmət olmasa SMS-kodu düzgün qeyd edin",
    saveUser: "Yadda saxla",
    userNotFound: "*İstifadəçi tapılmadı",
    loginWithBigI: "DAXİL OL",
    notProfileRegistration: "Hesabınız yoxdur? Qeydiyyatdan keç",
    profileExistLogin: "Hesabınız var? Daxil ol",
    registration: "Qeydiyyatdan",
    setEmail: "Email Adres",
    userAlreadyExist: "*Bu istifadəçi artıq qeydiyyatdan kecib",
    doRegistration: "Qeydİyyatdan keç",
    newOffer_flatType: "Əmlakın növü, ",
    newOffer_offerType: "Elanın tipi, ",
    newOffer_rentPeriod: "Kirayə müddəti, ",
    newOffer_AreaM2: "Sahə m², ",
    newOffer_AreaSot: "Sahə sot, ",
    newOffer_City: "Şəhər, ",
    newOffer_Price: "Qiymət, ",
    newOffer_Desc: "Məzmun, ",
    newOffer_Images: "Şəkillər (minimum 1 şəkil, maksimum 10 şəkil), ",
    newOffer_Name: "Ad, ",
    newOffer_Email: "E-mail, ",
    newOffer_PhoneNum: "Mobil nömrəni düzgün qeyd edin, ",
    videoSmall: "Video",
    addVideoSmall: "+Video əlavə et",
    watchVideo: "Video izlə",
    imagesSmall: "Şəkillər",
    addImagesSmall: "+Şəkil əlavə et",
    addImagesMaxCountSmall: "*Maksimum 10 şəkil",
    writeInfoInThisFields: "bu xanalarda məlumat yazın.",
    sendOffer: "Elanı göndər",
    simpleRules: "Ebaz.az-ın sadə qaydaları",
    simpleRules1: "Eyni elanı bir neçə dəfə təqdim etməyin.",
    simpleRules2:
      "Təsvir və ya şəkillərdə telefon, email və ya sayt ünvanı göstərməyin.",
    simpleRules3: "Ad yerində qiymət yazmayın - bunun üçün ayrıca yer var.",
    simpleRules4:
      'İlk elanınızı yerləşdirin və 2 gün ərzində bizdən "Çərçivələmə" və "İrəli çəkmə" qazanın.',
    offerView: "Elanın görünüşü",
    pageNotFoundSmall: "Səhifə tapılmadı",
    mainPageSmall: "Ana səhifə.",
    boostNameSmall: "Fərqləndir",
    makePremiumNameSmall: "Premium Et",
    deleteFromFavouritesNameSmall: "Seçilmişlərdən çıxart",
    positionOfOffer: "Yerləşmə yeri",
    addToFavouritesNameSmall: "Seçilmişlərdə saxla",
    priceByAgreement: "Razılaşma yolu ilə",
    linkCopied: "Link kopyalandı",
    similarOffers: "Bənzər elanlar",
    boostOffer: "İrəli çək",
    frameOffer: "Çərçivələ",
    fromPrice1: "0.49 AZN-dən",
    fromPrice2: "0.99 AZN-dən",
    fromPrice3: "1.99 AZN-dən",
    boostOfferDescription:
      "Elan, bütün elanların içində birinci yerə qalxacaq.",
    frameOfferDescription:
      "Elan göy çərçəvə ilə fərqlənəcək və bütün elanların içində birinci yerə qalxacaq.",
    urgentOfferDescription:
      "Elan qırmızı çərçəvə və “Təcili” etiketi ilə birbaşa fərqlənəcək və bütün elanların içində birinci yerə qalxacaq.",
    paidUntill: "Tarixinə qədər ödənilib.",
    serviceTime: "Xidmət müddəti",
    times: " dəfə",
    boostFor24Hours: "(Hər 24 saatda irəli çək)",
    boostName: "(+ İrəli çək)",
    paymentMethod: "Ödəniş üsulu",
    bankCard: "Bank kartı",
    addressSmall: "Ünvan",
    cancelPayment: "İmtina et",
    makePayment: "Ödəniş et",
    paymentAgreement:
      '"Ödəniş et" düyməsini sıxmaqla siz ebaz.az-ın İstifadəçi razılaşmasını və Ofertanı qəbul etmiş olursunuz.',
    makeOfferPremiumName: "Elanı Premium et",
    makePremiumDescription:
      "Elan, xidmət müddəti boyunca əsas səhifədə premium elanlar bölməsində göstəriləcək və pulsuz irəli çəkiləcək.",
    days: " gün",
    perDays: "/gün",
    errorName: "Xəta",
    errorDescription:
      "Ödənişinizdə xəta yarandı, zəhmət olmasa yenidən cəhd edin.",
    errorDescription2: `Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.`,
    successName: "Uğurlu Ödəniş",
    successDescription:
      "Ödənişiniz uğurla qeydə alındı, tez bir zamanda elanınız yenilənəcək.",
    successName2: "Uğurlu",
    successDescription2UpdateOffer: "Elan uğurla yeniləndi.",
    successDescription2DeleteOffer: "Elan silindi.",
    successDescription2RefreshOffer: "Elan uğurla yenidən paylaşıldı.",
    successDescription2RegistrationOffer: "Siz uğurla qeydiyyatdan keçdiniz.",
    successGoToOffers: "Elanlarınıza keçid edin.",
    updateOfferName: "Elanı yeniləmək",
    allRightsReserved: "Bütün hüquqlar qorunur.",
    siteMap: "Saytın xəritəsi",
    notUploadedOffer: "Elan yuklənməmişdir.",
    uploadedOffer: "Elanınız paylaşıldı!",
    showToMorePeople: "Elanınızı daha çox insan görsün!",
    miniBannerWords: [
      'İlk elanınızı yerləşdirin və 2 gün ərzində bizdən "Çərçivələmə" və "İrəli çəkmə" qazanın.',
    ],
    firstOfferWord:
      'İlk elanınız olduğu üçün bizdən sizə 2 gün ərzində "Çərçivələmə" və "İrəli çəkmə" hədiyyə olundu!',
    saleDeleted: "Satışdan çıxarılıb",
    rentDeleted: "Kirayədən çıxarılıb",
    cities: [
      {
        value: "0",
        label: "Ağcabədi",
        center: { lat: 40.053, lng: 47.4554 },
        zoom: "10",
      },
      {
        value: "1",
        label: "Ağdam",
        center: { lat: 39.9911, lng: 46.9297 },
        zoom: "10",
      },
      {
        value: "2",
        label: "Ağdaş",
        center: { lat: 40.6335, lng: 47.4674 },
        zoom: "10",
      },
      {
        value: "3",
        label: "Ağdərə",
        center: { lat: 40.2116, lng: 46.8237 },
        zoom: "10",
      },
      {
        value: "4",
        label: "Ağstafa",
        center: { lat: 41.1125, lng: 45.4477 },
        zoom: "10",
      },
      {
        value: "5",
        label: "Ağsu",
        center: { lat: 40.5283, lng: 48.3651 },
        zoom: "10",
      },
      {
        value: "6",
        label: "Astara",
        center: { lat: 38.4688, lng: 48.8728 },
        zoom: "10",
      },
      {
        value: "7",
        label: "Babək",
        center: { lat: 39.1508, lng: 45.4485 },
        zoom: "10",
      },
      {
        value: "8",
        label: "Bakı",
        center: { lat: 40.3953, lng: 49.8822 },
        zoom: "10",
      },
      {
        value: "9",
        label: "Balakən",
        center: { lat: 41.7038, lng: 46.4044 },
        zoom: "10",
      },
      {
        value: "10",
        label: "Beyləqan",
        center: { lat: 39.7723, lng: 47.6154 },
        zoom: "10",
      },
      {
        value: "11",
        label: "Bərdə",
        center: { lat: 40.3707, lng: 47.1379 },
        zoom: "10",
      },
      {
        value: "12",
        label: "Biləsuvar",
        center: { lat: 39.4599, lng: 48.551 },
        zoom: "10",
      },
      {
        value: "13",
        label: "Cəbrayıl",
        center: { lat: 39.3987, lng: 47.0245 },
        zoom: "10",
      },
      {
        value: "14",
        label: "Cəlilabad",
        center: { lat: 39.2052, lng: 48.5101 },
        zoom: "10",
      },
      {
        value: "15",
        label: "Culfa",
        center: { lat: 38.9605, lng: 45.6293 },
        zoom: "10",
      },
      {
        value: "16",
        label: "Daşkəsən",
        center: { lat: 40.5202, lng: 46.0779 },
        zoom: "10",
      },
      {
        value: "17",
        label: "Dəliməmmədli",
        center: { lat: 40.6844, lng: 46.5728 },
        zoom: "10",
      },
      {
        value: "18",
        label: "Füzuli",
        center: { lat: 39.6025, lng: 47.1392 },
        zoom: "10",
      },
      {
        value: "19",
        label: "Gədəbəy",
        center: { lat: 40.57, lng: 45.8107 },
        zoom: "10",
      },
      {
        value: "20",
        label: "Gəncə",
        center: { lat: 40.6579, lng: 46.3523 },
        zoom: "10",
      },
      {
        value: "21",
        label: "Goranboy",
        center: { lat: 40.6073, lng: 46.7807 },
        zoom: "10",
      },
      {
        value: "22",
        label: "Göyçay",
        center: { lat: 40.6236, lng: 47.7403 },
        zoom: "10",
      },
      {
        value: "23",
        label: "Göygöl",
        center: { lat: 40.5895, lng: 46.3271 },
        zoom: "10",
      },
      {
        value: "24",
        label: "Göytəpə",
        center: { lat: 39.1171, lng: 48.5844 },
        zoom: "10",
      },
      {
        value: "25",
        label: "Hacıqabul",
        center: { lat: 40.0394, lng: 48.9203 },
        zoom: "10",
      },
      {
        value: "26",
        label: "Horadiz",
        center: { lat: 39.4511, lng: 47.3399 },
        zoom: "10",
      },
      {
        value: "27",
        label: "Xaçmaz",
        center: { lat: 41.4591, lng: 48.8021 },
        zoom: "10",
      },
      {
        value: "28",
        label: "Xankəndi",
        center: { lat: 39.8265, lng: 46.7656 },
        zoom: "10",
      },
      {
        value: "29",
        label: "Xocalı",
        center: { lat: 39.9133, lng: 46.7943 },
        zoom: "10",
      },
      {
        value: "30",
        label: "Xocavənd",
        center: { lat: 39.7915, lng: 47.1101 },
        zoom: "10",
      },
      {
        value: "31",
        label: "Xızı",
        center: { lat: 40.9109, lng: 49.0729 },
        zoom: "10",
      },
      {
        value: "32",
        label: "Xırdalan",
        center: { lat: 40.4529, lng: 49.7333 },
        zoom: "10",
      },
      {
        value: "33",
        label: "Xudat",
        center: { lat: 41.6253, lng: 48.6764 },
        zoom: "10",
      },
      {
        value: "34",
        label: "İmişli",
        center: { lat: 39.8695, lng: 48.0665 },
        zoom: "10",
      },
      {
        value: "35",
        label: "İsmayıllı",
        center: { lat: 40.7871, lng: 48.1523 },
        zoom: "10",
      },
      {
        value: "36",
        label: "Kəlbəcər",
        center: { lat: 40.1024, lng: 46.0365 },
        zoom: "10",
      },
      {
        value: "37",
        label: "Kürdəmir",
        center: { lat: 40.3699, lng: 48.1645 },
        zoom: "10",
      },
      {
        value: "38",
        label: "Kəngərli",
        center: { lat: 39.3872, lng: 45.164 },
        zoom: "10",
      },
      {
        value: "39",
        label: "Qax",
        center: { lat: 41.4207, lng: 46.932 },
        zoom: "10",
      },
      {
        value: "40",
        label: "Qazax",
        center: { lat: 41.0971, lng: 45.3516 },
        zoom: "10",
      },
      {
        value: "41",
        label: "Qəbələ",
        center: { lat: 40.9982, lng: 47.87 },
        zoom: "10",
      },
      {
        value: "42",
        label: "Qobustan",
        center: { lat: 40.5326, lng: 48.9274 },
        zoom: "10",
      },
      {
        value: "43",
        label: "Qovlar",
        center: { lat: 40.9364, lng: 45.7475 },
        zoom: "10",
      },
      {
        value: "44",
        label: "Quba",
        center: { lat: 41.3643, lng: 48.5261 },
        zoom: "10",
      },
      {
        value: "45",
        label: "Qubadlı",
        center: { lat: 39.3453, lng: 46.5789 },
        zoom: "10",
      },
      {
        value: "46",
        label: "Qusar",
        center: { lat: 41.4275, lng: 48.4302 },
        zoom: "10",
      },
      {
        value: "47",
        label: "Laçın",
        center: { lat: 39.6383, lng: 46.5461 },
        zoom: "10",
      },
      {
        value: "48",
        label: "Lerik",
        center: { lat: 38.7736, lng: 48.4151 },
        zoom: "10",
      },
      {
        value: "49",
        label: "Lənkəran",
        center: { lat: 38.7529, lng: 48.8475 },
        zoom: "10",
      },
      {
        value: "50",
        label: "Liman",
        center: { lat: 41.312, lng: 72.9278 },
        zoom: "10",
      },
      {
        value: "51",
        label: "Masallı",
        center: { lat: 39.0341, lng: 48.6589 },
        zoom: "10",
      },
      {
        value: "52",
        label: "Mingəçevir",
        center: { lat: 40.7703, lng: 47.0496 },
        zoom: "10",
      },
      {
        value: "53",
        label: "Naftalan",
        center: { lat: 40.5076, lng: 46.824 },
        zoom: "10",
      },
      {
        value: "54",
        label: "Naxçıvan",
        center: { lat: 39.2009, lng: 45.4082 },
        zoom: "10",
      },
      {
        value: "55",
        label: "Neftçala",
        center: { lat: 39.3881, lng: 49.2414 },
        zoom: "10",
      },
      {
        value: "56",
        label: "Oğuz",
        center: { lat: 41.0728, lng: 47.4651 },
        zoom: "10",
      },
      {
        value: "57",
        label: "Ordubad",
        center: { lat: 38.9022, lng: 46.0238 },
        zoom: "10",
      },
      {
        value: "58",
        label: "Saatlı",
        center: { lat: 39.9096, lng: 48.3595 },
        zoom: "10",
      },
      {
        value: "59",
        label: "Sabirabad",
        center: { lat: 39.9871, lng: 48.4693 },
        zoom: "10",
      },
      {
        value: "60",
        label: "Salyan",
        center: { lat: 39.5779, lng: 48.9719 },
        zoom: "10",
      },
      {
        value: "61",
        label: "Samux",
        center: { lat: 40.7605, lng: 46.4063 },
        zoom: "10",
      },
      {
        value: "62",
        label: "Sədərək",
        center: { lat: 39.7105, lng: 44.8864 },
        zoom: "10",
      },
      {
        value: "63",
        label: "Siyəzən",
        center: { lat: 41.0784, lng: 49.1118 },
        zoom: "10",
      },
      {
        value: "64",
        label: "Sumqayıt",
        center: { lat: 40.5855, lng: 49.6317 },
        zoom: "10",
      },
      {
        value: "65",
        label: "Şabran",
        center: { lat: 41.216, lng: 48.9946 },
        zoom: "10",
      },
      {
        value: "66",
        label: "Şahbuz",
        center: { lat: 39.4056, lng: 45.5691 },
        zoom: "10",
      },
      {
        value: "67",
        label: "Şamaxı",
        center: { lat: 40.6319, lng: 48.6364 },
        zoom: "10",
      },
      {
        value: "68",
        label: "Şəki",
        center: { lat: 41.1975, lng: 47.1571 },
        zoom: "10",
      },
      {
        value: "69",
        label: "Şəmkir",
        center: { lat: 40.8288, lng: 46.0167 },
        zoom: "10",
      },
      {
        value: "70",
        label: "Şərur",
        center: { lat: 39.5536, lng: 44.9846 },
        zoom: "10",
      },
      {
        value: "71",
        label: "Şirvan",
        center: { lat: 39.9266, lng: 48.9206 },
        zoom: "10",
      },
      {
        value: "72",
        label: "Şuşa",
        center: { lat: 39.7537, lng: 46.7465 },
        zoom: "10",
      },
      {
        value: "73",
        label: "Tərtər",
        center: { lat: 40.3444, lng: 46.9377 },
        zoom: "10",
      },
      {
        value: "74",
        label: "Tovuz",
        center: { lat: 40.9955, lng: 45.6166 },
        zoom: "10",
      },
      {
        value: "75",
        label: "Ucar",
        center: { lat: 40.5068, lng: 47.649 },
        zoom: "10",
      },
      {
        value: "76",
        label: "Yardımlı",
        center: { lat: 38.9059, lng: 48.2496 },
        zoom: "10",
      },
      {
        value: "77",
        label: "Yevlax",
        center: { lat: 40.6197, lng: 47.15 },
        zoom: "10",
      },
      {
        value: "78",
        label: "Zaqatala",
        center: { lat: 41.6031, lng: 46.6383 },
        zoom: "10",
      },
      {
        value: "79",
        label: "Zəngilan",
        center: { lat: 39.0857, lng: 46.6525 },
        zoom: "10",
      },
      {
        value: "80",
        label: "Zərdab",
        center: { lat: 40.2148, lng: 47.7149 },
        zoom: "10",
      },
    ],
    region_options: [
      { value: "0", label: "Abşeron" },
      { value: "1", label: "Binəqədi" },
      { value: "2", label: "Nərimanov" },
      { value: "3", label: "Nəsimi" },
      { value: "4", label: "Nizami" },
      { value: "5", label: "Qaradağ" },
      { value: "6", label: "Sabunçu" },
      { value: "7", label: "Səbail" },
      { value: "8", label: "Suraxanı" },
      { value: "9", label: "Xətai" },
      { value: "10", label: "Xəzər" },
      { value: "11", label: "Yasamal" },
      { value: "12", label: "Pirallahı" },
    ],
    site_options: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "tap.az", label: "tap.az" },
      { value: "bina.az", label: "bina.az" },
      { value: "lalafo.az", label: "lalafo.az" },
      { value: "emlak.az", label: "emlak.az" },
      { value: "arenda.az", label: "arenda.az" },
      { value: "yeniemlak.az", label: "yeniemlak.az" },
      { value: "rahatemlak.az", label: "rahatemlak.az" },
      { value: "ucuztap.az", label: "ucuztap.az" },
    ],
    site_options_cars: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "turbo.az", label: "turbo.az" },
      { value: "masinlar.az", label: "masinlar.az" },
      { value: "auto.az", label: "auto.az" },
      { value: "mashin.al", label: "mashin.al" }
    ],
    room_options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4 " },
      { value: "50", label: "5 +" },
    ],
    station_options: [
      { label: "İçərişəhər m.", value: "1" },
      { label: "Sahil m.", value: "2" },
      { label: "28 May m.", value: "3" },
      { label: "Gənclik m.", value: "4" },
      { label: "Nəriman Nərimanov m.", value: "5" },
      { label: "Bakmil m.", value: "6" },
      { label: "Ulduz m.", value: "7" },
      { label: "Koroğlu m.", value: "8" },
      { label: "Qara Qarayev m.", value: "9" },
      { label: "Neftçilər m.", value: "10" },
      { label: "Xalqlar Dostluğu m.", value: "11" },
      { label: "Əhmədli m.", value: "12" },
      { label: "Həzi Aslanov m.", value: "13" },
      { label: "Nizami Gəncəvi m.", value: "14" },
      { label: "Elmlər Akademiyası m.", value: "15" },
      { label: "İnşaatçılar m.", value: "16" },
      { label: "20 Yanvar m.", value: "17" },
      { label: "Memar Əcəmi m.", value: "18" },
      { label: "Nəsimi m.", value: "19" },
      { label: "Azadlıq prospekti m.", value: "20" },
      { label: "Dərnəgül m.", value: "21" },
      { label: "Cəfər Cabbarlı m.", value: "22" },
      { label: "Şah İsmayıl Xətai m.", value: "23" },
      { label: "Avtovağzal m.", value: "24" },
      { label: "8 Noyabr m.", value: "25" },
      { label: "Xocəsən m.", value: "26" },
    ],
    resident_options: [
      { value: "1", label: "1-ci mikrorayon", region: "Nəsimi" },
      { value: "2", label: "2-ci mikrorayon", region: "Nəsimi" },
      { value: "3", label: "20-ci sahə", region: "Səbail" },
      { value: "4", label: "28 may", region: "Binəqədi" },
      { value: "5", label: "3-cü mikrorayon", region: "Nəsimi" },
      { value: "6", label: "4-cü mikrorayon", region: "Nəsimi" },
      { value: "7", label: "5-ci mikrorayon", region: "Nəsimi" },
      { value: "8", label: "6-ci mikrorayon", region: "Binəqədi" },
      { value: "9", label: "6-cı parallel", region: "Binəqədi" },
      { value: "10", label: "7-ci mikrorayon", region: "Binəqədi" },
      { value: "11", label: "8 km", region: "Nizami" },
      { value: "12", label: "8-ci mikrorayon", region: "Binəqədi" },
      { value: "13", label: "9-cu mikrorayon", region: "Binəqədi" },
      { value: "14", label: "Ağ şəhər", region: "Xətai" },
      { value: "15", label: "Alatava 1", region: "Binəqədi" },
      { value: "16", label: "Alatava 2", region: "Binəqədi" },
      { value: "17", label: "Albalı", region: "Sabunçu" },
      { value: "18", label: "Atyalı", region: "Abşeron" },
      { value: "19", label: "Badamdar", region: "Səbail" },
      { value: "20", label: "Bahar", region: "Suraxanı" },
      { value: "21", label: "Bakıxanov", region: "Sabunçu" },
      { value: "22", label: "Balaxanı", region: "Sabunçu" },
      { value: "23", label: "Bayıl", region: "Səbail" },
      { value: "24", label: "Bibiheybət", region: "Səbail" },
      { value: "25", label: "Bilgəh", region: "Sabunçu" },
      { value: "26", label: "Biləcəri", region: "Binəqədi" },
      { value: "27", label: "Binə", region: "Xəzər" },
      { value: "28", label: "Binəqədi", region: "Binəqədi" },
      { value: "29", label: "Böyükşor", region: "Nərimanov" },
      { value: "30", label: "Bülbülə", region: "Suraxanı" },
      { value: "31", label: "Buzovna", region: "Xəzər" },
      { value: "32", label: "Çermet", region: "" },
      { value: "33", label: "Ceyranbatan", region: "Abşeron" },
      { value: "34", label: "Çiçək", region: "Abşeron" },
      { value: "35", label: "Corat", region: "" },
      { value: "36", label: "Digah", region: "Abşeron" },
      { value: "37", label: "Dübəndi bağları", region: "Xəzər" },
      { value: "38", label: "Dədə Qorqud", region: "Suraxanı" },
      { value: "39", label: "Fatmayı", region: "Abşeron" },
      { value: "40", label: "Görədil", region: "Abşeron" },
      { value: "41", label: "Gürgən", region: "Xəzər" },
      { value: "42", label: "Güzdək", region: "Abşeron" },
      { value: "43", label: "Hökməli", region: "Abşeron" },
      { value: "44", label: "Hövsan", region: "Suraxanı" },
      { value: "45", label: "Həzi Aslanov", region: "Xətai" },
      { value: "46", label: "Keşlə", region: "Nizami" },
      { value: "47", label: "Kimya şəhərciyi", region: "Nəsimi" },
      { value: "48", label: "Köhnə Günəşli", region: "Xətai" },
      { value: "49", label: "Kürdəxanı", region: "Sabunçu" },
      { value: "50", label: "Lökbatan", region: "Qaradağ" },
      { value: "51", label: "Ləhic Bağları", region: "Sabunçu" },
      { value: "52", label: "Masazır", region: "Abşeron" },
      { value: "53", label: "Maştağa", region: "Sabunçu" },
      { value: "54", label: "Mehdiabad", region: "Abşeron" },
      { value: "55", label: "Montin", region: "Nərimanov" },
      { value: "56", label: "Müşfiqabad", region: "Qaradağ" },
      { value: "57", label: "Məhəmmədli", region: "Abşeron" },
      { value: "58", label: "Mərdəkan", region: "Xəzər" },
      { value: "59", label: "Nardaran", region: "Sabunçu" },
      { value: "60", label: "Nasosnu", region: "" },
      { value: "61", label: "Novxanı", region: "Abşeron" },
      { value: "62", label: "Nübar", region: "" },
      { value: "63", label: "NZS", region: "Xətai" },
      { value: "64", label: "Papanin", region: "" },
      { value: "65", label: "Perekeşkül", region: "Abşeron" },
      { value: "66", label: "Pirşağı", region: "Sabunçu" },
      { value: "67", label: "Puta", region: "Qaradağ" },
      { value: "68", label: "Qala", region: "Xəzər" },
      { value: "69", label: "Qara şəhər", region: "" },
      { value: "70", label: "Qaraçuxur", region: "Suraxanı" },
      { value: "71", label: "Qızıldaş", region: "Qaradağ" },
      { value: "72", label: "Qobu", region: "Abşeron" },
      { value: "73", label: "Qobustan", region: "Qaradağ" },
      { value: "74", label: "Ramana", region: "Sabunçu" },
      { value: "75", label: "Rəsulzadə", region: "Binəqədi" },
      { value: "76", label: "Sabunçu", region: "Sabunçu" },
      { value: "77", label: "Şağan", region: "Xəzər" },
      { value: "78", label: "Sahil", region: "Qaradağ" },
      { value: "79", label: "Saray", region: "Abşeron" },
      { value: "80", label: "Savalan", region: "Sabunçu" },
      { value: "81", label: "Şıxov", region: "Səbail" },
      { value: "82", label: "Şubanı", region: "Qaradağ" },
      { value: "83", label: "Sulutəpə", region: "Binəqədi" },
      { value: "84", label: "Şuşa", region: "Sabunçu" },
      { value: "85", label: "Şüvəlan", region: "Xəzər" },
      { value: "86", label: "Səngəçal", region: "Qaradağ" },
      { value: "87", label: "Türkan", region: "Xəzər" },
      { value: "88", label: "Ümid", region: "Qaradağ" },
      { value: "89", label: "Xaşaxuna", region: "Xəzər" },
      { value: "90", label: "Xocəsən", region: "Binəqədi" },
      { value: "91", label: "Xutor", region: "Binəqədi" },
      { value: "92", label: "Yeni Günəşli", region: "Suraxanı" },
      { value: "93", label: "Yeni Ramana", region: "Sabunçu" },
      { value: "94", label: "Yeni Suraxanı", region: "Suraxanı" },
      { value: "95", label: "Yeni Yasamal", region: "Yasamal" },
      { value: "96", label: "Zabrat 1", region: "Sabunçu" },
      { value: "97", label: "Zabrat 2", region: "Sabunçu" },
      { value: "98", label: "Zağulba", region: "Abşeron" },
      { value: "99", label: "Zığ", region: "Suraxanı" },
      { value: "100", label: "Zirə", region: "Xəzər" },
      { value: "101", label: "Əhmədli", region: "Xətai" },
      { value: "102", label: "Ələt", region: "Qaradağ" },
      { value: "103", label: "Əmircan", region: "Suraxanı" },
    ],
    target_options: [
      { value: "1", label: "28 Mall", region: "Nəsimi" },
      { value: "2", label: "A.S.Puşkin parkı", region: "Nəsimi" },
      { value: "3", label: "AAF PARK", region: "Abşeron" },
      { value: "4", label: "ABŞ səfirliyi", region: "Nəsimi" },
      { value: "5", label: "Abşeron Gənclər Şəhərciyi", region: "Abşeron" },
      { value: "6", label: "Absheron Marriott otel", region: "Nəsimi" },
      { value: "7", label: "Abu Arena", region: "Nərimanov" },
      { value: "8", label: "ADA universiteti", region: "Nərimanov" },
      { value: "9", label: "AF Business House", region: "Nəsimi" },
      { value: "10", label: "AGA Business Center", region: "Xətai" },
      { value: "11", label: "Altes Plaza", region: "Yasamal" },
      { value: "12", label: "AMAY", region: "Xətai" },
      { value: "13", label: "Ambassador otel", region: "Nərimanov" },
      { value: "14", label: "ANS telekanalı", region: "Səbail" },
      { value: "15", label: "Aqua Park", region: "Nəsimi" },
      { value: "16", label: "Araz kinoteatrı", region: "Binəqədi" },
      { value: "17", label: "Asan Xidmət-1", region: "Nərimanov" },
      { value: "18", label: "Asan Xidmət-2", region: "Xətai" },
      { value: "19", label: "Asan Xidmət-3", region: "Yasamal" },
      { value: "20", label: "Asan Xidmət-4", region: "Sabunçu" },
      { value: "21", label: "Asan Xidmət-5", region: "Nizami" },
      { value: "22", label: "Asan Xidmət-6", region: "Xətai" },
      { value: "23", label: "ASK Plaza", region: "Nəsimi" },
      { value: "24", label: "Atatürk parkı", region: "Nərimanov" },
      { value: "25", label: "ATV telekanalı", region: "Yasamal" },
      {
        value: "26",
        label: "Milli Aviasiya Akademiyası Universiteti",
        region: "Xəzər",
      },
      { value: "27", label: "Avropa otel", region: "Nəsimi" },
      { value: "28", label: "Axundov bağı", region: "Səbail" },
      { value: "29", label: "Aygun City", region: "Sabunçu" },
      { value: "30", label: "Ayna Sultanova heykəli", region: "Nərimanov" },
      { value: "31", label: "Azadlıq meydanı", region: "Səbail" },
      { value: "32", label: "Azneft meydanı", region: "Səbail" },
      { value: "33", label: "AZTV telekanalı", region: "Səbail" },
      {
        value: "34",
        label: "Azərbaycan Dillər Universiteti",
        region: "Nəsimi",
      },
      { value: "35", label: "Azərbaycan kinoteatrı", region: "Yasamal" },
      {
        value: "36",
        label: "Azərbaycan Тurizm İnstitutu",
        region: "Nərimanov",
      },
      { value: "37", label: "Babək Plaza", region: "Xətai" },
      { value: "38", label: "Bakı Asiya Universiteti", region: "Nəsimi" },
      { value: "39", label: "Bakı Dövlət Universiteti", region: "Yasamal" },
      { value: "40", label: "Bakı Musiqi Akademiyası", region: "Nəsimi" },
      { value: "41", label: "Bakı Slavyan Universiteti", region: "Nəsimi" },
      { value: "42", label: "Bakı univermağı", region: "Nizami" },
      { value: "43", label: "Baku Mall", region: "Yasamal" },
      { value: "44", label: "Bayıl parkı", region: "Səbail" },
      { value: "45", label: "Beşmərtəbə", region: "Sabunçu" },
      { value: "46", label: "Binə ticarət mərkəzi ", region: "Qaradağ" },
      { value: "47", label: "Botanika bağı", region: "Səbail" },
      { value: "48", label: "Bridge Plaza", region: "Nəsimi" },
      { value: "49", label: "C.Cabbarlı heykəli", region: "Nəsimi" },
      {
        value: "50",
        label: "C.Naxçıvanski adına Hərbi Akademiya",
        region: "Xətai",
      },
      { value: "51", label: "Caspian Plaza", region: "Yasamal" },
      { value: "52", label: "Caspian Shopping Center", region: "Nərimanov" },
      { value: "53", label: "Cavanşir körpüsü", region: "Nəsimi" },
      { value: "54", label: "Çin səfirliyi", region: "Qaradağ" },
      { value: "55", label: "Çıraq Plaza", region: "Nəsimi" },
      { value: "56", label: "Crystal Plaza", region: "Xətai" },
      { value: "57", label: "Dağüstü parkı", region: "Səbail" },
      { value: "58", label: "Dalğa Plaza", region: "Səbail" },
      { value: "59", label: "Daxili İşlər Nazirliyi", region: "Səbail" },
      { value: "60", label: "Dostluq kinoteatrı", region: "Nəsimi" },
      { value: "61", label: "Dövlət İdarəçilik Akademiyası", region: "Səbail" },
      { value: "62", label: "Dövlət Statistika Komitəsi", region: "Yasamal" },
      { value: "63", label: "Dədə Qorqud parkı", region: "Nərimanov" },
      { value: "64", label: "Dəmirçi Plaza", region: "Xətai" },
      { value: "65", label: "Dənizkənarı Milli park", region: "Səbail" },
      {
        value: "66",
        label: "Ekologiya və Təbii Sərvətlər Nazirliyi",
        region: "Yasamal",
      },
      { value: "67", label: "Elit ticarət mərkəzi", region: "Binəqədi" },
      { value: "68", label: "Energetika Nazirliyi", region: "Nizami" },
      { value: "69", label: "Fairmont otel", region: "Səbail" },
      { value: "70", label: "Flame Towers", region: "Səbail" },
      { value: "71", label: "Fontanlar bağı", region: "Səbail" },
      { value: "72", label: "Four Seasons otel", region: "Səbail" },
      { value: "73", label: "Fövqəladə Hallar Nazirliyi", region: "Yasamal" },
      {
        value: "74",
        label: "Fövqəladə Hallar Nazirliyi Akademiyası",
        region: "Suraxanı",
      },
      { value: "75", label: "Fəvvarələr meydanı", region: "Səbail" },
      { value: "76", label: "Gürgən", region: "Pirallahı" },
      { value: "77", label: "Gənclik Mall", region: "Nərimanov" },
      { value: "78", label: "Gənclər və İdman Nazirliyi", region: "Nərimanov" },
      { value: "79", label: "Heydər Məscidi", region: "Binəqədi" },
      { value: "80", label: "Heydər Əliyev Mərkəzi", region: "Nərimanov" },
      { value: "81", label: "Heydər Əliyev sarayı", region: "Nəsimi" },
      { value: "82", label: "Hilton otel", region: "Səbail" },
      { value: "83", label: "Hüseyn Cavid parkı", region: "Yasamal" },
      { value: "84", label: "Hyatt Regency", region: "Yasamal" },
      { value: "85", label: "Hərbi Hospital", region: "Nəsimi" },
      { value: "86", label: "İctimai telekanalı", region: "Yasamal" },
      { value: "87", label: "İçəri Şəhər", region: "Səbail" },
      { value: "88", label: "İncəsənət və Mədəniyyət Un.", region: "Yasamal" },
      { value: "89", label: "İnşaat Universiteti", region: "Yasamal" },
      { value: "90", label: "İqtisadiyyat  Nazirliyi", region: "Nizami" },
      { value: "91", label: "İqtisadiyyat Universiteti", region: "Nizami" },
      {
        value: "92",
        label: "İran İslam Respublikası səfirliyi",
        region: "Səbail",
      },
      { value: "93", label: "ISR Plaza", region: "Nəsimi" },
      { value: "94", label: "İzmir parkı", region: "Yasamal" },
      { value: "95", label: "Keşlə bazarı", region: "Nərimanov" },
      { value: "96", label: "Koala parkı", region: "Nəsimi" },
      { value: "97", label: "Kooperasiya Universiteti", region: "Nərimanov" },
      { value: "98", label: "Koroğlu Parkı", region: "Nəsimi" },
      { value: "99", label: "Landmark", region: "Səbail" },
      { value: "100", label: "Lider telekanalı", region: "Yasamal" },
      { value: "101", label: "M.Hüseynzadə parkı", region: "Nəsimi" },
      { value: "102", label: "M.Ə.Sabir parkı", region: "Sabunçu" },
      { value: "103", label: "Maliyyə Nazirliyi", region: "Nəsimi" },
      { value: "104", label: "Megafun", region: "Xətai" },
      {
        value: "105",
        label: "Memarlıq və İnşaat Universiteti",
        region: "Yasamal",
      },
      { value: "106", label: "Metropark", region: "Nərimanov" },
      { value: "107", label: "Milli Konservatoriya", region: "Yasamal" },
      { value: "108", label: "Milli Məclis", region: "Səbail" },
      {
        value: "109",
        label: "Dövlət Təhlükəsizliyi Xidməti",
        region: "Yasamal",
      },
      { value: "110", label: "Molokan bağı", region: "Səbail" },
      { value: "111", label: "Montin adına park", region: "Nərimanov" },
      { value: "112", label: "Moskva univermağı", region: "Nəsimi" },
      { value: "113", label: "Müdafiə Sənayesi Nazirliyi", region: "Xətai" },
      { value: "114", label: "MUM", region: "Səbail" },
      { value: "115", label: "Musabəyov parkı", region: "Yasamal" },
      {
        value: "116",
        label: "Mərkəzi Neftçilər xəstxəxanası",
        region: "Xətai",
      },
      { value: "117", label: "Mərkəzi Univermaq", region: "Səbail" },
      { value: "118", label: "Nargiz ticarət mərkəzi", region: "Səbail" },
      { value: "119", label: "Neapol dairəsi", region: "Xətai" },
      {
        value: "120",
        label: "Azərbaycan Dövlət Neft və Sənaye Universiteti",
        region: "Nəsimi",
      },
      { value: "121", label: "Neftçi bazası", region: "Nərimanov" },
      { value: "122", label: "Neftçilər metrosu", region: "Nizami" },
      { value: "123", label: "Nizami kinoteatrı", region: "Nəsimi" },
      { value: "124", label: "Nəqliyyat Nazirliyi", region: "Səbail" },
      { value: "125", label: "Nəriman Nərimanov parkı", region: "Nərimanov" },
      { value: "126", label: "Nərimanov heykəli", region: "Yasamal" },
      { value: "127", label: "Nəsimi bazarı", region: "Nəsimi" },
      { value: "128", label: "Nəsimi heykəli", region: "Nəsimi" },
      { value: "129", label: "Odlar Yurdu Universiteti", region: "Nərimanov" },
      { value: "130", label: "Olimpia Stadionu", region: "Sabunçu" },
      { value: "131", label: "Olimpik Star", region: "Nərimanov" },
      { value: "132", label: "Oskar şadlıq sarayı", region: "Nizami" },
      { value: "133", label: "Özbəkistan səfirliyi", region: "Səbail" },
      { value: "134", label: "Park Azure", region: "Xətai" },
      { value: "135", label: "Park Bulvar", region: "Səbail" },
      { value: "136", label: "Park Inn", region: "Səbail" },
      { value: "137", label: "Park Zorge", region: "Nəsimi" },
      { value: "138", label: "Pedaqoji Universiteti", region: "Səbail" },
      { value: "139", label: "Perekeşkül", region: "Abşeron" },
      { value: "140", label: "Port Baku", region: "Nəsimi" },
      { value: "141", label: "Prezident parkı", region: "Xətai" },
      { value: "142", label: "Pullman Hotel", region: "Yasamal" },
      { value: "143", label: "Qafqaz Resort otel", region: "Nəsimi" },
      { value: "144", label: "Mühəndislik Universiteti", region: "Abşeron" },
      { value: "145", label: "Qış parkı", region: "Yasamal" },
      { value: "146", label: "Qız Qalası", region: "Səbail" },
      { value: "147", label: "Qubernator parkı", region: "Səbail" },
      {
        value: "148",
        label: "Qurtuluş - 93 Yaşayış Kompleksi",
        region: "Abşeron",
      },
      { value: "149", label: "Qələbə dairəsi", region: "Yasamal" },
      { value: "150", label: "Qərb Universiteti", region: "Səbail" },
      {
        value: "151",
        label: "Rabitə və Yüksək Texnologiyalar Nazirliyi",
        region: "Nəsimi",
      },
      { value: "152", label: "Respublika stadionu", region: "Nərimanov" },
      { value: "153", label: "Rusiya səfirliyi", region: "Nəsimi" },
      { value: "154", label: "Rəssamlıq Akademiyası", region: "Nərimanov" },
      { value: "155", label: "Sahil bağı", region: "Nərimanov" },
      { value: "156", label: "Sea Breeze Resort", region: "Sabunçu" },
      { value: "157", label: "Sevgi parkı", region: "Xətai" },
      { value: "158", label: "Sevil Qazıyeva parkı", region: "Nəsimi" },
      { value: "159", label: "Sevinc k/t", region: "Nizami" },
      { value: "160", label: "Sirk", region: "Nəsimi" },
      { value: "161", label: "Sovetski", region: "Yasamal" },
      { value: "162", label: "Space TV", region: "Yasamal" },
      { value: "163", label: "Şüvəlan Park ticarət mərkəzi", region: "Xəzər" },
      { value: "164", label: "Sədərək ticarət mərkəzi", region: "Qaradağ" },
      { value: "165", label: "Şəfa stadionu", region: "Nizami" },
      { value: "166", label: "Şəhidlər xiyabanı", region: "Səbail" },
      { value: "167", label: "Səhiyyə Nazirliyi", region: "Nəsimi" },
      { value: "168", label: "Şəlalə parkı", region: "Səbail" },
      { value: "169", label: "Səməd Vurğun parkı ", region: "Nəsimi" },
      { value: "170", label: "Sərhədçi İdman kompleksi", region: "Xətai" },
      { value: "171", label: "Şərq Bazarı", region: "Nərimanov" },
      { value: "172", label: "Tarqovu", region: "Səbail" },
      { value: "173", label: "Texniki Universiteti", region: "Yasamal" },
      { value: "174", label: "Tibb Universiteti", region: "Nəsimi" },
      { value: "175", label: "Tofiq Bəhramov stadionu", region: "Nərimanov" },
      { value: "176", label: "Türkiyə səfirliyi", region: "Nəsimi" },
      { value: "177", label: "Təfəkkür Universiteti", region: "Nərimanov" },
      { value: "178", label: "Təhsil Nazirliyi", region: "Nərimanov" },
      { value: "179", label: "Təzə bazar", region: "Nəsimi" },
      { value: "180", label: "Ukrayna dairəsi", region: "Xətai" },
      { value: "181", label: "Vergilər Nazirliyi", region: "Nizami" },
      { value: "182", label: "Vətən kinoteatrı", region: "Nəsimi" },
      { value: "183", label: "World Business Center", region: "Nəsimi" },
      { value: "184", label: "Xalça Muzeyi", region: "Səbail" },
      { value: "185", label: "Xarici İşlər Nazirliyi", region: "Yasamal" },
      { value: "186", label: "Xəqani ticarət mərkəzi", region: "Səbail" },
      { value: "187", label: "Xəzər Universiteti", region: "Nizami" },
      { value: "188", label: "Yasamal bazarı", region: "Yasamal" },
      { value: "189", label: "Yasamal parkı", region: "Yasamal" },
      { value: "190", label: "Yaşıl bazar", region: "Nərimanov" },
      { value: "191", label: "Zabitlər parkı", region: "Nəsimi" },
      { value: "192", label: "Zoopark", region: "Nərimanov" },
      { value: "193", label: "Zərifə Əliyeva adına park", region: "Binəqədi" },
      { value: "194", label: "Ədliyyə Nazirliyi", region: "Yasamal" },
      {
        value: "195",
        label: "Əmək və Əhalinin Sosial Müdafiəsi Nazirliyi",
        region: "Yasamal",
      },
    ],
    flat_type_options: [
      { value: "1", label: "Mənzil" },
      { value: "2", label: "Həyət evi/Bağ/Villa", label2: "Həyət" },
      { value: "3", label: "Ofis" },
      { value: "4", label: "Qaraj" },
      { value: "5", label: "Torpaq" },
      { value: "6", label: "Obyekt" },
    ],
    offer_type_options: [
      { value: "true", label: "Satış" },
      { value: "false", label: "Kirayə" },
    ],
    mortgage_options: [
      { value: "true", label: "Bəli" },
      { value: "false", label: "Xeyr" },
    ],
    rent_type_options: [
      { value: "1", label: "Aylıq" },
      { value: "2", label: "Günlük" },
    ],
    type_of_flat: [
      { value: "1", label: "Biznes mərkəzi" },
      { value: "2", label: "Ev / Mənzil" },
    ],
    filter_options: [
      { value: "dn", label: "Tarix (Yeni-Köhnə)" },
      { value: "do", label: "Tarix (Köhnə-Yeni)" },
      { value: "pl", label: "Qiymət (ucuzdan bahaya)" },
      { value: "ph", label: "Qiymət (bahadan ucuza)" },
    ],
    filter_options_blog: [
      { value: "dn", label: "Tarix (Yeni - Köhnə)" },
      { value: "do", label: "Tarix (Köhnə - Yeni)" },
      { value: "pl", label: "Baxış (Azdan - Çoxa)" },
      { value: "ph", label: "Baxış (Çoxdan - Aza)" },
    ],
    auto_type_options: [
      { value: "1", label: "Toyota" },
      { value: "2", label: "BMW" },
      { value: "3", label: "Lexus" },
      { value: "4", label: "Chevrolet" },
      { value: "5", label: "Mercedes" },
      { value: "6", label: "Kia" },
      { value: "7", label: "Volvo" },
      { value: "8", label: "Nissan" },
      { value: "9", label: "LADA (VAZ)" },
      { value: "10", label: "Land Rover" },
      { value: "11", label: "Mitsubishi" },
      { value: "12", label: "Hyundai" },
      { value: "13", label: "MAN" },
      { value: "14", label: "Jeep" },
      { value: "15", label: "Opel" },
      { value: "16", label: "Porsche" },
      { value: "17", label: "Volkswagen" },
      { value: "18", label: "Changan" },
      { value: "19", label: "Skoda" },
      { value: "20", label: "IM" },
      { value: "21", label: "Ford" },
      { value: "22", label: "DongFeng" },
      { value: "23", label: "Audi" },
      { value: "24", label: "Leapmotor" },
      { value: "25", label: "ZIL" },
      { value: "26", label: "Honda" },
      { value: "27", label: "GWM" },
      { value: "28", label: "CFMOTO" },
      { value: "29", label: "DAF" },
      { value: "30", label: "JMC" },
      { value: "31", label: "Jaguar" },
      { value: "32", label: "Renault" },
      { value: "33", label: "Chery" },
      { value: "34", label: "Fiat" },
      { value: "35", label: "Mercedes-Maybach" },
      { value: "36", label: "Li Auto" },
      { value: "37", label: "ZEEKR" },
      { value: "38", label: "KamAz" },
      { value: "39", label: "BYD" },
      { value: "40", label: "Bestune" },
      { value: "41", label: "Seres Aito" },
      { value: "42", label: "GAZ" },
      { value: "43", label: "Avatr" },
      { value: "44", label: "Subaru" },
      { value: "45", label: "Dacia" },
      { value: "46", label: "Infiniti" },
      { value: "47", label: "Mazda" },
      { value: "48", label: "GMC" },
      { value: "49", label: "KAIYI" },
      { value: "50", label: "XPeng" },
      { value: "51", label: "Peugeot" },
      { value: "52", label: "Voyah" },
      { value: "53", label: "KrAZ" },
      { value: "54", label: "VGV" },
      { value: "55", label: "Jetour" },
      { value: "56", label: "Suzuki" },
      { value: "57", label: "Tesla" },
      { value: "58", label: "Tofas" },
      { value: "59", label: "Daewoo" },
      { value: "60", label: "Vespa" },
      { value: "61", label: "Aprilia" },
      { value: "62", label: "Hongqi" },
      { value: "63", label: "Isuzu" },
      { value: "64", label: "Geely" },
      { value: "65", label: "Ravon" },
      { value: "66", label: "Bentley" },
      { value: "67", label: "Denza" },
      { value: "68", label: "ROX (Polar Stone)" },
      { value: "69", label: "Haval" },
      { value: "70", label: "Lifan" },
      { value: "71", label: "Khazar" },
      { value: "72", label: "Nio" },
      { value: "73", label: "Maple" },
      { value: "74", label: "Dodge" },
      { value: "75", label: "Scania" },
      { value: "76", label: "Iran Khodro" },
      { value: "77", label: "ZAZ" },
      { value: "78", label: "Shacman" },
      { value: "79", label: "Tufan" },
      { value: "80", label: "Ssang Yong" },
      { value: "81", label: "Mini" },
      { value: "82", label: "Cadillac" },
      { value: "83", label: "Kawasaki" },
      { value: "84", label: "GAC" },
      { value: "85", label: "Maserati" },
      { value: "86", label: "DFSK" },
      { value: "87", label: "Baic" },
      { value: "88", label: "Karry" },
      { value: "89", label: "Lynk & Co" },
      { value: "90", label: "Kuba" },
      { value: "91", label: "Ferrari" },
      { value: "92", label: "TVS" },
      { value: "93", label: "Saipa" },
      { value: "94", label: "QJMotor" },
      { value: "95", label: "Polaris" },
      { value: "96", label: "HOWO" },
      { value: "97", label: "Yamaha" },
      { value: "98", label: "Xiaomi" },
      { value: "99", label: "Moskvich" },
      { value: "100", label: "FAW" },
      { value: "101", label: "Bajaj" },
      { value: "102", label: "M-Hero" },
      { value: "103", label: "Aston Martin" },
      { value: "104", label: "MG" },
      { value: "105", label: "Lotus" },
      { value: "106", label: "Benda" },
      { value: "107", label: "Mack" },
      { value: "108", label: "Forthing" },
      { value: "109", label: "UAZ" },
      { value: "110", label: "Megelli" },
      { value: "111", label: "Kanuni" },
      { value: "112", label: "Minsk" },
      { value: "113", label: "SEAT" },
      { value: "114", label: "Harley-Davidson" },
      { value: "115", label: "Muravey" },
      { value: "116", label: "Zontes" },
      { value: "117", label: "Lincoln" },
      { value: "118", label: "MAZ" },
      { value: "119", label: "Radar" },
      { value: "120", label: "Neta" },
      { value: "121", label: "Hummer" },
      { value: "122", label: "KTM" },
      { value: "123", label: "IJ" },
      { value: "124", label: "Citroen" },
      { value: "125", label: "Lamborghini" },
      { value: "126", label: "Daimler" },
      { value: "127", label: "Chrysler" },
      { value: "128", label: "JAC" },
      { value: "129", label: "Ural" },
      { value: "130", label: "Genesis" },
      { value: "131", label: "Volta" },
      { value: "132", label: "Wuling" },
      { value: "133", label: "Setra" },
      { value: "134", label: "Iveco" },
      { value: "135", label: "Alfa Romeo" },
      { value: "136", label: "Abarth" },
      { value: "137", label: "Saturn" },
      { value: "138", label: "C.Moto" },
      { value: "139", label: "Jianshe" },
      { value: "140", label: "Haima" },
      { value: "141", label: "Cevo" },
      { value: "142", label: "Polestar" },
      { value: "143", label: "Brilliance" },
      { value: "144", label: "Can-Am" },
      { value: "145", label: "iCar" },
      { value: "146", label: "Ducati" },
      { value: "147", label: "Polad" },
      { value: "148", label: "Foton" },
      { value: "149", label: "Haojue" },
      { value: "150", label: "Grandmoto" },
      { value: "151", label: "IM Motors" },
      { value: "152", label: "Mercedes-Benz" },
      { value: "153", label: "Skywell" },
      { value: "154", label: "Lada (VAZ)" },
      { value: "155", label: "LADA" },
      { value: "156", label: "VAZ" },
      { value: "157", label: "Yutong" },
      { value: "158", label: "PAZ" },
      { value: "159", label: "MV Agusta" },
      { value: "160", label: "Dayun" },
      { value: "161", label: "LuAz" },
      { value: "162", label: "Keeway" },
      { value: "163", label: "Renault Samsung" },
      { value: "164", label: "Dnepr" },
    ],
    auto_model_options: [
      {
        value: "1",
        brandId: "1",
        label: "Camry",
      },
      {
        value: "2",
        brandId: "2",
        label: "740",
      },
      {
        value: "3",
        brandId: "1",
        label: "Prius",
      },
      {
        value: "4",
        brandId: "3",
        label: "GX 470",
      },
      {
        value: "5",
        brandId: "4",
        label: "Cruze",
      },
      {
        value: "6",
        brandId: "5",
        label: "GL 550",
      },
      {
        value: "7",
        brandId: "6",
        label: "K5",
      },
      {
        value: "8",
        brandId: "5",
        label: "CLA 250",
      },
      {
        value: "9",
        brandId: "7",
        label: "S90",
      },
      {
        value: "10",
        brandId: "8",
        label: "Note",
      },
      {
        value: "11",
        brandId: "9",
        label: "Niva",
      },
      {
        value: "12",
        brandId: "4",
        label: "Equinox",
      },
      {
        value: "13",
        brandId: "2",
        label: "M3",
      },
      {
        value: "14",
        brandId: "10",
        label: "Discovery Sport",
      },
      {
        value: "15",
        brandId: "11",
        label: "L200",
      },
      {
        value: "16",
        brandId: "12",
        label: "Elantra",
      },
      {
        value: "17",
        brandId: "10",
        label: "Range Rover",
      },
      {
        value: "18",
        brandId: "13",
        label: "TGX 18.440",
      },
      {
        value: "19",
        brandId: "12",
        label: "Tucson",
      },
      {
        value: "20",
        brandId: "1",
        label: "Avalon",
      },
      {
        value: "21",
        brandId: "12",
        label: "Sonata",
      },
      {
        value: "22",
        brandId: "6",
        label: "Carens",
      },
      {
        value: "23",
        brandId: "6",
        label: "K3",
      },
      {
        value: "24",
        brandId: "9",
        label: "2107",
      },
      {
        value: "25",
        brandId: "14",
        label: "Wrangler",
      },
      {
        value: "26",
        brandId: "15",
        label: "Astra",
      },
      {
        value: "27",
        brandId: "13",
        label: "TGA 18.400",
      },
      {
        value: "28",
        brandId: "6",
        label: "Optima",
      },
      {
        value: "29",
        brandId: "12",
        label: "Avante",
      },
      {
        value: "30",
        brandId: "11",
        label: "Airtrek",
      },
      {
        value: "31",
        brandId: "6",
        label: "Sorento",
      },
      {
        value: "32",
        brandId: "2",
        label: "528",
      },
      {
        value: "33",
        brandId: "16",
        label: "Panamera 4S",
      },
      {
        value: "34",
        brandId: "5",
        label: "GLE 43 AMG 4MATIC Coupe",
      },
      {
        value: "35",
        brandId: "17",
        label: "ID.6 Crozz",
      },
      {
        value: "36",
        brandId: "14",
        label: "Grand Cherokee",
      },
      {
        value: "37",
        brandId: "18",
        label: "Qiyuan A06",
      },
      {
        value: "38",
        brandId: "19",
        label: "Octavia",
      },
      {
        value: "39",
        brandId: "1",
        label: "Land Cruiser",
      },
      {
        value: "40",
        brandId: "20",
        label: "LS6",
      },
      {
        value: "41",
        brandId: "6",
        label: "Carnival",
      },
      {
        value: "42",
        brandId: "2",
        label: "X5",
      },
      {
        value: "43",
        brandId: "12",
        label: "Santa Fe",
      },
      {
        value: "44",
        brandId: "20",
        label: "LS7",
      },
      {
        value: "45",
        brandId: "6",
        label: "Forte",
      },
      {
        value: "46",
        brandId: "12",
        label: "Grandeur",
      },
      {
        value: "47",
        brandId: "17",
        label: "Polo",
      },
      {
        value: "48",
        brandId: "16",
        label: "Panamera Turbo",
      },
      {
        value: "49",
        brandId: "21",
        label: "Fusion (North America)",
      },
      {
        value: "50",
        brandId: "6",
        label: "Ray",
      },
      {
        value: "51",
        brandId: "2",
        label: "320",
      },
      {
        value: "52",
        brandId: "6",
        label: "Morning",
      },
      {
        value: "53",
        brandId: "22",
        label: "Aeolus Huge",
      },
      {
        value: "54",
        brandId: "23",
        label: "A7 Sportback",
      },
      {
        value: "55",
        brandId: "5",
        label: "AMG GT 53 4MATIC+",
      },
      {
        value: "56",
        brandId: "24",
        label: "T03",
      },
      {
        value: "57",
        brandId: "11",
        label: "Pajero",
      },
      {
        value: "58",
        brandId: "2",
        label: "730",
      },
      {
        value: "59",
        brandId: "5",
        label: "C 180",
      },
      {
        value: "60",
        brandId: "5",
        label: "B 170",
      },
      {
        value: "61",
        brandId: "2",
        label: "X6",
      },
      {
        value: "62",
        brandId: "2",
        label: "428",
      },
      {
        value: "63",
        brandId: "5",
        label: "E 220 d",
      },
      {
        value: "64",
        brandId: "10",
        label: "Defender",
      },
      {
        value: "65",
        brandId: "17",
        label: "Passat",
      },
      {
        value: "66",
        brandId: "16",
        label: "Panamera GTS",
      },
      {
        value: "67",
        brandId: "25",
        label: "130",
      },
      {
        value: "68",
        brandId: "4",
        label: "Aveo",
      },
      {
        value: "69",
        brandId: "10",
        label: "RR Sport",
      },
      {
        value: "70",
        brandId: "5",
        label: "E 320",
      },
      {
        value: "71",
        brandId: "1",
        label: "Corolla",
      },
      {
        value: "72",
        brandId: "1",
        label: "Yaris",
      },
      {
        value: "73",
        brandId: "3",
        label: "ES 300h",
      },
      {
        value: "74",
        brandId: "11",
        label: "Mirage",
      },
      {
        value: "75",
        brandId: "4",
        label: "Spark",
      },
      {
        value: "76",
        brandId: "2",
        label: "530",
      },
      {
        value: "77",
        brandId: "8",
        label: "Tiida",
      },
      {
        value: "78",
        brandId: "19",
        label: "Kodiaq",
      },
      {
        value: "79",
        brandId: "2",
        label: "750",
      },
      {
        value: "80",
        brandId: "26",
        label: "CR-V",
      },
      {
        value: "81",
        brandId: "15",
        label: "Vita",
      },
      {
        value: "82",
        brandId: "6",
        label: "Cerato",
      },
      {
        value: "83",
        brandId: "9",
        label: "21099",
      },
      {
        value: "84",
        brandId: "12",
        label: "i40",
      },
      {
        value: "85",
        brandId: "5",
        label: "Sprinter 515",
      },
      {
        value: "86",
        brandId: "27",
        label: "Poer",
      },
      {
        value: "87",
        brandId: "12",
        label: "Maxcruz",
      },
      {
        value: "88",
        brandId: "26",
        label: "Accord",
      },
      {
        value: "89",
        brandId: "27",
        label: "ORA",
      },
      {
        value: "90",
        brandId: "9",
        label: "Granta",
      },
      {
        value: "91",
        brandId: "1",
        label: "Fortuner",
      },
      {
        value: "92",
        brandId: "28",
        label: "300 SR-S",
      },
      {
        value: "93",
        brandId: "28",
        label: "450 SR",
      },
      {
        value: "94",
        brandId: "28",
        label: "800 MT Touring",
      },
      {
        value: "95",
        brandId: "5",
        label: "E 320 4MATIC",
      },
      {
        value: "96",
        brandId: "28",
        label: "CFORCE 1000 TOURING",
      },
      {
        value: "97",
        brandId: "28",
        label: "ZFORCE 800 Trail",
      },
      {
        value: "98",
        brandId: "2",
        label: "520",
      },
      {
        value: "99",
        brandId: "1",
        label: "Land Cruiser Prado",
      },
      {
        value: "100",
        brandId: "2",
        label: "525",
      },
      {
        value: "101",
        brandId: "5",
        label: "GL 350 4MATIC",
      },
      {
        value: "102",
        brandId: "9",
        label: "2106",
      },
      {
        value: "103",
        brandId: "3",
        label: "GS 250",
      },
      {
        value: "104",
        brandId: "2",
        label: "735",
      },
      {
        value: "105",
        brandId: "12",
        label: "i30",
      },
      {
        value: "106",
        brandId: "5",
        label: "E 240",
      },
      {
        value: "107",
        brandId: "5",
        label: "V 220",
      },
      {
        value: "108",
        brandId: "29",
        label: "105 XF",
      },
      {
        value: "109",
        brandId: "18",
        label: "Eado",
      },
      {
        value: "110",
        brandId: "13",
        label: "TGA 18.440",
      },
      {
        value: "111",
        brandId: "30",
        label: "Carrying 4x2",
      },
      {
        value: "112",
        brandId: "31",
        label: "XF",
      },
      {
        value: "113",
        brandId: "2",
        label: "330",
      },
      {
        value: "114",
        brandId: "2",
        label: "318",
      },
      {
        value: "115",
        brandId: "32",
        label: "Megane",
      },
      {
        value: "116",
        brandId: "21",
        label: "Tourneo Connect",
      },
      {
        value: "117",
        brandId: "33",
        label: "Tiggo (T11)",
      },
      {
        value: "118",
        brandId: "5",
        label: "S 320",
      },
      {
        value: "119",
        brandId: "12",
        label: "Genesis",
      },
      {
        value: "120",
        brandId: "12",
        label: "i20",
      },
      {
        value: "122",
        brandId: "6",
        label: "Ceed",
      },
      {
        value: "123",
        brandId: "34",
        label: "Fiorino",
      },
      {
        value: "124",
        brandId: "5",
        label: "GLA 250 4MATIC",
      },
      {
        value: "125",
        brandId: "8",
        label: "Altima",
      },
      {
        value: "126",
        brandId: "16",
        label: "Cayenne S",
      },
      {
        value: "127",
        brandId: "12",
        label: "Accent",
      },
      {
        value: "128",
        brandId: "9",
        label: "2115",
      },
      {
        value: "129",
        brandId: "6",
        label: "Rio",
      },
      {
        value: "130",
        brandId: "35",
        label: "S 500",
      },
      {
        value: "131",
        brandId: "10",
        label: "RR Velar",
      },
      {
        value: "132",
        brandId: "36",
        label: "L9",
      },
      {
        value: "133",
        brandId: "2",
        label: "330e",
      },
      {
        value: "134",
        brandId: "37",
        label: "001",
      },
      {
        value: "135",
        brandId: "5",
        label: "Sprinter 316",
      },
      {
        value: "136",
        brandId: "11",
        label: "Pajero io",
      },
      {
        value: "137",
        brandId: "5",
        label: "Vito",
      },
      {
        value: "138",
        brandId: "21",
        label: "Explorer",
      },
      {
        value: "139",
        brandId: "8",
        label: "Xterra",
      },
      {
        value: "140",
        brandId: "5",
        label: "Viano",
      },
      {
        value: "141",
        brandId: "23",
        label: "A6",
      },
      {
        value: "142",
        brandId: "1",
        label: "Highlander",
      },
      {
        value: "143",
        brandId: "8",
        label: "Sunny",
      },
      {
        value: "144",
        brandId: "5",
        label: "E 300",
      },
      {
        value: "145",
        brandId: "5",
        label: "S 450",
      },
      {
        value: "146",
        brandId: "5",
        label: "E 350",
      },
      {
        value: "147",
        brandId: "18",
        label: "CS 15",
      },
      {
        value: "148",
        brandId: "5",
        label: "GLE 450 4MATIC",
      },
      {
        value: "149",
        brandId: "5",
        label: "CLS 63 AMG",
      },
      {
        value: "150",
        brandId: "10",
        label: "Discovery",
      },
      {
        value: "151",
        brandId: "5",
        label: "GLE 53 AMG 4MATIC Coupe",
      },
      {
        value: "152",
        brandId: "38",
        label: "5320",
      },
      {
        value: "153",
        brandId: "5",
        label: "S 560 4MATIC",
      },
      {
        value: "154",
        brandId: "3",
        label: "RX 350",
      },
      {
        value: "155",
        brandId: "5",
        label: "S 350",
      },
      {
        value: "156",
        brandId: "5",
        label: "Sprinter 315",
      },
      {
        value: "157",
        brandId: "36",
        label: "L8",
      },
      {
        value: "158",
        brandId: "39",
        label: "Qin Plus",
      },
      {
        value: "159",
        brandId: "19",
        label: "Scala",
      },
      {
        value: "160",
        brandId: "40",
        label: "T99",
      },
      {
        value: "161",
        brandId: "41",
        label: "M9",
      },
      {
        value: "162",
        brandId: "42",
        label: "33021",
      },
      {
        value: "163",
        brandId: "21",
        label: "Transit",
      },
      {
        value: "164",
        brandId: "43",
        label: "11",
      },
      {
        value: "165",
        brandId: "8",
        label: "Qashqai+2",
      },
      {
        value: "166",
        brandId: "32",
        label: "Laguna",
      },
      {
        value: "167",
        brandId: "5",
        label: "GLS 450 4MATIC",
      },
      {
        value: "168",
        brandId: "21",
        label: "Focus",
      },
      {
        value: "169",
        brandId: "8",
        label: "Patrol",
      },
      {
        value: "170",
        brandId: "44",
        label: "Forester",
      },
      {
        value: "171",
        brandId: "5",
        label: "E 250",
      },
      {
        value: "172",
        brandId: "5",
        label: "C 220 d",
      },
      {
        value: "173",
        brandId: "1",
        label: "RAV4",
      },
      {
        value: "174",
        brandId: "15",
        label: "Vectra",
      },
      {
        value: "175",
        brandId: "45",
        label: "Logan",
      },
      {
        value: "176",
        brandId: "16",
        label: "Cayenne",
      },
      {
        value: "177",
        brandId: "5",
        label: "ML 350 4MATIC",
      },
      {
        value: "178",
        brandId: "2",
        label: "530e",
      },
      {
        value: "179",
        brandId: "2",
        label: "X3",
      },
      {
        value: "180",
        brandId: "42",
        label: "3110",
      },
      {
        value: "181",
        brandId: "6",
        label: "Sportage",
      },
      {
        value: "182",
        brandId: "26",
        label: "Civic",
      },
      {
        value: "183",
        brandId: "12",
        label: "Grand Santa Fe",
      },
      {
        value: "184",
        brandId: "2",
        label: "328",
      },
      {
        value: "185",
        brandId: "7",
        label: "S80",
      },
      {
        value: "186",
        brandId: "5",
        label: "S 500 4MATIC",
      },
      {
        value: "187",
        brandId: "46",
        label: "FX",
      },
      {
        value: "188",
        brandId: "5",
        label: "E 350 e",
      },
      {
        value: "189",
        brandId: "8",
        label: "X-Trail",
      },
      {
        value: "190",
        brandId: "42",
        label: "24",
      },
      {
        value: "191",
        brandId: "23",
        label: "A4",
      },
      {
        value: "192",
        brandId: "42",
        label: "Next A21R22-30",
      },
      {
        value: "193",
        brandId: "3",
        label: "LX 470",
      },
      {
        value: "194",
        brandId: "5",
        label: "Vito 111",
      },
      {
        value: "195",
        brandId: "9",
        label: "Priora",
      },
      {
        value: "196",
        brandId: "18",
        label: "Deepal S7",
      },
      {
        value: "197",
        brandId: "1",
        label: "Corolla Fielder",
      },
      {
        value: "198",
        brandId: "47",
        label: "CX-5",
      },
      {
        value: "199",
        brandId: "5",
        label: "E 300 e",
      },
      {
        value: "200",
        brandId: "5",
        label: "E 200",
      },
      {
        value: "201",
        brandId: "3",
        label: "LX 450d",
      },
      {
        value: "202",
        brandId: "5",
        label: "C 230",
      },
      {
        value: "203",
        brandId: "47",
        label: "Xedos 9",
      },
      {
        value: "204",
        brandId: "5",
        label: "C 200",
      },
      {
        value: "205",
        brandId: "48",
        label: "Terrain",
      },
      {
        value: "206",
        brandId: "8",
        label: "Maxima",
      },
      {
        value: "207",
        brandId: "49",
        label: "E5",
      },
      {
        value: "208",
        brandId: "50",
        label: "P7",
      },
      {
        value: "209",
        brandId: "51",
        label: "408",
      },
      {
        value: "210",
        brandId: "5",
        label: "C 300",
      },
      {
        value: "211",
        brandId: "51",
        label: "Rifter",
      },
      {
        value: "212",
        brandId: "50",
        label: "G9",
      },
      {
        value: "213",
        brandId: "51",
        label: "508",
      },
      {
        value: "214",
        brandId: "51",
        label: "e-2008",
      },
      {
        value: "215",
        brandId: "1",
        label: "Prado",
      },
      {
        value: "216",
        brandId: "6",
        label: "K7",
      },
      {
        value: "217",
        brandId: "52",
        label: "Passion",
      },
      {
        value: "218",
        brandId: "53",
        label: "65053",
      },
      {
        value: "219",
        brandId: "18",
        label: "Qiyuan A07",
      },
      {
        value: "220",
        brandId: "54",
        label: "U75 Plus",
      },
      {
        value: "221",
        brandId: "10",
        label: "RR Evoque",
      },
      {
        value: "222",
        brandId: "30",
        label: "Conquer 4x2",
      },
      {
        value: "223",
        brandId: "17",
        label: "Tiguan",
      },
      {
        value: "224",
        brandId: "9",
        label: "2109",
      },
      {
        value: "225",
        brandId: "16",
        label: "718 Cayman GT4 RS",
      },
      {
        value: "226",
        brandId: "38",
        label: "5410",
      },
      {
        value: "227",
        brandId: "55",
        label: "X70",
      },
      {
        value: "228",
        brandId: "16",
        label: "Taycan",
      },
      {
        value: "229",
        brandId: "16",
        label: "Taycan Turbo S",
      },
      {
        value: "230",
        brandId: "5",
        label: "E 280",
      },
      {
        value: "231",
        brandId: "32",
        label: "Modus",
      },
      {
        value: "232",
        brandId: "2",
        label: "R 1200 GS Adventure",
      },
      {
        value: "233",
        brandId: "1",
        label: "C-HR",
      },
      {
        value: "234",
        brandId: "5",
        label: "C 300 4MATIC",
      },
      {
        value: "235",
        brandId: "54",
        label: "U70 Pro",
      },
      {
        value: "236",
        brandId: "5",
        label: "Atego 818",
      },
      {
        value: "237",
        brandId: "43",
        label: "12",
      },
      {
        value: "238",
        brandId: "26",
        label: "City",
      },
      {
        value: "239",
        brandId: "56",
        label: "GSX 1300 R Hayabusa",
      },
      {
        value: "240",
        brandId: "3",
        label: "GS 200t",
      },
      {
        value: "241",
        brandId: "15",
        label: "Meriva",
      },
      {
        value: "242",
        brandId: "31",
        label: "F-Pace",
      },
      {
        value: "243",
        brandId: "57",
        label: "Model Y",
      },
      {
        value: "244",
        brandId: "6",
        label: "Niro",
      },
      {
        value: "245",
        brandId: "5",
        label: "E 300 de",
      },
      {
        value: "246",
        brandId: "34",
        label: "Doblo",
      },
      {
        value: "247",
        brandId: "58",
        label: "Sahin",
      },
      {
        value: "248",
        brandId: "1",
        label: "Aqua",
      },
      {
        value: "249",
        brandId: "59",
        label: "Nexia",
      },
      {
        value: "250",
        brandId: "60",
        label: "SXL 150",
      },
      {
        value: "251",
        brandId: "39",
        label: "Song Plus DM-İ",
      },
      {
        value: "252",
        brandId: "60",
        label: "VXL 150",
      },
      {
        value: "253",
        brandId: "5",
        label: "GLS 500 4MATIC",
      },
      {
        value: "254",
        brandId: "26",
        label: "H’ness CB350 DLX Pro",
      },
      {
        value: "255",
        brandId: "8",
        label: "Micra",
      },
      {
        value: "256",
        brandId: "61",
        label: "RS 125",
      },
      {
        value: "257",
        brandId: "8",
        label: "Wingroad",
      },
      {
        value: "258",
        brandId: "1",
        label: "Corolla Cross",
      },
      {
        value: "259",
        brandId: "2",
        label: "420",
      },
      {
        value: "260",
        brandId: "4",
        label: "Lacetti",
      },
      {
        value: "261",
        brandId: "18",
        label: "Uni-K iDD",
      },
      {
        value: "262",
        brandId: "7",
        label: "XC90",
      },
      {
        value: "263",
        brandId: "6",
        label: "Soul",
      },
      {
        value: "264",
        brandId: "5",
        label: "E 220",
      },
      {
        value: "265",
        brandId: "1",
        label: "Yaris Cross",
      },
      {
        value: "266",
        brandId: "23",
        label: "A5 Sportback",
      },
      {
        value: "267",
        brandId: "12",
        label: "Veloster",
      },
      {
        value: "268",
        brandId: "3",
        label: "NX 200t",
      },
      {
        value: "269",
        brandId: "16",
        label: "Panamera",
      },
      {
        value: "270",
        brandId: "4",
        label: "Cobalt",
      },
      {
        value: "271",
        brandId: "8",
        label: "Juke",
      },
      {
        value: "272",
        brandId: "4",
        label: "Rezzo",
      },
      {
        value: "273",
        brandId: "37",
        label: "009",
      },
      {
        value: "274",
        brandId: "12",
        label: "Staria",
      },
      {
        value: "275",
        brandId: "39",
        label: "Destroyer 05",
      },
      {
        value: "276",
        brandId: "5",
        label: "E 300 d",
      },
      {
        value: "277",
        brandId: "12",
        label: "Venue",
      },
      {
        value: "278",
        brandId: "12",
        label: "Palisade",
      },
      {
        value: "279",
        brandId: "62",
        label: "H5",
      },
      {
        value: "280",
        brandId: "3",
        label: "LX 570",
      },
      {
        value: "281",
        brandId: "5",
        label: "B 180",
      },
      {
        value: "282",
        brandId: "4",
        label: "Camaro",
      },
      {
        value: "283",
        brandId: "55",
        label: "X95",
      },
      {
        value: "284",
        brandId: "63",
        label: "NPR 66 L",
      },
      {
        value: "285",
        brandId: "5",
        label: "190",
      },
      {
        value: "286",
        brandId: "4",
        label: "Silverado",
      },
      {
        value: "287",
        brandId: "37",
        label: "X",
      },
      {
        value: "288",
        brandId: "5",
        label: "GL 450 4MATIC",
      },
      {
        value: "289",
        brandId: "5",
        label: "GL 500 4MATIC",
      },
      {
        value: "290",
        brandId: "12",
        label: "H-1",
      },
      {
        value: "291",
        brandId: "5",
        label: "410 D",
      },
      {
        value: "292",
        brandId: "64",
        label: "Atlas Pro",
      },
      {
        value: "293",
        brandId: "6",
        label: "EV9",
      },
      {
        value: "294",
        brandId: "5",
        label: "Vito 115",
      },
      {
        value: "295",
        brandId: "3",
        label: "GX 460",
      },
      {
        value: "296",
        brandId: "37",
        label: "007",
      },
      {
        value: "297",
        brandId: "63",
        label: "Turkuaz",
      },
      {
        value: "298",
        brandId: "65",
        label: "Nexia R3",
      },
      {
        value: "299",
        brandId: "66",
        label: "Flying Spur",
      },
      {
        value: "300",
        brandId: "39",
        label: "Yuan Plus",
      },
      {
        value: "301",
        brandId: "39",
        label: "e2",
      },
      {
        value: "302",
        brandId: "67",
        label: "D9",
      },
      {
        value: "303",
        brandId: "44",
        label: "Tribeca",
      },
      {
        value: "304",
        brandId: "39",
        label: "Leopard 5",
      },
      {
        value: "305",
        brandId: "31",
        label: "XJ L",
      },
      {
        value: "306",
        brandId: "4",
        label: "Corvette",
      },
      {
        value: "307",
        brandId: "5",
        label: "E 350 d",
      },
      {
        value: "308",
        brandId: "21",
        label: "Transit Connect",
      },
      {
        value: "309",
        brandId: "42",
        label: "2705-757",
      },
      {
        value: "310",
        brandId: "9",
        label: "2104",
      },
      {
        value: "311",
        brandId: "5",
        label: "GL 400 4MATIC",
      },
      {
        value: "312",
        brandId: "12",
        label: "Azera",
      },
      {
        value: "313",
        brandId: "12",
        label: "ix35",
      },
      {
        value: "314",
        brandId: "9",
        label: "2103",
      },
      {
        value: "315",
        brandId: "8",
        label: "Qashqai",
      },
      {
        value: "316",
        brandId: "68",
        label: "01",
      },
      {
        value: "317",
        brandId: "15",
        label: "Zafira",
      },
      {
        value: "318",
        brandId: "9",
        label: "Vesta SW Cross",
      },
      {
        value: "319",
        brandId: "2",
        label: "M5",
      },
      {
        value: "320",
        brandId: "11",
        label: "Outlander",
      },
      {
        value: "321",
        brandId: "64",
        label: "Coolray",
      },
      {
        value: "322",
        brandId: "69",
        label: "Raptor",
      },
      {
        value: "323",
        brandId: "18",
        label: "Uni-V",
      },
      {
        value: "324",
        brandId: "26",
        label: "e:NS1",
      },
      {
        value: "325",
        brandId: "8",
        label: "Kicks",
      },
      {
        value: "326",
        brandId: "21",
        label: "Fiesta",
      },
      {
        value: "327",
        brandId: "27",
        label: "Tank 500",
      },
      {
        value: "328",
        brandId: "5",
        label: "C 250",
      },
      {
        value: "329",
        brandId: "21",
        label: "Escape",
      },
      {
        value: "330",
        brandId: "69",
        label: "Jolion",
      },
      {
        value: "331",
        brandId: "12",
        label: "Creta",
      },
      {
        value: "332",
        brandId: "2",
        label: "325",
      },
      {
        value: "333",
        brandId: "9",
        label: "2114",
      },
      {
        value: "334",
        brandId: "2",
        label: "640",
      },
      {
        value: "335",
        brandId: "6",
        label: "Picanto",
      },
      {
        value: "336",
        brandId: "5",
        label: "C 240",
      },
      {
        value: "338",
        brandId: "33",
        label: "Amulet (A15)",
      },
      {
        value: "339",
        brandId: "71",
        label: "LD",
      },
      {
        value: "340",
        brandId: "40",
        label: "B70S",
      },
      {
        value: "341",
        brandId: "72",
        label: "ES8",
      },
      {
        value: "342",
        brandId: "5",
        label: "CLA 250 4MATIC",
      },
      {
        value: "343",
        brandId: "28",
        label: "650 NK",
      },
      {
        value: "344",
        brandId: "12",
        label: "Genesis Coupe",
      },
      {
        value: "345",
        brandId: "6",
        label: "Rio X-Line",
      },
      {
        value: "346",
        brandId: "5",
        label: "GLC 300 e 4MATIC+ Coupe",
      },
      {
        value: "347",
        brandId: "46",
        label: "Q50",
      },
      {
        value: "348",
        brandId: "5",
        label: "G 55 AMG",
      },
      {
        value: "349",
        brandId: "73",
        label: "X3 Pro",
      },
      {
        value: "350",
        brandId: "4",
        label: "Volt",
      },
      {
        value: "351",
        brandId: "18",
        label: "Uni-T",
      },
      {
        value: "352",
        brandId: "29",
        label: "XF 460 FT",
      },
      {
        value: "353",
        brandId: "12",
        label: "H 100",
      },
      {
        value: "354",
        brandId: "3",
        label: "ES 250",
      },
      {
        value: "355",
        brandId: "2",
        label: "X7",
      },
      {
        value: "357",
        brandId: "74",
        label: "Charger",
      },
      {
        value: "358",
        brandId: "40",
        label: "T77",
      },
      {
        value: "359",
        brandId: "17",
        label: "Touareg",
      },
      {
        value: "360",
        brandId: "16",
        label: "Macan",
      },
      {
        value: "361",
        brandId: "5",
        label: "S 400",
      },
      {
        value: "362",
        brandId: "75",
        label: "R420",
      },
      {
        value: "363",
        brandId: "5",
        label: "GL 63 AMG",
      },
      {
        value: "364",
        brandId: "46",
        label: "QX70",
      },
      {
        value: "365",
        brandId: "64",
        label: "Okavango",
      },
      {
        value: "366",
        brandId: "2",
        label: "540",
      },
      {
        value: "367",
        brandId: "2",
        label: "750e xDrive",
      },
      {
        value: "368",
        brandId: "23",
        label: "A1",
      },
      {
        value: "369",
        brandId: "47",
        label: "6",
      },
      {
        value: "370",
        brandId: "76",
        label: "Samand",
      },
      {
        value: "371",
        brandId: "47",
        label: "CX-9",
      },
      {
        value: "372",
        brandId: "69",
        label: "H6",
      },
      {
        value: "373",
        brandId: "11",
        label: "Lancer",
      },
      {
        value: "374",
        brandId: "5",
        label: "GLC 300 4MATIC Coupe",
      },
      {
        value: "375",
        brandId: "12",
        label: "Kona",
      },
      {
        value: "376",
        brandId: "5",
        label: "G 63 AMG",
      },
      {
        value: "377",
        brandId: "18",
        label: "Qiyuan A05",
      },
      {
        value: "378",
        brandId: "77",
        label: "Slavuta",
      },
      {
        value: "380",
        brandId: "8",
        label: "Teana",
      },
      {
        value: "381",
        brandId: "5",
        label: "GLK 250 4MATIC",
      },
      {
        value: "382",
        brandId: "16",
        label: "Macan GTS",
      },
      {
        value: "383",
        brandId: "18",
        label: "Uni-K",
      },
      {
        value: "384",
        brandId: "78",
        label: "F3000",
      },
      {
        value: "385",
        brandId: "2",
        label: "XM",
      },
      {
        value: "386",
        brandId: "5",
        label: "E 270",
      },
      {
        value: "387",
        brandId: "35",
        label: "S 580 4MATIC",
      },
      {
        value: "388",
        brandId: "5",
        label: "S 65 AMG",
      },
      {
        value: "389",
        brandId: "79",
        label: "M50",
      },
      {
        value: "390",
        brandId: "18",
        label: "CS 55 Plus",
      },
      {
        value: "391",
        brandId: "2",
        label: "M6",
      },
      {
        value: "392",
        brandId: "3",
        label: "NX 200",
      },
      {
        value: "394",
        brandId: "5",
        label: "C 300 e",
      },
      {
        value: "395",
        brandId: "4",
        label: "Tahoe",
      },
      {
        value: "396",
        brandId: "12",
        label: "Veracruz",
      },
      {
        value: "397",
        brandId: "70",
        label: "620",
      },
      {
        value: "398",
        brandId: "26",
        label: "Insight",
      },
      {
        value: "399",
        brandId: "18",
        label: "Alsvin V3",
      },
      {
        value: "400",
        brandId: "5",
        label: "Sprinter 312",
      },
      {
        value: "401",
        brandId: "29",
        label: "85 CF",
      },
      {
        value: "402",
        brandId: "4",
        label: "Captiva",
      },
      {
        value: "403",
        brandId: "5",
        label: "V 300",
      },
      {
        value: "404",
        brandId: "9",
        label: "Niva Travel",
      },
      {
        value: "405",
        brandId: "5",
        label: "E 230",
      },
      {
        value: "406",
        brandId: "5",
        label: "GLS 450",
      },
      {
        value: "407",
        brandId: "5",
        label: "Sprinter 412",
      },
      {
        value: "408",
        brandId: "8",
        label: "Latio",
      },
      {
        value: "409",
        brandId: "57",
        label: "Model S",
      },
      {
        value: "410",
        brandId: "21",
        label: "Mustang Mach-E",
      },
      {
        value: "411",
        brandId: "57",
        label: "Model 3",
      },
      {
        value: "412",
        brandId: "23",
        label: "Q7",
      },
      {
        value: "413",
        brandId: "24",
        label: "C11",
      },
      {
        value: "414",
        brandId: "17",
        label: "Passat CC",
      },
      {
        value: "415",
        brandId: "5",
        label: "Atego 1222",
      },
      {
        value: "416",
        brandId: "39",
        label: "Dolphin",
      },
      {
        value: "420",
        brandId: "4",
        label: "Trax",
      },
      {
        value: "421",
        brandId: "17",
        label: "Beetle",
      },
      {
        value: "422",
        brandId: "42",
        label: "M-21",
      },
      {
        value: "423",
        brandId: "21",
        label: "Mustang",
      },
      {
        value: "424",
        brandId: "14",
        label: "Cherokee",
      },
      {
        value: "425",
        brandId: "42",
        label: "2757",
      },
      {
        value: "426",
        brandId: "80",
        label: "Korando",
      },
      {
        value: "427",
        brandId: "5",
        label: "GLS 400 4MATIC",
      },
      {
        value: "428",
        brandId: "81",
        label: "Countryman",
      },
      {
        value: "429",
        brandId: "27",
        label: "Tank 300",
      },
      {
        value: "430",
        brandId: "7",
        label: "EX90",
      },
      {
        value: "431",
        brandId: "34",
        label: "Ducato",
      },
      {
        value: "432",
        brandId: "62",
        label: "HS5",
      },
      {
        value: "433",
        brandId: "56",
        label: "SX4",
      },
      {
        value: "434",
        brandId: "71",
        label: "LX",
      },
      {
        value: "435",
        brandId: "7",
        label: "XC60",
      },
      {
        value: "436",
        brandId: "7",
        label: "EX30",
      },
      {
        value: "437",
        brandId: "3",
        label: "IS 250",
      },
      {
        value: "441",
        brandId: "16",
        label: "Taycan 4 Cross Turismo",
      },
      {
        value: "442",
        brandId: "17",
        label: "ID.4 Crozz",
      },
      {
        value: "443",
        brandId: "82",
        label: "Escalade",
      },
      {
        value: "444",
        brandId: "42",
        label: "31105",
      },
      {
        value: "445",
        brandId: "63",
        label: "NMR 77 H",
      },
      {
        value: "446",
        brandId: "59",
        label: "Matiz",
      },
      {
        value: "447",
        brandId: "47",
        label: "3",
      },
      {
        value: "448",
        brandId: "6",
        label: "Stinger",
      },
      {
        value: "449",
        brandId: "9",
        label: "21011",
      },
      {
        value: "450",
        brandId: "18",
        label: "Qiyuan Q05",
      },
      {
        value: "451",
        brandId: "69",
        label: "H4",
      },
      {
        value: "452",
        brandId: "22",
        label: "Aeolus Shine GS",
      },
      {
        value: "453",
        brandId: "51",
        label: "Pars",
      },
      {
        value: "454",
        brandId: "12",
        label: "Terracan",
      },
      {
        value: "458",
        brandId: "12",
        label: "Kona Electric",
      },
      {
        value: "459",
        brandId: "65",
        label: "R4",
      },
      {
        value: "460",
        brandId: "16",
        label: "Cayenne Turbo S",
      },
      {
        value: "461",
        brandId: "2",
        label: "316",
      },
      {
        value: "462",
        brandId: "81",
        label: "Cooper",
      },
      {
        value: "463",
        brandId: "51",
        label: "406",
      },
      {
        value: "464",
        brandId: "19",
        label: "Fabia",
      },
      {
        value: "466",
        brandId: "9",
        label: "Kalina",
      },
      {
        value: "471",
        brandId: "3",
        label: "UX 250h",
      },
      {
        value: "472",
        brandId: "5",
        label: "Vario",
      },
      {
        value: "473",
        brandId: "83",
        label: "Ninja 400",
      },
      {
        value: "474",
        brandId: "23",
        label: "S5",
      },
      {
        value: "475",
        brandId: "6",
        label: "Seltos",
      },
      {
        value: "476",
        brandId: "3",
        label: "RX 330",
      },
      {
        value: "477",
        brandId: "5",
        label: "SLK 230",
      },
      {
        value: "478",
        brandId: "1",
        label: "Corolla Levin",
      },
      {
        value: "483",
        brandId: "5",
        label: "Metris",
      },
      {
        value: "484",
        brandId: "16",
        label: "Cayenne Turbo",
      },
      {
        value: "485",
        brandId: "1",
        label: "Hilux Surf",
      },
      {
        value: "486",
        brandId: "84",
        label: "GS3 Emzoom",
      },
      {
        value: "487",
        brandId: "84",
        label: "GS8",
      },
      {
        value: "488",
        brandId: "84",
        label: "Empow",
      },
      {
        value: "489",
        brandId: "57",
        label: "Model X",
      },
      {
        value: "490",
        brandId: "1",
        label: "bZ4X",
      },
      {
        value: "491",
        brandId: "1",
        label: "Sienta",
      },
      {
        value: "492",
        brandId: "44",
        label: "XV",
      },
      {
        value: "493",
        brandId: "5",
        label: "CLS 300",
      },
      {
        value: "494",
        brandId: "85",
        label: "Grecale",
      },
      {
        value: "495",
        brandId: "44",
        label: "Outback",
      },
      {
        value: "496",
        brandId: "5",
        label: "Atego 815",
      },
      {
        value: "497",
        brandId: "86",
        label: "D71 Plus",
      },
      {
        value: "502",
        brandId: "2",
        label: "630",
      },
      {
        value: "503",
        brandId: "4",
        label: "Malibu",
      },
      {
        value: "504",
        brandId: "16",
        label: "Cayenne GTS",
      },
      {
        value: "505",
        brandId: "11",
        label: "Pajero Sport",
      },
      {
        value: "506",
        brandId: "52",
        label: "Free",
      },
      {
        value: "508",
        brandId: "5",
        label: "S 63 AMG 4MATIC+",
      },
      {
        value: "509",
        brandId: "5",
        label: "E 200 d",
      },
      {
        value: "510",
        brandId: "5",
        label: "S 430",
      },
      {
        value: "518",
        brandId: "31",
        label: "F-Type R",
      },
      {
        value: "519",
        brandId: "32",
        label: "Grand Scenic",
      },
      {
        value: "521",
        brandId: "12",
        label: "Getz",
      },
      {
        value: "523",
        brandId: "16",
        label: "Panamera 4S Executive",
      },
      {
        value: "529",
        brandId: "5",
        label: "E 63 AMG",
      },
      {
        value: "530",
        brandId: "5",
        label: "E 430",
      },
      {
        value: "531",
        brandId: "7",
        label: "460",
      },
      {
        value: "534",
        brandId: "78",
        label: "F2000",
      },
      {
        value: "535",
        brandId: "88",
        label: "Elephant",
      },
      {
        value: "536",
        brandId: "88",
        label: "Cowfish",
      },
      {
        value: "537",
        brandId: "34",
        label: "500",
      },
      {
        value: "547",
        brandId: "2",
        label: "430",
      },
      {
        value: "548",
        brandId: "81",
        label: "Clubman",
      },
      {
        value: "549",
        brandId: "51",
        label: "307",
      },
      {
        value: "550",
        brandId: "2",
        label: "X6 M",
      },
      {
        value: "551",
        brandId: "2",
        label: "120",
      },
      {
        value: "552",
        brandId: "23",
        label: "A6 allroad",
      },
      {
        value: "553",
        brandId: "5",
        label: "Actros 1848",
      },
      {
        value: "554",
        brandId: "5",
        label: "Sprinter 318",
      },
      {
        value: "555",
        brandId: "2",
        label: "535",
      },
      {
        value: "565",
        brandId: "5",
        label: "GLC 250 Coupe",
      },
      {
        value: "566",
        brandId: "36",
        label: "L6",
      },
      {
        value: "567",
        brandId: "15",
        label: "Omega",
      },
      {
        value: "568",
        brandId: "42",
        label: "3302-744",
      },
      {
        value: "569",
        brandId: "79",
        label: "S 150",
      },
      {
        value: "572",
        brandId: "89",
        label: "09",
      },
      {
        value: "573",
        brandId: "21",
        label: "Mondeo",
      },
      {
        value: "574",
        brandId: "1",
        label: "Hilux",
      },
      {
        value: "575",
        brandId: "90",
        label: "X-boss",
      },
      {
        value: "576",
        brandId: "7",
        label: "FH 13",
      },
      {
        value: "577",
        brandId: "3",
        label: "GS 300",
      },
      {
        value: "587",
        brandId: "91",
        label: "California",
      },
      {
        value: "588",
        brandId: "6",
        label: "Shuma",
      },
      {
        value: "589",
        brandId: "5",
        label: "C 220",
      },
      {
        value: "590",
        brandId: "6",
        label: "Pride",
      },
      {
        value: "591",
        brandId: "44",
        label: "Legacy",
      },
      {
        value: "592",
        brandId: "5",
        label: "V 250",
      },
      {
        value: "593",
        brandId: "6",
        label: "EV6",
      },
      {
        value: "604",
        brandId: "1",
        label: "Harrier",
      },
      {
        value: "605",
        brandId: "5",
        label: "CLS 350",
      },
      {
        value: "606",
        brandId: "6",
        label: "Sedona",
      },
      {
        value: "607",
        brandId: "18",
        label: "Benni E-Star",
      },
      {
        value: "608",
        brandId: "36",
        label: "L7",
      },
      {
        value: "609",
        brandId: "18",
        label: "Uni-Z",
      },
      {
        value: "620",
        brandId: "64",
        label: "Emgrand",
      },
      {
        value: "621",
        brandId: "5",
        label: "ML 63 AMG",
      },
      {
        value: "622",
        brandId: "92",
        label: "Apache RTR 200",
      },
      {
        value: "623",
        brandId: "93",
        label: "Tiba",
      },
      {
        value: "624",
        brandId: "94",
        label: "SRK 600",
      },
      {
        value: "625",
        brandId: "64",
        label: "Galaxy E8",
      },
      {
        value: "626",
        brandId: "5",
        label: "SLK 200",
      },
      {
        value: "627",
        brandId: "64",
        label: "Tugella",
      },
      {
        value: "628",
        brandId: "64",
        label: "Monjaro",
      },
      {
        value: "629",
        brandId: "7",
        label: "S60",
      },
      {
        value: "630",
        brandId: "13",
        label: "TGA 18.480",
      },
      {
        value: "631",
        brandId: "42",
        label: "24-10",
      },
      {
        value: "643",
        brandId: "21",
        label: "F-150",
      },
      {
        value: "644",
        brandId: "95",
        label: "Sportsman 450",
      },
      {
        value: "645",
        brandId: "17",
        label: "Bora",
      },
      {
        value: "646",
        brandId: "34",
        label: "Tipo",
      },
      {
        value: "647",
        brandId: "5",
        label: "E 300 4MATIC",
      },
      {
        value: "648",
        brandId: "26",
        label: "CMX 500",
      },
      {
        value: "649",
        brandId: "96",
        label: "3241V",
      },
      {
        value: "660",
        brandId: "12",
        label: "Galloper",
      },
      {
        value: "661",
        brandId: "97",
        label: "Crux",
      },
      {
        value: "662",
        brandId: "8",
        label: "March",
      },
      {
        value: "663",
        brandId: "93",
        label: "132",
      },
      {
        value: "664",
        brandId: "98",
        label: "SU7",
      },
      {
        value: "665",
        brandId: "5",
        label: "208 D",
      },
      {
        value: "666",
        brandId: "5",
        label: "GLE 63 S AMG 4MATIC Coupe",
      },
      {
        value: "677",
        brandId: "52",
        label: "Dream",
      },
      {
        value: "678",
        brandId: "84",
        label: "Trumpchi GA5",
      },
      {
        value: "679",
        brandId: "3",
        label: "NX 350h",
      },
      {
        value: "680",
        brandId: "12",
        label: "Mighty EX6",
      },
      {
        value: "681",
        brandId: "12",
        label: "County",
      },
      {
        value: "682",
        brandId: "12",
        label: "Mighty EX9",
      },
      {
        value: "684",
        brandId: "31",
        label: "I-Pace",
      },
      {
        value: "695",
        brandId: "84",
        label: "Trumpchi GS5",
      },
      {
        value: "697",
        brandId: "51",
        label: "308 CC",
      },
      {
        value: "707",
        brandId: "9",
        label: "2112",
      },
      {
        value: "708",
        brandId: "74",
        label: "Durango",
      },
      {
        value: "709",
        brandId: "3",
        label: "LX 600",
      },
      {
        value: "710",
        brandId: "21",
        label: "Cargo",
      },
      {
        value: "711",
        brandId: "12",
        label: "HD-72",
      },
      {
        value: "721",
        brandId: "85",
        label: "Quattroporte",
      },
      {
        value: "722",
        brandId: "5",
        label: "GLE 350 4MATIC",
      },
      {
        value: "732",
        brandId: "5",
        label: "E 250 4MATIC",
      },
      {
        value: "733",
        brandId: "23",
        label: "A7",
      },
      {
        value: "734",
        brandId: "2",
        label: "i7",
      },
      {
        value: "735",
        brandId: "5",
        label: "E 450 4MATIC",
      },
      {
        value: "736",
        brandId: "74",
        label: "Caliber",
      },
      {
        value: "746",
        brandId: "17",
        label: "Atlas",
      },
      {
        value: "747",
        brandId: "42",
        label: "53",
      },
      {
        value: "748",
        brandId: "99",
        label: "412",
      },
      {
        value: "749",
        brandId: "51",
        label: "208",
      },
      {
        value: "750",
        brandId: "23",
        label: "S8",
      },
      {
        value: "760",
        brandId: "3",
        label: "ES 350",
      },
      {
        value: "761",
        brandId: "5",
        label: "Sprinter 311",
      },
      {
        value: "762",
        brandId: "9",
        label: "21045",
      },
      {
        value: "763",
        brandId: "61",
        label: "RS 660",
      },
      {
        value: "764",
        brandId: "5",
        label: "Sprinter 516",
      },
      {
        value: "774",
        brandId: "6",
        label: "Stonic",
      },
      {
        value: "775",
        brandId: "5",
        label: "Axor 3340",
      },
      {
        value: "776",
        brandId: "38",
        label: "53229",
      },
      {
        value: "777",
        brandId: "96",
        label: "371",
      },
      {
        value: "778",
        brandId: "9",
        label: "Niva Bronto",
      },
      {
        value: "779",
        brandId: "9",
        label: "Largus",
      },
      {
        value: "780",
        brandId: "15",
        label: "Mokka X",
      },
      {
        value: "789",
        brandId: "17",
        label: "Jetta",
      },
      {
        value: "790",
        brandId: "55",
        label: "Dashing",
      },
      {
        value: "791",
        brandId: "71",
        label: "SD",
      },
      {
        value: "792",
        brandId: "5",
        label: "ML 350",
      },
      {
        value: "799",
        brandId: "5",
        label: "Actros 3235",
      },
      {
        value: "800",
        brandId: "66",
        label: "Continental GT",
      },
      {
        value: "801",
        brandId: "18",
        label: "CS 85",
      },
      {
        value: "802",
        brandId: "25",
        label: "5301",
      },
      {
        value: "803",
        brandId: "6",
        label: "K8",
      },
      {
        value: "808",
        brandId: "27",
        label: "Wingle",
      },
      {
        value: "809",
        brandId: "89",
        label: "06",
      },
      {
        value: "810",
        brandId: "100",
        label: "Tiger VN",
      },
      {
        value: "811",
        brandId: "100",
        label: "J6P dumper 6x4",
      },
      {
        value: "812",
        brandId: "100",
        label: "J6P dumper 8x4",
      },
      {
        value: "813",
        brandId: "100",
        label: "Tiger VH-L",
      },
      {
        value: "818",
        brandId: "79",
        label: "CUB50",
      },
      {
        value: "819",
        brandId: "101",
        label: "Pulsar RS 200",
      },
      {
        value: "820",
        brandId: "5",
        label: "S 400 d",
      },
      {
        value: "821",
        brandId: "2",
        label: "X1",
      },
      {
        value: "826",
        brandId: "75",
        label: "G420",
      },
      {
        value: "827",
        brandId: "101",
        label: "Dominar 400",
      },
      {
        value: "828",
        brandId: "5",
        label: "Atego 814",
      },
      {
        value: "831",
        brandId: "8",
        label: "Sentra",
      },
      {
        value: "832",
        brandId: "102",
        label: "917",
      },
      {
        value: "833",
        brandId: "59",
        label: "Racer",
      },
      {
        value: "834",
        brandId: "103",
        label: "DB11",
      },
      {
        value: "835",
        brandId: "42",
        label: "3105",
      },
      {
        value: "836",
        brandId: "82",
        label: "ATS",
      },
      {
        value: "837",
        brandId: "17",
        label: "ID.6",
      },
      {
        value: "841",
        brandId: "5",
        label: "S 450 4MATIC",
      },
      {
        value: "842",
        brandId: "23",
        label: "RS7",
      },
      {
        value: "843",
        brandId: "10",
        label: "Freelander",
      },
      {
        value: "844",
        brandId: "23",
        label: "R8",
      },
      {
        value: "845",
        brandId: "5",
        label: "GLS 350 4MATIC",
      },
      {
        value: "846",
        brandId: "3",
        label: "LS 500",
      },
      {
        value: "847",
        brandId: "104",
        label: "One",
      },
      {
        value: "848",
        brandId: "23",
        label: "A8",
      },
      {
        value: "851",
        brandId: "5",
        label: "E 350 4MATIC",
      },
      {
        value: "852",
        brandId: "56",
        label: "GSR 750",
      },
      {
        value: "853",
        brandId: "30",
        label: "Grand Avenue",
      },
      {
        value: "854",
        brandId: "3",
        label: "RX 300",
      },
      {
        value: "856",
        brandId: "5",
        label: "Sprinter 308",
      },
      {
        value: "859",
        brandId: "5",
        label: "814 D",
      },
      {
        value: "860",
        brandId: "2",
        label: "X4",
      },
      {
        value: "861",
        brandId: "16",
        label: "Taycan GTS",
      },
      {
        value: "862",
        brandId: "84",
        label: "Aion S",
      },
      {
        value: "863",
        brandId: "5",
        label: "Atego 816",
      },
      {
        value: "864",
        brandId: "84",
        label: "Trumpchi ES9",
      },
      {
        value: "865",
        brandId: "84",
        label: "Aion Hyper GT",
      },
      {
        value: "866",
        brandId: "64",
        label: "Radar RD6",
      },
      {
        value: "867",
        brandId: "105",
        label: "Eletre",
      },
      {
        value: "868",
        brandId: "84",
        label: "Emkoo",
      },
      {
        value: "871",
        brandId: "15",
        label: "Mokka",
      },
      {
        value: "872",
        brandId: "1",
        label: "Vitz",
      },
      {
        value: "873",
        brandId: "5",
        label: "G 350",
      },
      {
        value: "877",
        brandId: "2",
        label: "M440i",
      },
      {
        value: "878",
        brandId: "2",
        label: "840",
      },
      {
        value: "879",
        brandId: "103",
        label: "Rapide",
      },
      {
        value: "880",
        brandId: "5",
        label: "Atego 1828",
      },
      {
        value: "882",
        brandId: "23",
        label: "Q8",
      },
      {
        value: "883",
        brandId: "5",
        label: "EQC 400 4MATIC",
      },
      {
        value: "884",
        brandId: "33",
        label: "Tiggo 2 Pro",
      },
      {
        value: "885",
        brandId: "106",
        label: "Napoleon 500",
      },
      {
        value: "886",
        brandId: "2",
        label: "X5 M",
      },
      {
        value: "887",
        brandId: "9",
        label: "2105",
      },
      {
        value: "891",
        brandId: "22",
        label: "Aeolus Shine Max",
      },
      {
        value: "892",
        brandId: "5",
        label: "ML 550",
      },
      {
        value: "893",
        brandId: "15",
        label: "Signum",
      },
      {
        value: "894",
        brandId: "107",
        label: "Granite",
      },
      {
        value: "895",
        brandId: "66",
        label: "Bentayga",
      },
      {
        value: "896",
        brandId: "108",
        label: "U-Tour",
      },
      {
        value: "897",
        brandId: "83",
        label: "Z650",
      },
      {
        value: "898",
        brandId: "109",
        label: "Pickup",
      },
      {
        value: "899",
        brandId: "2",
        label: "iX",
      },
      {
        value: "900",
        brandId: "2",
        label: "i5",
      },
      {
        value: "902",
        brandId: "2",
        label: "i7 M70",
      },
      {
        value: "903",
        brandId: "5",
        label: "A 160",
      },
      {
        value: "904",
        brandId: "3",
        label: "CT 200h",
      },
      {
        value: "908",
        brandId: "21",
        label: "Ranger",
      },
      {
        value: "909",
        brandId: "8",
        label: "Leaf",
      },
      {
        value: "910",
        brandId: "110",
        label: "Naked",
      },
      {
        value: "911",
        brandId: "21",
        label: "Fusion",
      },
      {
        value: "912",
        brandId: "9",
        label: "21123",
      },
      {
        value: "913",
        brandId: "55",
        label: "T2",
      },
      {
        value: "918",
        brandId: "1",
        label: "Frontlander",
      },
      {
        value: "919",
        brandId: "111",
        label: "Mati 125",
      },
      {
        value: "920",
        brandId: "26",
        label: "H’ness CB350 DLX",
      },
      {
        value: "921",
        brandId: "17",
        label: "ID.7",
      },
      {
        value: "923",
        brandId: "5",
        label: "E 180",
      },
      {
        value: "926",
        brandId: "11",
        label: "Eclipse Cross",
      },
      {
        value: "927",
        brandId: "111",
        label: "Trodon XS 50",
      },
      {
        value: "928",
        brandId: "111",
        label: "Nev 50",
      },
      {
        value: "929",
        brandId: "5",
        label: "GLE 450 AMG 4MATIC",
      },
      {
        value: "930",
        brandId: "97",
        label: "MT-15",
      },
      {
        value: "931",
        brandId: "97",
        label: "FZ-S FI Deluxe",
      },
      {
        value: "932",
        brandId: "112",
        label: "X250",
      },
      {
        value: "933",
        brandId: "26",
        label: "CB300R",
      },
      {
        value: "934",
        brandId: "5",
        label: "Vito 113",
      },
      {
        value: "935",
        brandId: "19",
        label: "Superb",
      },
      {
        value: "936",
        brandId: "100",
        label: "Tiger VR",
      },
      {
        value: "940",
        brandId: "78",
        label: "SX3314DT366",
      },
      {
        value: "941",
        brandId: "2",
        label: "M4",
      },
      {
        value: "942",
        brandId: "5",
        label: "S 600",
      },
      {
        value: "943",
        brandId: "96",
        label: "Sinotruk",
      },
      {
        value: "944",
        brandId: "76",
        label: "Soren",
      },
      {
        value: "945",
        brandId: "5",
        label: "EQE 350 4MATIC SUV",
      },
      {
        value: "949",
        brandId: "5",
        label: "E 260",
      },
      {
        value: "950",
        brandId: "86",
        label: "D51",
      },
      {
        value: "951",
        brandId: "5",
        label: "308 D",
      },
      {
        value: "953",
        brandId: "5",
        label: "A 200",
      },
      {
        value: "954",
        brandId: "21",
        label: "Maverick",
      },
      {
        value: "955",
        brandId: "2",
        label: "520e",
      },
      {
        value: "956",
        brandId: "5",
        label: "Atego 817",
      },
      {
        value: "957",
        brandId: "4",
        label: "Orlando",
      },
      {
        value: "962",
        brandId: "32",
        label: "Tondar",
      },
      {
        value: "963",
        brandId: "113",
        label: "Ibiza",
      },
      {
        value: "964",
        brandId: "5",
        label: "ML 320 4MATIC",
      },
      {
        value: "965",
        brandId: "56",
        label: "Grand Vitara",
      },
      {
        value: "966",
        brandId: "15",
        label: "Corsa",
      },
      {
        value: "970",
        brandId: "14",
        label: "Renegade",
      },
      {
        value: "974",
        brandId: "2",
        label: "760",
      },
      {
        value: "979",
        brandId: "12",
        label: "Matrix",
      },
      {
        value: "980",
        brandId: "84",
        label: "M8",
      },
      {
        value: "981",
        brandId: "6",
        label: "Cadenza",
      },
      {
        value: "985",
        brandId: "5",
        label: "GLA 250",
      },
      {
        value: "986",
        brandId: "39",
        label: "F3",
      },
      {
        value: "987",
        brandId: "8",
        label: "Ariya",
      },
      {
        value: "988",
        brandId: "84",
        label: "E8",
      },
      {
        value: "989",
        brandId: "80",
        label: "Tivoli",
      },
      {
        value: "993",
        brandId: "93",
        label: "Saina",
      },
      {
        value: "997",
        brandId: "5",
        label: "AMG GT 43 4MATIC+",
      },
      {
        value: "998",
        brandId: "5",
        label: "E 280 4MATIC",
      },
      {
        value: "999",
        brandId: "5",
        label: "C 200 d",
      },
      {
        value: "1003",
        brandId: "33",
        label: "Tiggo 8 Pro e+",
      },
      {
        value: "1004",
        brandId: "51",
        label: "Khazar 406",
      },
      {
        value: "1005",
        brandId: "5",
        label: "G 500",
      },
      {
        value: "1009",
        brandId: "114",
        label: "Sportster Custom 1200",
      },
      {
        value: "1010",
        brandId: "115",
        label: "TG200",
      },
      {
        value: "1011",
        brandId: "108",
        label: "S60 EV",
      },
      {
        value: "1012",
        brandId: "108",
        label: "T5 EVO",
      },
      {
        value: "1013",
        brandId: "21",
        label: "Tourneo Custom",
      },
      {
        value: "1014",
        brandId: "22",
        label: "Rich 6",
      },
      {
        value: "1015",
        brandId: "108",
        label: "Friday EV",
      },
      {
        value: "1016",
        brandId: "5",
        label: "E 500",
      },
      {
        value: "1020",
        brandId: "9",
        label: "2108",
      },
      {
        value: "1021",
        brandId: "5",
        label: "GLC 200 4MATIC+ Coupe",
      },
      {
        value: "1024",
        brandId: "51",
        label: "3008",
      },
      {
        value: "1025",
        brandId: "30",
        label: "JX5066",
      },
      {
        value: "1026",
        brandId: "38",
        label: "43101",
      },
      {
        value: "1029",
        brandId: "13",
        label: "TGA 480",
      },
      {
        value: "1032",
        brandId: "5",
        label: "CLA 180",
      },
      {
        value: "1033",
        brandId: "47",
        label: "CX-7",
      },
      {
        value: "1034",
        brandId: "2",
        label: "i3",
      },
      {
        value: "1037",
        brandId: "116",
        label: "Tiger ZT150-3A",
      },
      {
        value: "1038",
        brandId: "9",
        label: "2110",
      },
      {
        value: "1040",
        brandId: "21",
        label: "C-MAX",
      },
      {
        value: "1041",
        brandId: "32",
        label: "Megane Scenic",
      },
      {
        value: "1042",
        brandId: "11",
        label: "Attrage",
      },
      {
        value: "1043",
        brandId: "9",
        label: "21103",
      },
      {
        value: "1045",
        brandId: "42",
        label: "33023-744",
      },
      {
        value: "1046",
        brandId: "1",
        label: "Venza",
      },
      {
        value: "1048",
        brandId: "46",
        label: "JX35",
      },
      {
        value: "1049",
        brandId: "6",
        label: "Joice",
      },
      {
        value: "1050",
        brandId: "18",
        label: "Star",
      },
      {
        value: "1051",
        brandId: "4",
        label: "Monza",
      },
      {
        value: "1053",
        brandId: "18",
        label: "CS 35 Plus",
      },
      {
        value: "1054",
        brandId: "8",
        label: "Murano",
      },
      {
        value: "1055",
        brandId: "97",
        label: "R15 V4",
      },
      {
        value: "1056",
        brandId: "9",
        label: "Vesta",
      },
      {
        value: "1058",
        brandId: "18",
        label: "CX 20",
      },
      {
        value: "1059",
        brandId: "5",
        label: "S 550 e",
      },
      {
        value: "1060",
        brandId: "87",
        label: "D20",
      },
      {
        value: "1062",
        brandId: "1",
        label: "Sequoia",
      },
      {
        value: "1063",
        brandId: "38",
        label: "55115",
      },
      {
        value: "1064",
        brandId: "117",
        label: "Navigator",
      },
      {
        value: "1065",
        brandId: "36",
        label: "Mega",
      },
      {
        value: "1066",
        brandId: "6",
        label: "ProCeed",
      },
      {
        value: "1067",
        brandId: "3",
        label: "GS 300h",
      },
      {
        value: "1069",
        brandId: "118",
        label: "5549",
      },
      {
        value: "1070",
        brandId: "5",
        label: "GLC 250 4MATIC Coupe",
      },
      {
        value: "1072",
        brandId: "1",
        label: "Prius C",
      },
      {
        value: "1074",
        brandId: "97",
        label: "Grizzly 660",
      },
      {
        value: "1075",
        brandId: "4",
        label: "Epica",
      },
      {
        value: "1076",
        brandId: "5",
        label: "S 350 d 4MATIC",
      },
      {
        value: "1078",
        brandId: "75",
        label: "R500",
      },
      {
        value: "1079",
        brandId: "23",
        label: "TT",
      },
      {
        value: "1080",
        brandId: "34",
        label: "500L",
      },
      {
        value: "1081",
        brandId: "46",
        label: "FX35",
      },
      {
        value: "1083",
        brandId: "46",
        label: "QX4",
      },
      {
        value: "1085",
        brandId: "5",
        label: "Sprinter 313",
      },
      {
        value: "1086",
        brandId: "5",
        label: "S 350 d",
      },
      {
        value: "1087",
        brandId: "5",
        label: "711 D",
      },
      {
        value: "1089",
        brandId: "1",
        label: "Prius Prime",
      },
      {
        value: "1090",
        brandId: "17",
        label: "Golf",
      },
      {
        value: "1092",
        brandId: "17",
        label: "ID.4 X",
      },
      {
        value: "1093",
        brandId: "26",
        label: "Pilot",
      },
      {
        value: "1094",
        brandId: "5",
        label: "C 280",
      },
      {
        value: "1096",
        brandId: "119",
        label: "RD6",
      },
      {
        value: "1098",
        brandId: "120",
        label: "U",
      },
      {
        value: "1099",
        brandId: "5",
        label: "B 200",
      },
      {
        value: "1100",
        brandId: "121",
        label: "H3",
      },
      {
        value: "1101",
        brandId: "74",
        label: "Challenger",
      },
      {
        value: "1102",
        brandId: "5",
        label: "GLE 350 4MATIC Coupe",
      },
      {
        value: "1103",
        brandId: "1",
        label: "Aygo",
      },
      {
        value: "1104",
        brandId: "5",
        label: "Vito 120",
      },
      {
        value: "1105",
        brandId: "5",
        label: "Axor 1843",
      },
      {
        value: "1106",
        brandId: "17",
        label: "Vento",
      },
      {
        value: "1108",
        brandId: "122",
        label: "RC 390",
      },
      {
        value: "1109",
        brandId: "123",
        label: "Planeta 5",
      },
      {
        value: "1110",
        brandId: "1",
        label: "Yaris iA",
      },
      {
        value: "1111",
        brandId: "7",
        label: "C40",
      },
      {
        value: "1112",
        brandId: "30",
        label: "Vigus Plus",
      },
      {
        value: "1113",
        brandId: "59",
        label: "Damas",
      },
      {
        value: "1114",
        brandId: "109",
        label: "Profi",
      },
      {
        value: "1115",
        brandId: "109",
        label: "Hunter",
      },
      {
        value: "1116",
        brandId: "46",
        label: "Q70",
      },
      {
        value: "1117",
        brandId: "109",
        label: "Patriot",
      },
      {
        value: "1118",
        brandId: "33",
        label: "Arrizo 5",
      },
      {
        value: "1119",
        brandId: "5",
        label: "320 E 4MATIC",
      },
      {
        value: "1120",
        brandId: "23",
        label: "A5",
      },
      {
        value: "1121",
        brandId: "17",
        label: "Transporter",
      },
      {
        value: "1122",
        brandId: "8",
        label: "Primera",
      },
      {
        value: "1124",
        brandId: "56",
        label: "Burgman",
      },
      {
        value: "1125",
        brandId: "5",
        label: "Atego 1218",
      },
      {
        value: "1126",
        brandId: "46",
        label: "QX50",
      },
      {
        value: "1127",
        brandId: "5",
        label: "560 SEL",
      },
      {
        value: "1128",
        brandId: "47",
        label: "CX-60",
      },
      {
        value: "1129",
        brandId: "47",
        label: "CX-30",
      },
      {
        value: "1130",
        brandId: "47",
        label: "CX-90",
      },
      {
        value: "1131",
        brandId: "77",
        label: "968M",
      },
      {
        value: "1132",
        brandId: "120",
        label: "V",
      },
      {
        value: "1133",
        brandId: "5",
        label: "EQS 53 AMG 4MATIC+.",
      },
      {
        value: "1134",
        brandId: "3",
        label: "IS 200",
      },
      {
        value: "1135",
        brandId: "5",
        label: "X 250D 4MATIC",
      },
      {
        value: "1136",
        brandId: "2",
        label: "323",
      },
      {
        value: "1137",
        brandId: "86",
        label: "C-35",
      },
      {
        value: "1138",
        brandId: "5",
        label: "Actros 1846",
      },
      {
        value: "1139",
        brandId: "5",
        label: "G 300",
      },
      {
        value: "1140",
        brandId: "3",
        label: "ES 200",
      },
      {
        value: "1141",
        brandId: "39",
        label: "Song Plus EV",
      },
      {
        value: "1142",
        brandId: "121",
        label: "H2",
      },
      {
        value: "1143",
        brandId: "17",
        label: "Phaeton",
      },
      {
        value: "1144",
        brandId: "46",
        label: "QX56",
      },
      {
        value: "1145",
        brandId: "112",
        label: "D4 125",
      },
      {
        value: "1146",
        brandId: "124",
        label: "Berlingo",
      },
      {
        value: "1147",
        brandId: "59",
        label: "Gentra",
      },
      {
        value: "1148",
        brandId: "32",
        label: "Master",
      },
      {
        value: "1149",
        brandId: "1",
        label: "Sienna",
      },
      {
        value: "1150",
        brandId: "125",
        label: "Huracan",
      },
      {
        value: "1151",
        brandId: "31",
        label: "XE",
      },
      {
        value: "1152",
        brandId: "8",
        label: "Pathfinder",
      },
      {
        value: "1153",
        brandId: "19",
        label: "Kamiq",
      },
      {
        value: "1154",
        brandId: "21",
        label: "Kuga",
      },
      {
        value: "1155",
        brandId: "2",
        label: "523",
      },
      {
        value: "1156",
        brandId: "18",
        label: "CS 95",
      },
      {
        value: "1157",
        brandId: "2",
        label: "335",
      },
      {
        value: "1158",
        brandId: "59",
        label: "Lanos",
      },
      {
        value: "1161",
        brandId: "5",
        label: "GLC 300 e 4MATIC",
      },
      {
        value: "1162",
        brandId: "5",
        label: "GLB 200 4MATIC",
      },
      {
        value: "1163",
        brandId: "5",
        label: "GLC 200 4MATIC",
      },
      {
        value: "1164",
        brandId: "5",
        label: "GLE 350",
      },
      {
        value: "1168",
        brandId: "1",
        label: "Auris",
      },
      {
        value: "1169",
        brandId: "17",
        label: "Multivan",
      },
      {
        value: "1170",
        brandId: "80",
        label: "Rexton",
      },
      {
        value: "1174",
        brandId: "33",
        label: "Tiggo 7 Pro e+",
      },
      {
        value: "1178",
        brandId: "81",
        label: "Paceman",
      },
      {
        value: "1182",
        brandId: "17",
        label: "ID.4",
      },
      {
        value: "1183",
        brandId: "56",
        label: "S-Cross",
      },
      {
        value: "1184",
        brandId: "56",
        label: "Fronx",
      },
      {
        value: "1188",
        brandId: "5",
        label: "GLC 63 S AMG 4MATIC+",
      },
      {
        value: "1189",
        brandId: "6",
        label: "Venga",
      },
      {
        value: "1190",
        brandId: "7",
        label: "FH 460",
      },
      {
        value: "1191",
        brandId: "8",
        label: "AD",
      },
      {
        value: "1192",
        brandId: "3",
        label: "RX 350h",
      },
      {
        value: "1196",
        brandId: "16",
        label: "Cayenne Turbo Coupe",
      },
      {
        value: "1197",
        brandId: "5",
        label: "AMG GT 63 S",
      },
      {
        value: "1198",
        brandId: "46",
        label: "Q70S",
      },
      {
        value: "1199",
        brandId: "16",
        label: "Macan S",
      },
      {
        value: "1200",
        brandId: "76",
        label: "Runna",
      },
      {
        value: "1204",
        brandId: "15",
        label: "Tigra",
      },
      {
        value: "1205",
        brandId: "5",
        label: "ML 270 4MATIC",
      },
      {
        value: "1206",
        brandId: "89",
        label: "05",
      },
      {
        value: "1208",
        brandId: "5",
        label: "S 63 AMG 4MATIC",
      },
      {
        value: "1209",
        brandId: "89",
        label: "03",
      },
      {
        value: "1210",
        brandId: "26",
        label: "CB650R",
      },
      {
        value: "1212",
        brandId: "1",
        label: "Esquire",
      },
      {
        value: "1213",
        brandId: "1",
        label: "Avensis",
      },
      {
        value: "1217",
        brandId: "40",
        label: "T55",
      },
      {
        value: "1218",
        brandId: "5",
        label: "250",
      },
      {
        value: "1219",
        brandId: "6",
        label: "Mohave",
      },
      {
        value: "1220",
        brandId: "2",
        label: "745",
      },
      {
        value: "1225",
        brandId: "1",
        label: "Proace",
      },
      {
        value: "1226",
        brandId: "5",
        label: "S 350 4MATIC",
      },
      {
        value: "1227",
        brandId: "109",
        label: "3303",
      },
      {
        value: "1228",
        brandId: "60",
        label: "GTS 300",
      },
      {
        value: "1229",
        brandId: "7",
        label: "XC40",
      },
      {
        value: "1230",
        brandId: "31",
        label: "F-Type",
      },
      {
        value: "1231",
        brandId: "109",
        label: "33036",
      },
      {
        value: "1232",
        brandId: "4",
        label: "Tracker",
      },
      {
        value: "1237",
        brandId: "46",
        label: "EX35",
      },
      {
        value: "1238",
        brandId: "126",
        label: "Double Six",
      },
      {
        value: "1245",
        brandId: "5",
        label: "CLK 200",
      },
      {
        value: "1246",
        brandId: "46",
        label: "Q50S",
      },
      {
        value: "1251",
        brandId: "47",
        label: "5",
      },
      {
        value: "1252",
        brandId: "5",
        label: "GLK 350 4MATIC",
      },
      {
        value: "1253",
        brandId: "5",
        label: "CLK 230",
      },
      {
        value: "1257",
        brandId: "42",
        label: "3102",
      },
      {
        value: "1258",
        brandId: "114",
        label: "FLHX Street Glide",
      },
      {
        value: "1259",
        brandId: "5",
        label: "GLE 450 AMG 4MATIC Coupe",
      },
      {
        value: "1260",
        brandId: "22",
        label: "Aeolus S30",
      },
      {
        value: "1261",
        brandId: "9",
        label: "2101",
      },
      {
        value: "1262",
        brandId: "42",
        label: "3302-288",
      },
      {
        value: "1263",
        brandId: "12",
        label: "Verna",
      },
      {
        value: "1264",
        brandId: "12",
        label: "ix55",
      },
      {
        value: "1265",
        brandId: "46",
        label: "FX37",
      },
      {
        value: "1266",
        brandId: "32",
        label: "Scenic",
      },
      {
        value: "1267",
        brandId: "38",
        label: "54112",
      },
      {
        value: "1269",
        brandId: "117",
        label: "MKZ",
      },
      {
        value: "1270",
        brandId: "19",
        label: "Rapid",
      },
      {
        value: "1271",
        brandId: "33",
        label: "Tiggo 3",
      },
      {
        value: "1274",
        brandId: "38",
        label: "65115",
      },
      {
        value: "1275",
        brandId: "9",
        label: "Oka",
      },
      {
        value: "1276",
        brandId: "34",
        label: "Albea",
      },
      {
        value: "1277",
        brandId: "75",
        label: "P380",
      },
      {
        value: "1278",
        brandId: "115",
        label: "Muravey- 2 01",
      },
      {
        value: "1279",
        brandId: "97",
        label: "R15M",
      },
      {
        value: "1280",
        brandId: "127",
        label: "50 ZNU",
      },
      {
        value: "1281",
        brandId: "82",
        label: "CT6",
      },
      {
        value: "1282",
        brandId: "46",
        label: "M37S",
      },
      {
        value: "1283",
        brandId: "128",
        label: "X-PRO",
      },
      {
        value: "1284",
        brandId: "104",
        label: "350",
      },
      {
        value: "1285",
        brandId: "5",
        label: "GLE 400",
      },
      {
        value: "1286",
        brandId: "69",
        label: "H9",
      },
      {
        value: "1287",
        brandId: "56",
        label: "Jimny",
      },
      {
        value: "1288",
        brandId: "3",
        label: "GS 350",
      },
      {
        value: "1289",
        brandId: "2",
        label: "728",
      },
      {
        value: "1290",
        brandId: "127",
        label: "Voyager",
      },
      {
        value: "1291",
        brandId: "16",
        label: "911 Carrera",
      },
      {
        value: "1292",
        brandId: "97",
        label: "R15",
      },
      {
        value: "1293",
        brandId: "85",
        label: "Ghibli",
      },
      {
        value: "1294",
        brandId: "109",
        label: "390945",
      },
      {
        value: "1295",
        brandId: "118",
        label: "5334",
      },
      {
        value: "1296",
        brandId: "79",
        label: "Istiqlal",
      },
      {
        value: "1297",
        brandId: "5",
        label: "Vito 116",
      },
      {
        value: "1298",
        brandId: "63",
        label: "NMR 85 H",
      },
      {
        value: "1299",
        brandId: "38",
        label: "6520",
      },
      {
        value: "1300",
        brandId: "63",
        label: "NPR75L-K",
      },
      {
        value: "1301",
        brandId: "35",
        label: "S 450",
      },
      {
        value: "1302",
        brandId: "5",
        label: "S 550",
      },
      {
        value: "1303",
        brandId: "128",
        label: "HK6818K",
      },
      {
        value: "1304",
        brandId: "51",
        label: "405",
      },
      {
        value: "1305",
        brandId: "5",
        label: "GLE 250 4MATIC",
      },
      {
        value: "1306",
        brandId: "16",
        label: "Panamera 4 E-Hybrid",
      },
      {
        value: "1307",
        brandId: "18",
        label: "Lamore",
      },
      {
        value: "1308",
        brandId: "69",
        label: "H6 GT",
      },
      {
        value: "1309",
        brandId: "69",
        label: "M6",
      },
      {
        value: "1310",
        brandId: "5",
        label: "CLS 53 AMG 4MATIC+",
      },
      {
        value: "1311",
        brandId: "16",
        label: "Taycan 4S",
      },
      {
        value: "1312",
        brandId: "104",
        label: "7",
      },
      {
        value: "1313",
        brandId: "129",
        label: "DNEPR MT",
      },
      {
        value: "1314",
        brandId: "17",
        label: "Amarok",
      },
      {
        value: "1315",
        brandId: "17",
        label: "ID.3",
      },
      {
        value: "1316",
        brandId: "39",
        label: "Han",
      },
      {
        value: "1317",
        brandId: "89",
        label: "01",
      },
      {
        value: "1318",
        brandId: "9",
        label: "2111",
      },
      {
        value: "1319",
        brandId: "5",
        label: "GLK 300 4MATIC",
      },
      {
        value: "1320",
        brandId: "33",
        label: "Arrizo 5 Plus",
      },
      {
        value: "1321",
        brandId: "4",
        label: "Niva",
      },
      {
        value: "1322",
        brandId: "62",
        label: "E-QM5",
      },
      {
        value: "1323",
        brandId: "23",
        label: "e-tron Sportback",
      },
      {
        value: "1324",
        brandId: "42",
        label: "Next A65R52",
      },
      {
        value: "1325",
        brandId: "42",
        label: "330200",
      },
      {
        value: "1326",
        brandId: "5",
        label: "A 170",
      },
      {
        value: "1327",
        brandId: "5",
        label: "Atego 823",
      },
      {
        value: "1328",
        brandId: "1",
        label: "Urban Cruiser",
      },
      {
        value: "1329",
        brandId: "99",
        label: "408",
      },
      {
        value: "1330",
        brandId: "23",
        label: "Q5",
      },
      {
        value: "1331",
        brandId: "5",
        label: "R 350",
      },
      {
        value: "1332",
        brandId: "12",
        label: "Excel",
      },
      {
        value: "1333",
        brandId: "3",
        label: "LS 460",
      },
      {
        value: "1334",
        brandId: "115",
        label: "TGA200",
      },
      {
        value: "1335",
        brandId: "5",
        label: "R 320 4MATIC",
      },
      {
        value: "1336",
        brandId: "100",
        label: "Besturn X80",
      },
      {
        value: "1337",
        brandId: "59",
        label: "Espero",
      },
      {
        value: "1338",
        brandId: "13",
        label: "TGA 18.430",
      },
      {
        value: "1339",
        brandId: "17",
        label: "Tayron",
      },
      {
        value: "1340",
        brandId: "33",
        label: "Tiggo 7 Pro",
      },
      {
        value: "1341",
        brandId: "38",
        label: "55111",
      },
      {
        value: "1342",
        brandId: "5",
        label: "R 350 4MATIC",
      },
      {
        value: "1343",
        brandId: "14",
        label: "Grand Commander",
      },
      {
        value: "1344",
        brandId: "84",
        label: "Aion Y Plus",
      },
      {
        value: "1345",
        brandId: "84",
        label: "Aion S Max",
      },
      {
        value: "1346",
        brandId: "3",
        label: "IS 200t",
      },
      {
        value: "1347",
        brandId: "21",
        label: "Edge",
      },
      {
        value: "1348",
        brandId: "11",
        label: "Galant",
      },
      {
        value: "1349",
        brandId: "18",
        label: "Benni",
      },
      {
        value: "1350",
        brandId: "5",
        label: "GLE 63 AMG S 4MATIC+ Coupe",
      },
      {
        value: "1351",
        brandId: "42",
        label: "Next A21R32-30",
      },
      {
        value: "1352",
        brandId: "5",
        label: "S 500",
      },
      {
        value: "1353",
        brandId: "11",
        label: "Nativa",
      },
      {
        value: "1354",
        brandId: "20",
        label: "L7",
      },
      {
        value: "1355",
        brandId: "42",
        label: "330202-740",
      },
      {
        value: "1356",
        brandId: "72",
        label: "EC6",
      },
      {
        value: "1357",
        brandId: "30",
        label: "D77",
      },
      {
        value: "1358",
        brandId: "38",
        label: "5511",
      },
      {
        value: "1359",
        brandId: "130",
        label: "G70",
      },
      {
        value: "1360",
        brandId: "1",
        label: "Crown",
      },
      {
        value: "1361",
        brandId: "14",
        label: "Liberty",
      },
      {
        value: "1362",
        brandId: "30",
        label: "Touring",
      },
      {
        value: "1363",
        brandId: "23",
        label: "A3",
      },
      {
        value: "1364",
        brandId: "42",
        label: "330232-744",
      },
      {
        value: "1365",
        brandId: "2",
        label: "K 1300 S",
      },
      {
        value: "1366",
        brandId: "5",
        label: "V 200",
      },
      {
        value: "1367",
        brandId: "12",
        label: "IONIQ",
      },
      {
        value: "1368",
        brandId: "86",
        label: "Glory 330 S",
      },
      {
        value: "1369",
        brandId: "9",
        label: "21013",
      },
      {
        value: "1370",
        brandId: "32",
        label: "Sandero Stepway",
      },
      {
        value: "1371",
        brandId: "15",
        label: "Combo",
      },
      {
        value: "1372",
        brandId: "5",
        label: "E 53 AMG 4MATIC+",
      },
      {
        value: "1373",
        brandId: "5",
        label: "609 D",
      },
      {
        value: "1374",
        brandId: "1",
        label: "FJ Cruiser",
      },
      {
        value: "1375",
        brandId: "16",
        label: "Cayenne Coupe",
      },
      {
        value: "1376",
        brandId: "5",
        label: "CLS 55 AMG",
      },
      {
        value: "1377",
        brandId: "5",
        label: "GLE 450 4MATIC Coupe",
      },
      {
        value: "1378",
        brandId: "131",
        label: "BCN Sport",
      },
      {
        value: "1379",
        brandId: "29",
        label: "XF 480",
      },
      {
        value: "1380",
        brandId: "42",
        label: "27527-745",
      },
      {
        value: "1381",
        brandId: "22",
        label: "Aeolus A30",
      },
      {
        value: "1382",
        brandId: "17",
        label: "Scirocco R",
      },
      {
        value: "1383",
        brandId: "2",
        label: "Z4",
      },
      {
        value: "1384",
        brandId: "70",
        label: "320",
      },
      {
        value: "1385",
        brandId: "132",
        label: "Alvez",
      },
      {
        value: "1386",
        brandId: "6",
        label: "Cerato Koup",
      },
      {
        value: "1387",
        brandId: "47",
        label: "MX-6",
      },
      {
        value: "1388",
        brandId: "5",
        label: "Actros 1842",
      },
      {
        value: "1389",
        brandId: "113",
        label: "Leon",
      },
      {
        value: "1390",
        brandId: "5",
        label: "E 400",
      },
      {
        value: "1391",
        brandId: "35",
        label: "S-Class",
      },
      {
        value: "1392",
        brandId: "133",
        label: "S 416 HDH",
      },
      {
        value: "1393",
        brandId: "5",
        label: "C 250 d",
      },
      {
        value: "1394",
        brandId: "8",
        label: "Versa",
      },
      {
        value: "1395",
        brandId: "18",
        label: "Eado Plus",
      },
      {
        value: "1396",
        brandId: "12",
        label: "Coupe",
      },
      {
        value: "1397",
        brandId: "3",
        label: "RX 200t",
      },
      {
        value: "1398",
        brandId: "46",
        label: "Q30",
      },
      {
        value: "1399",
        brandId: "3",
        label: "NX 250",
      },
      {
        value: "1400",
        brandId: "5",
        label: "Actros 1844",
      },
      {
        value: "1401",
        brandId: "11",
        label: "Outlander Sport",
      },
      {
        value: "1402",
        brandId: "1",
        label: "GT 86",
      },
      {
        value: "1403",
        brandId: "31",
        label: "XJ",
      },
      {
        value: "1404",
        brandId: "12",
        label: "Santa Cruz",
      },
      {
        value: "1405",
        brandId: "16",
        label: "Panamera 4 Executive",
      },
      {
        value: "1406",
        brandId: "11",
        label: "Rosa",
      },
      {
        value: "1407",
        brandId: "5",
        label: "AMG EQE 53 4MATIC",
      },
      {
        value: "1408",
        brandId: "3",
        label: "IS 300",
      },
      {
        value: "1409",
        brandId: "79",
        label: "CUB50 S",
      },
      {
        value: "1410",
        brandId: "5",
        label: "GL 350",
      },
      {
        value: "1411",
        brandId: "5",
        label: "CLA 250 e",
      },
      {
        value: "1412",
        brandId: "134",
        label: "Daily 35C15VH",
      },
      {
        value: "1413",
        brandId: "1",
        label: "Corolla II",
      },
      {
        value: "1414",
        brandId: "17",
        label: "Taos",
      },
      {
        value: "1415",
        brandId: "96",
        label: "Shacman F2000",
      },
      {
        value: "1416",
        brandId: "4",
        label: "Nexia",
      },
      {
        value: "1417",
        brandId: "34",
        label: "Punto",
      },
      {
        value: "1418",
        brandId: "5",
        label: "Actros 2544",
      },
      {
        value: "1419",
        brandId: "5",
        label: "Vito 121",
      },
      {
        value: "1420",
        brandId: "16",
        label: "Panamera Turbo S",
      },
      {
        value: "1421",
        brandId: "42",
        label: "69",
      },
      {
        value: "1422",
        brandId: "51",
        label: "206",
      },
      {
        value: "1423",
        brandId: "26",
        label: "M-NV",
      },
      {
        value: "1424",
        brandId: "69",
        label: "H5",
      },
      {
        value: "1425",
        brandId: "18",
        label: "Oshan Z6",
      },
      {
        value: "1426",
        brandId: "19",
        label: "Karoq",
      },
      {
        value: "1427",
        brandId: "18",
        label: "Alsvin V7",
      },
      {
        value: "1428",
        brandId: "50",
        label: "G6",
      },
      {
        value: "1429",
        brandId: "17",
        label: "Passat (North America)",
      },
      {
        value: "1430",
        brandId: "56",
        label: "Intruder M1800R",
      },
      {
        value: "1431",
        brandId: "132",
        label: "Asta",
      },
      {
        value: "1432",
        brandId: "25",
        label: "MMZ 45023",
      },
      {
        value: "1433",
        brandId: "104",
        label: "ZS",
      },
      {
        value: "1434",
        brandId: "16",
        label: "924",
      },
      {
        value: "1435",
        brandId: "104",
        label: "5",
      },
      {
        value: "1436",
        brandId: "104",
        label: "GT",
      },
      {
        value: "1437",
        brandId: "2",
        label: "118",
      },
      {
        value: "1438",
        brandId: "11",
        label: "Montero Sport",
      },
      {
        value: "1439",
        brandId: "3",
        label: "LX 500d",
      },
      {
        value: "1440",
        brandId: "127",
        label: "PT Cruiser",
      },
      {
        value: "1441",
        brandId: "127",
        label: "Town & Country",
      },
      {
        value: "1442",
        brandId: "127",
        label: "300C",
      },
      {
        value: "1443",
        brandId: "2",
        label: "iX1",
      },
      {
        value: "1444",
        brandId: "80",
        label: "Actyon Sports",
      },
      {
        value: "1445",
        brandId: "117",
        label: "Town Car",
      },
      {
        value: "1446",
        brandId: "32",
        label: "Premium 250",
      },
      {
        value: "1447",
        brandId: "5",
        label: "C 300 d",
      },
      {
        value: "1448",
        brandId: "5",
        label: "Atego 824",
      },
      {
        value: "1449",
        brandId: "135",
        label: "MiTo",
      },
      {
        value: "1450",
        brandId: "16",
        label: "Panamera 4S E-hybrid",
      },
      {
        value: "1451",
        brandId: "15",
        label: "Frontera",
      },
      {
        value: "1452",
        brandId: "85",
        label: "GranTurismo S",
      },
      {
        value: "1453",
        brandId: "109",
        label: "452",
      },
      {
        value: "1454",
        brandId: "35",
        label: "GLS 600 4MATIC",
      },
      {
        value: "1455",
        brandId: "15",
        label: "Antara",
      },
      {
        value: "1456",
        brandId: "5",
        label: "Vito 112",
      },
      {
        value: "1457",
        brandId: "82",
        label: "XTS",
      },
      {
        value: "1458",
        brandId: "5",
        label: "GL 500",
      },
      {
        value: "1459",
        brandId: "42",
        label: "12 ZIM",
      },
      {
        value: "1460",
        brandId: "46",
        label: "FX50",
      },
      {
        value: "1461",
        brandId: "21",
        label: "Focus (North America)",
      },
      {
        value: "1462",
        brandId: "2",
        label: "340",
      },
      {
        value: "1463",
        brandId: "46",
        label: "QX80",
      },
      {
        value: "1464",
        brandId: "46",
        label: "QX55",
      },
      {
        value: "1465",
        brandId: "136",
        label: "595",
      },
      {
        value: "1466",
        brandId: "87",
        label: "Senova D50",
      },
      {
        value: "1467",
        brandId: "18",
        label: "CS 75 Plus",
      },
      {
        value: "1468",
        brandId: "8",
        label: "Skyline",
      },
      {
        value: "1469",
        brandId: "5",
        label: "E 200 4MATIC",
      },
      {
        value: "1470",
        brandId: "32",
        label: "Trafic",
      },
      {
        value: "1471",
        brandId: "64",
        label: "Tugella S",
      },
      {
        value: "1472",
        brandId: "5",
        label: "S 420",
      },
      {
        value: "1473",
        brandId: "5",
        label: "CLS 320",
      },
      {
        value: "1474",
        brandId: "6",
        label: "Besta",
      },
      {
        value: "1475",
        brandId: "5",
        label: "E 220 d 4MATIC",
      },
      {
        value: "1476",
        brandId: "5",
        label: "GLK 220 4MATIC",
      },
      {
        value: "1477",
        brandId: "33",
        label: "Tiggo 8 Pro",
      },
      {
        value: "1478",
        brandId: "114",
        label: "XL883N Sportster Iron 883",
      },
      {
        value: "1479",
        brandId: "5",
        label: "CLA 200",
      },
      {
        value: "1480",
        brandId: "47",
        label: "2",
      },
      {
        value: "1481",
        brandId: "35",
        label: "S 560",
      },
      {
        value: "1482",
        brandId: "5",
        label: "GLE 53 AMG",
      },
      {
        value: "1483",
        brandId: "5",
        label: "Atego 1523",
      },
      {
        value: "1484",
        brandId: "124",
        label: "Jumpy",
      },
      {
        value: "1485",
        brandId: "16",
        label: "Panamera 4",
      },
      {
        value: "1486",
        brandId: "5",
        label: "GLE 400 4MATIC",
      },
      {
        value: "1487",
        brandId: "3",
        label: "NX 300h",
      },
      {
        value: "1488",
        brandId: "5",
        label: "Axor 1840",
      },
      {
        value: "1489",
        brandId: "18",
        label: "Honor",
      },
      {
        value: "1490",
        brandId: "66",
        label: "Mulsanne",
      },
      {
        value: "1491",
        brandId: "8",
        label: "Bluebird",
      },
      {
        value: "1492",
        brandId: "5",
        label: "C 180 d",
      },
      {
        value: "1493",
        brandId: "1",
        label: "Caldina",
      },
      {
        value: "1494",
        brandId: "48",
        label: "Yukon",
      },
      {
        value: "1495",
        brandId: "5",
        label: "Actros 1843",
      },
      {
        value: "1496",
        brandId: "2",
        label: "116",
      },
      {
        value: "1497",
        brandId: "46",
        label: "G35",
      },
      {
        value: "1498",
        brandId: "127",
        label: "Neon",
      },
      {
        value: "1499",
        brandId: "87",
        label: "X3",
      },
      {
        value: "1500",
        brandId: "58",
        label: "Dogan",
      },
      {
        value: "1501",
        brandId: "18",
        label: "CS 35",
      },
      {
        value: "1502",
        brandId: "136",
        label: "500",
      },
      {
        value: "1503",
        brandId: "100",
        label: "Besturn B30",
      },
      {
        value: "1504",
        brandId: "5",
        label: "Atego 2528",
      },
      {
        value: "1505",
        brandId: "137",
        label: "Astra",
      },
      {
        value: "1506",
        brandId: "138",
        label: "CM250R-HY",
      },
      {
        value: "1507",
        brandId: "139",
        label: "YBR-K",
      },
      {
        value: "1508",
        brandId: "138",
        label: "CM400R-18",
      },
      {
        value: "1509",
        brandId: "46",
        label: "QX60",
      },
      {
        value: "1510",
        brandId: "5",
        label: "A 190",
      },
      {
        value: "1511",
        brandId: "2",
        label: "M8",
      },
      {
        value: "1512",
        brandId: "23",
        label: "S4",
      },
      {
        value: "1513",
        brandId: "79",
        label: "City 125",
      },
      {
        value: "1514",
        brandId: "7",
        label: "960",
      },
      {
        value: "1515",
        brandId: "56",
        label: "Ignis",
      },
      {
        value: "1516",
        brandId: "69",
        label: "H8",
      },
      {
        value: "1517",
        brandId: "140",
        label: "8S",
      },
      {
        value: "1518",
        brandId: "40",
        label: "NAT",
      },
      {
        value: "1519",
        brandId: "80",
        label: "Kyron",
      },
      {
        value: "1520",
        brandId: "141",
        label: "C SE",
      },
      {
        value: "1521",
        brandId: "135",
        label: "Giulia",
      },
      {
        value: "1522",
        brandId: "128",
        label: "J7",
      },
      {
        value: "1523",
        brandId: "114",
        label: "Touring Electra Glide",
      },
      {
        value: "1524",
        brandId: "130",
        label: "GV70",
      },
      {
        value: "1525",
        brandId: "130",
        label: "GV80",
      },
      {
        value: "1526",
        brandId: "4",
        label: "Evanda",
      },
      {
        value: "1527",
        brandId: "1",
        label: "Corolla Axio",
      },
      {
        value: "1528",
        brandId: "32",
        label: "Logan",
      },
      {
        value: "1529",
        brandId: "28",
        label: "450 SR-S",
      },
      {
        value: "1530",
        brandId: "142",
        label: "2",
      },
      {
        value: "1531",
        brandId: "143",
        label: "H 330",
      },
      {
        value: "1532",
        brandId: "3",
        label: "RC 200t",
      },
      {
        value: "1533",
        brandId: "127",
        label: "300",
      },
      {
        value: "1534",
        brandId: "5",
        label: "Atego 1224",
      },
      {
        value: "1535",
        brandId: "13",
        label: "TGS 33.360",
      },
      {
        value: "1536",
        brandId: "27",
        label: "Voleex C30",
      },
      {
        value: "1537",
        brandId: "11",
        label: "Grandis",
      },
      {
        value: "1538",
        brandId: "83",
        label: "ER6N",
      },
      {
        value: "1539",
        brandId: "17",
        label: "Crafter",
      },
      {
        value: "1540",
        brandId: "1",
        label: "Prius V",
      },
      {
        value: "1541",
        brandId: "38",
        label: "53212",
      },
      {
        value: "1542",
        brandId: "2",
        label: "New Class",
      },
      {
        value: "1543",
        brandId: "3",
        label: "LS 460L",
      },
      {
        value: "1544",
        brandId: "46",
        label: "QX30",
      },
      {
        value: "1545",
        brandId: "2",
        label: "X2",
      },
      {
        value: "1546",
        brandId: "5",
        label: "A 140",
      },
      {
        value: "1547",
        brandId: "32",
        label: "Dokker",
      },
      {
        value: "1548",
        brandId: "21",
        label: "Bronco Sport",
      },
      {
        value: "1549",
        brandId: "74",
        label: "Ram",
      },
      {
        value: "1550",
        brandId: "42",
        label: "21",
      },
      {
        value: "1551",
        brandId: "5",
        label: "ML 250 4MATIC",
      },
      {
        value: "1552",
        brandId: "83",
        label: "Ninja 650",
      },
      {
        value: "1553",
        brandId: "32",
        label: "Duster",
      },
      {
        value: "1554",
        brandId: "5",
        label: "GLS 580",
      },
      {
        value: "1555",
        brandId: "5",
        label: "C 270",
      },
      {
        value: "1556",
        brandId: "66",
        label: "Continental",
      },
      {
        value: "1557",
        brandId: "125",
        label: "Urus",
      },
      {
        value: "1558",
        brandId: "5",
        label: "CLE 300",
      },
      {
        value: "1559",
        brandId: "42",
        label: "31029",
      },
      {
        value: "1560",
        brandId: "5",
        label: "Vito 110",
      },
      {
        value: "1561",
        brandId: "63",
        label: "MD 22 B",
      },
      {
        value: "1562",
        brandId: "85",
        label: "MC 20",
      },
      {
        value: "1563",
        brandId: "5",
        label: "CLA 45 S AMG",
      },
      {
        value: "1564",
        brandId: "6",
        label: "Magentis",
      },
      {
        value: "1565",
        brandId: "6",
        label: "EV5",
      },
      {
        value: "1566",
        brandId: "5",
        label: "C 43 AMG 4MATIC",
      },
      {
        value: "1567",
        brandId: "5",
        label: "EQS 450 4MATIC SUV",
      },
      {
        value: "1568",
        brandId: "88",
        label: "Dolphin",
      },
      {
        value: "1569",
        brandId: "88",
        label: "X5",
      },
      {
        value: "1570",
        brandId: "118",
        label: "54322",
      },
      {
        value: "1571",
        brandId: "5",
        label: "Axor 1829",
      },
      {
        value: "1572",
        brandId: "56",
        label: "GSX-S1000",
      },
      {
        value: "1573",
        brandId: "23",
        label: "e-tron",
      },
      {
        value: "1574",
        brandId: "144",
        label: "Outlander 570",
      },
      {
        value: "1575",
        brandId: "5",
        label: "508",
      },
      {
        value: "1576",
        brandId: "137",
        label: "VUE",
      },
      {
        value: "1577",
        brandId: "44",
        label: "Impreza",
      },
      {
        value: "1578",
        brandId: "145",
        label: "03",
      },
      {
        value: "1579",
        brandId: "18",
        label: "Hunter",
      },
      {
        value: "1580",
        brandId: "63",
        label: "NKR 55 E",
      },
      {
        value: "1581",
        brandId: "38",
        label: "53215",
      },
      {
        value: "1582",
        brandId: "146",
        label: "Streetfighter V2",
      },
      {
        value: "1583",
        brandId: "5",
        label: "S 55 AMG",
      },
      {
        value: "1584",
        brandId: "118",
        label: "551630",
      },
      {
        value: "1585",
        brandId: "5",
        label: "GLE 250",
      },
      {
        value: "1586",
        brandId: "134",
        label: "Otoyol",
      },
      {
        value: "1587",
        brandId: "12",
        label: "Elantra N",
      },
      {
        value: "1588",
        brandId: "8",
        label: "Navara",
      },
      {
        value: "1589",
        brandId: "5",
        label: "230 E",
      },
      {
        value: "1590",
        brandId: "116",
        label: "U-125",
      },
      {
        value: "1591",
        brandId: "5",
        label: "C 200 4MATIC",
      },
      {
        value: "1592",
        brandId: "8",
        label: "Juke Nismo",
      },
      {
        value: "1593",
        brandId: "147",
        label: "M 50",
      },
      {
        value: "1594",
        brandId: "2",
        label: "i8",
      },
      {
        value: "1595",
        brandId: "5",
        label: "G 36 AMG",
      },
      {
        value: "1596",
        brandId: "32",
        label: "12 Toros",
      },
      {
        value: "1597",
        brandId: "18",
        label: "CS 75",
      },
      {
        value: "1598",
        brandId: "51",
        label: "Partner Tepee",
      },
      {
        value: "1599",
        brandId: "44",
        label: "Impreza WRX STi",
      },
      {
        value: "1600",
        brandId: "59",
        label: "Tico",
      },
      {
        value: "1601",
        brandId: "33",
        label: "eQ7",
      },
      {
        value: "1602",
        brandId: "5",
        label: "GLC 300 4MATIC",
      },
      {
        value: "1603",
        brandId: "82",
        label: "SRX",
      },
      {
        value: "1604",
        brandId: "34",
        label: "Linea",
      },
      {
        value: "1605",
        brandId: "5",
        label: "GLA 180",
      },
      {
        value: "1606",
        brandId: "5",
        label: "S 550 4MATIC",
      },
      {
        value: "1607",
        brandId: "5",
        label: "GLE 43 AMG Coupe",
      },
      {
        value: "1608",
        brandId: "26",
        label: "CBR 400",
      },
      {
        value: "1609",
        brandId: "42",
        label: "322173",
      },
      {
        value: "1610",
        brandId: "5",
        label: "MB140D",
      },
      {
        value: "1611",
        brandId: "42",
        label: "Biznes 330273",
      },
      {
        value: "1612",
        brandId: "1",
        label: "4Runner",
      },
      {
        value: "1613",
        brandId: "9",
        label: "XRAY",
      },
      {
        value: "1614",
        brandId: "5",
        label: "Sprinter 208",
      },
      {
        value: "1615",
        brandId: "5",
        label: "CLK 320",
      },
      {
        value: "1616",
        brandId: "42",
        label: "Volga Siber",
      },
      {
        value: "1617",
        brandId: "15",
        label: "Movano",
      },
      {
        value: "1618",
        brandId: "148",
        label: "Forland",
      },
      {
        value: "1619",
        brandId: "5",
        label: "Axor 4140",
      },
      {
        value: "1620",
        brandId: "2",
        label: "518",
      },
      {
        value: "1621",
        brandId: "5",
        label: "EQB 250",
      },
      {
        value: "1622",
        brandId: "149",
        label: "HJ125-20",
      },
      {
        value: "1623",
        brandId: "150",
        label: "G50",
      },
      {
        value: "1624",
        brandId: "95",
        label: "RZR XP 1000",
      },
      {
        value: "1625",
        brandId: "5",
        label: "ML 320",
      },
      {
        value: "1626",
        brandId: "33",
        label: "Fulwin 2",
      },
      {
        value: "1627",
        brandId: "5",
        label: "Sprinter 213",
      },
      {
        value: "1628",
        brandId: "1",
        label: "HiAce",
      },
      {
        value: "1629",
        brandId: "26",
        label: "e:NP1",
      },
      {
        value: "1630",
        brandId: "87",
        label: "Senova X55",
      },
      {
        value: "1631",
        brandId: "5",
        label: "GLC Coupe",
      },
      {
        value: "1632",
        brandId: "42",
        label: "2705",
      },
      {
        value: "1633",
        brandId: "5",
        label: "S 63 AMG",
      },
      {
        value: "1634",
        brandId: "39",
        label: "Yangwang U8",
      },
      {
        value: "1635",
        brandId: "87",
        label: "M60",
      },
      {
        value: "1636",
        brandId: "27",
        label: "Florid",
      },
      {
        value: "1637",
        brandId: "5",
        label: "GLB 250",
      },
      {
        value: "1638",
        brandId: "15",
        label: "Insignia",
      },
      {
        value: "1639",
        brandId: "5",
        label: "S 63 AMG Coupe",
      },
      {
        value: "1640",
        brandId: "13",
        label: "TGA 440",
      },
      {
        value: "1641",
        brandId: "26",
        label: "Odyssey",
      },
      {
        value: "1642",
        brandId: "5",
        label: "EQE 350",
      },
      {
        value: "1643",
        brandId: "5",
        label: "GLK 320 CDI",
      },
      {
        value: "1644",
        brandId: "20",
        label: "L6",
      },
      {
        value: "1645",
        brandId: "5",
        label: "GLB 250 4MATIC",
      },
      {
        value: "1646",
        brandId: "39",
        label: "Yuan Up",
      },
      {
        value: "1647",
        brandId: "87",
        label: "A1",
      },
      {
        value: "1648",
        brandId: "7",
        label: "940",
      },
      {
        value: "1649",
        brandId: "51",
        label: "308",
      },
      {
        value: "1650",
        brandId: "38",
        label: "53213",
      },
      {
        value: "1651",
        brandId: "15",
        label: "Crossland X",
      },
      {
        value: "1652",
        brandId: "1",
        label: "Rush",
      },
      {
        value: "1653",
        brandId: "97",
        label: "FZ25",
      },
      {
        value: "1654",
        brandId: "17",
        label: "Arteon",
      },
      {
        value: "1655",
        brandId: "32",
        label: "Magnum",
      },
      {
        value: "1656",
        brandId: "5",
        label: "Atego 1518",
      },
      {
        value: "1657",
        brandId: "15",
        label: "Vivaro",
      },
      {
        value: "1658",
        brandId: "63",
        label: "NQR90L-K",
      },
      {
        value: "1659",
        brandId: "5",
        label: "E 290",
      },
      {
        value: "1660",
        brandId: "5",
        label: "GLS 580 4MATIC",
      },
      {
        value: "1661",
        brandId: "14",
        label: "Gladiator",
      },
      {
        value: "1662",
        brandId: "5",
        label: "GLE 400 4MATIC Coupe",
      },
      {
        value: "1663",
        brandId: "5",
        label: "CLS 400 4MATIC",
      },
      {
        value: "1664",
        brandId: "128",
        label: "e-JS1",
      },
      {
        value: "1665",
        brandId: "6",
        label: "Opirus",
      },
      {
        value: "1666",
        brandId: "104",
        label: "4 EV",
      },
      {
        value: "1667",
        brandId: "2",
        label: "iX3",
      },
      {
        value: "1668",
        brandId: "69",
        label: "H3",
      },
      {
        value: "1669",
        brandId: "23",
        label: "Q8 Sportback e-tron",
      },
      {
        value: "1670",
        brandId: "5",
        label: "Vito 122",
      },
      {
        value: "1671",
        brandId: "56",
        label: "GSX-S 750",
      },
      {
        value: "1672",
        brandId: "5",
        label: "CLA 45 AMG 4MATIC",
      },
      {
        value: "1673",
        brandId: "4",
        label: "Express",
      },
      {
        value: "1674",
        brandId: "151",
        label: "LS6",
      },
      {
        value: "1675",
        brandId: "2",
        label: "5 Series",
      },
      {
        value: "1676",
        brandId: "151",
        label: "LS7",
      },
      {
        value: "1677",
        brandId: "152",
        label: "C-Class",
      },
      {
        value: "1678",
        brandId: "153",
        label: "HT-i",
      },
      {
        value: "1679",
        brandId: "153",
        label: "ET5",
      },
      {
        value: "1680",
        brandId: "23",
        label: "TTS",
      },
      {
        value: "1681",
        brandId: "154",
        label: "2103",
      },
      {
        value: "1682",
        brandId: "5",
        label: "Citan",
      },
      {
        value: "1683",
        brandId: "154",
        label: "2106",
      },
      {
        value: "1684",
        brandId: "154",
        label: "2107",
      },
      {
        value: "1685",
        brandId: "42",
        label: "Next A31R32",
      },
      {
        value: "1686",
        brandId: "9",
        label: "Largus Cross",
      },
      {
        value: "1687",
        brandId: "42",
        label: "Next A32R32-40",
      },
      {
        value: "1688",
        brandId: "51",
        label: "RCZ",
      },
      {
        value: "1689",
        brandId: "2",
        label: "i4",
      },
      {
        value: "1690",
        brandId: "5",
        label: "AMG GT 43",
      },
      {
        value: "1691",
        brandId: "12",
        label: "i10",
      },
      {
        value: "1692",
        brandId: "134",
        label: "Stralis",
      },
      {
        value: "1693",
        brandId: "5",
        label: "ML 400 4MATIC",
      },
      {
        value: "1694",
        brandId: "13",
        label: "TGA 18.410",
      },
      {
        value: "1695",
        brandId: "5",
        label: "C 63 AMG",
      },
      {
        value: "1696",
        brandId: "23",
        label: "Q3",
      },
      {
        value: "1697",
        brandId: "70",
        label: "K19",
      },
      {
        value: "1698",
        brandId: "82",
        label: "CTS",
      },
      {
        value: "1699",
        brandId: "48",
        label: "Acadia",
      },
      {
        value: "1700",
        brandId: "19",
        label: "Yeti",
      },
      {
        value: "1701",
        brandId: "5",
        label: "0403",
      },
      {
        value: "1702",
        brandId: "12",
        label: "HD-78",
      },
      {
        value: "1703",
        brandId: "152",
        label: "E-Class",
      },
      {
        value: "1704",
        brandId: "152",
        label: "A-Class",
      },
      {
        value: "1705",
        brandId: "154",
        label: "2115",
      },
      {
        value: "1706",
        brandId: "104",
        label: "Marvel R",
      },
      {
        value: "1707",
        brandId: "5",
        label: "e-class",
      },
      {
        value: "1708",
        brandId: "11",
        label: "Pajero iO",
      },
      {
        value: "1709",
        brandId: "32",
        label: "Megan",
      },
      {
        value: "1710",
        brandId: "8",
        label: "Nissan Sunny",
      },
      {
        value: "1711",
        brandId: "12",
        label: "İoniq",
      },
      {
        value: "1712",
        brandId: "5",
        label: "A180",
      },
      {
        value: "1713",
        brandId: "8",
        label: "Rogue",
      },
      {
        value: "1714",
        brandId: "154",
        label: "Niva",
      },
      {
        value: "1715",
        brandId: "113",
        label: "IBİZA",
      },
      {
        value: "1716",
        brandId: "152",
        label: "190",
      },
      {
        value: "1717",
        brandId: "1",
        label: "Carolla",
      },
      {
        value: "1718",
        brandId: "59",
        label: "nexsia",
      },
      {
        value: "1719",
        brandId: "9",
        label: "Vaz 21011",
      },
      {
        value: "1720",
        brandId: "155",
        label: "2106",
      },
      {
        value: "1721",
        brandId: "5",
        label: "C200",
      },
      {
        value: "1722",
        brandId: "5",
        label: "C180",
      },
      {
        value: "1723",
        brandId: "155",
        label: "21099",
      },
      {
        value: "1724",
        brandId: "5",
        label: "B-Klass",
      },
      {
        value: "1725",
        brandId: "1",
        label: "Land cruser",
      },
      {
        value: "1726",
        brandId: "5",
        label: "B klass",
      },
      {
        value: "1727",
        brandId: "14",
        label: "Graoce",
      },
      {
        value: "1728",
        brandId: "156",
        label: "99",
      },
      {
        value: "1729",
        brandId: "17",
        label: "Tiquan",
      },
      {
        value: "1730",
        brandId: "5",
        label: "E220",
      },
      {
        value: "1731",
        brandId: "154",
        label: "Vesta",
      },
      {
        value: "1732",
        brandId: "156",
        label: "2107",
      },
      {
        value: "1733",
        brandId: "4",
        label: "cruze",
      },
      {
        value: "1734",
        brandId: "154",
        label: "2104",
      },
      {
        value: "1735",
        brandId: "21",
        label: "Fusion North America",
      },
      {
        value: "1736",
        brandId: "21",
        label: "transit",
      },
      {
        value: "1737",
        brandId: "152",
        label: "Vito",
      },
      {
        value: "1738",
        brandId: "152",
        label: "M-Class",
      },
      {
        value: "1739",
        brandId: "18",
        label: "CX20",
      },
      {
        value: "1740",
        brandId: "77",
        label: "1103 Slavuta",
      },
      {
        value: "1741",
        brandId: "154",
        label: "Granta",
      },
      {
        value: "1742",
        brandId: "6",
        label: "Quoris",
      },
      {
        value: "1743",
        brandId: "39",
        label: "Seagull",
      },
      {
        value: "1744",
        brandId: "2",
        label: "7 Series",
      },
      {
        value: "1745",
        brandId: "13",
        label: "L 2000",
      },
      {
        value: "1746",
        brandId: "157",
        label: "6831",
      },
      {
        value: "1747",
        brandId: "63",
        label: "NQR 71 PL",
      },
      {
        value: "1748",
        brandId: "2",
        label: "3 Series",
      },
      {
        value: "1749",
        brandId: "46",
        label: "FX Series",
      },
      {
        value: "1750",
        brandId: "5",
        label: "GLC 300",
      },
      {
        value: "1751",
        brandId: "49",
        label: "X7",
      },
      {
        value: "1752",
        brandId: "5",
        label: "Mersedes",
      },
      {
        value: "1753",
        brandId: "5",
        label: "Atego 1229",
      },
      {
        value: "1754",
        brandId: "101",
        label: "Dominar 250",
      },
      {
        value: "1755",
        brandId: "7",
        label: "FH 12",
      },
      {
        value: "1756",
        brandId: "32",
        label: "Sandero",
      },
      {
        value: "1757",
        brandId: "130",
        label: "G80",
      },
      {
        value: "1758",
        brandId: "17",
        label: "Caravelle",
      },
      {
        value: "1759",
        brandId: "5",
        label: "CLS 400",
      },
      {
        value: "1760",
        brandId: "6",
        label: "Avella",
      },
      {
        value: "1761",
        brandId: "17",
        label: "ID.6 X",
      },
      {
        value: "1762",
        brandId: "26",
        label: "Fit",
      },
      {
        value: "1763",
        brandId: "114",
        label: "Street Rod",
      },
      {
        value: "1764",
        brandId: "83",
        label: "Ninja 300",
      },
      {
        value: "1765",
        brandId: "27",
        label: "Wingle 7",
      },
      {
        value: "1766",
        brandId: "12",
        label: "HD-65",
      },
      {
        value: "1767",
        brandId: "38",
        label: "65201",
      },
      {
        value: "1768",
        brandId: "55",
        label: "X90 PLUS",
      },
      {
        value: "1769",
        brandId: "7",
        label: "FH 500",
      },
      {
        value: "1770",
        brandId: "63",
        label: "NPR 75 L",
      },
      {
        value: "1771",
        brandId: "22",
        label: "Aeolus H30",
      },
      {
        value: "1772",
        brandId: "158",
        label: "32054",
      },
      {
        value: "1773",
        brandId: "2",
        label: "M340",
      },
      {
        value: "1774",
        brandId: "63",
        label: "FTR 33 M",
      },
      {
        value: "1775",
        brandId: "17",
        label: "Passat B7",
      },
      {
        value: "1776",
        brandId: "64",
        label: "Farizon FX",
      },
      {
        value: "1777",
        brandId: "86",
        label: "C-32 S",
      },
      {
        value: "1778",
        brandId: "42",
        label: "Sobol",
      },
      {
        value: "1779",
        brandId: "5",
        label: "C 160",
      },
      {
        value: "1780",
        brandId: "87",
        label: "X55",
      },
      {
        value: "1781",
        brandId: "16",
        label: "Macan T",
      },
      {
        value: "1782",
        brandId: "146",
        label: "1199 Panigale S",
      },
      {
        value: "1783",
        brandId: "124",
        label: "C3",
      },
      {
        value: "1784",
        brandId: "128",
        label: "HFC 1040K",
      },
      {
        value: "1785",
        brandId: "123",
        label: "Planeta 4",
      },
      {
        value: "1786",
        brandId: "3",
        label: "NX 300",
      },
      {
        value: "1787",
        brandId: "70",
        label: "720",
      },
      {
        value: "1788",
        brandId: "4",
        label: "Onix",
      },
      {
        value: "1789",
        brandId: "28",
        label: "250 NK",
      },
      {
        value: "1790",
        brandId: "5",
        label: "SL 350",
      },
      {
        value: "1791",
        brandId: "5",
        label: "E 350 d 4MATIC",
      },
      {
        value: "1792",
        brandId: "6",
        label: "Bongo",
      },
      {
        value: "1793",
        brandId: "144",
        label: "Spyder RS",
      },
      {
        value: "1794",
        brandId: "58",
        label: "Şahin",
      },
      {
        value: "1795",
        brandId: "155",
        label: "2107",
      },
      {
        value: "1796",
        brandId: "5",
        label: "G 400",
      },
      {
        value: "1797",
        brandId: "35",
        label: "S 450 4MATIC",
      },
      {
        value: "1798",
        brandId: "45",
        label: "Lodgy",
      },
      {
        value: "1799",
        brandId: "28",
        label: "ZFORCE 1000 Sport R",
      },
      {
        value: "1800",
        brandId: "3",
        label: "LS 350",
      },
      {
        value: "1801",
        brandId: "28",
        label: "CX-2E",
      },
      {
        value: "1802",
        brandId: "28",
        label: "CFORCE 520L",
      },
      {
        value: "1803",
        brandId: "28",
        label: "450 CL-C",
      },
      {
        value: "1804",
        brandId: "5",
        label: "EQS 450 4MATIC",
      },
      {
        value: "1805",
        brandId: "99",
        label: "426",
      },
      {
        value: "1806",
        brandId: "3",
        label: "HS 250h",
      },
      {
        value: "1807",
        brandId: "42",
        label: "33027-745",
      },
      {
        value: "1808",
        brandId: "33",
        label: "Tiggo 2 Pro Max",
      },
      {
        value: "1809",
        brandId: "159",
        label: "F3 800",
      },
      {
        value: "1810",
        brandId: "18",
        label: "F70 (Hunter)",
      },
      {
        value: "1811",
        brandId: "56",
        label: "GSX-S1000GT",
      },
      {
        value: "1812",
        brandId: "5",
        label: "ML 300",
      },
      {
        value: "1813",
        brandId: "16",
        label: "Cayenne Turbo GT Coupe",
      },
      {
        value: "1814",
        brandId: "59",
        label: "Nubira",
      },
      {
        value: "1815",
        brandId: "104",
        label: "3",
      },
      {
        value: "1816",
        brandId: "29",
        label: "95 XF",
      },
      {
        value: "1817",
        brandId: "160",
        label: "DY150-12",
      },
      {
        value: "1818",
        brandId: "118",
        label: "54323",
      },
      {
        value: "1819",
        brandId: "21",
        label: "Cougar",
      },
      {
        value: "1820",
        brandId: "160",
        label: "Yuehu",
      },
      {
        value: "1821",
        brandId: "5",
        label: "C 320",
      },
      {
        value: "1822",
        brandId: "44",
        label: "B9 Tribeca",
      },
      {
        value: "1823",
        brandId: "11",
        label: "Canter",
      },
      {
        value: "1824",
        brandId: "63",
        label: "HD 50",
      },
      {
        value: "1825",
        brandId: "158",
        label: "3204",
      },
      {
        value: "1826",
        brandId: "161",
        label: "969M",
      },
      {
        value: "1827",
        brandId: "23",
        label: "Q5 Sportback",
      },
      {
        value: "1828",
        brandId: "23",
        label: "Q8 e-tron",
      },
      {
        value: "1829",
        brandId: "156",
        label: "Priora",
      },
      {
        value: "1830",
        brandId: "64",
        label: "Galaxy L7",
      },
      {
        value: "1831",
        brandId: "14",
        label: "Patriot",
      },
      {
        value: "1832",
        brandId: "28",
        label: "700 CL-X Heritage",
      },
      {
        value: "1833",
        brandId: "134",
        label: "Astra HD8",
      },
      {
        value: "1834",
        brandId: "5",
        label: "S 400 d 4MATIC",
      },
      {
        value: "1835",
        brandId: "109",
        label: "31514",
      },
      {
        value: "1836",
        brandId: "101",
        label: "Pulsar NS 200",
      },
      {
        value: "1837",
        brandId: "21",
        label: "Bronco",
      },
      {
        value: "1838",
        brandId: "16",
        label: "Cayenne Turbo S E-Hybrid",
      },
      {
        value: "1839",
        brandId: "74",
        label: "Nitro",
      },
      {
        value: "1840",
        brandId: "5",
        label: "C220d",
      },
      {
        value: "1841",
        brandId: "86",
        label: "Glory 330 S Cargo",
      },
      {
        value: "1842",
        brandId: "1",
        label: "bZ3",
      },
      {
        value: "1843",
        brandId: "56",
        label: "Intruder M-800",
      },
      {
        value: "1844",
        brandId: "27",
        label: "Hover M4",
      },
      {
        value: "1845",
        brandId: "5",
        label: "GL 320 4MATIC",
      },
      {
        value: "1846",
        brandId: "5",
        label: "Atego 2518",
      },
      {
        value: "1847",
        brandId: "5",
        label: "CLS 450 4MATIC",
      },
      {
        value: "1848",
        brandId: "5",
        label: "CLK 220",
      },
      {
        value: "1849",
        brandId: "5",
        label: "S 580 4MATIC",
      },
      {
        value: "1850",
        brandId: "69",
        label: "F7x",
      },
      {
        value: "1851",
        brandId: "99",
        label: "2715",
      },
      {
        value: "1852",
        brandId: "162",
        label: "Superlight 150cc",
      },
      {
        value: "1853",
        brandId: "3",
        label: "UX 200",
      },
      {
        value: "1854",
        brandId: "33",
        label: "Fora (A21)",
      },
      {
        value: "1855",
        brandId: "42",
        label: "33027",
      },
      {
        value: "1856",
        brandId: "42",
        label: "3307",
      },
      {
        value: "1857",
        brandId: "16",
        label: "Panamera Turbo Executive",
      },
      {
        value: "1858",
        brandId: "9",
        label: "2102",
      },
      {
        value: "1859",
        brandId: "32",
        label: "Clio",
      },
      {
        value: "1860",
        brandId: "5",
        label: "W124",
      },
      {
        value: "1861",
        brandId: "5",
        label: "CLA 220",
      },
      {
        value: "1862",
        brandId: "5",
        label: "GLE 53 4MATIC+",
      },
      {
        value: "1863",
        brandId: "42",
        label: "M-20 Pobeda",
      },
      {
        value: "1864",
        brandId: "163",
        label: "SM5",
      },
      {
        value: "1865",
        brandId: "37",
        label: "7X",
      },
      {
        value: "1866",
        brandId: "5",
        label: "GLE 300 4MATIC",
      },
      {
        value: "1867",
        brandId: "87",
        label: "X7",
      },
      {
        value: "1868",
        brandId: "5",
        label: "Sprinter 211",
      },
      {
        value: "1869",
        brandId: "14",
        label: "Compass",
      },
      {
        value: "1870",
        brandId: "77",
        label: "Tavriya",
      },
      {
        value: "1871",
        brandId: "99",
        label: "2141",
      },
      {
        value: "1872",
        brandId: "5",
        label: "E 300 e 4MATIC",
      },
      {
        value: "1873",
        brandId: "164",
        label: "K-750",
      },
      {
        value: "1874",
        brandId: "104",
        label: "EHS",
      },
      {
        value: "1875",
        brandId: "12",
        label: "Aerotown",
      },
      {
        value: "1876",
        brandId: "3",
        label: "RX 450h",
      },
      {
        value: "1877",
        brandId: "5",
        label: "G 55 Brabus K8",
      },
      {
        value: "1878",
        brandId: "5",
        label: "Sprinter 511",
      },
      {
        value: "1879",
        brandId: "46",
        label: "M45",
      },
      {
        value: "1880",
        brandId: "12",
        label: "Hyundai Sonata",
      },
      {
        value: "1881",
        brandId: "5",
        label: "EQS 350",
      },
      {
        value: "1882",
        brandId: "50",
        label: "X9",
      },
      {
        value: "1883",
        brandId: "146",
        label: "Diavel",
      },
      {
        value: "1884",
        brandId: "100",
        label: "Oley",
      },
      {
        value: "1885",
        brandId: "93",
        label: "141",
      },
      {
        value: "1886",
        brandId: "146",
        label: "1199 Panigale",
      },
      {
        value: "1887",
        brandId: "63",
        label: "Ecobus",
      },
      {
        value: "1888",
        brandId: "2",
        label: "E 36 cabriolet",
      },
      {
        value: "1889",
        brandId: "17",
        label: "Golf R",
      },
      {
        value: "1890",
        brandId: "118",
        label: "dartqi",
      },
    ],
    auto_body_type_options: [
      { value: "1", label: "Fayton" },
      { value: "2", label: "Karvan" },
      { value: "3", label: "Mikrovan" },
      { value: "4", label: "Pikap, tək kabin" },
      { value: "5", label: "Offroader / SUV, açıq" },
      { value: "6", label: "Rodster" },
      { value: "7", label: "Hetçbek, 4 qapı" },
      { value: "8", label: "Avtobus" },
      { value: "9", label: "Kabriolet" },
      { value: "10, 33", label: "Hetçbek, 3 qapı" },
      { value: "11", label: "Mikroavtobus" },
      { value: "12", label: "Kompakt-Van" },
      { value: "13", label: "Dartqı" },
      { value: "14", label: "Kupe" },
      { value: "15", label: "Pikap, ikiqat kabin" },
      { value: "16", label: "SUV Kupe" },
      { value: "17", label: "Offroader / SUV, 3 qapı" },
      { value: "18", label: "Minivan" },
      { value: "19", label: "Yük maşını" },
      { value: "20", label: "Furqon" },
      { value: "21", label: "Universal, 5 qapı" },
      { value: "22", label: "Liftbek" },
      { value: "23, 30", label: "Hetçbek, 5 qapı" },
      { value: "24", label: "Offroader / SUV, 5 qapı" },
      { value: "25", label: "Sedan" },
      { value: "26", label: "Motosiklet" },
      { value: "27", label: "Moped" },
      { value: "28", label: "Kvadrosikl" },
      { value: "29", label: "Universal" },
      { value: "31", label: "Offroader, SUV" },
      { value: "32", label: "Hetçbek" },
      { value: "34", label: "Miniven" },
      { value: "35", label: "Van" },
      { value: "36", label: "Limuzin" },
    ],
    auto_fuel_type_options: [
      { value: "1", label: "Benzin" },
      { value: "2", label: "Dizel" },
      { value: "3", label: "Qaz" },
      { value: "4", label: "Elektro" },
      { value: "5", label: "Hibrid" },
      { value: "6", label: "Plug-in Hibrid" },
    ],
    auto_drive_type_options: [
      { value: "1", label: "Arxa" },
      { value: "2", label: "Tam" },
      { value: "3", label: "Ön" },
    ],
    auto_transmission_type_options: [
      { value: "1", label: "Avtomat" },
      { value: "2", label: "Variator" },
      { value: "3", label: "Mexaniki" },
      { value: "4", label: "Robot" },
      { value: "5", label: "Reduktor" },
      { value: "6", label: "Robotlaşdırılmış" },
    ],
    auto_color_type_options: [
      { value: "1", label: "Çəhrayı" },
      { value: "2", label: "Bənövşəyi" },
      { value: "3", label: "Sarı" },
      { value: "4", label: "Narıncı" },
      { value: "5", label: "Açıq yaşıl" },
      { value: "6", label: "Bej" },
      { value: "7", label: "Qəhvəyi" },
      { value: "8", label: "Qızılı" },
      { value: "9", label: "Mavi" },
      { value: "10", label: "Yaşıl" },
      { value: "11", label: "Tünd qırmızı" },
      { value: "12", label: "Qırmızı" },
      { value: "13", label: "Boz" },
      { value: "14", label: "Göy" },
      { value: "15", label: "Yaş Asfalt" },
      { value: "16", label: "Gümüşü" },
      { value: "17", label: "Qara" },
      { value: "18", label: "Ağ" },
      { value: "19", label: "Yaş asfalt" },
    ],
    packets: {
      boost: [
        { dayOrTime: "1", price: "0.49" },
        { dayOrTime: "5", price: "1.99" },
        { dayOrTime: "10", price: "3.99" },
      ],
      frame: [
        { dayOrTime: "1", price: "0.99" },
        { dayOrTime: "5", price: "2.49" },
        { dayOrTime: "10", price: "4.49" },
      ],
      urgent: [
        { dayOrTime: "1", price: "1.99" },
        { dayOrTime: "5", price: "5.99" },
        { dayOrTime: "10", price: "9.99" },
      ],
      premium: [
        { dayOrTime: "1", price: "2.49" },
        { dayOrTime: "5", price: "10.99" },
        { dayOrTime: "10", price: "19.99" },
        { dayOrTime: "30", price: "39.99" },
      ],
    },
    blog: "Blog",
    blogAll: "Hamısı",
    blogMore: "Daha çox...",
    blogNotFound: "Blog tapılmadı",

    saleForTitle: " satışı",
    dailyForTitle: " günlük",
    rentForTitle: " kirayəsi",
    roomsForTitle: "otaqlı",
    minAreaForTitle: "minimum sahə - ",
    maxAreaForTitle: "maksimum sahə - ",
    minFloorForTitle: "minimum mərtəbə - ",
    maxFloorForTitle: "maksimum mərtəbə - ",
    minPriceForTitle: "minimum qiymət - ",
    maxPriceForTitle: "maksimum qiymət - ",
    withGoodsForTitle: ", Əşyalı",
    withoutGoodsForTitle: ", Əşyasız",
    withRepairForTitle: ", Təmirli",
    withoutRepairForTitle: ", Təmirsiz",
    withDocumentForTitle: ", Sənədli",
    withoutDocumentForTitle: ", Sənədsiz",
    withCreditForTitle: ", İpotekaya yararlı",
    cars: "Maşınlar",
    homes: "Əmlak",
  },
  ru: {
    allFavouriteOffersTitle: "Ebaz.az - Все избранные объявления",
    allPremiumOffersTitle: "Ebaz.az - Все премиум объявления",
    loginTitle: "Ebaz.az - Вход",
    registrationTitle: "Ebaz.az - Регистрация",
    myOffersTitle: "Ebaz.az - Профиль",
    newOffersTitle: "Ebaz.az - Новое объявление",
    notFoundTitle: "Ebaz.az - Ошибка 404",
    paymentErrorTitle: "Ebaz.az - Ошибка платежа!",
    paymentSuccessTitle: "Ebaz.az - Успешный платеж!",
    newOfferTitle: "Ebaz.az - Обнавление объявления",
    findOffer: "Найти объявление",
    offersCommon: "Объявления",
    favourites: "Избранные",
    profile: "Профиль",
    signIn: "Войти",
    uploadOffer: "Разместить объявление",
    sale: "Продажа",
    rent: "Аренда",
    day: "Посуточно",
    clean: "Очистить",
    add: "Добавить",
    cityName: "Город",
    flatTypeName: "Тип недвижимости",
    autoTypeName: "Марка",
    autoBodyType: "Тип кузова",
    autoFuelType: "Тип топлива",
    autoTransmissionType: "Коробка передач",
    autoColorName: "Цвет",
    autoEngineVolume:"Объем (см",
    autoHorsePower:"Мощность (л.с.)",
    autoDriveType: "Привод",
    autoModelName: "Модель",
    autoModelNameFirst: "Сперва выберите модель",
    roomsCountName: "Комнат",
    priceName: "Цена:",
    yearName: "Год:",
    mileageName: "Пробег (км):",
    pricePerM2Name: "Цена кв. метра: AZN/м2.",
    pricePerSotName: "Цена a.:  AZN/a",
    min: "мин.",
    max: "макс.",
    areaName: "Площадь",
    sot: "а",
    m2: "м²",
    l: "Л",
    km:"км",
    regionName: "Район",
    residentName: "Городок",
    stationName: "Метро",
    targetName: "Ориентиры",
    floorName: "Этаж:",
    siteName: "Сайты",
    siteNameEnter: "Выберите сайты",
    withGoodsName: "С мебелью",
    withoutGoodsName: "Без мебели",
    repairName: "С ремонтом",
    withoutRepairName: "Без ремонта",
    withDocumentsName: "С документами",
    withoutDocumentsName: "Без документов",
    mortgageName: "Ипотека",
    showOffersName: " объявлений",
    searchName: "Поиск",
    historyName: "История поиска",
    additionalsearchName: "Обширный поиск",
    lastFiveSearchName: "Последние 5 поисков",
    allFloorNumberName: "Количество этажей",
    sale2: "Продажа",
    clean2: "Очистить",
    sloqan: "Все недвижимости в одном месте!",
    usersAgree: "Пользовательское соглашение",
    privicy: "Политика конфиденциальности",
    services: "Услуги",
    connect: "Контакты",
    analytics: "Аналитика",
    predicted: "Ожидается",
    predictedHigh: "увеличение",
    predictedLow: "понижение",
    disclaimer:
      "*Администрация сайта не несет ответственности за содержание рекламных баннеров и размещенных объявлений.",
    enterAddress: "Введите адрес",
    showAll: "Показать все",
    premiumOffers: "Премиум объявления",
    saleCars: "Продажа машин",
    saleFlats: "Продажа квартир",
    rentFlats: "Аренда квартир",
    saleCountry: "Продажа домов/дач/вилл",
    rentCountry: "Аренда домов/дач/вилл",
    saleOffice: "Продажа офисов",
    rentOffice: "Аренда офисов",
    saleGarage: "Продажа гаражей",
    rentGarage: "Аренда гаражей",
    login: "Вход",
    saleLand: "Продажа земли",
    rentLand: "Аренда земли",
    saleCommercial: "Продажа коммерческой недвижимости",
    rentCommercial: "Аренда коммерческой недвижимости",
    searchOffersLoading: "Поиск объявлений",
    logout: "Выход",
    myOffersName: "Мои объявления",
    myOffersActiveName: "Активные",
    myOffersDeactiveName: "Неактивные",
    myOffersReductName: "Редактировать",
    myOffersDeleteFromOffersName: "Удалить из объявлений",
    myOffersRefreshOfferName: "Заново опубкликовать объявление",
    myOffersDontHaveOffersName: "У вас нет объявлений.",
    myOffersDontHaveActiveOffersName: "У вас нет активных объявлений.",
    myOffersDontHaveDeactiveOffersName: "У вас нет неактивных объявлений.",
    myOffersNameSurnameChangeName: "Изменить Имя и Фамилию",
    myOffersNameChangeName: "Имя",
    myOffersLastnameChangeName: "Фамилия",
    myOffersNameChangeRequareName: "*Пожалуйста, укажите ваше имя",
    myOffersLastnameChangeRequareName: "*Пожалуйста, укажите вашу фамилию",
    myOffersSetNameChangeName: "Введите ваше имя",
    myOffersSetLastnameChangeName: "Введите вашу фамилию",
    myOffersNameLastnameChangedSuccessfullyName: "Успешно изменено",
    myOffersNameLastnameChangeButtonName: "Изменить",
    residentsSmall: " городок",
    WithRoomsSmall: " комн.",
    floorSmall: "этаж",
    todaySmall: "Сегодня",
    yesterdaySmall: "Вчера",
    searchSmall: "Поиск",
    newOfferSmall: "Разместить",
    cabinetSmall: "Кабинет",
    moreSmall: "Больше",
    menuSmall: "Меню",
    languageSmall: "Язык",
    languageBig: "Русский",
    languageAZ: "Azərbaycan",
    languageRU: "Русский",
    languageEN: "English",
    yesSmall: "Да",
    noSmall: "Нет",
    aboutOffer: "О недвижимости",
    urgentSmall: "Срочно",
    offersFilterSloqan:
      "Город, Недвижимость, Цена, Площадь, Комнаты, Ремонт...",
    offersFound: "Объявлений",
    listSmall: "Список",
    mapSmall: "Карта",
    mapSmallShow: "Показать карту",
    mapSmallDontShow: "Скрыть карту",
    notFoundOffers: "К сожалению, объявлений по вашему запросу не найдено.",
    writeLinkForSearch: "Введите ссылку объявления для поиска",
    supportedSitesSearch:
      "Поддерживаемые сайты: tap.az, bina.az, lalafo.az, arenda.az, emlak.az, yeniemlak.az, rahatemlak.az, ucuztap.az",
    showOfferSearch: "Показать объявление",
    favouriteOffersCount: "Избранные объявления",
    notFavouritesOffers: "Нет избранных объявлений.",
    seeAllOffers: "Просмотреть все объявления",
    phoneNumberName: "Мобильный номер",
    writePhoneNumber: "*Пожалуйста, укажите ваш номер",
    sendSMStoPhone: "На ваш номер отправлен SMS-код",
    sendRepeatSMStoPhone: "Отправить SMS-код повторно",
    pleaseWriteSMSCode: "*Пожалуйста, введите SMS-код",
    pleaseWriteSMSCodeCorrect: "*Пожалуйста, введите корректный SMS-код",
    saveUser: "Сохранить",
    userNotFound: "*Пользователь не найден",
    loginWithBigI: "ВОЙТИ",
    notProfileRegistration: "Нет аккаунта? Пройти регистрацию",
    profileExistLogin: "Есть аккаунт? Войти",
    registration: "Регистрация",
    setEmail: "Email адрес",
    userAlreadyExist: "*Этот пользователь уже зарегистрирован",
    doRegistration: "Зарегистрироваться",
    newOffer_flatType: "Тип недвижимости, ",
    newOffer_offerType: "Тип объявления, ",
    newOffer_rentPeriod: "Срок аренды, ",
    newOffer_AreaM2: "Площадь м², ",
    newOffer_AreaSot: "Площадь а, ",
    newOffer_City: "Город, ",
    newOffer_Price: "Цена, ",
    newOffer_Desc: "Описание, ",
    newOffer_Images:
      "Изображения (минимум 1 изображение, максимум 10 изображений), ",
    newOffer_Name: "Имя, ",
    newOffer_Email: "E-mail, ",
    newOffer_PhoneNum: "Пожалуйста, укажите корректный мобильный номер, ",
    videoSmall: "Видео",
    addVideoSmall: "+Добавить видео",
    watchVideo: "Смотреть видео",
    imagesSmall: "Изображения",
    addImagesSmall: "+Добавить изображение",
    addImagesMaxCountSmall: "*Максимум 10 изображений",
    writeInfoInThisFields: "введите информацию в эти поля.",
    sendOffer: "Отправить объявление",
    simpleRules: "Простые правила Ebaz.az",
    simpleRules1: "Не публикуйте одно и то же объявление несколько раз.",
    simpleRules2:
      "Не указывайте номера телефонов, электронные адреса или веб-сайты в описании или на изображениях.",
    simpleRules3:
      "Не указывайте цену в поле 'Имя' - для этого есть отдельное поле.",
    simpleRules4:
      'Разместите свое первое объявление и получите от нас "В рамке" и "Поднять" в течение 2 дней.',
    offerView: "Вид объявления",
    pageNotFoundSmall: "Страница не найдена",
    mainPageSmall: "Главная страница.",
    boostNameSmall: "Выделить",
    makePremiumNameSmall: "Премиум",
    deleteFromFavouritesNameSmall: "Удалить из избранных",
    positionOfOffer: "Расположение",
    addToFavouritesNameSmall: "Добавить в избранное",
    priceByAgreement: "По договоренности",
    linkCopied: "Ссылка скопирована",
    similarOffers: "Похожие объявления",
    boostOffer: "Поднять",
    frameOffer: "В рамке",
    fromPrice1: "От 0.49 AZN",
    fromPrice2: "От 0.99 AZN",
    fromPrice3: "От 1.99 AZN",
    boostOfferDescription:
      "Объявление будет поднято на первое место среди всех объявлений.",
    frameOfferDescription:
      "Объявление будет выделено рамкой и поднято на первое место среди всех объявлений.",
    urgentOfferDescription:
      "Объявление будет выделено красной рамкой и помечено как 'Срочное', также будет поднято на первое место среди всех объявлений.",
    paidUntill: "до данной даты оплачено",
    serviceTime: "Срок предоставления услуги",
    times: " раз",
    boostFor24Hours: "(Каждые 24 часа подъем)",
    boostName: "(+ Поднять)",
    paymentMethod: "Метод оплаты",
    bankCard: "Банковская карта",
    addressSmall: "Адрес",
    cancelPayment: "Отмена",
    makePayment: "Оплатить",
    paymentAgreement:
      'Нажимая кнопку "Оплатить", вы соглашаетесь с Пользовательским соглашением и Офертой сайта ebaz.az.',
    makeOfferPremiumName: "Сделать объявление премиум",
    makePremiumDescription:
      "Объявление будет отображаться в разделе премиум-объявлений на главной странице в течение всего срока действия услуги и будет продвигаться бесплатно.",
    days: " дней",
    perDays: "/день",
    errorName: "Ошибка",
    errorDescription:
      "Произошла ошибка при оплате, пожалуйста, попробуйте снова.",
    errorDescription2: `Произошла ошибка. Пожалуйста, попробуйте снова.`,
    successName: "Успешная оплата",
    successDescription:
      "Ваша оплата успешно зафиксирована, ваше объявление будет обновлено в ближайшее время.",
    successName2: "Успешно",
    successDescription2UpdateOffer: "Объявление успешно обновлено.",
    successDescription2DeleteOffer: "Объявление удалено.",
    successDescription2RefreshOffer: "Объявление успешно обновлено.",
    successDescription2RegistrationOffer: "Вы успешно зарегистрировались.",
    successGoToOffers: "Перейти к вашим объявлениям.",
    updateOfferName: "Обновить объявление",
    allRightsReserved: "Все права защищены.",
    siteMap: "Карта сайта",
    notUploadedOffer: "Нет зашруженного объявления.",
    uploadedOffer: "Ваше объявление опубликовано!",
    showToMorePeople: "Пусть больше людей увидят объявление!",
    miniBannerWords: [
      'Разместите свое первое объявление и получите от нас "Фрейминг" и "Продвижение" в течение 2 дней.',
    ],
    firstOfferWord:
      'Так как это ваше первое объявление, мы подарили вам "Фрейминг" и "Продвижение" на 2 дня!',
    saleDeleted: "Снято с продажи",
    rentDeleted: "Снято с аренды",
    cities: [
      {
        value: "0",
        label: "Агджабеди",
        center: { lat: 40.053, lng: 47.4554 },
        zoom: "10",
      },
      {
        value: "1",
        label: "Агдам",
        center: { lat: 39.9911, lng: 46.9297 },
        zoom: "10",
      },
      {
        value: "2",
        label: "Агдаш",
        center: { lat: 40.6335, lng: 47.4674 },
        zoom: "10",
      },
      {
        value: "3",
        label: "Агдере",
        center: { lat: 40.2116, lng: 46.8237 },
        zoom: "10",
      },
      {
        value: "4",
        label: "Агстафа",
        center: { lat: 41.1125, lng: 45.4477 },
        zoom: "10",
      },
      {
        value: "5",
        label: "Агсу",
        center: { lat: 40.5283, lng: 48.3651 },
        zoom: "10",
      },
      {
        value: "6",
        label: "Астара",
        center: { lat: 38.4688, lng: 48.8728 },
        zoom: "10",
      },
      {
        value: "7",
        label: "Бабек",
        center: { lat: 39.1508, lng: 45.4485 },
        zoom: "10",
      },
      {
        value: "8",
        label: "Баку",
        center: { lat: 40.3953, lng: 49.8822 },
        zoom: "10",
      },
      {
        value: "9",
        label: "Балакен",
        center: { lat: 41.7038, lng: 46.4044 },
        zoom: "10",
      },
      {
        value: "10",
        label: "Бейлаган",
        center: { lat: 39.7723, lng: 47.6154 },
        zoom: "10",
      },
      {
        value: "11",
        label: "Бердя",
        center: { lat: 40.3707, lng: 47.1379 },
        zoom: "10",
      },
      {
        value: "12",
        label: "Билясувар",
        center: { lat: 39.4599, lng: 48.551 },
        zoom: "10",
      },
      {
        value: "13",
        label: "Джебраил",
        center: { lat: 39.3987, lng: 47.0245 },
        zoom: "10",
      },
      {
        value: "14",
        label: "Джалилабад",
        center: { lat: 39.2052, lng: 48.5101 },
        zoom: "10",
      },
      {
        value: "15",
        label: "Джульфа",
        center: { lat: 38.9605, lng: 45.6293 },
        zoom: "10",
      },
      {
        value: "16",
        label: "Дашкесан",
        center: { lat: 40.5202, lng: 46.0779 },
        zoom: "10",
      },
      {
        value: "17",
        label: "Делимамедли",
        center: { lat: 40.6844, lng: 46.5728 },
        zoom: "10",
      },
      {
        value: "18",
        label: "Физули",
        center: { lat: 39.6025, lng: 47.1392 },
        zoom: "10",
      },
      {
        value: "19",
        label: "Гедебей",
        center: { lat: 40.57, lng: 45.8107 },
        zoom: "10",
      },
      {
        value: "20",
        label: "Гянджа",
        center: { lat: 40.6579, lng: 46.3523 },
        zoom: "10",
      },
      {
        value: "21",
        label: "Горанбой",
        center: { lat: 40.6073, lng: 46.7807 },
        zoom: "10",
      },
      {
        value: "22",
        label: "Гейджа",
        center: { lat: 40.6236, lng: 47.7403 },
        zoom: "10",
      },
      {
        value: "23",
        label: "Гейгель",
        center: { lat: 40.5895, lng: 46.3271 },
        zoom: "10",
      },
      {
        value: "24",
        label: "Гейтепе",
        center: { lat: 39.1171, lng: 48.5844 },
        zoom: "10",
      },
      {
        value: "25",
        label: "Гаджигабул",
        center: { lat: 40.0394, lng: 48.9203 },
        zoom: "10",
      },
      {
        value: "26",
        label: "Горадиз",
        center: { lat: 39.4511, lng: 47.3399 },
        zoom: "10",
      },
      {
        value: "27",
        label: "Хачмаз",
        center: { lat: 41.4591, lng: 48.8021 },
        zoom: "10",
      },
      {
        value: "28",
        label: "Ханкенди",
        center: { lat: 39.8265, lng: 46.7656 },
        zoom: "10",
      },
      {
        value: "29",
        label: "Ходжалы",
        center: { lat: 39.9133, lng: 46.7943 },
        zoom: "10",
      },
      {
        value: "30",
        label: "Ходжавенд",
        center: { lat: 39.7915, lng: 47.1101 },
        zoom: "10",
      },
      {
        value: "31",
        label: "Хызы",
        center: { lat: 40.9109, lng: 49.0729 },
        zoom: "10",
      },
      {
        value: "32",
        label: "Хырдалан",
        center: { lat: 40.4529, lng: 49.7333 },
        zoom: "10",
      },
      {
        value: "33",
        label: "Гудат",
        center: { lat: 41.6253, lng: 48.6764 },
        zoom: "10",
      },
      {
        value: "34",
        label: "Имышли",
        center: { lat: 39.8695, lng: 48.0665 },
        zoom: "10",
      },
      {
        value: "35",
        label: "Исмаиллы",
        center: { lat: 40.7871, lng: 48.1523 },
        zoom: "10",
      },
      {
        value: "36",
        label: "Кельбаджар",
        center: { lat: 40.1024, lng: 46.0365 },
        zoom: "10",
      },
      {
        value: "37",
        label: "Курдамыр",
        center: { lat: 40.3699, lng: 48.1645 },
        zoom: "10",
      },
      {
        value: "38",
        label: "Кенгерли",
        center: { lat: 39.3872, lng: 45.164 },
        zoom: "10",
      },
      {
        value: "39",
        label: "Ках",
        center: { lat: 41.4207, lng: 46.932 },
        zoom: "10",
      },
      {
        value: "40",
        label: "Газах",
        center: { lat: 41.0971, lng: 45.3516 },
        zoom: "10",
      },
      {
        value: "41",
        label: "Габала",
        center: { lat: 40.9982, lng: 47.87 },
        zoom: "10",
      },
      {
        value: "42",
        label: "Гобустан",
        center: { lat: 40.5326, lng: 48.9274 },
        zoom: "10",
      },
      {
        value: "43",
        label: "Говлар",
        center: { lat: 40.9364, lng: 45.7475 },
        zoom: "10",
      },
      {
        value: "44",
        label: "Куба",
        center: { lat: 41.3643, lng: 48.5261 },
        zoom: "10",
      },
      {
        value: "45",
        label: "Губадли",
        center: { lat: 39.3453, lng: 46.5789 },
        zoom: "10",
      },
      {
        value: "46",
        label: "Кусар",
        center: { lat: 41.4275, lng: 48.4302 },
        zoom: "10",
      },
      {
        value: "47",
        label: "Лачин",
        center: { lat: 39.6383, lng: 46.5461 },
        zoom: "10",
      },
      {
        value: "48",
        label: "Лерик",
        center: { lat: 38.7736, lng: 48.4151 },
        zoom: "10",
      },
      {
        value: "49",
        label: "Ленкеран",
        center: { lat: 38.7529, lng: 48.8475 },
        zoom: "10",
      },
      {
        value: "50",
        label: "Лиман",
        center: { lat: 41.312, lng: 72.9278 },
        zoom: "10",
      },
      {
        value: "51",
        label: "Масаллы",
        center: { lat: 39.0341, lng: 48.6589 },
        zoom: "10",
      },
      {
        value: "52",
        label: "Мингечаур",
        center: { lat: 40.7703, lng: 47.0496 },
        zoom: "10",
      },
      {
        value: "53",
        label: "Нафталан",
        center: { lat: 40.5076, lng: 46.824 },
        zoom: "10",
      },
      {
        value: "54",
        label: "Нахичевань",
        center: { lat: 39.2009, lng: 45.4082 },
        zoom: "10",
      },
      {
        value: "55",
        label: "Нефтчала",
        center: { lat: 39.3881, lng: 49.2414 },
        zoom: "10",
      },
      {
        value: "56",
        label: "Огуз",
        center: { lat: 41.0728, lng: 47.4651 },
        zoom: "10",
      },
      {
        value: "57",
        label: "Ордубад",
        center: { lat: 38.9022, lng: 46.0238 },
        zoom: "10",
      },
      {
        value: "58",
        label: "Саатлы",
        center: { lat: 39.9096, lng: 48.3595 },
        zoom: "10",
      },
      {
        value: "59",
        label: "Сабирабад",
        center: { lat: 39.9871, lng: 48.4693 },
        zoom: "10",
      },
      {
        value: "60",
        label: "Салян",
        center: { lat: 39.5779, lng: 48.9719 },
        zoom: "10",
      },
      {
        value: "61",
        label: "Самух",
        center: { lat: 40.7605, lng: 46.4063 },
        zoom: "10",
      },
      {
        value: "62",
        label: "Садарак",
        center: { lat: 39.7105, lng: 44.8864 },
        zoom: "10",
      },
      {
        value: "63",
        label: "Сиазань",
        center: { lat: 41.0784, lng: 49.1118 },
        zoom: "10",
      },
      {
        value: "64",
        label: "Сумгаит",
        center: { lat: 40.5855, lng: 49.6317 },
        zoom: "10",
      },
      {
        value: "65",
        label: "Шабран",
        center: { lat: 41.216, lng: 48.9946 },
        zoom: "10",
      },
      {
        value: "66",
        label: "Шахбуз",
        center: { lat: 39.4056, lng: 45.5691 },
        zoom: "10",
      },
      {
        value: "67",
        label: "Шамахи",
        center: { lat: 40.6319, lng: 48.6364 },
        zoom: "10",
      },
      {
        value: "68",
        label: "Шеки",
        center: { lat: 41.1975, lng: 47.1571 },
        zoom: "10",
      },
      {
        value: "69",
        label: "Шамкир",
        center: { lat: 40.8288, lng: 46.0167 },
        zoom: "10",
      },
      {
        value: "70",
        label: "Шерур",
        center: { lat: 39.5536, lng: 44.9846 },
        zoom: "10",
      },
      {
        value: "71",
        label: "Ширван",
        center: { lat: 39.9266, lng: 48.9206 },
        zoom: "10",
      },
      {
        value: "72",
        label: "Шуша",
        center: { lat: 39.7537, lng: 46.7465 },
        zoom: "10",
      },
      {
        value: "73",
        label: "Тертер",
        center: { lat: 40.3444, lng: 46.9377 },
        zoom: "10",
      },
      {
        value: "74",
        label: "Товуз",
        center: { lat: 40.9955, lng: 45.6166 },
        zoom: "10",
      },
      {
        value: "75",
        label: "Уджар",
        center: { lat: 40.5068, lng: 47.649 },
        zoom: "10",
      },
      {
        value: "76",
        label: "Ярдымлы",
        center: { lat: 38.9059, lng: 48.2496 },
        zoom: "10",
      },
      {
        value: "77",
        label: "Евлах",
        center: { lat: 40.6197, lng: 47.15 },
        zoom: "10",
      },
      {
        value: "78",
        label: "Закатала",
        center: { lat: 41.6031, lng: 46.6383 },
        zoom: "10",
      },
      {
        value: "79",
        label: "Зангелан",
        center: { lat: 39.0857, lng: 46.6525 },
        zoom: "10",
      },
      {
        value: "80",
        label: "Зардаб",
        center: { lat: 40.2148, lng: 47.7149 },
        zoom: "10",
      },
    ],
    region_options: [
      { value: "0", label: "Абшерон" },
      { value: "1", label: "Бинагади" },
      { value: "2", label: "Нариманов" },
      { value: "3", label: "Насими" },
      { value: "4", label: "Низами" },
      { value: "5", label: "Карадаг" },
      { value: "6", label: "Сабунчу" },
      { value: "7", label: "Себайл" },
      { value: "8", label: "Сураханы" },
      { value: "9", label: "Хатаи" },
      { value: "10", label: "Хезер" },
      { value: "11", label: "Ясамаль" },
      { value: "12", label: "Пираллахы" },
    ],
    site_options: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "tap.az", label: "tap.az" },
      { value: "bina.az", label: "bina.az" },
      { value: "lalafo.az", label: "lalafo.az" },
      { value: "emlak.az", label: "emlak.az" },
      { value: "arenda.az", label: "arenda.az" },
      { value: "yeniemlak.az", label: "yeniemlak.az" },
      { value: "rahatemlak.az", label: "rahatemlak.az" },
      { value: "ucuztap.az", label: "ucuztap.az" },
    ],
    site_options_cars: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "turbo.az", label: "turbo.az" },
      { value: "masinlar.az", label: "masinlar.az" },
      { value: "auto.az", label: "auto.az" },
      { value: "mashin.al", label: "mashin.al" }
    ],
    room_options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "50", label: "5 +" },
    ],
    station_options: [
      { label: "Ичеришехер м.", value: "1" },
      { label: "Сахил м.", value: "2" },
      { label: "28 Мая м.", value: "3" },
      { label: "Гянджлик м.", value: "4" },
      { label: "Нариман Нариманов м.", value: "5" },
      { label: "Бакмил м.", value: "6" },
      { label: "Улдуз м.", value: "7" },
      { label: "Короглу м.", value: "8" },
      { label: "Кара Карай м.", value: "9" },
      { label: "Нефтчилер м.", value: "10" },
      { label: "Дружба народов м.", value: "11" },
      { label: "Ахмедли м.", value: "12" },
      { label: "Гези Асланов м.", value: "13" },
      { label: "Низами Гянджеви м.", value: "14" },
      { label: "Академия Наук м.", value: "15" },
      { label: "Строители м.", value: "16" },
      { label: "20 Января м.", value: "17" },
      { label: "Мемар Аджеми м.", value: "18" },
      { label: "Насими м.", value: "19" },
      { label: "Проспект Свободы м.", value: "20" },
      { label: "Дернегюль м.", value: "21" },
      { label: "Джафар Джаббарли м.", value: "22" },
      { label: "Шах Исмаил Хатаи м.", value: "23" },
      { label: "Автовокзал м.", value: "24" },
      { label: "8 Ноября м.", value: "25" },
      { label: "Ходжасан м.", value: "26" },
    ],
    resident_options: [
      { value: "1", label: "1-й микрорайон", region: "Насими" },
      { value: "2", label: "2-й микрорайон", region: "Насими" },
      { value: "3", label: "20-я площадь", region: "Себайл" },
      { value: "4", label: "28 мая", region: "Бинагади" },
      { value: "5", label: "3-й микрорайон", region: "Насими" },
      { value: "6", label: "4-й микрорайон", region: "Насими" },
      { value: "7", label: "5-й микрорайон", region: "Насими" },
      { value: "8", label: "6-й микрорайон", region: "Бинагади" },
      { value: "9", label: "6-я параллель", region: "Бинагади" },
      { value: "10", label: "7-й микрорайон", region: "Бинагади" },
      { value: "11", label: "8 км", region: "Низами" },
      { value: "12", label: "8-й микрорайон", region: "Бинагади" },
      { value: "13", label: "9-й микрорайон", region: "Бинагади" },
      { value: "14", label: "Аг шахар", region: "Хатаи" },
      { value: "15", label: "Алатава 1", region: "Бинагади" },
      { value: "16", label: "Алатава 2", region: "Бинагади" },
      { value: "17", label: "Албали", region: "Сабунчу" },
      { value: "18", label: "Атялы", region: "Абшерон" },
      { value: "19", label: "Бадамдар", region: "Себайл" },
      { value: "20", label: "Бахар", region: "Сураханы" },
      { value: "21", label: "Бакиханов", region: "Сабунчу" },
      { value: "22", label: "Балакяны", region: "Сабунчу" },
      { value: "23", label: "Байыл", region: "Себайл" },
      { value: "24", label: "Бибихейбат", region: "Себайл" },
      { value: "25", label: "Бильгях", region: "Сабунчу" },
      { value: "26", label: "Биледжери", region: "Бинагади" },
      { value: "27", label: "Биня", region: "Каспий" },
      { value: "28", label: "Бинагади", region: "Бинагади" },
      { value: "29", label: "Бёюкшор", region: "Нариманов" },
      { value: "30", label: "Бюльбюля", region: "Сураханы" },
      { value: "31", label: "Бузовна", region: "Каспий" },
      { value: "32", label: "Чермет", region: "" },
      { value: "33", label: "Джейранбатан", region: "Абшерон" },
      { value: "34", label: "Чичек", region: "Абшерон" },
      { value: "35", label: "Чорат", region: "" },
      { value: "36", label: "Дига", region: "Абшерон" },
      { value: "37", label: "Сады Дюбенди", region: "Каспий" },
      { value: "38", label: "Деде Горгуд", region: "Сураханы" },
      { value: "39", label: "Фатмайы", region: "Абшерон" },
      { value: "40", label: "Горадил", region: "Абшерон" },
      { value: "41", label: "Гюргян", region: "Каспий" },
      { value: "42", label: "Гюздек", region: "Абшерон" },
      { value: "43", label: "Гекмели", region: "Абшерон" },
      { value: "44", label: "Гюздек", region: "Сураханы" },
      { value: "45", label: "Гези Асланов", region: "Хатаи" },
      { value: "46", label: "Кешле", region: "Низами" },
      { value: "47", label: "Химический городок", region: "Насими" },
      { value: "48", label: "Старый Гюнешли", region: "Хатаи" },
      { value: "49", label: "Кюрдехани", region: "Сабунчу" },
      { value: "50", label: "Локбатан", region: "Карадаг" },
      { value: "51", label: "Лахичские сады", region: "Сабунчу" },
      { value: "52", label: "Масазыр", region: "Абшерон" },
      { value: "53", label: "Мастава", region: "Сабунчу" },
      { value: "54", label: "Мехдиабад", region: "Абшерон" },
      { value: "55", label: "Монтин", region: "Нариманов" },
      { value: "56", label: "Мушфигабад", region: "Карадаг" },
      { value: "57", label: "Мехмедли", region: "Абшерон" },
      { value: "58", label: "Мердекан", region: "Каспий" },
      { value: "59", label: "Нардаран", region: "Сабунчу" },
      { value: "60", label: "Насосно", region: "" },
      { value: "61", label: "Новханы", region: "Абшерон" },
      { value: "62", label: "Нубар", region: "" },
      { value: "63", label: "НЗС", region: "Хатаи" },
      { value: "64", label: "Папанин", region: "" },
      { value: "65", label: "Перекешкюль", region: "Абшерон" },
      { value: "66", label: "Пиршаги", region: "Сабунчу" },
      { value: "67", label: "Пута", region: "Карадаг" },
      { value: "68", label: "Кала", region: "Каспий" },
      { value: "69", label: "Черный город", region: "" },
      { value: "70", label: "Караджур", region: "Сураханы" },
      { value: "71", label: "Гизилджа", region: "Карадаг" },
      { value: "72", label: "Гобустан", region: "Карадаг" },
      { value: "73", label: "Рамана", region: "Сабунчу" },
      { value: "74", label: "Ресульзаде", region: "Бинагади" },
      { value: "75", label: "Сабунчу", region: "Сабунчу" },
      { value: "76", label: "Шаган", region: "Каспий" },
      { value: "77", label: "Сахил", region: "Карадаг" },
      { value: "78", label: "Сарай", region: "Абшерон" },
      { value: "79", label: "Савалан", region: "Сабунчу" },
      { value: "80", label: "Шаган", region: "Сураханы" },
      { value: "81", label: "Шихов", region: "Себайл" },
      { value: "82", label: "Шубаны", region: "Карадаг" },
      { value: "83", label: "Сулутепе", region: "Бинагади" },
      { value: "84", label: "Шуша", region: "Сабунчу" },
      { value: "85", label: "Шувалан", region: "Каспий" },
      { value: "86", label: "Сенгечал", region: "Карадаг" },
      { value: "87", label: "Туркан", region: "Каспий" },
      { value: "88", label: "Умид", region: "Карадаг" },
      { value: "89", label: "Хашахуна", region: "Каспий" },
      { value: "90", label: "Ходжасан", region: "Бинагади" },
      { value: "91", label: "Хутор", region: "Бинагади" },
      { value: "92", label: "Новый Гюнешли", region: "Сураханы" },
      { value: "93", label: "Новая Рамана", region: "Сабунчу" },
      { value: "94", label: "Новая Сураханы", region: "Сураханы" },
      { value: "95", label: "Новый Ясамаль", region: "Ясамаль" },
      { value: "96", label: "Забрат 1", region: "Сабунчу" },
      { value: "97", label: "Забрат 2", region: "Сабунчу" },
      { value: "98", label: "Загульба", region: "Абшерон" },
      { value: "99", label: "Зыг", region: "Сураханы" },
      { value: "100", label: "Зире", region: "Каспий" },
      { value: "101", label: "Ахмедли", region: "Хатаи" },
      { value: "102", label: "Алет", region: "Карадаг" },
      { value: "103", label: "Амирджан", region: "Сураханы" },
    ],
    target_options: [
      { value: "1", label: "28 Mall", region: "Насими" },
      { value: "2", label: "Парк им. А.С. Пушкина", region: "Насими" },
      { value: "3", label: "AAF PARK", region: "Абшерон" },
      { value: "4", label: "Посольство США", region: "Насими" },
      {
        value: "5",
        label: "Абшеронская городская молодежная площадка",
        region: "Абшерон",
      },
      { value: "6", label: "Отель Absheron Marriott", region: "Насими" },
      { value: "7", label: "Стадион Abu Arena", region: "Нариманов" },
      { value: "8", label: "Университет ADA", region: "Нариманов" },
      {
        value: "9",
        label: "Деловой центр AF Business House",
        region: "Насими",
      },
      { value: "10", label: "Бизнес-центр AGA", region: "Хатаи" },
      { value: "11", label: "Altes Plaza", region: "Ясамал" },
      { value: "12", label: "AMAY", region: "Хатаи" },
      { value: "13", label: "Отель Ambassador", region: "Нариманов" },
      { value: "14", label: "Телеканал ANS", region: "Себайл" },
      { value: "15", label: "Аквапарк", region: "Насими" },
      { value: "16", label: "Кинотеатр Араз", region: "Бинагади" },
      { value: "17", label: "Asan Xidmət-1", region: "Нариманов" },
      { value: "18", label: "Asan Xidmət-2", region: "Хатаи" },
      { value: "19", label: "Asan Xidmət-3", region: "Ясамал" },
      { value: "20", label: "Asan Xidmət-4", region: "Сабунчу" },
      { value: "21", label: "Asan Xidmət-5", region: "Низами" },
      { value: "22", label: "Asan Xidmət-6", region: "Хатаи" },
      { value: "23", label: "Плаза ASK", region: "Насими" },
      { value: "24", label: "Парк Ататюрка", region: "Нариманов" },
      { value: "25", label: "Телеканал ATV", region: "Ясамал" },
      {
        value: "26",
        label: "Университет национальной авиации",
        region: "Хазар",
      },
      { value: "27", label: "Отель Europa", region: "Насими" },
      { value: "28", label: "Сад Ахундова", region: "Себайл" },
      {
        value: "29",
        label: "Городской комплекс Aygun City",
        region: "Сабунчу",
      },
      { value: "30", label: "Памятник Айна Султановой", region: "Нариманов" },
      { value: "31", label: "Площадь Свободы", region: "Себайл" },
      { value: "32", label: "Площадь Азнефть", region: "Себайл" },
      { value: "33", label: "Телеканал AZTV", region: "Себайл" },
      { value: "34", label: "Университет языков", region: "Насими" },
      { value: "35", label: "Кинотеатр Азербайджан", region: "Ясамал" },
      {
        value: "36",
        label: "Институт туризма Азербайджана",
        region: "Нариманов",
      },
      { value: "37", label: "Плаза Babek", region: "Хатаи" },
      { value: "38", label: "Университет Baku Asia", region: "Насими" },
      {
        value: "39",
        label: "Бакинский государственный университет",
        region: "Ясамал",
      },
      { value: "40", label: "Академия музыки Баку", region: "Насими" },
      { value: "41", label: "Славянский университет Баку", region: "Насими" },
      { value: "42", label: "Бакинский универмаг", region: "Низами" },
      { value: "43", label: "Торговый центр Baku Mall", region: "Ясамал" },
      { value: "44", label: "Парк Байл", region: "Себайл" },
      { value: "45", label: "Пятиэтажка", region: "Сабунчу" },
      { value: "46", label: "Торговый центр Бинагади", region: "Гарадаг" },
      { value: "47", label: "Ботанический сад", region: "Себайл" },
      { value: "48", label: "Мостовая плаза", region: "Насими" },
      { value: "49", label: "Памятник Джавида", region: "Насими" },
      {
        value: "50",
        label: "Военная академия имени Нахчыванского",
        region: "Хатаи",
      },
      { value: "51", label: "Плаза Каспий", region: "Ясамал" },
      {
        value: "52",
        label: "Торговый центр Caspian Shopping Center",
        region: "Нариманов",
      },
      { value: "53", label: "Мост Чаваншир", region: "Насими" },
      { value: "54", label: "Посольство Китая", region: "Гарадаг" },
      { value: "55", label: "Плаза Чыраг", region: "Насими" },
      { value: "56", label: "Плаза Crystal", region: "Хатаи" },
      { value: "57", label: "Парк Дагустан", region: "Себайл" },
      { value: "58", label: "Плаза Далга", region: "Себайл" },
      { value: "59", label: "Министерство внутренних дел", region: "Себайл" },
      { value: "60", label: "Кинотеатр Дружба", region: "Насими" },
      {
        value: "61",
        label: "Академия государственного управления",
        region: "Себайл",
      },
      { value: "62", label: "Комитет по статистике", region: "Ясамал" },
      { value: "63", label: "Парк Дадаша Горгуда", region: "Нариманов" },
      { value: "64", label: "Плаза Демирчи", region: "Хатаи" },
      { value: "65", label: "Национальный парк у моря", region: "Себайл" },
      {
        value: "66",
        label: "Министерство экологии и природных ресурсов",
        region: "Ясамал",
      },
      { value: "67", label: "Торговый центр Elite", region: "Бинагади" },
      { value: "68", label: "Министерство энергетики", region: "Низами" },
      { value: "69", label: "Отель Fairmont", region: "Себайл" },
      { value: "70", label: "Башни Flame Towers", region: "Себайл" },
      { value: "71", label: "Фонтанный сад", region: "Себайл" },
      { value: "72", label: "Отель Four Seasons", region: "Себайл" },
      {
        value: "73",
        label: "Министерство чрезвычайных ситуаций",
        region: "Ясамал",
      },
      { value: "74", label: "Академия МЧС", region: "Сурахани" },
      { value: "75", label: "Площадь Февраль", region: "Себайл" },
      { value: "76", label: "Гурген", region: "Пирали" },
      {
        value: "77",
        label: "Торговый центр Genclik Mall",
        region: "Нариманов",
      },
      {
        value: "78",
        label: "Министерство молодежи и спорта",
        region: "Нариманов",
      },
      { value: "79", label: "Мечеть Гейдара", region: "Бинагади" },
      { value: "80", label: "Центр имени Гейдара Алиева", region: "Нариманов" },
      { value: "81", label: "Дворец Гейдара Алиева", region: "Насими" },
      { value: "82", label: "Отель Hilton", region: "Себайл" },
      { value: "83", label: "Парк Гусейна Джавида", region: "Ясамал" },
      { value: "84", label: "Отель Hyatt Regency", region: "Ясамал" },
      { value: "85", label: "Военный госпиталь", region: "Насими" },
      { value: "86", label: "Телеканал Ичмайы", region: "Ясамал" },
      { value: "87", label: "Старый город", region: "Себайл" },
      {
        value: "88",
        label: "Институт изящных искусств и культуры",
        region: "Ясамал",
      },
      { value: "89", label: "Университет строительства", region: "Ясамал" },
      { value: "90", label: "Министерство экономики", region: "Низами" },
      { value: "91", label: "Университет экономики", region: "Низами" },
      {
        value: "92",
        label: "Посольство Исламской Республики Иран",
        region: "Себайл",
      },
      { value: "93", label: "Плаза ИСР", region: "Насими" },
      { value: "94", label: "Измировский парк", region: "Ясамал" },
      { value: "95", label: "Кешле базар", region: "Нариманов" },
      { value: "96", label: "Парк коал", region: "Насими" },
      { value: "97", label: "Кооперативный университет", region: "Нариманов" },
      { value: "98", label: "Замок Короглу", region: "Насими" },
      { value: "99", label: "Landmark", region: "Себайл" },
      { value: "100", label: "Лидерский телеканал", region: "Ясамал" },
      { value: "101", label: "Парк Мирзы Хусейна", region: "Насими" },
      { value: "102", label: "Парк Мирзы Сабира", region: "Сабунчу" },
      { value: "103", label: "Министерство финансов", region: "Насими" },
      { value: "104", label: "Megafun", region: "Хатаи" },
      {
        value: "105",
        label: "Университет строительства и архитектуры",
        region: "Ясамал",
      },
      { value: "106", label: "Метропарк", region: "Нариманов" },
      { value: "107", label: "Национальная консерватория", region: "Ясамал" },
      { value: "108", label: "Милли меджлис", region: "Себайл" },
      { value: "109", label: "Служба госбезопасности", region: "Ясамал" },
      { value: "110", label: "Молоканский двор", region: "Себайл" },
      { value: "111", label: "Парк Монтина", region: "Нариманов" },
      { value: "112", label: "Московский университет", region: "Насими" },
      {
        value: "113",
        label: "Министерство обороны промышленности",
        region: "Хатаи",
      },
      { value: "114", label: "MUM", region: "Себайл" },
      { value: "115", label: "Парк Мусабаева", region: "Ясамал" },
      {
        value: "116",
        label: "Центральная больница нефтяников",
        region: "Хатаи",
      },
      { value: "117", label: "Центральный универмаг", region: "Себайл" },
      { value: "118", label: "Торговый центр Наргиз", region: "Себайл" },
      { value: "119", label: "Напольное кольцо", region: "Хатаи" },
      {
        value: "120",
        label:
          "Азербайджанский Государственный Университет Нефти и Промышленности",
        region: "Насими",
      },
      { value: "121", label: "База нефтяников", region: "Нариманов" },
      { value: "122", label: "Метро нефтяников", region: "Низами" },
      { value: "123", label: "Кинотеатр Низами", region: "Насими" },
      { value: "124", label: "Министерство транспорта", region: "Нариманов" },
      { value: "125", label: "Парк Наримана Нариманова", region: "Нариманов" },
      { value: "126", label: "Памятник Нариманову", region: "Ясамал" },
      { value: "127", label: "Базар Насими", region: "Насими" },
      { value: "128", label: "Памятник Насими", region: "Насими" },
      {
        value: "129",
        label: "Университет Огненной Земли",
        region: "Нариманов",
      },
      { value: "130", label: "Олимпийский стадион", region: "Сабунчу" },
      { value: "131", label: "Олимпийская звезда", region: "Нариманов" },
      { value: "132", label: "Дворец гостеприимства Оскар", region: "Низами" },
      { value: "133", label: "Посольство Узбекистана", region: "Сабаил" },
      { value: "134", label: "Парк Азур", region: "Хатаи" },
      { value: "135", label: "Парк Бульвар", region: "Сабаил" },
      { value: "136", label: "Отель Парк Инн", region: "Сабаил" },
      { value: "137", label: "Парк Зорге", region: "Насими" },
      { value: "138", label: "Педагогический университет", region: "Сабаил" },
      { value: "139", label: "Перекешкуль", region: "Абшерон" },
      { value: "140", label: "Порт Баку", region: "Насими" },
      { value: "141", label: "Парк Президента", region: "Хатаи" },
      { value: "142", label: "Отель Пульман", region: "Ясамал" },
      { value: "143", label: "Отели курорта Гянджа", region: "Насими" },
      { value: "144", label: "Университет инженерии", region: "Абшерон" },
      { value: "145", label: "Зимний парк", region: "Ясамал" },
      { value: "146", label: "Девичья башня", region: "Сабаил" },
      { value: "147", label: "Парк Губернатора", region: "Сабаил" },
      {
        value: "148",
        label: "Жилой комплекс 'Освобождение - 93'",
        region: "Абшерон",
      },
      { value: "149", label: "Круг Победы", region: "Ясамал" },
      { value: "150", label: "Университет Запада", region: "Сабаил" },
      {
        value: "151",
        label: "Министерство связи и высоких технологий",
        region: "Насими",
      },
      { value: "152", label: "Республиканский стадион", region: "Нариманов" },
      { value: "153", label: "Посольство России", region: "Насими" },
      { value: "154", label: "Академия живописи", region: "Нариманов" },
      { value: "155", label: "Набережная", region: "Нариманов" },
      { value: "156", label: "Курорт 'Морской бриз'", region: "Сабунчу" },
      { value: "157", label: "Парк Любви", region: "Хатаи" },
      { value: "158", label: "Парк имени Севиль Казиевой", region: "Насими" },
      { value: "159", label: "Жилой комплекс 'Севиндж'", region: "Низами" },
      { value: "160", label: "Цирк", region: "Насими" },
      { value: "161", label: "Советский", region: "Ясамал" },
      { value: "162", label: "Space TV", region: "Ясамал" },
      { value: "163", label: "Торговый центр 'Шувелан Парк'", region: "Хазар" },
      { value: "164", label: "Торговый центр 'Седерек'", region: "Гарадаг" },
      { value: "165", label: "Стадион 'Шафа'", region: "Низами" },
      { value: "166", label: "Аллея Погибших", region: "Сабаил" },
      { value: "167", label: "Министерство здравоохранения", region: "Насими" },
      { value: "168", label: "Парк 'Шелале'", region: "Сабаил" },
      { value: "169", label: "Парк имени Самеда Вургуна", region: "Насими" },
      { value: "170", label: "Спортивный комплекс 'Граница'", region: "Хатаи" },
      { value: "171", label: "Восточный рынок", region: "Нариманов" },
      { value: "172", label: "Таркову", region: "Сабаил" },
      { value: "173", label: "Технический университет", region: "Ясамал" },
      { value: "174", label: "Медицинский университет", region: "Насими" },
      { value: "175", label: "Стадион 'Тофик Бахрамов'", region: "Нариманов" },
      { value: "176", label: "Посольство Турции", region: "Насими" },
      { value: "177", label: "Университет мышления", region: "Нариманов" },
      { value: "178", label: "Министерство образования", region: "Нариманов" },
      { value: "179", label: "Новый рынок", region: "Насими" },
      { value: "180", label: "Посольство Украины", region: "Хатаи" },
      { value: "181", label: "Министерство налогов", region: "Низами" },
      { value: "182", label: "Кинотеатр 'Родина'", region: "Насими" },
      { value: "183", label: "Деловой центр 'Мировой'", region: "Насими" },
      { value: "184", label: "Музей ковров", region: "Сабаил" },
      { value: "185", label: "Министерство иностранных дел", region: "Ясамал" },
      { value: "186", label: "Торговый центр 'Хагани'", region: "Сабаил" },
      { value: "187", label: "Университет Хазар", region: "Низами" },
      { value: "188", label: "Ясамальский рынок", region: "Ясамал" },
      { value: "189", label: "Парк Ясамаль", region: "Ясамал" },
      { value: "190", label: "Зеленый рынок", region: "Нариманов" },
      { value: "191", label: "Парк 'Забитлер'", region: "Насими" },
      { value: "192", label: "Зоопарк", region: "Нариманов" },
      { value: "193", label: "Парк имени Зарифы Алиевой", region: "Бинагади" },
      { value: "194", label: "Министерство юстиции", region: "Ясамал" },
      {
        value: "195",
        label: "Министерство труда и социальной защиты населения",
        region: "Ясамал",
      },
    ],
    flat_type_options: [
      { value: "1", label: "Квартира" },
      { value: "2", label: "Частный дом/Сад/Вилла", label2: "Частный" },
      { value: "3", label: "Офис" },
      { value: "4", label: "Гараж" },
      { value: "5", label: "Участок" },
      { value: "6", label: "Объект" },
    ],
    offer_type_options: [
      { value: "true", label: "Продажа" },
      { value: "false", label: "Аренда" },
    ],
    mortgage_options: [
      { value: "true", label: "Да" },
      { value: "false", label: "Нет" },
    ],
    rent_type_options: [
      { value: "1", label: "Aylıq" },
      { value: "2", label: "Günlük" },
    ],
    filter_options: [
      { value: "dn", label: "По дата (Сначало новые)" },
      { value: "do", label: "По дата (Сначало старые)" },
      { value: "pl", label: "По цена (Сначало дешевые)" },
      { value: "ph", label: "По цена (Сначало дорогие)" },
    ],
    filter_options_blog: [
      { value: "dn", label: "По дата (Сначало новые)" },
      { value: "do", label: "По дата (Сначало старые)" },
      { value: "pl", label: "Просмотры (Сначало меньше)" },
      { value: "ph", label: "Просмотры (Сначало больше)" },
    ],
    type_of_flat: [
      { value: "1", label: "Ежемесячно" },
      { value: "2", label: "По дням" },
    ],auto_body_type_options: [
      { value: "1", label: "Фаэтон" },
      { value: "2", label: "Караван" },
      { value: "3", label: "Микрован" },
      { value: "4", label: "Пикап, одна кабина" },
      { value: "5", label: "Внедорожник / SUV, открытый" },
      { value: "6", label: "Родстер" },
      { value: "7", label: "Хэтчбек, 4 двери" },
      { value: "8", label: "Автобус" },
      { value: "9", label: "Кабриолет" },
      { value: "10, 33", label: "Хэтчбек, 3 двери" },
      { value: "11", label: "Микроавтобус" },
      { value: "12", label: "Компакт-вэн" },
      { value: "13", label: "Тягач" },
      { value: "14", label: "Купе" },
      { value: "15", label: "Пикап, двойная кабина" },
      { value: "16", label: "SUV Купе" },
      { value: "17", label: "Внедорожник / SUV, 3 двери" },
      { value: "18", label: "Минивэн" },
      { value: "19", label: "Грузовик" },
      { value: "20", label: "Фургон" },
      { value: "21", label: "Универсал, 5 дверей" },
      { value: "22", label: "Лифтбек" },
      { value: "23, 30", label: "Хэтчбек, 5 дверей" },
      { value: "24", label: "Внедорожник / SUV, 5 дверей" },
      { value: "25", label: "Седан" },
      { value: "26", label: "Мотоцикл" },
      { value: "27", label: "Мопед" },
      { value: "28", label: "Квадроцикл" },
      { value: "29", label: "Универсал" },
      { value: "31", label: "Внедорожник, SUV" },
      { value: "32", label: "Хэтчбек" },
      { value: "34", label: "Минивэн" },
      { value: "35", label: "Вэн" },
      { value: "36", label: "Лимузин" },
    ],
    
    auto_fuel_type_options: [
      { value: "1", label: "Бензин" },
      { value: "2", label: "Дизель" },
      { value: "3", label: "Газ" },
      { value: "4", label: "Электро" },
      { value: "5", label: "Гибрид" },
      { value: "6", label: "Подключаемый гибрид" },
    ],
    
    auto_drive_type_options: [
      { value: "1", label: "Задний" },
      { value: "2", label: "Полный" },
      { value: "3", label: "Передний" },
    ],
    
    auto_transmission_type_options: [
      { value: "1", label: "Автомат" },
      { value: "2", label: "Вариатор" },
      { value: "3", label: "Механическая" },
      { value: "4", label: "Робот" },
      { value: "5", label: "Редуктор" },
      { value: "6", label: "Роботизированная" },
    ],
    auto_color_type_options: [
      { value: "1", label: "Розовый" },
      { value: "2", label: "Фиолетовый" },
      { value: "3", label: "Желтый" },
      { value: "4", label: "Оранжевый" },
      { value: "5", label: "Салатовый" },
      { value: "6", label: "Бежевый" },
      { value: "7", label: "Коричневый" },
      { value: "8", label: "Золотой" },
      { value: "9", label: "Голубой" },
      { value: "10", label: "Зеленый" },
      { value: "11", label: "Темно-красный" },
      { value: "12", label: "Красный" },
      { value: "13", label: "Серый" },
      { value: "14", label: "Синий" },
      { value: "15", label: "Мокрый асфальт" },
      { value: "16", label: "Серебристый" },
      { value: "17", label: "Черный" },
      { value: "18", label: "Белый" },
      { value: "19", label: "Мокрый асфальт" },
    ],
    packets: {
      boost: [
        { dayOrTime: "1", price: "0.49" },
        { dayOrTime: "5", price: "1.99" },
        { dayOrTime: "10", price: "3.99" },
      ],
      frame: [
        { dayOrTime: "1", price: "0.99" },
        { dayOrTime: "5", price: "2.49" },
        { dayOrTime: "10", price: "4.49" },
      ],
      urgent: [
        { dayOrTime: "1", price: "1.99" },
        { dayOrTime: "5", price: "5.99" },
        { dayOrTime: "10", price: "9.99" },
      ],
      premium: [
        { dayOrTime: "1", price: "2.49" },
        { dayOrTime: "5", price: "10.99" },
        { dayOrTime: "10", price: "19.99" },
        { dayOrTime: "30", price: "39.99" },
      ],
    },
    blog: "Блог",
    blogAll: "Все",
    blogMore: "Подробнее...",
    blogNotFound: "Блог не найден",
    saleForTitle: " продажа",
    dailyForTitle: " посуточно",
    rentForTitle: " аренда",
    roomsForTitle: " комнатная",
    minAreaForTitle: "минимум площадь - ",
    maxAreaForTitle: "максимум площадь - ",
    minFloorForTitle: "минимум этажей - ",
    maxFloorForTitle: "максимум этажей - ",
    minPriceForTitle: "минимум цена - ",
    maxPriceForTitle: "максимум цена - ",
    withGoodsForTitle: ", С вещями",
    withoutGoodsForTitle: ", Без вещей",
    withRepairForTitle: ", Ремонтом",
    withoutRepairForTitle: ", Без ремонта",
    withDocumentForTitle: ", С документами",
    withoutDocumentForTitle: ", Без документов",
    withCreditForTitle: ", Ипотека",
    cars: "Машины",
    homes: "Недвижимость",
  },
  en: {
    allFavouriteOffersTitle: "Ebaz.az - All selected listings",
    allPremiumOffersTitle: "Ebaz.az - All premium listings",
    loginTitle: "Ebaz.az - Log in",
    registrationTitle: "Ebaz.az - Registration",
    myOffersTitle: "Ebaz.az - Profile",
    newOffersTitle: "Ebaz.az - New listing",
    notFoundTitle: "Ebaz.az - Error 404",
    paymentErrorTitle: "Ebaz.az - Payment unsuccessful!",
    paymentSuccessTitle: "Ebaz.az - Payment successful!",
    newOfferTitle: "Ebaz.az - Change listing",
    findOffer: "Find listing",
    offersCommon: "Listings",
    favourites: "Favorites",
    profile: "Profile",
    signIn: "Sign In",
    uploadOffer: "Upload listing",
    sale: "For Sale",
    rent: "For Rent",
    day: "For Day",
    clean: "Clean",
    add: "Add",
    cityName: "City",
    flatTypeName: "Property Type",
    autoTypeName: "Brand",
    autoBodyType: "Body type",
    autoFuelType: "Fuel type",
    autoTransmissionType: "Gear box",
    autoColorName: "Color",
    autoEngineVolume:"Volume (cm",
    autoHorsePower:"Power (h.p.)",
    autoDriveType: "Gear",
    autoModelName: "Model",
    autoModelNameFirst: "First select the model",
    roomsCountName: "Rooms",
    priceName: "Price:",
    yearName: "Year:",
    mileageName: "Mileage (km):",
    pricePerM2Name: "Square meter price: AZN/m2",
    pricePerSotName: "Acr price:  AZN/acr",
    min: "min.",
    max: "max.",
    areaName: "Area",
    sot: "acr",
    m2: "m²",
    l: "L",
    km:"km",
    regionName: "Region",
    residentName: "Resident",
    stationName: "Station",
    targetName: "Target",
    floorName: "Floor:",
    siteName: "Websites",
    siteNameEnter: "Select websites",
    withGoodsName: "Furnished",
    withoutGoodsName: "Unfurnished",
    repairName: "With Repair",
    withoutRepairName: "Without Repair",
    withDocumentsName: "With Documents",
    withoutDocumentsName: "Without Documents",
    mortgageName: "Mortgage",
    showOffersName: "Show listings",
    searchName: "Search",
    historyName: "Search History",
    additionalsearchName: "Advanced Search",
    lastFiveSearchName: "Last 5 Searches",
    allFloorNumberName: "Total number of floors",
    sale2: "Sale",
    clean2: "Clear",
    sloqan: "All real estate in one place!",
    usersAgree: "User agreement",
    privicy: "Privacy policy",
    services: "Services",
    connect: "Contact",
    analytics: "Analytics",
    predicted: "Expected",
    predictedHigh: "increase",
    predictedLow: "low",
    disclaimer:
      "*The site administration is not responsible for the content of advertising banners and placed listings.",
    enterAddress: "Enter address",
    showAll: "Show all",
    premiumOffers: "Premium listings",
    saleCars: "Sale cars",
    saleFlats: "Sale flats",
    rentFlats: "Rent flats",
    saleCountry: "Sale houses/cottages/villas",
    rentCountry: "Rent houses/cottages/villas",
    saleOffice: "Sale offices",
    rentOffice: "Rent offices",
    saleGarage: "Sale garages",
    rentGarage: "Rent garages",
    login: "Login",
    saleLand: "Sale land",
    rentLand: "Rent land",
    saleCommercial: "Sale commercial property",
    rentCommercial: "Rent commercial property",
    searchOffersLoading: "Searching listings",
    logout: "Logout",
    myOffersName: "My listings",
    myOffersActiveName: "Active",
    myOffersDeactiveName: "Deactivated",
    myOffersReductName: "Edit",
    myOffersDeleteFromOffersName: "Remove from listings",
    myOffersRefreshOfferName: "Refresh listing",
    myOffersDontHaveOffersName: "You don't have any listings.",
    myOffersDontHaveActiveOffersName: "You don't have any active listings.",
    myOffersDontHaveDeactiveOffersName:
      "You don't have any deactivated listings.",
    myOffersNameSurnameChangeName: "Change Name and Surname",
    myOffersNameChangeName: "Name",
    myOffersLastnameChangeName: "Surname",
    myOffersNameChangeRequareName: "*Please enter your name",
    myOffersLastnameChangeRequareName: "*Please enter your surname",
    myOffersSetNameChangeName: "Enter your name",
    myOffersSetLastnameChangeName: "Enter your surname",
    myOffersNameLastnameChangedSuccessfullyName: "Successfully updated",
    myOffersNameLastnameChangeButtonName: "Change",
    residentsSmall: " res.",
    WithRoomsSmall: " rooms",
    floorSmall: "floor",
    todaySmall: "Today",
    yesterdaySmall: "Yesterday",
    searchSmall: "Search",
    newOfferSmall: "New listing",
    cabinetSmall: "Cabinet",
    moreSmall: "More",
    menuSmall: "Menu",
    languageSmall: "Language",
    languageBig: "English",
    languageAZ: "Azərbaycan",
    languageRU: "Русский",
    languageEN: "English",
    yesSmall: "Yes",
    noSmall: "No",
    aboutOffer: "About the property",
    urgentSmall: "Urgent",
    offersFilterSloqan: "City, Real Estate, Price, Area, Rooms, Repair...",
    offersFound: "Listings ",
    listSmall: "List",
    mapSmall: "Map",
    mapSmallShow: "Show map",
    mapSmallDontShow: "Hide map",
    notFoundOffers: "Unfortunately, no listings were found for your search.",
    writeLinkForSearch: "Enter the link of the ad you want to search",
    supportedSitesSearch:
      "Supported sites: tap.az, bina.az, lalafo.az, arenda.az, emlak.az, yeniemlak.az, rahatemlak.az, ucuztap.az",
    showOfferSearch: "Show ad",
    favouriteOffersCount: "Favorite listings",
    notFavouritesOffers: "No favorite listings.",
    seeAllOffers: "See all listings",
    phoneNumberName: "Mobile number",
    writePhoneNumber: "*Please enter your number",
    sendSMStoPhone: "An SMS code has been sent to your number",
    sendRepeatSMStoPhone: "Resend SMS code",
    pleaseWriteSMSCode: "*Please enter the SMS code",
    pleaseWriteSMSCodeCorrect: "*Please enter the correct SMS code",
    saveUser: "Save",
    userNotFound: "*User not found",
    loginWithBigI: "LOG IN",
    notProfileRegistration: "Don't have an account? Register",
    profileExistLogin: "Already have an account? Log in",
    registration: "Registration",
    setEmail: "Email Address",
    userAlreadyExist: "*This user is already registered",
    doRegistration: "Register",
    newOffer_flatType: "Property type, ",
    newOffer_offerType: "Listing type, ",
    newOffer_rentPeriod: "Rent period, ",
    newOffer_AreaM2: "Area m², ",
    newOffer_AreaSot: "Area acr, ",
    newOffer_City: "City, ",
    newOffer_Price: "Price, ",
    newOffer_Desc: "Description, ",
    newOffer_Images: "Images (minimum 1 image, maximum 10 images), ",
    newOffer_Name: "Name, ",
    newOffer_Email: "E-mail, ",
    newOffer_PhoneNum: "Please enter a correct mobile number, ",
    videoSmall: "Video",
    addVideoSmall: "+Add video",
    watchVideo: "Watch video",
    imagesSmall: "Images",
    addImagesSmall: "+Add image",
    addImagesMaxCountSmall: "*Maximum 10 images",
    writeInfoInThisFields: "enter information in these fields.",
    sendOffer: "Send listing",
    simpleRules: "Simple rules",
    simpleRules1: "Do not post the same listing multiple times.",
    simpleRules2:
      "Do not include phone numbers, email addresses, or website URLs in the description or images.",
    simpleRules3:
      "Do not include the price in the 'Name' field - there is a separate field for that.",
    simpleRules4:
      'Post your first ad and get "Framing" and "Boost" from us within 2 days.',
    offerView: "Listing view",
    pageNotFoundSmall: "Page not found",
    mainPageSmall: "Main page.",
    boostNameSmall: "Boost",
    makePremiumNameSmall: "Premium",
    deleteFromFavouritesNameSmall: "Remove from favorites",
    positionOfOffer: "Position of the offer",
    addToFavouritesNameSmall: "Add to favorites",
    priceByAgreement: "Price by agreement",
    linkCopied: "Link copied",
    similarOffers: "Similar listings",
    boostOffer: "Boost",
    frameOffer: "Framed",
    fromPrice1: "From 0.49 AZN",
    fromPrice2: "From 0.99 AZN",
    fromPrice3: "From 1.99 AZN",
    boostOfferDescription:
      "The listing will be boosted to the top among all listings.",
    frameOfferDescription:
      "The listing will be framed and boosted to the top among all listings.",
    urgentOfferDescription:
      "The listing will be framed in red and marked as 'Urgent', it will also be boosted to the top among all listings.",
    paidUntill: "Paid until",
    serviceTime: "Service time",
    times: " times",
    boostFor24Hours: "(Boost every 24 hours)",
    boostName: "(+ Boost)",
    paymentMethod: "Payment method",
    bankCard: "Bank card",
    addressSmall: "Address",
    cancelPayment: "Cancel payment",
    makePayment: "Make payment",
    paymentAgreement:
      'By clicking "Make payment", you agree to the User Agreement and Offer of the ebaz.az website.',
    makeOfferPremiumName: "Make ad premium",
    makePremiumDescription:
      "The listing will be displayed in the premium listings section on the main page for the duration of the service and will be promoted for free.",
    days: " days",
    perDays: "/day",
    errorName: "Error",
    errorDescription: "There was an error in your payment, please try again.",
    errorDescription2: "An error occurred. Please try again.",
    successName: "Successful Payment",
    successDescription:
      "Your payment has been successfully recorded, your listing will be updated shortly.",
    successName2: "Successful",
    successDescription2UpdateOffer: "The listing was successfully updated.",
    successDescription2DeleteOffer: "The listing was deleted.",
    successDescription2RefreshOffer: "The listing was successfully refreshed.",
    successDescription2RegistrationOffer: "You have successfully registered.",
    successGoToOffers: "Go to your listings.",
    updateOfferName: "Update listing",
    allRightsReserved: "All rights reserved.",
    siteMap: "Site map",
    notUploadedOffer: "The listing has not been uploaded.",
    uploadedOffer: "Your listing has been shared!",
    showToMorePeople: "Get more people to see your listing!",
    miniBannerWords: [
      'Post your first listing and get "Framing" and "Boost" from us within 2 days.',
    ],
    firstOfferWord:
      'Since it\'s your first listing, we gave you a gift of "Framing" and "Boost" for 2 days!',
    saleDeleted: "Removed from sale",
    rentDeleted: "Removed from rent",
    cities: [
      {
        value: "0",
        label: "Agcabedi",
        center: { lat: 40.053, lng: 47.4554 },
        zoom: "10",
      },
      {
        value: "1",
        label: "Agdam",
        center: { lat: 39.9911, lng: 46.9297 },
        zoom: "10",
      },
      {
        value: "2",
        label: "Agdash",
        center: { lat: 40.6335, lng: 47.4674 },
        zoom: "10",
      },
      {
        value: "3",
        label: "Agdere",
        center: { lat: 40.2116, lng: 46.8237 },
        zoom: "10",
      },
      {
        value: "4",
        label: "Agstafa",
        center: { lat: 41.1125, lng: 45.4477 },
        zoom: "10",
      },
      {
        value: "5",
        label: "Agsu",
        center: { lat: 40.5283, lng: 48.3651 },
        zoom: "10",
      },
      {
        value: "6",
        label: "Astara",
        center: { lat: 38.4688, lng: 48.8728 },
        zoom: "10",
      },
      {
        value: "7",
        label: "Babek",
        center: { lat: 39.1508, lng: 45.4485 },
        zoom: "10",
      },
      {
        value: "8",
        label: "Baku",
        center: { lat: 40.3953, lng: 49.8822 },
        zoom: "10",
      },
      {
        value: "9",
        label: "Balakan",
        center: { lat: 41.7038, lng: 46.4044 },
        zoom: "10",
      },
      {
        value: "10",
        label: "Beylagan",
        center: { lat: 39.7723, lng: 47.6154 },
        zoom: "10",
      },
      {
        value: "11",
        label: "Barda",
        center: { lat: 40.3707, lng: 47.1379 },
        zoom: "10",
      },
      {
        value: "12",
        label: "Bilasuvar",
        center: { lat: 39.4599, lng: 48.551 },
        zoom: "10",
      },
      {
        value: "13",
        label: "Jabrayil",
        center: { lat: 39.3987, lng: 47.0245 },
        zoom: "10",
      },
      {
        value: "14",
        label: "Jalilabad",
        center: { lat: 39.2052, lng: 48.5101 },
        zoom: "10",
      },
      {
        value: "15",
        label: "Julfa",
        center: { lat: 38.9605, lng: 45.6293 },
        zoom: "10",
      },
      {
        value: "16",
        label: "Dashkasan",
        center: { lat: 40.5202, lng: 46.0779 },
        zoom: "10",
      },
      {
        value: "17",
        label: "Delimammadli",
        center: { lat: 40.6844, lng: 46.5728 },
        zoom: "10",
      },
      {
        value: "18",
        label: "Fuzuli",
        center: { lat: 39.6025, lng: 47.1392 },
        zoom: "10",
      },
      {
        value: "19",
        label: "Gedabey",
        center: { lat: 40.57, lng: 45.8107 },
        zoom: "10",
      },
      {
        value: "20",
        label: "Ganja",
        center: { lat: 40.6579, lng: 46.3523 },
        zoom: "10",
      },
      {
        value: "21",
        label: "Goranboy",
        center: { lat: 40.6073, lng: 46.7807 },
        zoom: "10",
      },
      {
        value: "22",
        label: "Goychay",
        center: { lat: 40.6236, lng: 47.7403 },
        zoom: "10",
      },
      {
        value: "23",
        label: "Goygol",
        center: { lat: 40.5895, lng: 46.3271 },
        zoom: "10",
      },
      {
        value: "24",
        label: "Goytepe",
        center: { lat: 39.1171, lng: 48.5844 },
        zoom: "10",
      },
      {
        value: "25",
        label: "Hajigabul",
        center: { lat: 40.0394, lng: 48.9203 },
        zoom: "10",
      },
      {
        value: "26",
        label: "Horadiz",
        center: { lat: 39.4511, lng: 47.3399 },
        zoom: "10",
      },
      {
        value: "27",
        label: "Khachmaz",
        center: { lat: 41.4591, lng: 48.8021 },
        zoom: "10",
      },
      {
        value: "28",
        label: "Stepanakert",
        center: { lat: 39.8265, lng: 46.7656 },
        zoom: "10",
      },
      {
        value: "29",
        label: "Khojaly",
        center: { lat: 39.9133, lng: 46.7943 },
        zoom: "10",
      },
      {
        value: "30",
        label: "Khojavend",
        center: { lat: 39.7915, lng: 47.1101 },
        zoom: "10",
      },
      {
        value: "31",
        label: "Khyzy",
        center: { lat: 40.9109, lng: 49.0729 },
        zoom: "10",
      },
      {
        value: "32",
        label: "Khirdalan",
        center: { lat: 40.4529, lng: 49.7333 },
        zoom: "10",
      },
      {
        value: "33",
        label: "Khudat",
        center: { lat: 41.6253, lng: 48.6764 },
        zoom: "10",
      },
      {
        value: "34",
        label: "Imishli",
        center: { lat: 39.8695, lng: 48.0665 },
        zoom: "10",
      },
      {
        value: "35",
        label: "Ismayilli",
        center: { lat: 40.7871, lng: 48.1523 },
        zoom: "10",
      },
      {
        value: "36",
        label: "Kelbajar",
        center: { lat: 40.1024, lng: 46.0365 },
        zoom: "10",
      },
      {
        value: "37",
        label: "Kurdamir",
        center: { lat: 40.3699, lng: 48.1645 },
        zoom: "10",
      },
      {
        value: "38",
        label: "Kengerli",
        center: { lat: 39.3872, lng: 45.164 },
        zoom: "10",
      },
      {
        value: "39",
        label: "Qakh",
        center: { lat: 41.4207, lng: 46.932 },
        zoom: "10",
      },
      {
        value: "40",
        label: "Qazakh",
        center: { lat: 41.0971, lng: 45.3516 },
        zoom: "10",
      },
      {
        value: "41",
        label: "Gabala",
        center: { lat: 40.9982, lng: 47.87 },
        zoom: "10",
      },
      {
        value: "42",
        label: "Gobustan",
        center: { lat: 40.5326, lng: 48.9274 },
        zoom: "10",
      },
      {
        value: "43",
        label: "Goblar",
        center: { lat: 40.9364, lng: 45.7475 },
        zoom: "10",
      },
      {
        value: "44",
        label: "Quba",
        center: { lat: 41.3643, lng: 48.5261 },
        zoom: "10",
      },
      {
        value: "45",
        label: "Qubadli",
        center: { lat: 39.3453, lng: 46.5789 },
        zoom: "10",
      },
      {
        value: "46",
        label: "Qusar",
        center: { lat: 41.4275, lng: 48.4302 },
        zoom: "10",
      },
      {
        value: "47",
        label: "Lachin",
        center: { lat: 39.6383, lng: 46.5461 },
        zoom: "10",
      },
      {
        value: "48",
        label: "Lerik",
        center: { lat: 38.7736, lng: 48.4151 },
        zoom: "10",
      },
      {
        value: "49",
        label: "Lenkaran",
        center: { lat: 38.7529, lng: 48.8475 },
        zoom: "10",
      },
      {
        value: "50",
        label: "Liman",
        center: { lat: 41.312, lng: 72.9278 },
        zoom: "10",
      },
      {
        value: "51",
        label: "Masalli",
        center: { lat: 39.0341, lng: 48.6589 },
        zoom: "10",
      },
      {
        value: "52",
        label: "Mingachevir",
        center: { lat: 40.7703, lng: 47.0496 },
        zoom: "10",
      },
      {
        value: "53",
        label: "Naftalan",
        center: { lat: 40.5076, lng: 46.824 },
        zoom: "10",
      },
      {
        value: "54",
        label: "Nakhchivan",
        center: { lat: 39.2009, lng: 45.4082 },
        zoom: "10",
      },
      {
        value: "55",
        label: "Neftchala",
        center: { lat: 39.3881, lng: 49.2414 },
        zoom: "10",
      },
      {
        value: "56",
        label: "Oghuz",
        center: { lat: 41.0728, lng: 47.4651 },
        zoom: "10",
      },
      {
        value: "57",
        label: "Ordubad",
        center: { lat: 38.9022, lng: 46.0238 },
        zoom: "10",
      },
      {
        value: "58",
        label: "Saatli",
        center: { lat: 39.9096, lng: 48.3595 },
        zoom: "10",
      },
      {
        value: "59",
        label: "Sabirabad",
        center: { lat: 39.9871, lng: 48.4693 },
        zoom: "10",
      },
      {
        value: "60",
        label: "Salyan",
        center: { lat: 39.5779, lng: 48.9719 },
        zoom: "10",
      },
      {
        value: "61",
        label: "Samukh",
        center: { lat: 40.7605, lng: 46.4063 },
        zoom: "10",
      },
      {
        value: "62",
        label: "Sederek",
        center: { lat: 39.7105, lng: 44.8864 },
        zoom: "10",
      },
      {
        value: "63",
        label: "Siazan",
        center: { lat: 41.0784, lng: 49.1118 },
        zoom: "10",
      },
      {
        value: "64",
        label: "Sumqayit",
        center: { lat: 40.5855, lng: 49.6317 },
        zoom: "10",
      },
      {
        value: "65",
        label: "Shabran",
        center: { lat: 41.216, lng: 48.9946 },
        zoom: "10",
      },
      {
        value: "66",
        label: "Shahbuz",
        center: { lat: 39.4056, lng: 45.5691 },
        zoom: "10",
      },
      {
        value: "67",
        label: "Shamakhi",
        center: { lat: 40.6319, lng: 48.6364 },
        zoom: "10",
      },
      {
        value: "68",
        label: "Sheki",
        center: { lat: 41.1975, lng: 47.1571 },
        zoom: "10",
      },
      {
        value: "69",
        label: "Shamkir",
        center: { lat: 40.8288, lng: 46.0167 },
        zoom: "10",
      },
      {
        value: "70",
        label: "Shirur",
        center: { lat: 39.5536, lng: 44.9846 },
        zoom: "10",
      },
      {
        value: "71",
        label: "Shirvan",
        center: { lat: 39.9266, lng: 48.9206 },
        zoom: "10",
      },
      {
        value: "72",
        label: "Shusha",
        center: { lat: 39.7537, lng: 46.7465 },
        zoom: "10",
      },
      {
        value: "73",
        label: "Tartar",
        center: { lat: 40.3444, lng: 46.9377 },
        zoom: "10",
      },
      {
        value: "74",
        label: "Tovuz",
        center: { lat: 40.9955, lng: 45.6166 },
        zoom: "10",
      },
      {
        value: "75",
        label: "Ujar",
        center: { lat: 40.5068, lng: 47.649 },
        zoom: "10",
      },
      {
        value: "76",
        label: "Yardimli",
        center: { lat: 38.9059, lng: 48.2496 },
        zoom: "10",
      },
      {
        value: "77",
        label: "Yevlakh",
        center: { lat: 40.6197, lng: 47.15 },
        zoom: "10",
      },
      {
        value: "78",
        label: "Zaqatala",
        center: { lat: 41.6031, lng: 46.6383 },
        zoom: "10",
      },
      {
        value: "79",
        label: "Zangilan",
        center: { lat: 39.0857, lng: 46.6525 },
        zoom: "10",
      },
      {
        value: "80",
        label: "Zardab",
        center: { lat: 40.2148, lng: 47.7149 },
        zoom: "10",
      },
    ],
    region_options: [
      { value: "0", label: "Absheron" },
      { value: "1", label: "Binagadi" },
      { value: "2", label: "Narimanov" },
      { value: "3", label: "Nasimi" },
      { value: "4", label: "Nizami" },
      { value: "5", label: "Qaradag" },
      { value: "6", label: "Sabunchu" },
      { value: "7", label: "Sabail" },
      { value: "8", label: "Surakhani" },
      { value: "9", label: "Khatai" },
      { value: "10", label: "Khazar" },
      { value: "11", label: "Yasamal" },
      { value: "12", label: "Pirallahi" },
    ],
    site_options: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "tap.az", label: "tap.az" },
      { value: "bina.az", label: "bina.az" },
      { value: "lalafo.az", label: "lalafo.az" },
      { value: "emlak.az", label: "emlak.az" },
      { value: "arenda.az", label: "arenda.az" },
      { value: "yeniemlak.az", label: "yeniemlak.az" },
      { value: "rahatemlak.az", label: "rahatemlak.az" },
      { value: "ucuztap.az", label: "ucuztap.az" },
    ],
    site_options_cars: [
      { value: "ebaz.az", label: "ebaz.az" },
      { value: "turbo.az", label: "turbo.az" },
      { value: "masinlar.az", label: "masinlar.az" },
      { value: "auto.az", label: "auto.az" },
      { value: "mashin.al", label: "mashin.al" }
    ],
    room_options: [
      { value: "1", label: "1" },
      { value: "2", label: "2" },
      { value: "3", label: "3" },
      { value: "4", label: "4" },
      { value: "50", label: "5 +" },
    ],
    station_options: [
      { label: "Icherisheher metro station", value: "1" },
      { label: "Sahil metro station", value: "2" },
      { label: "28 May metro station", value: "3" },
      { label: "Ganjlik metro station", value: "4" },
      { label: "Nariman Narimanov metro station", value: "5" },
      { label: "Bakmil metro station", value: "6" },
      { label: "Ulduz metro station", value: "7" },
      { label: "Koroglu metro station", value: "8" },
      { label: "Qara Qarayev metro station", value: "9" },
      { label: "Neftchilar metro station", value: "10" },
      { label: "Xalqlar Dostluğu metro station", value: "11" },
      { label: "Ahmadli metro station", value: "12" },
      { label: "Hazi Aslanov metro station", value: "13" },
      { label: "Nizami Ganjavi metro station", value: "14" },
      { label: "Elmler Akademiyasi metro station", value: "15" },
      { label: "Inshaatchilar metro station", value: "16" },
      { label: "20 Yanvar metro station", value: "17" },
      { label: "Memar Ajami metro station", value: "18" },
      { label: "Nasimi metro station", value: "19" },
      { label: "Azadliq prospekti metro station", value: "20" },
      { label: "Dernegul metro station", value: "21" },
      { label: "Jafar Jabbarli metro station", value: "22" },
      { label: "Shah Ismayil Khatai metro station", value: "23" },
      { label: "Avtovagzal metro station", value: "24" },
      { label: "8 November metro station", value: "25" },
      { label: "Khatai metro station", value: "26" },
    ],
    resident_options: [
      { value: "1", label: "1st microdistrict", region: "Nasimi" },
      { value: "2", label: "2nd microdistrict", region: "Nasimi" },
      { value: "3", label: "20th sector", region: "Sahil" },
      { value: "4", label: "28 May", region: "Binagadi" },
      { value: "5", label: "3rd microdistrict", region: "Nasimi" },
      { value: "6", label: "4th microdistrict", region: "Nasimi" },
      { value: "7", label: "5th microdistrict", region: "Nasimi" },
      { value: "8", label: "6th microdistrict", region: "Binagadi" },
      { value: "9", label: "6th parallel", region: "Binagadi" },
      { value: "10", label: "7th microdistrict", region: "Binagadi" },
      { value: "11", label: "8 km", region: "Nizami" },
      { value: "12", label: "8th microdistrict", region: "Binagadi" },
      { value: "13", label: "9th microdistrict", region: "Binagadi" },
      { value: "14", label: "White City", region: "Khatai" },
      { value: "15", label: "Alatava 1", region: "Binagadi" },
      { value: "16", label: "Alatava 2", region: "Binagadi" },
      { value: "17", label: "Albaly", region: "Sabunchu" },
      { value: "18", label: "Atyali", region: "Absheron" },
      { value: "19", label: "Badamdar", region: "Sahil" },
      { value: "20", label: "Bahar", region: "Surakhani" },
      { value: "21", label: "Bakikhanov", region: "Sabunchu" },
      { value: "22", label: "Balakhany", region: "Sabunchu" },
      { value: "23", label: "Bayil", region: "Sahil" },
      { value: "24", label: "Bibiheybat", region: "Sahil" },
      { value: "25", label: "Bilgeh", region: "Sabunchu" },
      { value: "26", label: "Bilajari", region: "Binagadi" },
      { value: "27", label: "Bina", region: "Khazar" },
      { value: "28", label: "Binagadi", region: "Binagadi" },
      { value: "29", label: "Büyükshor", region: "Narimanov" },
      { value: "30", label: "Bulbul", region: "Surakhani" },
      { value: "31", label: "Buzovna", region: "Khazar" },
      { value: "32", label: "Chermet", region: "" },
      { value: "33", label: "Ceyranbatan", region: "Absheron" },
      { value: "34", label: "Chichek", region: "Absheron" },
      { value: "35", label: "Corat", region: "" },
      { value: "36", label: "Digah", region: "Absheron" },
      { value: "37", label: "Dubendi vineyards", region: "Khazar" },
      { value: "38", label: "Dede Gorgud", region: "Surakhani" },
      { value: "39", label: "Fatmai", region: "Absheron" },
      { value: "40", label: "Goradil", region: "Absheron" },
      { value: "41", label: "Gurgan", region: "Khazar" },
      { value: "42", label: "Guzdek", region: "Absheron" },
      { value: "43", label: "Hokmeli", region: "Absheron" },
      { value: "44", label: "Hovsan", region: "Surakhani" },
      { value: "45", label: "Heydar Aliyev", region: "Khatai" },
      { value: "46", label: "Keshla", region: "Nizami" },
      { value: "47", label: "Chemist town", region: "Nasimi" },
      { value: "48", label: "Old Gunesli", region: "Khatai" },
      { value: "49", label: "Kurdakhani", region: "Sabunchu" },
      { value: "50", label: "Lokbatan", region: "Garadagh" },
      { value: "51", label: "Lahic Gardens", region: "Sabunchu" },
      { value: "52", label: "Masazir", region: "Absheron" },
      { value: "53", label: "Mashtagha", region: "Sabunchu" },
      { value: "54", label: "Mehdiabad", region: "Absheron" },
      { value: "55", label: "Montin", region: "Narimanov" },
      { value: "56", label: "Mushfigabad", region: "Garadagh" },
      { value: "57", label: "Mekhamedli", region: "Absheron" },
      { value: "58", label: "Mardakan", region: "Khazar" },
      { value: "59", label: "Nardaran", region: "Sabunchu" },
      { value: "60", label: "Nasosnaya", region: "" },
      { value: "61", label: "Novkhani", region: "Absheron" },
      { value: "62", label: "Nubar", region: "" },
      { value: "63", label: "NZS", region: "Khatai" },
      { value: "64", label: "Papanin", region: "" },
      { value: "65", label: "Perekeskul", region: "Absheron" },
      { value: "66", label: "Pirshagi", region: "Sabunchu" },
      { value: "67", label: "Puta", region: "Garadagh" },
      { value: "68", label: "Fortress", region: "Khazar" },
      { value: "69", label: "Black City", region: "" },
      { value: "70", label: "Qarachukhur", region: "Surakhani" },
      { value: "71", label: "Qizildash", region: "Garadagh" },
      { value: "72", label: "Qobu", region: "Absheron" },
      { value: "73", label: "Gobustan", region: "Garadagh" },
      { value: "74", label: "Ramana", region: "Sabunchu" },
      { value: "75", label: "Rasulzade", region: "Binagadi" },
      { value: "76", label: "Sabunchu", region: "Sabunchu" },
      { value: "77", label: "Shagan", region: "Khazar" },
      { value: "78", label: "Coastal", region: "Garadagh" },
      { value: "79", label: "Palace", region: "Absheron" },
      { value: "80", label: "Savalan", region: "Sabunchu" },
      { value: "81", label: "Shikhov", region: "Sahil" },
      { value: "82", label: "Shubani", region: "Garadagh" },
      { value: "83", label: "Sulutepe", region: "Binagadi" },
      { value: "84", label: "Shusha", region: "Sabunchu" },
      { value: "85", label: "Shuvalan", region: "Khazar" },
      { value: "86", label: "Sengechal", region: "Garadagh" },
      { value: "87", label: "Turkan", region: "Khazar" },
      { value: "88", label: "Umid", region: "Garadagh" },
      { value: "89", label: "Khashakhuna", region: "Khazar" },
      { value: "90", label: "Khodjasan", region: "Binagadi" },
      { value: "91", label: "Khyutor", region: "Binagadi" },
      { value: "92", label: "New Gunesli", region: "Surakhani" },
      { value: "93", label: "New Ramana", region: "Sabunchu" },
      { value: "94", label: "New Surakhani", region: "Surakhani" },
      { value: "95", label: "New Yasamal", region: "Yasamal" },
      { value: "96", label: "Zabrat 1", region: "Sabunchu" },
      { value: "97", label: "Zabrat 2", region: "Sabunchu" },
      { value: "98", label: "Zagulba", region: "Absheron" },
      { value: "99", label: "Zig", region: "Surakhani" },
      { value: "100", label: "Zire", region: "Khazar" },
      { value: "101", label: "Ahmadli", region: "Khatai" },
      { value: "102", label: "Alet", region: "Garadagh" },
      { value: "103", label: "Amirjan", region: "Surakhani" },
    ],
    target_options: [
      { value: "1", label: "28 Mall", region: "Nasimi" },
      { value: "2", label: "A.S.Pushkin Park", region: "Nasimi" },
      { value: "3", label: "AAF PARK", region: "Absheron" },
      { value: "4", label: "US Embassy", region: "Nasimi" },
      { value: "5", label: "Absheron Youth City", region: "Absheron" },
      { value: "6", label: "Absheron Marriott Hotel", region: "Nasimi" },
      { value: "7", label: "Abu Arena", region: "Narimanov" },
      { value: "8", label: "ADA University", region: "Narimanov" },
      { value: "9", label: "AF Business House", region: "Nasimi" },
      { value: "10", label: "AGA Business Center", region: "Khatai" },
      { value: "11", label: "Altes Plaza", region: "Yasamal" },
      { value: "12", label: "AMAY", region: "Khatai" },
      { value: "13", label: "Ambassador Hotel", region: "Narimanov" },
      { value: "14", label: "ANS TV Channel", region: "Sabail" },
      { value: "15", label: "Aqua Park", region: "Nasimi" },
      { value: "16", label: "Araz Cinema", region: "Binegedi" },
      { value: "17", label: "ASAN Service-1", region: "Narimanov" },
      { value: "18", label: "ASAN Service-2", region: "Khatai" },
      { value: "19", label: "ASAN Service-3", region: "Yasamal" },
      { value: "20", label: "ASAN Service-4", region: "Sabunchu" },
      { value: "21", label: "ASAN Service-5", region: "Nizami" },
      { value: "22", label: "ASAN Service-6", region: "Khatai" },
      { value: "23", label: "ASK Plaza", region: "Nasimi" },
      { value: "24", label: "Ataturk Park", region: "Narimanov" },
      { value: "25", label: "ATV TV Channel", region: "Yasamal" },
      {
        value: "26",
        label: "National Aviation Academy University",
        region: "Khazar",
      },
      { value: "27", label: "Europe Hotel", region: "Nasimi" },
      { value: "28", label: "Axundov Garden", region: "Sabail" },
      { value: "29", label: "Aygun City", region: "Sabunchu" },
      { value: "30", label: "Ayna Sultanova Monument", region: "Narimanov" },
      { value: "31", label: "Azadlig Square", region: "Sabail" },
      { value: "32", label: "Azneft Square", region: "Sabail" },
      { value: "33", label: "AZTV TV Channel", region: "Sabail" },
      {
        value: "34",
        label: "Azerbaijan Languages University",
        region: "Nasimi",
      },
      { value: "35", label: "Azerbaijan Cinema", region: "Yasamal" },
      {
        value: "36",
        label: "Azerbaijan Tourism Institute",
        region: "Narimanov",
      },
      { value: "37", label: "Babek Plaza", region: "Khatai" },
      { value: "38", label: "Baku Asia University", region: "Nasimi" },
      { value: "39", label: "Baku State University", region: "Yasamal" },
      { value: "40", label: "Baku Music Academy", region: "Nasimi" },
      { value: "41", label: "Baku Slavic University", region: "Nasimi" },
      { value: "42", label: "Baku University", region: "Nizami" },
      { value: "43", label: "Baku Mall", region: "Yasamal" },
      { value: "44", label: "Bayil Park", region: "Sabail" },
      { value: "45", label: "Five-Story", region: "Sabunchu" },
      { value: "46", label: "Bina Trade Center", region: "Garadagh" },
      { value: "47", label: "Botanical Garden", region: "Sabail" },
      { value: "48", label: "Bridge Plaza", region: "Nasimi" },
      { value: "49", label: "J.Jabbarli Monument", region: "Nasimi" },
      {
        value: "50",
        label: "Military Academy named after Heydar Aliyev",
        region: "Khatai",
      },
      { value: "51", label: "Caspian Plaza", region: "Yasamal" },
      { value: "52", label: "Caspian Shopping Center", region: "Narimanov" },
      { value: "53", label: "Youth Bridge", region: "Nasimi" },
      { value: "54", label: "Chinese Embassy", region: "Garadagh" },
      { value: "55", label: "Chirag Plaza", region: "Nasimi" },
      { value: "56", label: "Crystal Plaza", region: "Khatai" },
      { value: "57", label: "Mountain Park", region: "Sabail" },
      { value: "58", label: "Wave Plaza", region: "Sabail" },
      { value: "59", label: "Ministry of Internal Affairs", region: "Sabail" },
      { value: "60", label: "Friendship Cinema", region: "Nasimi" },
      {
        value: "61",
        label: "Academy of Public Administration",
        region: "Sabail",
      },
      { value: "62", label: "State Statistics Committee", region: "Yasamal" },
      { value: "63", label: "Grandfather Koroglu Park", region: "Narimanov" },
      { value: "64", label: "Demirchi Plaza", region: "Khatai" },
      { value: "65", label: "Seaside National Park", region: "Sabail" },
      {
        value: "66",
        label: "Ministry of Ecology and Natural Resources",
        region: "Yasamal",
      },
      { value: "67", label: "Elite Shopping Center", region: "Binegedi" },
      { value: "68", label: "Ministry of Energy", region: "Nizami" },
      { value: "69", label: "Fairmont Hotel", region: "Sabail" },
      { value: "70", label: "Flame Towers", region: "Sabail" },
      { value: "71", label: "Fountain Garden", region: "Sabail" },
      { value: "72", label: "Four Seasons Hotel", region: "Sabail" },
      {
        value: "73",
        label: "Ministry of Emergency Situations",
        region: "Yasamal",
      },
      {
        value: "74",
        label: "Academy of the Ministry of Emergency Situations",
        region: "Surakhani",
      },
      { value: "75", label: "Heroes Square", region: "Sabail" },
      { value: "76", label: "Gurgen", region: "Pirallahi" },
      { value: "77", label: "Ganjlik Mall", region: "Narimanov" },
      {
        value: "78",
        label: "Ministry of Youth and Sports",
        region: "Narimanov",
      },
      { value: "79", label: "Heydar Mosque", region: "Binegedi" },
      { value: "80", label: "Heydar Aliyev Center", region: "Narimanov" },
      { value: "81", label: "Heydar Aliyev Palace", region: "Nasimi" },
      { value: "82", label: "Hilton Hotel", region: "Sabail" },
      { value: "83", label: "Huseyn Javid Park", region: "Yasamal" },
      { value: "84", label: "Hyatt Regency", region: "Yasamal" },
      { value: "85", label: "Military Hospital", region: "Nasimi" },
      { value: "86", label: "Public TV Channel", region: "Yasamal" },
      { value: "87", label: "Inner City", region: "Sabail" },
      {
        value: "88",
        label: "Fine Arts and Culture University",
        region: "Yasamal",
      },
      { value: "89", label: "Construction University", region: "Yasamal" },
      { value: "90", label: "Ministry of Economy", region: "Nizami" },
      { value: "91", label: "Economic University", region: "Nizami" },
      {
        value: "92",
        label: "Embassy of the Islamic Republic of Iran",
        region: "Sabail",
      },
      { value: "93", label: "ISR Plaza", region: "Nasimi" },
      { value: "94", label: "Izmir Park", region: "Yasamal" },
      { value: "95", label: "Keshla Market", region: "Narimanov" },
      { value: "96", label: "Koala Park", region: "Nasimi" },
      { value: "97", label: "Cooperation University", region: "Narimanov" },
      { value: "98", label: "Koroglu Park", region: "Nasimi" },
      { value: "99", label: "Landmark", region: "Sabail" },
      { value: "100", label: "Lider TV Channel", region: "Yasamal" },
      { value: "101", label: "M. Husseynzade Park", region: "Nasimi" },
      { value: "102", label: "M.A.Sabir Park", region: "Sabunchu" },
      { value: "103", label: "Ministry of Finance", region: "Nasimi" },
      { value: "104", label: "Megafun", region: "Khatai" },
      {
        value: "105",
        label: "Architecture and Construction University",
        region: "Yasamal",
      },
      { value: "106", label: "Metropark", region: "Narimanov" },
      { value: "107", label: "National Conservatory", region: "Yasamal" },
      { value: "108", label: "National Assembly", region: "Sabail" },
      {
        value: "109",
        label: "State Security Service",
        region: "Yasamal",
      },
      { value: "110", label: "Molokan Garden", region: "Sabail" },
      { value: "111", label: "Montin Park", region: "Narimanov" },
      { value: "112", label: "Moscow University", region: "Nasimi" },
      { value: "113", label: "Ministry of Defense Industry", region: "Khatai" },
      { value: "114", label: "MUM", region: "Sabail" },
      { value: "115", label: "Musabayov Park", region: "Yasamal" },
      {
        value: "116",
        label: "Central Oil Workers Hospital",
        region: "Khatai",
      },
      { value: "117", label: "Central Univermag", region: "Sabail" },
      { value: "118", label: "Nargiz Shopping Center", region: "Sabail" },
      { value: "119", label: "Neapol Circle", region: "Khatai" },
      {
        value: "120",
        label: "Azerbaijan State Oil and Industry University",
        region: "Nasimi",
      },
      { value: "121", label: "Neftchi Base", region: "Narimanov" },
      { value: "122", label: "Neftchilar Metro", region: "Nizami" },
      { value: "123", label: "Nizami Cinema", region: "Nasimi" },
      { value: "124", label: "Ministry of Transport", region: "Sabail" },
      { value: "125", label: "Nariman Narimanov Park", region: "Narimanov" },
      { value: "126", label: "Narimanov Statue", region: "Yasamal" },
      { value: "127", label: "Nasimi Bazaar", region: "Nasimi" },
      { value: "128", label: "Nasimi Statue", region: "Nasimi" },
      { value: "129", label: "Land of Fire University", region: "Narimanov" },
      { value: "130", label: "Olympic Stadium", region: "Sabunchu" },
      { value: "131", label: "Olimpik Star", region: "Narimanov" },
      { value: "132", label: "Oscar Wedding Palace", region: "Nizami" },
      { value: "133", label: "Embassy of Uzbekistan", region: "Sabail" },
      { value: "134", label: "Park Azure", region: "Khatai" },
      { value: "135", label: "Park Bulvar", region: "Sabail" },
      { value: "136", label: "Park Inn", region: "Sabail" },
      { value: "137", label: "Park Zorge", region: "Nasimi" },
      { value: "138", label: "Pedagogical University", region: "Sabail" },
      { value: "139", label: "Perekechku", region: "Absheron" },
      { value: "140", label: "Port Baku", region: "Nasimi" },
      { value: "141", label: "Presidential Park", region: "Khatai" },
      { value: "142", label: "Pullman Hotel", region: "Yasamal" },
      { value: "143", label: "Caucasus Resort Hotel", region: "Nasimi" },
      { value: "144", label: "Engineering University", region: "Absheron" },
      { value: "145", label: "Winter Park", region: "Yasamal" },
      { value: "146", label: "Maiden Tower", region: "Sabail" },
      { value: "147", label: "Governor Park", region: "Sabail" },
      {
        value: "148",
        label: "Liberation - 93 Residential Complex",
        region: "Absheron",
      },
      { value: "149", label: "Victory Circle", region: "Yasamal" },
      { value: "150", label: "West University", region: "Sabail" },
      {
        value: "151",
        label: "Ministry of Transport, Communications and High Technologies",
        region: "Nasimi",
      },
      { value: "152", label: "Republic Stadium", region: "Narimanov" },
      { value: "153", label: "Russian Embassy", region: "Nasimi" },
      { value: "154", label: "Academy of Painting", region: "Narimanov" },
      { value: "155", label: "Coastal Garden", region: "Narimanov" },
      { value: "156", label: "Sea Breeze Resort", region: "Sabunchu" },
      { value: "157", label: "Love Park", region: "Khatai" },
      { value: "158", label: "Sevil Gaziyeva Park", region: "Nasimi" },
      { value: "159", label: "Sevinc Shopping Center", region: "Nizami" },
      { value: "160", label: "Circus", region: "Nasimi" },
      { value: "161", label: "Sovetski", region: "Yasamal" },
      { value: "162", label: "Space TV", region: "Yasamal" },
      {
        value: "163",
        label: "Shuvalan Park Shopping Center",
        region: "Khatai",
      },
      { value: "164", label: "Saderat Shopping Center", region: "Qaradag" },
      { value: "165", label: "Shafa Stadium", region: "Nizami" },
      { value: "166", label: "Martyrs Alley", region: "Sabail" },
      { value: "167", label: "Ministry of Health", region: "Nasimi" },
      { value: "168", label: "Waterfall Park", region: "Sabail" },
      { value: "169", label: "Samed Vurgun Park", region: "Nasimi" },
      { value: "170", label: "Border Sports Complex", region: "Khatai" },
      { value: "171", label: "East Bazaar", region: "Narimanov" },
      { value: "172", label: "Tarkov", region: "Sabail" },
      { value: "173", label: "Technical University", region: "Yasamal" },
      { value: "174", label: "Medical University", region: "Nasimi" },
      { value: "175", label: "Tofik Bakhramov Stadium", region: "Narimanov" },
      { value: "176", label: "Embassy of Turkey", region: "Nasimi" },
      { value: "177", label: "Thinking University", region: "Narimanov" },
      { value: "178", label: "Ministry of Education", region: "Narimanov" },
      { value: "179", label: "New Market", region: "Nasimi" },
      { value: "180", label: "Ukraine Department", region: "Khatai" },
      { value: "181", label: "Ministry of Taxes", region: "Nizami" },
      { value: "182", label: "Homeland Cinema", region: "Nasimi" },
      { value: "183", label: "World Business Center", region: "Nasimi" },
      { value: "184", label: "Carpet Museum", region: "Sabail" },
      { value: "185", label: "Ministry of Foreign Affairs", region: "Yasamal" },
      { value: "186", label: "Hagani Trade Center", region: "Sabail" },
      { value: "187", label: "Caspian University", region: "Nizami" },
      { value: "188", label: "Yasamal Bazaar", region: "Yasamal" },
      { value: "189", label: "Yasamal Park", region: "Yasamal" },
      { value: "190", label: "Green Market", region: "Narimanov" },
      { value: "191", label: "Soldiers Park", region: "Nasimi" },
      { value: "192", label: "Zoo", region: "Narimanov" },
      { value: "193", label: "Zarifa Aliyeva Park", region: "Binagadi" },
      { value: "194", label: "Ministry of Justice", region: "Yasamal" },
      {
        value: "195",
        label: "Ministry of Labor and Social Protection of the Population",
        region: "Yasamal",
      },
    ],
    flat_type_options: [
      { value: "1", label: "Apartment" },
      { value: "2", label: "House/Cottage/Villa", label2: "House" },
      { value: "3", label: "Office" },
      { value: "4", label: "Garage" },
      { value: "5", label: "Land" },
      { value: "6", label: "Property" },
    ],
    offer_type_options: [
      { value: "true", label: "Sale" },
      { value: "false", label: "Rent" },
    ],
    mortgage_options: [
      { value: "true", label: "Yes" },
      { value: "false", label: "No" },
    ],
    rent_type_options: [
      { value: "1", label: "Monthly" },
      { value: "2", label: "Daily" },
    ],
    filter_options: [
      { value: "dn", label: "Date (New-Old)" },
      { value: "do", label: "Date (Old-New)" },
      { value: "pl", label: "Price (Low to High)" },
      { value: "ph", label: "Price (High to Low)" },
    ],
    filter_options_blog: [
      { value: "dn", label: "Date (New - Old)" },
      { value: "do", label: "Date (Old - New)" },
      { value: "pl", label: "Views (Few - Many)" },
      { value: "ph", label: "Views (Many - Few)" },
    ],
    type_of_flat: [
      { value: "1", label: "Business center" },
      { value: "2", label: "House / Apartment" },
    ],auto_body_type_options: [
      { value: "1", label: "Phaeton" },
      { value: "2", label: "Caravan" },
      { value: "3", label: "Microvan" },
      { value: "4", label: "Pickup, single cabin" },
      { value: "5", label: "Offroader / SUV, open" },
      { value: "6", label: "Roadster" },
      { value: "7", label: "Hatchback, 4 doors" },
      { value: "8", label: "Bus" },
      { value: "9", label: "Convertible" },
      { value: "10, 33", label: "Hatchback, 3 doors" },
      { value: "11", label: "Minibus" },
      { value: "12", label: "Compact-van" },
      { value: "13", label: "Tractor unit" },
      { value: "14", label: "Coupe" },
      { value: "15", label: "Pickup, double cabin" },
      { value: "16", label: "SUV Coupe" },
      { value: "17", label: "Offroader / SUV, 3 doors" },
      { value: "18", label: "Minivan" },
      { value: "19", label: "Truck" },
      { value: "20", label: "Van" },
      { value: "21", label: "Station wagon, 5 doors" },
      { value: "22", label: "Liftback" },
      { value: "23, 30", label: "Hatchback, 5 doors" },
      { value: "24", label: "Offroader / SUV, 5 doors" },
      { value: "25", label: "Sedan" },
      { value: "26", label: "Motorcycle" },
      { value: "27", label: "Moped" },
      { value: "28", label: "Quad bike" },
      { value: "29", label: "Station wagon" },
      { value: "31", label: "Offroader, SUV" },
      { value: "32", label: "Hatchback" },
      { value: "34", label: "Minivan" },
      { value: "35", label: "Van" },
      { value: "36", label: "Limousine" },
    ],
    
    auto_fuel_type_options: [
      { value: "1", label: "Petrol" },
      { value: "2", label: "Diesel" },
      { value: "3", label: "Gas" },
      { value: "4", label: "Electric" },
      { value: "5", label: "Hybrid" },
      { value: "6", label: "Plug-in Hybrid" },
    ],
    
    auto_drive_type_options: [
      { value: "1", label: "Rear" },
      { value: "2", label: "All-wheel" },
      { value: "3", label: "Front" },
    ],
    
    auto_transmission_type_options: [
      { value: "1", label: "Automatic" },
      { value: "2", label: "CVT" },
      { value: "3", label: "Manual" },
      { value: "4", label: "Robot" },
      { value: "5", label: "Reducer" },
      { value: "6", label: "Automated" },
    ],
    auto_color_type_options: [
      { value: "1", label: "Pink" },
      { value: "2", label: "Purple" },
      { value: "3", label: "Yellow" },
      { value: "4", label: "Orange" },
      { value: "5", label: "Light Green" },
      { value: "6", label: "Beige" },
      { value: "7", label: "Brown" },
      { value: "8", label: "Golden" },
      { value: "9", label: "Blue" },
      { value: "10", label: "Green" },
      { value: "11", label: "Dark Red" },
      { value: "12", label: "Red" },
      { value: "13", label: "Gray" },
      { value: "14", label: "Navy Blue" },
      { value: "15", label: "Wet Asphalt" },
      { value: "16", label: "Silver" },
      { value: "17", label: "Black" },
      { value: "18", label: "White" },
      { value: "19", label: "Wet Asphalt" },
    ],
    packets: {
      boost: [
        { dayOrTime: "1", price: "0.49" },
        { dayOrTime: "5", price: "1.99" },
        { dayOrTime: "10", price: "3.99" },
      ],
      frame: [
        { dayOrTime: "1", price: "0.99" },
        { dayOrTime: "5", price: "2.49" },
        { dayOrTime: "10", price: "4.49" },
      ],
      urgent: [
        { dayOrTime: "1", price: "1.99" },
        { dayOrTime: "5", price: "5.99" },
        { dayOrTime: "10", price: "9.99" },
      ],
      premium: [
        { dayOrTime: "1", price: "2.49" },
        { dayOrTime: "5", price: "10.99" },
        { dayOrTime: "10", price: "19.99" },
        { dayOrTime: "30", price: "39.99" },
      ],
    },
    blog: "Blog",
    blogAll: "All",
    blogMore: "Read More...",
    blogNotFound: "Blog not found",

    saleForTitle: " sale",
    dailyForTitle: " daily",
    rentForTitle: " rent",
    roomsForTitle: " rooms",
    minAreaForTitle: "minimum area - ",
    maxAreaForTitle: "maximum area - ",
    minFloorForTitle: "minimum floors - ",
    maxFloorForTitle: "maximum floors - ",
    minPriceForTitle: "minimum price - ",
    maxPriceForTitle: "maximum price - ",
    withGoodsForTitle: ", With things",
    withoutGoodsForTitle: ", Without things",
    withRepairForTitle: ", Repair",
    withoutRepairForTitle: ", Without repair",
    withDocumentForTitle: ", With documents",
    withoutDocumentForTitle: ", Without documents",
    withCreditForTitle: ", Mortgage",
    cars: "Cars",
    homes: "Homes",
  },
};
