import axios from "axios";
import Swal from "sweetalert2";
import { options } from "../constants";

export function addToken(value) {
  return {
    type: "ADD_TOKEN",
    payload: {
      value: value,
    },
  };
}
export function changeFilter(value) {
  return {
    type: "CHANGE_FILTER",
    payload: {
      value: value,
    },
  };
}
export function deleteToken() {
  return {
    type: "DELETE_TOKEN",
    payload: {
      value: "",
    },
  };
}
export function loadingBoostButtonAction(value) {
  return {
    type: "SET_LOADING_BOOST_BUTTON",
    payload: {
      value: value,
    },
  };
}
export function loadingPremiumButtonAction(value) {
  return {
    type: "SET_LOADING_PREMIUM_BUTTON",
    payload: {
      value: value,
    },
  };
}
export function getDefaultOffers(defaultoffers) {
  return {
    type: "GET_DEFAULT_OFFERS",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getInitialOffersFlat(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_FLAT",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getInitialOffersCar(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_CAR",
    payload: {
      value: defaultoffers,
    },
  };
}
export function openSearchByUrlPopUp(value) {
  if (value) {
    document.body.classList.add("noscroll");
  } else {
    document.body.classList.remove("noscroll");
  }
  return {
    type: "SET_OPEN_SEARCH_BY_URL_POPUP",
    payload: {
      value: value,
    },
  };
}
export function getInitialOffersCountry(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_COUNTRY",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getInitialOffersObyekt(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_OBYEKT",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getInitialOffersGarage(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_GARAGE",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getInitialOffersOffice(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_OFFICE",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getInitialOffersLand(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_LAND",
    payload: {
      value: defaultoffers,
    },
  };
}
export function getDefaultSimilarOffers(defaultoffers) {
  return {
    type: "GET_DEFAULT_SIMILAR_OFFERS",
    payload: {
      value: defaultoffers,
    },
  };
}
export function offersFoundOrNot(trueorfalse) {
  return {
    type: "OFFERS_FOUND_OR_NOT",
    payload: {
      value: trueorfalse,
    },
  };
}
export function setLatLng(value) {
  return {
    type: "SET_LAT_LNG",
    payload: {
      value: value,
    },
  };
}
export function getOffersWithLocations(defaultoffersloc) {
  return {
    type: "GET_DEFAULT_OFFERS_WITH_LOCATIONS",
    payload: {
      value: defaultoffersloc,
    },
  };
}
export function showOrCloseMapF(trueOrFalse) {
  return {
    type: "SHOW_OR_CLOSE_MAP",
    payload: {
      value: trueOrFalse,
    },
  };
}
export function setIsMobile(value) {
  return {
    type: "SET_IS_MOBILE",
    payload: {
      value: value,
    },
  };
}
export function getDefaultOffersCount(defaultoffersCount) {
  return {
    type: "GET_DEFAULT_OFFERS_COUNT",
    payload: {
      value: defaultoffersCount,
    },
  };
}
export function setCenter(center) {
  return {
    type: "SET_CENTER",
    payload: {
      value: center,
    },
  };
}
export function setZoom(zoom) {
  return {
    type: "SET_ZOOM",
    payload: {
      value: zoom,
    },
  };
}
export function loginBid(loginData) {
  return {
    type: "LOGIN",
    payload: {
      value: loginData,
    },
  };
}
export function loadingUploadOfferAction(value) {
  return {
    type: "LOADING_UPLOAD_OFFER",
    payload: {
      value: value,
    },
  };
}
export function setShowMobileFilter(value) {
  if (value) {
    document.body.classList.add("noscroll");
  } else {
    document.body.classList.remove("noscroll");
  }
  return {
    type: "SET_SHOW_MOBILE_FILTER",
    payload: {
      value: value,
    },
  };
}
export function setShowMorebar(value) {
  if (value) {
    document.body.classList.add("noscroll");
  } else {
    document.body.classList.remove("noscroll");
  }
  return {
    type: "SET_SHOW_MOREBAR",
    payload: {
      value: value,
    },
  };
}
export function getInitialOffersVips(defaultoffers) {
  return {
    type: "GET_INITIAL_OFFERS_VIPS",
    payload: {
      value: defaultoffers,
    },
  };
}
export function changePageTitle(
  flatType,
  offerType,
  page,
  filter,
  city,
  rooms,
  minPrice,
  maxPrice,
  areaMin,
  areaMax,
  province,
  residents,
  stations,
  targets,
  floorMin,
  floorMax,
  sites,
  withGoodsTrue,
  withGoodsFalse,
  withCredit,
  repairTrue,
  repairFalse,
  documentTrue,
  documentFalse,
  minPricePerM2,
  maxPricePerM2
) {
  const language = localStorage.getItem("language") ?? "az";
  let str = "";
  flatType && flatType?.label ? (str += flatType.label) : (str += "");
  offerType === "s"
    ? (str += options[language].saleForTitle)
    : offerType === "g"
    ? (str += options[language].dailyForTitle)
    : (str += options[language].rentForTitle);
  city?.length
    ? city.map((c) => {
        return (str += ", " + c.label);
      })
    : (str += "");
  province?.length !== 0 && city.some((c) => c.value.includes("8"))
    ? province?.map((r) => {
        return (str += ", " + r.label);
      })
    : (str += "");
  residents?.length && city.some((c) => c.value.includes("8"))
    ? residents.map((r) => {
        return (str += ", " + r.label);
      })
    : (str += "");
  targets?.length && city.some((c) => c.value.includes("8"))
    ? targets.map((r) => {
        return (str += ", " + r.label);
      })
    : (str += "");
  stations?.length && city.some((c) => c.value.includes("8"))
    ? stations.map((r) => {
        return (str += ", " + r.label);
      })
    : (str += "");
  rooms?.length
    ? rooms.map((r) => {
        return (str += ", " + r.label + options[language].roomsForTitle);
      })
    : (str += "");
  sites?.length
    ? sites.map((r) => {
        return (str += ", " + r.label);
      })
    : (str += "");
  areaMin !== ""
    ? (str += ", " + options[language].minAreaForTitle + areaMin)
    : (str += "");
  areaMax !== ""
    ? (str += ", " + options[language].maxAreaForTitle + areaMax)
    : (str += "");
  floorMin !== ""
    ? (str += ", " + options[language].minFloorForTitle + floorMin)
    : (str += "");
  floorMax !== ""
    ? (str += ", " + options[language].maxFloorForTitle + floorMax)
    : (str += "");
  minPrice !== 0 && minPrice !== ""
    ? (str += ", " + options[language].minPriceForTitle + minPrice)
    : (str += "");
  maxPrice !== 99999999999 && maxPrice !== ""
    ? (str += ", " + options[language].maxPriceForTitle + maxPrice)
    : (str += "");
  withGoodsTrue === true
    ? (str += options[language].withGoodsForTitle)
    : (str += "");
  withGoodsFalse === true
    ? (str += options[language].withoutGoodsForTitle)
    : (str += "");
  repairTrue === true
    ? (str += options[language].withRepairForTitle)
    : (str += "");
  repairFalse === true
    ? (str += options[language].withoutRepairForTitle)
    : (str += "");
  documentTrue === true
    ? (str += options[language].withDocumentForTitle)
    : (str += "");
  documentFalse === true
    ? (str += options[language].withoutDocumentForTitle)
    : (str += "");
  withCredit === true
    ? (str += options[language].withCreditForTitle)
    : (str += "");
  str = "ebaz.az - " + str;
  window.document.title = filterAzerbaijanLetters(str);
}
export function changePageTitleAuto(
  flatType,
  offerType,
  page,
  filter,
  city,
  minPrice,
  maxPrice,
  sites,
  autoBrand,
  autoModel,
  bodyType,
  fuelType,
  driveType,
  transmissionType,
  minMileage,
  maxMileage,
  minYear,
  maxYear,
  minVolume,
  maxVolume,
  minHorse,
  maxHorse,
  colors
) {
  const language = localStorage.getItem("language") ?? "az";
  let str = "";
  str += options[language].cars;
  offerType === "s"
    ? (str += options[language].saleForTitle)
    : offerType === "g"
    ? (str += options[language].dailyForTitle)
    : (str += options[language].rentForTitle);
  city?.length
    ? city.map((c) => {
        return (str += ", " + c.label);
      })
    : (str += "");
  sites?.length
    ? sites.map((r) => {
        return (str += ", " + r.label);
      })
    : (str += "");
  minPrice !== 0 && minPrice !== ""
    ? (str += ", " + options[language].minPriceForTitle + minPrice)
    : (str += "");
  maxPrice !== 99999999999 && maxPrice !== ""
    ? (str += ", " + options[language].maxPriceForTitle + maxPrice)
    : (str += "");
  str = "ebaz.az - " + str;
  window.document.title = filterAzerbaijanLetters(str);
}

export function filterAzerbaijanLetters(str) {
  const replacements = {
    Ç: "C",
    ç: "c",
    Ə: "E",
    ə: "e",
    Ğ: "g",
    ğ: "g",
    I: "i",
    ı: "i",
    Ö: "o",
    ö: "o",
    Ş: "s",
    ş: "s",
    Ü: "u",
    ü: "u",
  };

  return str.replace(/[ÇçƏəĞğIıÖöŞşÜü]/g, (char) => replacements[char]);
}

export function searchOfferAuto(
  flatType,
  offerType,
  page,
  filter,
  city,
  minPrice,
  maxPrice,
  sites,
  autoBrand,
  autoModel,
  bodyType,
  fuelType,
  driveType,
  transmissionType,
  minMileage,
  maxMileage,
  minYear,
  maxYear,
  minVolume,
  maxVolume,
  minHorse,
  maxHorse,
  colors
) {
  changePageTitleAuto(
    flatType,
    offerType,
    page,
    filter,
    city,
    minPrice,
    maxPrice,
    sites,
    autoBrand,
    autoModel,
    bodyType,
    fuelType,
    driveType,
    transmissionType,
    minMileage,
    maxMileage,
    minYear,
    maxYear,
    minVolume,
    maxVolume,
    minHorse,
    maxHorse,
    colors
  );
  let fType = "auto";
  let oType =
    offerType === "s" ? "sales" : offerType === "g" ? "rents" : "rents";
  const siteValues = sites?.map((site) => site?.value).join(",");
  const cityValues = city?.map((city) => Number(city.value) + 1).join(",");
  if (!window.location.href.includes("/elan/")) {
    const url = new URL(window.location.href);
    offerType
      ? url.searchParams.set(
          offerType === "s" ? "satis" : offerType === "g" ? "gunluk" : "kiraye",
          ""
        ) ||
        url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
        url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
        url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
        url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
        url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
        url.searchParams.delete(offerType === "g" ? "satis" : "gunluk")
      : url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
        url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
        url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
        url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
        url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
        url.searchParams.delete(offerType === "g" ? "satis" : "gunluk");
    flatType
      ? url.searchParams.set("elan", "masin")
      : url.searchParams.delete("elan");
    cityValues.length !== 0
      ? url.searchParams.set(
          "seher",
          city.length > 3
            ? city?.map((city) => city.value).join("-")
            : city?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("seher");
    minPrice !== ""
      ? url.searchParams.set("min-qiymet", minPrice)
      : url.searchParams.delete("min-qiymet");
    maxPrice !== "" && maxPrice !== 99999999999
      ? url.searchParams.set("max-qiymet", maxPrice)
      : url.searchParams.delete("max-qiymet");
    siteValues?.length !== 0
      ? url.searchParams.set(
          "sayt",
          sites.length > 1
            ? sites?.map((city) => city.value).join("-")
            : sites?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("sayt");
    filter && url.searchParams.set("filter", filter.value);
    autoBrand
      ? url.searchParams.set("marka", autoBrand?.label)
      : url.searchParams.delete("marka");
    autoModel
      ? url.searchParams.set("model", autoModel?.label)
      : url.searchParams.delete("model");
    bodyType
      ? url.searchParams.set("ban-novu", bodyType?.label)
      : url.searchParams.delete("ban-novu");
    fuelType
      ? url.searchParams.set("yanacaq", fuelType?.label)
      : url.searchParams.delete("yanacaq");
    driveType
      ? url.searchParams.set("oturucu", driveType?.label)
      : url.searchParams.delete("oturucu");
    transmissionType
      ? url.searchParams.set("suretler-qutusu", transmissionType?.label)
      : url.searchParams.delete("suretler-qutusu");
    minMileage
      ? url.searchParams.set("min-yurus", minMileage)
      : url.searchParams.delete("min-yurus");
    maxMileage
      ? url.searchParams.set("max-yurus", maxMileage)
      : url.searchParams.delete("max-yurus");
    minYear
      ? url.searchParams.set("min-il", minYear)
      : url.searchParams.delete("min-il");
    maxYear
      ? url.searchParams.set("max-il", maxYear)
      : url.searchParams.delete("max-il");
    minVolume
      ? url.searchParams.set("min-hecm", minVolume)
      : url.searchParams.delete("min-hecm");
    maxVolume
      ? url.searchParams.set("max-hecm", maxVolume)
      : url.searchParams.delete("max-hecm");
    minHorse
      ? url.searchParams.set("min-atgucu", minHorse)
      : url.searchParams.delete("min-atgucu");
    maxHorse
      ? url.searchParams.set("max-atgucu", maxHorse)
      : url.searchParams.delete("max-atgucu");
    colors
      ? url.searchParams.set("reng", colors?.label)
      : url.searchParams.delete("reng");
    page !== 0
      ? url.searchParams.set("page", page)
      : url.searchParams.delete("page");
    window.history.pushState(
      {},
      "",
      url.toString().replace("butun-premium-elanlar", "")
    );
    // window.location.href = url.toString().replace("butun-premium-elanlar", "");
  }
  return function (dispatch) {
    axios
      .get(`https://api.ebaz.az/getvip?type=${fType}${oType}`, {})
      .then((response) => {
        dispatch(getInitialOffersVips(response.data.vips));
        dispatch(setSearchTrueOrFalse(false));
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(getDefaultOffers([]));
    dispatch(getDefaultOffersCount(""));
    dispatch(setSearchTrueOrFalse(true));
    dispatch(setCountOfOffers(""));
    dispatch(setLoadingOfCount(true));
    axios
      .post(`https://api.ebaz.az/${fType}${oType}`, {
        city: cityValues,
        minPrice: minPrice !== "" ? parseFloat(minPrice) : 0,
        maxPrice: maxPrice !== "" ? parseFloat(maxPrice) : 9999999999999,
        sites: siteValues,
        perDay: offerType === "g" ? true : false,
        orderBy: filter.value,
        page: page,
        autoBrand: autoBrand?.value ?? null,
        autoModel: autoModel?.value ?? null,
        bodyType: bodyType?.value ?? null,
        fuelType: fuelType?.value ?? null,
        driveType: driveType?.value ?? null,
        transmissionType: transmissionType?.value ?? null,
        minMileage: minMileage ?? null,
        maxMileage: maxMileage ?? null,
        minYear: minYear ?? null,
        maxYear: maxYear ?? null,
        minVolume: minVolume ?? null,
        maxVolume: maxVolume ?? null,
        minHorse: minHorse ?? null,
        maxHorse: maxHorse ?? null,
        color: colors?.value ?? null,
      })
      .then((response) => {
        dispatch(changeOfferType(`${fType}${oType}`));
        dispatch(getDefaultOffers(response.data.autoDataWithImages));
        dispatch(getDefaultOffersCount(response.data.count));
        dispatch(
          getCountOfPages(parseInt(Math.ceil(response.data.count / 20)))
        );
        dispatch(setSearchTrueOrFalse(false));
        dispatch(setCountOfOffers(response.data.count));
        dispatch(setLoadingOfCount(false));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
        if (
          response.data.count !== 0 &&
          response.data.autoDataWithImages.length === 0
        ) {
          dispatch(getPage(1));
          dispatch(
            searchOfferAuto(
              flatType,
              offerType,
              1,
              filter,
              city,
              minPrice,
              maxPrice,
              sites,
              autoBrand,
              autoModel,
              bodyType,
              fuelType,
              driveType,
              transmissionType,
              minMileage,
              maxMileage,
              minYear,
              maxYear,
              minVolume,
              maxVolume,
              minHorse,
              maxHorse,
              colors
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCountOfOffersAuto(
  flatType,
  offerType,
  city,
  minPrice,
  maxPrice,
  sites,
  autoBrand,
  autoModel,
  bodyType,
  fuelType,
  driveType,
  transmissionType,
  minMileage,
  maxMileage,
  minYear,
  maxYear,
  minVolume,
  maxVolume,
  minHorse,
  maxHorse,
  colors
) {
  let fType = "auto";
  let oType =
    offerType === "s" ? "sales" : offerType === "g" ? "rents" : "rents";
  const siteValues = sites?.map((site) => site?.value).join(",");
  const cityValues = city?.map((city) => Number(city.value) + 1).join(",");
  return function (dispatch) {
    dispatch(setCountOfOffers(""));
    dispatch(setLoadingOfCount(true));
    axios
      .post(`https://api.ebaz.az/getCount/${fType}${oType}`, {
        city: cityValues,
        minPrice: minPrice !== "" ? parseFloat(minPrice) : 0,
        maxPrice: maxPrice !== "" ? parseFloat(maxPrice) : 9999999999999,
        sites: siteValues,
        perDay: offerType === "g" ? true : false,
        autoBrand: autoBrand?.value ?? null,
        autoModel: autoModel?.value ?? null,
        bodyType: bodyType?.value ?? null,
        fuelType: fuelType?.value ?? null,
        driveType: driveType?.value ?? null,
        transmissionType: transmissionType?.value ?? null,
        minMileage: minMileage ?? null,
        maxMileage: maxMileage ?? null,
        minYear: minYear ?? null,
        maxYear: maxYear ?? null,
        minVolume: minVolume ?? null,
        maxVolume: maxVolume ?? null,
        minHorse: minHorse ?? null,
        maxHorse: maxHorse ?? null,
        color: colors?.value ?? null,
      })
      .then((response) => {
        dispatch(setCountOfOffers(response.data.count));
        dispatch(setLoadingOfCount(false));
      })
      .catch((error) => {
        dispatch(setLoadingOfCount(false));
        console.log(error);
      });
  };
}
export function searchOffer(
  flatType,
  offerType,
  page,
  filter,
  city,
  rooms,
  minPrice,
  maxPrice,
  areaMin,
  areaMax,
  province,
  residents,
  stations,
  targets,
  floorMin,
  floorMax,
  sites,
  withGoodsTrue,
  withGoodsFalse,
  withCredit,
  repairTrue,
  repairFalse,
  documentTrue,
  documentFalse,
  minPricePerM2,
  maxPricePerM2
) {
  changePageTitle(
    flatType,
    offerType,
    page,
    filter,
    city,
    rooms,
    minPrice,
    maxPrice,
    areaMin,
    areaMax,
    province,
    residents,
    stations,
    targets,
    floorMin,
    floorMax,
    sites,
    withGoodsTrue,
    withGoodsFalse,
    withCredit,
    repairTrue,
    repairFalse,
    documentTrue,
    documentFalse,
    minPricePerM2,
    maxPricePerM2
  );
  let fType =
    flatType === 1 || flatType?.value === "1"
      ? "flat"
      : flatType?.value === "2"
      ? "country"
      : flatType?.value === "3"
      ? "office"
      : flatType?.value === "4"
      ? "garage"
      : flatType?.value === "5"
      ? "land"
      : flatType?.value === "6"
      ? "commercial"
      : "flat";
  let oType =
    offerType === "s" ? "sales" : offerType === "g" ? "rents" : "rents";
  const stationValues = stations?.map((station) => station.value - 1).join(",");
  const roomValues = rooms?.map((room) => room.value).join(",");
  const siteValues = sites?.map((site) => site?.value).join(",");
  const residentValues = residents
    ?.map((resident) => resident.value - 1)
    .join(",");
  const targetValues = targets?.map((target) => target.value - 1).join(",");
  const regionValues = province
    ?.filter((value, index, array) => array.indexOf(value) === index)
    ?.map((region) => region.value)
    .join(",");
  const cityValues = city?.map((city) => city.value).join(",");

  if (!window.location.href.includes("/elan/")) {
    const url = new URL(window.location.href);
    // window.history.pushState({}, "");
    offerType
      ? url.searchParams.set(
          offerType === "s" ? "satis" : offerType === "g" ? "gunluk" : "kiraye",
          ""
        ) ||
        url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
        url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
        url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
        url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
        url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
        url.searchParams.delete(offerType === "g" ? "satis" : "gunluk")
      : url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
        url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
        url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
        url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
        url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
        url.searchParams.delete(offerType === "g" ? "satis" : "gunluk");
    flatType
      ? url.searchParams.set(
          "elan",
          flatType === 1 || flatType?.value === "1"
            ? "menzil"
            : flatType?.value === "2"
            ? "heyet-evi-bag-villa"
            : flatType?.value === "3"
            ? "ofis"
            : flatType?.value === "4"
            ? "qaraj"
            : flatType?.value === "5"
            ? "torpaq"
            : flatType?.value === "6"
            ? "obyekt"
            : "menzil"
        )
      : url.searchParams.delete("elan");
    cityValues.length !== 0
      ? url.searchParams.set(
          "seher",
          city.length > 3
            ? city?.map((city) => city.value).join("-")
            : city?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("seher");
    cityValues.includes("8") && regionValues.length !== 0
      ? url.searchParams.set(
          "rayon",
          province.length > 1
            ? province?.map((city) => city.value).join("-")
            : province?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("rayon");
    cityValues.includes("8") && residentValues.length !== 0
      ? url.searchParams.set(
          "qesebe",
          residents.length > 1
            ? residents?.map((city) => city.value).join("-")
            : residents?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("qesebe");
    roomValues.length !== 0
      ? url.searchParams.set("otaqlar", roomValues.replaceAll(",", "-"))
      : url.searchParams.delete("otaqlar");
    minPrice !== ""
      ? url.searchParams.set("min-qiymet", minPrice)
      : url.searchParams.delete("min-qiymet");
    maxPrice !== "" && maxPrice !== 99999999999
      ? url.searchParams.set("max-qiymet", maxPrice)
      : url.searchParams.delete("max-qiymet");
    minPricePerM2 !== ""
      ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
      : url.searchParams.delete("min-qiymet-m2");
    maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
      ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
      : url.searchParams.delete("max-qiymet-m2");
    areaMin !== ""
      ? url.searchParams.set("min-sahe", areaMin)
      : url.searchParams.delete("min-sahe");
    areaMax !== "" && areaMax !== 99999999999
      ? url.searchParams.set("max-sahe", areaMax)
      : url.searchParams.delete("max-sahe");
    floorMin !== ""
      ? url.searchParams.set("min-mertebe", floorMin)
      : url.searchParams.delete("min-mertebe");
    floorMax !== "" && floorMax !== 99999999999
      ? url.searchParams.set("max-mertebe", floorMax)
      : url.searchParams.delete("max-mertebe");
    cityValues.includes("8") && stationValues.length !== 0
      ? url.searchParams.set(
          "metro",
          stations.length > 1
            ? stations?.map((city) => city.value).join("-")
            : stations?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("metro");
    cityValues.includes("8") && targetValues.length !== 0
      ? url.searchParams.set(
          "nisangah",
          targets.length > 1
            ? targets?.map((city) => city.value).join("-")
            : targets?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("nisangah");
    withGoodsTrue
      ? url.searchParams.set("esyali", "")
      : url.searchParams.delete("esyali");
    withGoodsFalse
      ? url.searchParams.set("esyasiz", "")
      : url.searchParams.delete("esyasiz");
    repairTrue
      ? url.searchParams.set("temirli", "")
      : url.searchParams.delete("temirli");
    repairFalse
      ? url.searchParams.set("temirsiz", "")
      : url.searchParams.delete("temirsiz");
    documentTrue
      ? url.searchParams.set("senedli", "")
      : url.searchParams.delete("senedli");
    documentFalse
      ? url.searchParams.set("senedsiz", "")
      : url.searchParams.delete("senedsiz");
    siteValues.length !== 0
      ? url.searchParams.set(
          "sayt",
          sites.length > 1
            ? sites?.map((city) => city.value).join("-")
            : sites?.map((city) => city.label).join("-")
        )
      : url.searchParams.delete("sayt");
    withCredit
      ? url.searchParams.set("ipoteka", "")
      : url.searchParams.delete("ipoteka");
    filter && url.searchParams.set("filter", filter.value);
    page !== 0
      ? url.searchParams.set("page", page)
      : url.searchParams.delete("page");
    window.history.pushState(
      {},
      "",
      url.toString().replace("butun-premium-elanlar", "")
    );
    // window.location.href = url.toString().replace("butun-premium-elanlar", "");
  }
  return function (dispatch) {
    axios
      .get(`https://api.ebaz.az/getvip?type=${fType}${oType}`, {})
      .then((response) => {
        dispatch(getInitialOffersVips(response.data.vips));
        dispatch(setSearchTrueOrFalse(false));
      })
      .catch((error) => {
        console.log(error);
      });
    dispatch(getDefaultOffers([]));
    dispatch(getDefaultOffersCount(""));
    dispatch(setSearchTrueOrFalse(true));
    dispatch(setCountOfOffers(""));
    dispatch(setLoadingOfCount(true));
    axios
      .post(`https://api.ebaz.az/${fType}${oType}`, {
        city: cityValues,
        province: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? regionValues
          : null,
        residents: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? residentValues
          : null,
        targets: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? targetValues
          : null,
        metro: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? stationValues
          : null,
        minPrice: minPrice !== "" ? parseFloat(minPrice) : 0,
        maxPrice: maxPrice !== "" ? parseFloat(maxPrice) : 9999999999999,
        minPricePerM2: minPricePerM2 !== "" ? parseFloat(minPricePerM2) : null,
        maxPricePerM2: maxPricePerM2 !== "" ? parseFloat(maxPricePerM2) : null,
        withCredit: withCredit,
        document:
          (documentTrue === true && documentFalse === true) ||
          (documentTrue === false && documentFalse === false)
            ? null
            : documentTrue === true
            ? documentTrue
            : documentFalse,
        repair:
          (repairTrue === true && repairFalse === true) ||
          (repairTrue === false && repairFalse === false)
            ? null
            : repairTrue === true
            ? repairTrue
            : repairFalse,
        sites: siteValues,
        rooms: roomValues,
        minArea: areaMin,
        maxArea: areaMax,
        minFloor: floorMin,
        maxFloor: floorMax,
        perDay: offerType === "g" ? true : false,
        withGoods:
          (withGoodsTrue === true && withGoodsFalse === true) ||
          (withGoodsTrue === false && withGoodsFalse === false)
            ? null
            : withGoodsTrue === true
            ? withGoodsTrue
            : withGoodsFalse,
        orderBy: filter.value,
        page: page,
      })
      .then((response) => {
        dispatch(changeOfferType(`${fType}${oType}`));
        dispatch(getDefaultOffers(response.data.flatDataWithImages));
        dispatch(getDefaultOffersCount(response.data.count));
        dispatch(
          getCountOfPages(parseInt(Math.ceil(response.data.count / 20)))
        );
        dispatch(setSearchTrueOrFalse(false));
        dispatch(setCountOfOffers(response.data.count));
        dispatch(setLoadingOfCount(false));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
        if (
          response.data.count !== 0 &&
          response.data.flatDataWithImages.length === 0
        ) {
          dispatch(getPage(1));
          dispatch(
            searchOffer(
              flatType,
              offerType,
              1,
              filter,
              city,
              rooms,
              minPrice,
              maxPrice,
              areaMin,
              areaMax,
              province,
              residents,
              stations,
              targets,
              floorMin,
              floorMax,
              sites,
              withGoodsTrue,
              withGoodsFalse,
              withCredit,
              repairTrue,
              repairFalse,
              documentTrue,
              documentFalse,
              minPricePerM2,
              maxPricePerM2
            )
          );
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getCountOfOffers(
  flatType,
  offerType,
  city,
  rooms,
  minPrice,
  maxPrice,
  areaMin,
  areaMax,
  province,
  residents,
  stations,
  targets,
  floorMin,
  floorMax,
  sites,
  withGoodsTrue,
  withGoodsFalse,
  withCredit,
  repairTrue,
  repairFalse,
  documentTrue,
  documentFalse,
  minPricePerM2,
  maxPricePerM2
) {
  let fType =
    flatType === 1 || flatType?.value === "1"
      ? "flat"
      : flatType?.value === "2"
      ? "country"
      : flatType?.value === "3"
      ? "office"
      : flatType?.value === "4"
      ? "garage"
      : flatType?.value === "5"
      ? "land"
      : flatType?.value === "6"
      ? "commercial"
      : "flat";
  let oType =
    offerType === "s" ? "sales" : offerType === "g" ? "rents" : "rents";
  const stationValues = stations?.map((station) => station.value - 1).join(",");
  const roomValues = rooms?.map((room) => room.value).join(",");
  const siteValues = sites?.map((site) => site?.value).join(",");
  const residentValues = residents
    ?.map((resident) => resident.value - 1)
    .join(",");
  const targetValues = targets?.map((target) => target.value - 1).join(",");
  const regionValues = province
    ?.filter((value, index, array) => array.indexOf(value) === index)
    ?.map((region) => region.value)
    .join(",");
  const cityValues = city?.map((city) => city.value).join(",");
  return function (dispatch) {
    dispatch(setCountOfOffers(""));
    dispatch(setLoadingOfCount(true));
    axios
      .post(`https://api.ebaz.az/getCount/${fType}${oType}`, {
        city: cityValues,
        province: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? regionValues
          : null,
        residents: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? residentValues
          : null,
        targets: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? targetValues
          : null,
        metro: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? stationValues
          : null,
        minPrice: minPrice !== "" ? parseFloat(minPrice) : 0,
        maxPrice: maxPrice !== "" ? parseFloat(maxPrice) : 9999999999999,
        minPricePerM2: minPricePerM2 !== "" ? parseFloat(minPricePerM2) : null,
        maxPricePerM2: maxPricePerM2 !== "" ? parseFloat(maxPricePerM2) : null,
        withCredit: withCredit,
        document:
          (documentTrue === true && documentFalse === true) ||
          (documentTrue === false && documentFalse === false)
            ? null
            : documentTrue === true
            ? documentTrue
            : documentFalse,
        repair:
          (repairTrue === true && repairFalse === true) ||
          (repairTrue === false && repairFalse === false)
            ? null
            : repairTrue === true
            ? repairTrue
            : repairFalse,
        sites: siteValues,
        perDay: offerType === "g" ? true : false,
        rooms: fType === "garage" || fType === "commercial" ? "" : roomValues,
        minArea: areaMin,
        maxArea: areaMax,
        minFloor: floorMin,
        maxFloor: floorMax,
        withGoods:
          (withGoodsTrue === true && withGoodsFalse === true) ||
          (withGoodsTrue === false && withGoodsFalse === false)
            ? null
            : withGoodsTrue === true
            ? withGoodsTrue
            : withGoodsFalse,
      })
      .then((response) => {
        dispatch(setCountOfOffers(response.data.count));
        dispatch(setLoadingOfCount(false));
      })
      .catch((error) => {
        dispatch(setLoadingOfCount(false));
        console.log(error);
      });
  };
}
export function setCountOfOffers(value) {
  return {
    type: "SET_COUNT_OF_OFFERS",
    payload: {
      value: value,
    },
  };
}
export function setLoadingOfCount(value) {
  return {
    type: "SET_LOADING_OF_COUNT",
    payload: {
      value: value,
    },
  };
}
export function getInitial() {
  return function (dispatch) {
    dispatch(getCountOfPages(1));
    dispatch(changeOfferType(`get`));
    dispatch(setSearchTrueOrFalse(false));
    axios
      .get(`https://api.ebaz.az/getvip`, {})
      .then((response) => {
        dispatch(getInitialOffersVips(response.data.vips));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getcar`, {})
      .then((response) => {
        dispatch(getInitialOffersCar(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getflat`, {})
      .then((response) => {
        dispatch(getInitialOffersFlat(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getcountry`, {})
      .then((response) => {
        dispatch(getInitialOffersCountry(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getcommercial`, {})
      .then((response) => {
        dispatch(getInitialOffersObyekt(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getoffice`, {})
      .then((response) => {
        dispatch(getInitialOffersOffice(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getland`, {})
      .then((response) => {
        dispatch(getInitialOffersLand(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get(`https://api.ebaz.az/getgarage`, {})
      .then((response) => {
        dispatch(getInitialOffersGarage(response.data));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function searchSimilar(offerType, city, price, offerId) {
  return function (dispatch) {
    axios
      .post(`https://api.ebaz.az/${offerType}/similar`, {
        city: city,
        price: price,
      })
      .then((response) => {
        dispatch(
          getDefaultSimilarOffers(
            response.data[
              offerType.includes("auto")
                ? "autoDataWithImages"
                : "flatDataWithImages"
            ]
              .filter((item) => item.id !== offerId)
              .slice(0, 8)
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function newOfferAction(offerData, title) {
  let fType =
    offerData.flatType === 1 || offerData.flatType?.value === "1"
      ? "flat"
      : offerData.flatType?.value === "2"
      ? "country"
      : offerData.flatType?.value === "3"
      ? "office"
      : offerData.flatType?.value === "4"
      ? "garage"
      : offerData.flatType?.value === "5"
      ? "land"
      : offerData.flatType?.value === "6"
      ? "commercial"
      : "flat";
  let oType = offerData.offerType?.value === "true" ? "saveSale" : "saveRent";
  const fd = new FormData();
  if (fType === "land") {
    fd.append("area", parseFloat(offerData.landArea) || null);
  } else {
    fd.append("area", parseFloat(offerData.area) || null);
  }
  fd.append("title", title || null);
  fd.append("city", offerData.city ? parseFloat(offerData.city.value) : null);
  fd.append("details", offerData.description || null);
  fd.append("documents", offerData.documents || null);
  fd.append("email", offerData.email || null);
  fd.append("floor", parseFloat(offerData.floor) || null);
  fd.append("location", offerData.latLng || null);
  fd.append("maxFloor", parseFloat(offerData.max_floor) || null);
  fd.append(
    "mortgage",
    offerData.mortgage?.value === "true"
      ? true
      : offerData.mortgage?.value === "false"
      ? false
      : null
  );
  fd.append("mobileNum", offerData.mobileNum || null);
  fd.append(
    "m2",
    parseFloat(offerData.price) / parseFloat(offerData.area) ||
      parseFloat(offerData.price) / parseFloat(offerData.landArea) ||
      null
  );
  fd.append("name", offerData.name || null);
  fd.append("price", parseFloat(offerData.price) || null);
  fd.append(
    "region",
    offerData.city?.value === "8"
      ? parseFloat(offerData.region?.value) || null
      : null
  );
  fd.append(
    "perDay",
    oType === "saveRent"
      ? parseFloat(offerData.rentPeriod?.value) === 2
        ? true
        : false
      : false
  );
  fd.append("repair", offerData.repair || null);
  fd.append(
    "residents",
    offerData.city?.value === "8"
      ? parseFloat(offerData.resident?.value - 1) || null
      : null
  );
  fd.append("rooms", parseFloat(offerData.rooms) || null);
  for (let i = 0; i < offerData.selectedImage?.length; i++) {
    fd.append(`img`, offerData.selectedImage[i]);
  }
  if (offerData.selectedVideo?.length !== 0) {
    fd.append(`img`, offerData.selectedVideo?.[0]?.file);
  }

  fd.append(
    "metro",
    offerData.city?.value === "8"
      ? offerData.station?.map((station) => station.value - 1).join(",") || null
      : null
  );
  fd.append(
    "target",
    offerData.city?.value === "8"
      ? offerData.target?.map((target) => target.value - 1).join(",") || null
      : null
  );
  fd.append("typeOfFlat", parseFloat(offerData.typeOfFlat) || null);
  fd.append("withGoods", offerData.withGoods || null);
  fd.append("userId", localStorage.getItem("id"));
  return function (dispatch) {
    dispatch(loadingUploadOfferAction(true));
    axios
      .post(`https://api.ebaz.az/api/${fType}/${oType}`, fd, {
        headers: { "x-access-token": localStorage.getItem("token") },
      })
      .then(async (response) => {
        window.scrollTo({ top: 0, behavior: "instant" });
        dispatch(loadingUploadOfferAction(false));
        dispatch(
          uploadedOfferAction({
            ...response.data.offer,
            images: offerData.selectedImage,
          })
        );
        dispatch(
          isFirstOfferOfUserAction(
            response.data.isFirst === true || response.data.isFirst === false
              ? response.data.isFirst
              : true
          )
        );
        dispatch(showSuccessUploadOfferPopUp(true));

        sessionStorage.setItem(
          "uploadedOffer",
          JSON.stringify({
            ...response.data.offer,
            images: offerData.selectedImage,
          })
        );
        sessionStorage.setItem(
          "isFirst",
          JSON.stringify(
            response.data.isFirst === true || response.data.isFirst === false
              ? response.data.isFirst
              : true
          )
        );
        await sessionStorage.setItem(
          "showSuccessUploadOfferPopUp",
          JSON.stringify(true)
        );
        // await Swal.fire({
        //   title:
        //     options[
        //       localStorage.getItem('language')
        //         ? localStorage.getItem('language')
        //         : 'az'
        //     ].successName2,
        //   text: 'Yeni elan uğurla əlavə olundu.',
        //   icon: 'success'
        // })
        window.location.href = "https://ebaz.az/elan-paylasildi";
      })
      .catch((error) => {
        console.log(error);
        dispatch(loadingUploadOfferAction(false));
        if (error?.response?.data?.message === "No token provided!") {
          window.location.href = "https://ebaz.az/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function updateOfferAction(offerData, title, id) {
  let fType =
    offerData.flatType === 1 || offerData.flatType?.value === "1"
      ? "flat"
      : offerData.flatType?.value === "2"
      ? "country"
      : offerData.flatType?.value === "3"
      ? "office"
      : offerData.flatType?.value === "4"
      ? "garage"
      : offerData.flatType?.value === "5"
      ? "land"
      : offerData.flatType?.value === "6"
      ? "commercial"
      : "flat";
  let oType = offerData.offerType?.value === "true" ? "Sale" : "Rent";
  const fd = new FormData();
  fd.append("id", id);
  if (fType === "land") {
    fd.append("area", parseFloat(offerData.landArea) || null);
  } else {
    fd.append("area", parseFloat(offerData.area) || null);
  }
  fd.append("title", title || null);
  fd.append("city", parseFloat(offerData.city.value) || null);
  fd.append("details", offerData.description || null);
  fd.append("documents", offerData.documents || null);
  fd.append("email", offerData.email || null);
  fd.append("floor", parseFloat(offerData.floor) || null);
  fd.append("location", offerData.latLng || null);
  fd.append("maxFloor", parseFloat(offerData.max_floor) || null);
  fd.append(
    "mortgage",
    offerData.mortgage?.value === "true"
      ? true
      : offerData.mortgage?.value === "false"
      ? false
      : null
  );
  fd.append("mobileNum", offerData.mobileNum || null);
  fd.append(
    "m2",
    parseFloat(offerData.price) / parseFloat(offerData.area) ||
      parseFloat(offerData.price) / parseFloat(offerData.landArea) ||
      null
  );
  fd.append("name", offerData.name || null);
  fd.append("price", parseFloat(offerData.price) || null);
  fd.append("region", parseFloat(offerData.region?.value) || null);
  fd.append(
    "perDay",
    oType === "saveRent"
      ? parseFloat(offerData.rentPeriod?.value) === 2
        ? true
        : false
      : false
  );
  fd.append("repair", offerData.repair || null);
  fd.append("residents", parseFloat(offerData.resident?.value - 1) || null);
  fd.append("rooms", parseFloat(offerData.rooms) || null);
  for (let i = 0; i < offerData.selectedImage?.length; i++) {
    if (typeof offerData.selectedImage[i] !== "string")
      fd.append(`img`, offerData.selectedImage[i]);
  }
  if (offerData.selectedVideo?.length !== 0) {
    fd.append(`img`, offerData.selectedVideo?.[0]?.file);
  }
  fd.append(
    "metro",
    offerData.station?.map((station) => station.value - 1).join(",") || null
  );
  fd.append(
    "target",
    offerData.target?.map((target) => target.value - 1).join(",") || null
  );
  fd.append("typeOfFlat", parseFloat(offerData.typeOfFlat) || null);
  fd.append("withGoods", offerData.withGoods || null);
  fd.append("userId", localStorage.getItem("id"));
  return function (dispatch) {
    dispatch(loadingUploadOfferAction(true));
    axios
      .post(`https://api.ebaz.az/api/${fType}/update${oType}`, fd, {
        headers: {
          // 'Content-Type': 'multipart/form-data',
          "x-access-token": localStorage.getItem("token"),
        },
      })
      .then(async (response) => {
        await Swal.fire({
          title:
            options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].successName2,
          text: options[
            localStorage.getItem("language")
              ? localStorage.getItem("language")
              : "az"
          ].successDescription2UpdateOffer,
          icon: "success",
        });
        window.location.href = "https://ebaz.az/profil";
        dispatch(loadingUploadOfferAction(false));
      })
      .catch((error) => {
        dispatch(loadingUploadOfferAction(false));
        if (error.response.data.message === "No token provided!") {
          window.location.href = "/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function deleteOfferAction(flatType, id) {
  return function (dispatch) {
    axios
      .post(
        `https://api.ebaz.az/api/${flatType.split(" ")[0]}/delete${
          flatType.split(" ")[1]
        }`,
        {
          id: id,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      )
      .then(async (response) => {
        await Swal.fire({
          title:
            options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].successName2,
          text: options[
            localStorage.getItem("language")
              ? localStorage.getItem("language")
              : "az"
          ].successDescription2DeleteOffer,
          icon: "success",
        });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.message === "No token provided!") {
          window.location.href = "/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function boostOfferAction(flatType, id) {
  return function (dispatch) {
    axios
      .post(
        `https://api.ebaz.az/api/${flatType.split(" ")[0]}/boost${
          flatType.split(" ")[1]
        }`,
        {
          id: id,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      )
      .then(async (response) => {
        await Swal.fire({
          title:
            options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].successName2,
          text: options[
            localStorage.getItem("language")
              ? localStorage.getItem("language")
              : "az"
          ].successDescription2RefreshOffer,
          icon: "success",
        });
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.data.message === "No token provided!") {
          window.location.href = "/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function requestDefaultOffers(page, filter) {
  return function (dispatch) {
    axios
      .post("https://api.ebaz.az/get", {
        page: page,
        orderBy: filter,
      })
      .then((response) => {
        dispatch(getDefaultOffersCount(response.data.count));
        dispatch(getDefaultOffers(response.data.flatDataWithImages));
        dispatch(
          getCountOfPages(parseInt(Math.ceil(response.data.count / 20)))
        );
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
}
export function requestOffersWithLocations(
  flatType,
  offerType,
  page,
  filter,
  city,
  rooms,
  minPrice,
  maxPrice,
  areaMin,
  areaMax,
  province,
  residents,
  stations,
  targets,
  floorMin,
  floorMax,
  sites,
  withGoodsTrue,
  withGoodsFalse,
  withCredit,
  repairTrue,
  repairFalse,
  documentTrue,
  documentFalse,
  minPricePerM2,
  maxPricePerM2,
  bounds
) {
  changePageTitle(
    flatType,
    offerType,
    page,
    filter,
    city,
    rooms,
    minPrice,
    maxPrice,
    areaMin,
    areaMax,
    province,
    residents,
    stations,
    targets,
    floorMin,
    floorMax,
    sites,
    withGoodsTrue,
    withGoodsFalse,
    withCredit,
    repairTrue,
    repairFalse,
    documentTrue,
    documentFalse,
    minPricePerM2,
    maxPricePerM2
  );
  let fType =
    flatType === 1 || flatType?.value === "1"
      ? "flat"
      : flatType?.value === "2"
      ? "country"
      : flatType?.value === "3"
      ? "office"
      : flatType?.value === "4"
      ? "garage"
      : flatType?.value === "5"
      ? "land"
      : flatType?.value === "6"
      ? "commercial"
      : "flat";
  let oType =
    offerType === "s" ? "sales" : offerType === "g" ? "rents" : "rents";
  const stationValues = stations?.map((station) => station.value - 1).join(",");
  const roomValues = rooms?.map((room) => room.value).join(",");
  const siteValues = sites?.map((site) => site?.value).join(",");
  const residentValues = residents
    ?.map((resident) => resident.value - 1)
    .join(",");
  const targetValues = targets?.map((target) => target.value - 1).join(",");
  const regionValues = province
    ?.filter((value, index, array) => array.indexOf(value) === index)
    ?.map((region) => region.value)
    .join(",");
  const cityValues = city?.map((city) => city.value).join(",");
  const url = new URL(window.location.href);
  window.history.pushState({}, "");
  offerType &&
    url.searchParams.set(
      offerType === "s" ? "satis" : offerType === "g" ? "gunluk" : "kiraye",
      ""
    );
  flatType
    ? url.searchParams.set(
        "elan",
        flatType === 1 || flatType?.value === "1"
          ? "menzil"
          : flatType?.value === "2"
          ? "heyet-evi-bag-villa"
          : flatType?.value === "3"
          ? "ofis"
          : flatType?.value === "4"
          ? "qaraj"
          : flatType?.value === "5"
          ? "torpaq"
          : flatType?.value === "6"
          ? "obyekt"
          : "menzil"
      )
    : url.searchParams.delete("elan");
  cityValues.length !== 0
    ? url.searchParams.set(
        "seher",
        city.length > 3
          ? city?.map((city) => city.value).join("-")
          : city?.map((city) => city.label).join("-")
      )
    : url.searchParams.delete("seher");
  cityValues.includes("8") && regionValues.length !== 0
    ? url.searchParams.set(
        "rayon",
        province.length > 1
          ? province?.map((city) => city.value).join("-")
          : province?.map((city) => city.label).join("-")
      )
    : url.searchParams.delete("rayon");
  cityValues.includes("8") && residentValues.length !== 0
    ? url.searchParams.set(
        "qesebe",
        residents.length > 1
          ? residents?.map((city) => city.value).join("-")
          : residents?.map((city) => city.label).join("-")
      )
    : url.searchParams.delete("qesebe");
  roomValues.length !== 0
    ? url.searchParams.set("otaqlar", roomValues.replaceAll(",", "-"))
    : url.searchParams.delete("otaqlar");
  minPrice !== ""
    ? url.searchParams.set("min-qiymet", minPrice)
    : url.searchParams.delete("min-qiymet");
  maxPrice !== "" && maxPrice !== 99999999999
    ? url.searchParams.set("max-qiymet", maxPrice)
    : url.searchParams.delete("max-qiymet");
  minPricePerM2 !== ""
    ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
    : url.searchParams.delete("min-qiymet-m2");
  maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
    ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
    : url.searchParams.delete("max-qiymet-m2");
  areaMin !== ""
    ? url.searchParams.set("min-sahe", areaMin)
    : url.searchParams.delete("min-sahe");
  areaMax !== "" && areaMax !== 99999999999
    ? url.searchParams.set("max-sahe", areaMax)
    : url.searchParams.delete("max-sahe");
  floorMin !== ""
    ? url.searchParams.set("min-mertebe", floorMin)
    : url.searchParams.delete("min-mertebe");
  floorMax !== "" && floorMax !== 99999999999
    ? url.searchParams.set("max-mertebe", floorMax)
    : url.searchParams.delete("max-mertebe");
  cityValues.includes("8") && stationValues.length !== 0
    ? url.searchParams.set(
        "metro",
        stations.length > 1
          ? stations?.map((city) => city.value).join("-")
          : stations?.map((city) => city.label).join("-")
      )
    : url.searchParams.delete("metro");
  cityValues.includes("8") && targetValues.length !== 0
    ? url.searchParams.set(
        "nisangah",
        targets.length > 1
          ? targets?.map((city) => city.value).join("-")
          : targets?.map((city) => city.label).join("-")
      )
    : url.searchParams.delete("nisangah");
  withGoodsTrue
    ? url.searchParams.set("esyali", "")
    : url.searchParams.delete("esyali");
  withGoodsFalse
    ? url.searchParams.set("esyasiz", "")
    : url.searchParams.delete("esyasiz");
  repairTrue
    ? url.searchParams.set("temirli", "")
    : url.searchParams.delete("temirli");
  repairFalse
    ? url.searchParams.set("temirsiz", "")
    : url.searchParams.delete("temirsiz");
  documentTrue
    ? url.searchParams.set("senedli", "")
    : url.searchParams.delete("senedli");
  documentFalse
    ? url.searchParams.set("senedsiz", "")
    : url.searchParams.delete("senedsiz");
  siteValues.length !== 0
    ? url.searchParams.set(
        "sayt",
        sites.length > 1
          ? sites?.map((city) => city.value).join("-")
          : sites?.map((city) => city.label).join("-")
      )
    : url.searchParams.delete("sayt");
  withCredit
    ? url.searchParams.set("ipoteka", "")
    : url.searchParams.delete("ipoteka");
  filter && url.searchParams.set("filter", filter.value);
  page !== 0
    ? url.searchParams.set("page", page)
    : url.searchParams.delete("page");
  window.history.pushState({}, "", url.toString());
  return function (dispatch) {
    dispatch(getOffersWithLocations([]));
    dispatch(getDefaultOffersCount(""));
    dispatch(setSearchTrueOrFalse(true));
    axios
      .post(`https://api.ebaz.az/${fType}${oType}locations`, {
        city: cityValues,
        province: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? regionValues
          : null,
        residents: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? residentValues
          : null,
        targets: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? targetValues
          : null,
        metro: city
          .map((city) => city.value)
          .join(",")
          .includes("8")
          ? stationValues
          : null,
        minPrice: minPrice !== "" ? parseFloat(minPrice) : 0,
        maxPrice: maxPrice !== "" ? parseFloat(maxPrice) : 9999999999999,
        minPricePerM2: minPricePerM2 !== "" ? parseFloat(minPricePerM2) : null,
        maxPricePerM2: maxPricePerM2 !== "" ? parseFloat(maxPricePerM2) : null,
        withCredit: withCredit,
        document:
          (documentTrue === true && documentFalse === true) ||
          (documentTrue === false && documentFalse === false)
            ? null
            : documentTrue === true
            ? documentTrue
            : documentFalse,
        repair:
          (repairTrue === true && repairFalse === true) ||
          (repairTrue === false && repairFalse === false)
            ? null
            : repairTrue === true
            ? repairTrue
            : repairFalse,
        sites: siteValues,
        rooms: roomValues,
        minArea: areaMin,
        maxArea: areaMax,
        minFloor: floorMin,
        maxFloor: floorMax,
        orderBy: filter.value,
        perDay: offerType === "g" ? true : false,
        bouds: bounds,
        withGoods:
          (withGoodsTrue === true && withGoodsFalse === true) ||
          (withGoodsTrue === false && withGoodsFalse === false)
            ? null
            : withGoodsTrue === true
            ? withGoodsTrue
            : withGoodsFalse,
      })
      .then((response) => {
        dispatch(
          getOffersWithLocations(response.data.flatDataWithLocations.rows)
        );
        dispatch(
          getDefaultOffersCount(response.data.flatDataWithLocations.count)
        );
        dispatch(
          getDefaultOffers(response.data.flatDataWithLocations.rows.slice(0, 8))
        );
        dispatch(changeOfferType(`${fType}${oType}`));
        dispatch(setSearchTrueOrFalse(false));
        if (response.data.flatDataWithLocations.count === 0)
          dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getStationsNames(stationName) {
  return {
    type: "GET_STATION_NAMES",
    payload: {
      value: stationName,
    },
  };
}
export function changeSearchRow(row) {
  return {
    type: "CHANGE_SEARCH_ROW",
    payload: {
      value: row,
    },
  };
}
export function changeFlatType(value) {
  return {
    type: "CHANGE_FLAT_TYPE",
    payload: {
      value: value,
    },
  };
}
export function changeAutoType(value) {
  return {
    type: "CHANGE_AUTO_TYPE",
    payload: {
      value: value,
    },
  };
}
export function changeSiteLanguage(language) {
  return {
    type: "CHANGE_SITE_LANGUAGE",
    payload: {
      value: language,
    },
  };
}
export function getMyOffers(myoffers) {
  return {
    type: "GET_MY_OFFERS",
    payload: {
      value: myoffers,
    },
  };
}
export function changeOfferType(offerType) {
  return {
    type: "CHANGE_OFFER_TYPE",
    payload: {
      value: offerType,
    },
  };
}
export function getAllMyOffers() {
  return function (dispatch) {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://api.ebaz.az/api/properties/get",
      headers: {
        "x-access-token": localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      params: {
        userId: localStorage.getItem("id"),
      },
    };

    axios
      .request(config)
      .then((response) => {
        dispatch(getMyOffers(response.data));
        dispatch(setSearchTrueOrFalse(false));
      })
      .catch((error) => {
        if (error.response.data.message === "No token provided!") {
          window.location.href = "/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function getCountOfPages(count) {
  return {
    type: "GET_COUNT_OF_PAGES",
    payload: {
      value: count,
    },
  };
}
export function changeBounds(bounds) {
  return {
    type: "CHANGE_BOUNDS",
    payload: {
      value: bounds,
    },
  };
}
export function setSearchTrueOrFalse(value) {
  return {
    type: "SET_SEARCH_TRUE",
    payload: {
      value: value,
    },
  };
}
export function getPage(count) {
  return {
    type: "GET_PAGE",
    payload: {
      value: count,
    },
  };
}
export function getSiteRoad() {
  return {
    type: "GET_SITE_ROAD",
    payload: {
      value: document.location.pathname,
    },
  };
}
export function getById(value) {
  return {
    type: "GET_BY_ID",
    payload: {
      value: value,
    },
  };
}
export function offerTypeForUpdate(value) {
  return {
    type: "OFFER_TYPE_FOR_UPDATE",
    payload: {
      value: value,
    },
  };
}
export function setShowModal(value) {
  if (value) {
    document.body.classList.add("noscroll");
  } else {
    document.body.classList.remove("noscroll");
  }
  return {
    type: "SHOW_OR_CLOSE_MODAL",
    payload: {
      value: value,
    },
  };
}
export function getByIdAction(offerType, id) {
  return function (dispatch) {
    dispatch(getById([]));
    axios
      .post(
        `https://api.ebaz.az/${offerType
          ?.replace("sales", "sale")
          ?.replace("rents", "rent")}/getById`,
        {
          // axios.post(`https://api.ebaz.az/${offerType.replace("sales", "sale").replace("rents", "rent")}/getById`, {
          id: id,
        }
      )
      .then((response) => {
        dispatch(getById(response.data));
        dispatch(offerTypeForUpdate(offerType));
      })
      .catch((error) => {
        window.location = "/xeta-404";
      });
  };
}
export function clickOnPhoneNum(offerType, id) {
  return function (dispatch) {
    axios
      .post(
        `https://api.ebaz.az/${offerType
          .replace("sales", "sale")
          .replace("rents", "rent")}/clickOnPhoneNum`,
        {
          // axios.post(`https://api.ebaz.az/${offerType.replace("sales", "sale").replace("rents", "rent")}/clickOnPhoneNum`, {
          id: id,
        }
      )
      .then((response) => {})
      .catch((error) => {
        console.error(error);
      });
  };
}
export function saveLastSearchUrl(url) {
  return {
    type: "SAVE_LAST_SEARCH_URL",
    payload: {
      value: url,
    },
  };
}
export function setShouldRunSecondEffect(value) {
  return {
    type: "SET_SHOULD_RUN_SECOND_EFFECT",
    payload: {
      value: value,
    },
  };
}
export function setCounter(time) {
  return {
    type: "SET_TIMER",
    payload: {
      value: time,
    },
  };
}
export function isPasswordCorrect(value) {
  return {
    type: "IS_PASSWORD_CORRECT",
    payload: {
      value: value,
    },
  };
}
export function isUserExist(value) {
  return {
    type: "IS_USER_EXIST",
    payload: {
      value: value,
    },
  };
}
export function isCorrectOtp(value) {
  return {
    type: "SET_IS_OTP_CORRECT",
    payload: {
      value: value,
    },
  };
}
export function isMobileNumberUsed(value) {
  return {
    type: "IS_MOBILENUMBER_USED",
    payload: {
      value: value,
    },
  };
}
export function openOtpPart(value) {
  return {
    type: "SET_OPEN_OTP_PART",
    payload: {
      value: value,
    },
  };
}
export function loginSendOtp(phoneNumber) {
  return function (dispatch) {
    axios
      .post("https://api.ebaz.az/loginSendOTP", {
        mobileNum: phoneNumber
          .replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
      })
      .then((response) => {
        if (response.data.message) {
          dispatch(openOtpPart(true));
          dispatch(setCounter(59));
        }
      })
      .catch((error) => {
        if (error.response.data.message === "User Not found.") {
          dispatch(isUserExist(false));
          window.location = "/qeydiyyat";
        }
      });
  };
}
export function loginAction(phoneNumber, otp) {
  return function (dispatch) {
    axios
      .post("https://api.ebaz.az/auth/login", {
        mobileNum: phoneNumber
          .replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
        code: otp,
      })
      .then((response) => {
        dispatch(loginBid(response.data));
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("id", response.data.id);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("lastname", response.data.lastname);
        localStorage.setItem("mobileNum", response.data.mobileNum);
        localStorage.setItem("email", response.data.email);
      })
      .catch((error) => {
        if (error.response.data.message === "Wrong code") {
          dispatch(isCorrectOtp(false));
        } else if (error.response.data.message === "User Not found.") {
          dispatch(isUserExist(false));
          window.location = "/qeydiyyat";
        }
      });
  };
}
export function registrationSendOtp(phoneNumber) {
  return function (dispatch) {
    axios
      .post("https://api.ebaz.az/registrationSendOTP", {
        mobileNum: phoneNumber
          .replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
      })
      .then((response) => {
        if (response.data.message) {
          dispatch(openOtpPart(true));
          dispatch(setCounter(59));
        }
      })
      .catch((error) => {
        if (error.response.data.message === "User is already registered.") {
          dispatch(isUserExist(false));
          window.location = "/daxil-ol";
        }
      });
  };
}
export function registrationAction(name, surname, phoneNumber, email, otp) {
  return function (dispatch) {
    axios
      .post("https://api.ebaz.az/auth/register", {
        name: name,
        lastname: surname,
        mobileNum: phoneNumber
          .replaceAll("-", "")
          .replaceAll("(", "")
          .replaceAll(")", ""),
        email: email,
        code: otp,
      })
      .then((response) => {
        dispatch(loginBid(response.data));
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("id", response.data.id);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("lastname", response.data.lastname);
        localStorage.setItem("mobileNum", response.data.mobileNum);
        localStorage.setItem("email", response.data.email);
        Swal.fire({
          title:
            options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].successName2,
          text: options[
            localStorage.getItem("language")
              ? localStorage.getItem("language")
              : "az"
          ].successDescription2RegistrationOffer,
          icon: "success",
        });
      })
      .catch((error) => {
        if (
          error.response.data.message === "Mobile number is already in use!"
        ) {
          dispatch(isMobileNumberUsed(true));
        } else if (error.response.data.message === "Wrong code") {
          dispatch(isCorrectOtp(false));
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function searchByUrl(url) {
  return function (dispatch) {
    dispatch(setSearchByUrlData([]));
    dispatch(loadingSearchByUrlAction(true));
    axios
      .post("https://api.ebaz.az/searchByUrl", {
        link: url,
      })
      .then((response) => {
        dispatch(setSearchByUrlData(response.data));
        dispatch(loadingSearchByUrlAction(false));
      })
      .catch((error) => {
        dispatch(loadingSearchByUrlAction(false));
        if (error.response.data.message === "Link not sent.") {
          console.log(error);
        }
      });
  };
}
export function setSearchByUrlData(time) {
  return {
    type: "SET_BY_URL_DATA",
    payload: {
      value: time,
    },
  };
}
export function loadingSearchByUrlAction(value) {
  return {
    type: "LOADING_SEARCH_BY_URL",
    payload: {
      value: value,
    },
  };
}
export function getAllVipsAction() {
  return function (dispatch) {
    dispatch(getDefaultOffers([]));
    dispatch(getDefaultOffersCount(""));
    dispatch(setSearchTrueOrFalse(true));
    axios
      .get(`https://api.ebaz.az/getAllVips`)
      .then((response) => {
        dispatch(getDefaultOffers(response.data.vips));
        dispatch(changeOfferType(`vips`));
        dispatch(setSearchTrueOrFalse(false));
        if (response.data.count === 0) dispatch(offersFoundOrNot(false));
        else dispatch(offersFoundOrNot(true));
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function makePremiumOfferApi(
  amount,
  offerId,
  userrId,
  day,
  border,
  urgent,
  flatType
) {
  let flat = flatType
    .replace("sales", "")
    .replace("rents", "")
    .replace("sale", "")
    .replace("rent", "");
  let saleOrRent = flatType.includes("sale") ? "Sale" : "Rent";
  let setTarif = urgent ? 2 : border ? 1 : 0;
  return function (dispatch) {
    axios
      .post(
        `https://api.ebaz.az/${flat}/payVip${saleOrRent}`,
        {
          amount: amount,
          id: offerId,
          userId: userrId,
          day: day,
          type: setTarif,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        dispatch(loadingPremiumButtonAction(false));
        dispatch(loadingBoostButtonAction(false));
        var screenWidth = window.innerWidth;
        var screenHeight = window.innerHeight;
        var popupWidth = 600; // Example width
        var popupHeight = 800; // Example height
        var leftPosition = (screenWidth - popupWidth) / 2;
        var topPosition = (screenHeight - popupHeight) / 2;
        var windowFeatures =
          "width=" +
          popupWidth +
          ",height=" +
          popupHeight +
          ",left=" +
          leftPosition +
          ",top=" +
          topPosition +
          ",resizable=yes,scrollbars=yes";
        window.open(response.data.url, "mozillaWindow", windowFeatures);
      })
      .catch((error) => {
        dispatch(loadingPremiumButtonAction(false));
        dispatch(loadingBoostButtonAction(false));
        if (error.response.data.message === "No token provided!") {
          window.location.href = "/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}
export function makeBoostOfferApi(
  amount,
  offerId,
  userrId,
  day,
  flatType,
  tarif
) {
  let flat = flatType
    .replace("sales", "")
    .replace("rents", "")
    .replace("sale", "")
    .replace("rent", "");
  let saleOrRent = flatType.includes("sale") ? "Sale" : "Rent";
  let setTarif = tarif === "urgent" ? 2 : tarif === "frame" ? 1 : 0;
  return function (dispatch) {
    axios
      .post(
        `https://api.ebaz.az/${flat}/payBoost${saleOrRent}`,
        {
          id: offerId,
          userId: userrId,
          day: Number(day),
          type: setTarif,
          amount: amount,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        dispatch(loadingBoostButtonAction(false));
        dispatch(loadingPremiumButtonAction(false));
        var screenWidth = window.innerWidth;
        var screenHeight = window.innerHeight;
        var popupWidth = 600;
        var popupHeight = 800;
        var leftPosition = (screenWidth - popupWidth) / 2;
        var topPosition = (screenHeight - popupHeight) / 2;
        var windowFeatures =
          "width=" +
          popupWidth +
          ",height=" +
          popupHeight +
          ",left=" +
          leftPosition +
          ",top=" +
          topPosition +
          ",resizable=yes,scrollbars=yes";
        window.open(response.data.url, "mozillaWindow", windowFeatures);
      })
      .catch((error) => {
        dispatch(loadingBoostButtonAction(false));
        dispatch(loadingPremiumButtonAction(false));
        if (error.response.data.message === "No token provided!") {
          window.location.href = "/daxil-ol";
        } else {
          Swal.fire({
            title:
              options[
                localStorage.getItem("language")
                  ? localStorage.getItem("language")
                  : "az"
              ].errorName,
            text: options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2,
            icon: "error",
          });
        }
      });
  };
}

export function changeProfilInfoAction(name, lastname) {
  return function (dispatch) {
    dispatch(loadingUpdateProfilInfoAction(true));
    dispatch(updateProfilInfoSuccessAction(""));
    axios
      .post(
        `https://api.ebaz.az/api/changeProfilInfo`,
        {
          userId: localStorage.getItem("id"),
          name: name,
          lastname: lastname,
        },
        {
          headers: {
            "x-access-token": localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        dispatch(loadingUpdateProfilInfoAction(false));
        localStorage.setItem("name", name);
        localStorage.setItem("lastname", lastname);
        dispatch(
          updateProfilInfoSuccessAction(
            options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].myOffersNameLastnameChangedSuccessfullyName
          )
        );
      })
      .catch((error) => {
        dispatch(loadingUpdateProfilInfoAction(false));
        dispatch(
          updateProfilInfoSuccessAction(
            options[
              localStorage.getItem("language")
                ? localStorage.getItem("language")
                : "az"
            ].errorDescription2
          )
        );
      });
  };
}

export function loadingUpdateProfilInfoAction(value) {
  return {
    type: "LOADING_UPDATE_PROFIL_INFO",
    payload: {
      value: value,
    },
  };
}
export function updateProfilInfoSuccessAction(value) {
  return {
    type: "UPDATE_PROFIL_INFO_SUCCESS",
    payload: {
      value: value,
    },
  };
}

export function showSuccessUploadOfferPopUp(value) {
  return {
    type: "SHOW_SUCCESS_UPLOAD_OFFER_POPUP",
    payload: {
      value: value,
    },
  };
}
export function uploadedOfferAction(value) {
  return {
    type: "UPLOADED_OFFER",
    payload: {
      value: value,
    },
  };
}
export function isFirstOfferOfUserAction(value) {
  return {
    type: "IS_FIRST_OFFER_OF_USER",
    payload: {
      value: value,
    },
  };
}

export function getBlogs(page, filter) {
  return function (dispatch) {
    dispatch(setSearchTrueOrFalse(true));
    axios
      .post("https://api.ebaz.az/blog/getAll", {
        page: page || 1,
        orderBy: filter ? filter.value : "dn",
      })
      .then((response) => {
        dispatch(getBlogCounts(response.data.count));
        dispatch(setBlogs(response.data.blog));
        dispatch(
          getCountOfBlogPages(parseInt(Math.ceil(response.data.count / 6)))
        );
      })
      .catch((error) => {
        console.log(error.message);
        dispatch(setBlogs([]));
        dispatch(getCountOfBlogPages(1));
      })
      .finally(() => {
        dispatch(setSearchTrueOrFalse(false));
        document
          ?.querySelector(".main")
          ?.scrollIntoView({ behavior: "instant" });
      });
  };
}
export function getBlogCounts(value) {
  return {
    type: "SET_BLOGS_COUNT",
    payload: {
      value: value,
    },
  };
}
export function setBlogs(value) {
  return {
    type: "SET_BLOGS",
    payload: {
      value: value,
    },
  };
}
export function setBlog(value) {
  return {
    type: "SET_BLOG",
    payload: {
      value: value,
    },
  };
}
export function getCountOfBlogPages(value) {
  return {
    type: "SET_BLOGS_PAGE_COUNT",
    payload: {
      value: value,
    },
  };
}

export function getBlog(id) {
  return function (dispatch) {
    dispatch(setSearchTrueOrFalse(true));
    axios
      .post("https://api.ebaz.az/blog/getById", {
        id: id || 1,
      })
      .then((response) => {
        dispatch(setBlog(response.data));
      })
      .catch((error) => {
        console.log(error.message);
        dispatch(setBlog([]));
      })
      .finally(() => {
        dispatch(setSearchTrueOrFalse(false));
        document
          ?.querySelector(".main")
          ?.scrollIntoView({ behavior: "instant" });
      });
  };
}

export function setAdsType(value) {
  return {
    type: "SET_ADS_TYPES",
    payload: {
      value: value,
    },
  };
}
