import React, { useEffect, useState, useLayoutEffect } from "react";
import "./Offer.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import OfferCard from "../../Components/Offers/OfferCard";
import axios from "axios";
import { FaDownLong } from "react-icons/fa6";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CircularProgress from "@mui/material/CircularProgress";
import CallIcon from "@mui/icons-material/Call";
import ImageGallery from "react-image-gallery";
import GoogleMapsOnePoint from "./GoogleMapsOnePoint";
import { useDispatch, useSelector } from "react-redux";
import {
  clickOnPhoneNum,
  getByIdAction,
  loadingBoostButtonAction,
  loadingPremiumButtonAction,
  makeBoostOfferApi,
  makePremiumOfferApi,
  searchSimilar,
  setShowModal,
} from "../../redux/action";
import FavoriteIcon from "@mui/icons-material/Favorite";
import HeartWhite from "../../images/blurHeart.svg";
import BoostIcon from "../../img/boostSVG.svg";
import PremiumIcon from "../../img/premiumSVG.svg";
import whiteFon from "../../images/whitefon.jpg";
import { options } from "../../constants";
import Loading from "../../Components/Loading/Loading";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Frame from "./Frame";
import Urgent from "./Urgent";
import PremiumIcon2 from "./premiumIcon.svg";
import CrownIcon from "../../Components/images/CrownCard.svg";
import mobileHeaderBack from "./backToMobile.svg";
import mobileHeaderLogo from "./mobileHeaderLogo.svg";
import mobileHeaderShare from "./mobileHeaderShare.svg";
import mobileHeaderHeart from "./mobileHeaderHeart.svg";
import mobileHeaderHeartRed from "./mobileHeaderHeartRed.svg";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { IconButton, Snackbar } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import XIconBlack from "../../images/xBlack.svg";
import XIcon from "../../images/x.svg";
import Top from "./Top";
import Predict from "../../Components/PredictGraph/Predict";
import OfferCardAuto from "../../Components/Offers/OfferCardAuto";
const OfferAuto = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const offerDetail = useSelector((state) => state.offerDetail);
  const isMobile = useSelector((state) => state.isMobile);
  const loadingPremiumButton = useSelector(
    (state) => state.loadingClickOnMakePremiumButton
  );
  const loadingBoostButton = useSelector(
    (state) => state.loadingClickOnMakeBoostButton
  );
  const language = useSelector((state) => state.language);
  const [url, setUrl] = useState();
  const [changed, setChanged] = useState(false);
  const [filteredArray, setFilteredArray] = useState([]);
  const [isFavorite, setIsFavorite] = useState(false);
  const [open, setOpen] = useState(false);
  const [predictData, setPredictData] = useState([]);
  const [showFullScreen, setShowFullScreen] = useState(false);
  const [showFullScreenVideo, setShowFullScreenVideo] = useState(false);
  const [phoneNumClicked, setPhoneNumClicked] = useState(false);
  const lastSearchUrl = useSelector((state) => state.lastSearchUrl);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showPremiumModal, setShowPremiumModal] = useState(false);
  const handleClickToCopy = () => {
    setOpen(true);
    navigator?.clipboard?.writeText(
      window?.location?.toString()?.replace("secilmis-elanlar", "elan")
    );
  };
  const closeFullscreen = (e) => {
    if (e.target.className !== "image-gallery-image") {
      setShowFullScreen(false);
    }
  };
  useLayoutEffect(() => {
    if (offerDetail.id) {
      let ogTitle = document.getElementById("ogTitle");
      let ogDescription = document.getElementById("ogDescription");
      let ogImage = document.getElementById("ogImage");
      let offerDetail2 = {
        title: `${offerDetail?.details}`,
        details: `${offerDetail?.details}`,
        imageUrl: `${
          filteredArray.length ? filteredArray[0].original : whiteFon
        }`,
      };
      ogTitle.setAttribute("content", `Ebaz.az - ${offerDetail2.details}`);
      ogDescription.setAttribute("content", `${offerDetail2.details}`);
      ogImage.setAttribute("content", `${offerDetail2.imageUrl}`);
      document.title = `Ebaz.az - ${offerDetail?.details}`;
    }
  }, [offerDetail, filteredArray, whiteFon]);
  useEffect(() => {
    if (
      window.location.pathname.split("/")[2] &&
      window.location.pathname.split("/")[3]
    )
      dispatch(
        getByIdAction(
          window.location.pathname.split("/")[2],
          window.location.pathname.split("/")[3]
        )
      );
  }, []);
  useEffect(() => {
    if (
      window.location.pathname.split("/")[2] &&
      window.location.pathname.split("/")[3]
    )
      dispatch(
        getByIdAction(
          window.location.pathname.split("/")[2],
          window.location.pathname.split("/")[3]
        )
      );
  }, [window.location.pathname]);
  useEffect(() => {
    // PREDICT FETCH const fetchData = async (
    //   flatType,
    //   offerType,
    //   city,
    //   province,
    //   residents
    // ) => {
    //   axios
    //     .post('https://api.ebaz.az/predictPrice', {
    //       flat_type: flatType,
    //       offer_type: offerType,
    //       city: city ? city - 0 : 8,
    //       region:
    //         city === '8' || city || city === 8
    //           ? province
    //             ? province - 0
    //             : null
    //           : null,
    //       resident:
    //         city === '8' || city || city === 8
    //           ? residents
    //             ? residents - 1
    //             : null
    //           : null
    //     })
    //     .then(response => {
    //       setPredictData(response.data)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // }
    if (offerDetail?.photos) {
      const mappedArray = JSON.parse(offerDetail?.photos)?.map((image) => {
        return {
          original: image,
          thumbnail: image,
        };
      });
      mappedArray?.length && setFilteredArray(mappedArray);
    }
    if (offerDetail.length !== 0) {
      dispatch(
        searchSimilar(
          window.location.pathname.split("/")[2],
          offerDetail?.city,
          offerDetail?.price,
          offerDetail?.id
        )
      );
      // PREDICT FETCH fetchData(
      //   window.location.pathname
      //     ?.split('/')[2]
      //     ?.replace('sales', '')
      //     ?.replace('rents', ''),
      //   window.location.pathname.split('/')[2]?.includes('sales')
      //     ? 'sales'
      //     : 'rents',
      //   offerDetail?.city,
      //   offerDetail?.region,
      //   offerDetail?.residential_area
      // )
    }
  }, [offerDetail]);
  useEffect(() => {
    try {
      setUrl(offerDetail.url.split("/")[2]);
      if (url === "dasinmaz-emlak") {
        setUrl("tap.az");
        setChanged(true);
      }
    } catch {}
  }, [offerDetail.url]);

  const makeBoostOfferAction = () => {
    setShowPaymentModal(true);
  };
  const makePremiumOfferAction = () => {
    setShowPremiumModal(true);
  };
  const showFullscreenVideoF = () => {
    offerDetail?.status === 1 && setShowFullScreenVideo(true);
  };
  // const premiumOffer = (offerId) => {
  //   dispatch(loadingPremiumButtonAction(true));
  //   let premiumPrice = options[language].packets["premium"].find(
  //     (option) => option.dayOrTime === selectedTarif
  //   )?.price;
  //   dispatch(
  //     makePremiumOfferApi(
  //       additionalsForPremium === "frame"
  //         ? (Number(premiumPrice) + 0.99).toFixed(2)
  //         : additionalsForPremium === "urgent"
  //         ? (Number(premiumPrice) + 1.99).toFixed(2)
  //         : Number(premiumPrice),
  //       offerId,
  //       localStorage.getItem("id"),
  //       selectedTarif,
  //       additionalsForPremium === "frame" ? true : false,
  //       additionalsForPremium === "urgent" ? true : false,
  //       window.location.pathname.split("/")[2]
  //     )
  //   );
  // };
  // const calculatePercantage = (data) => {
  //   return (
  //     ((data[data?.length - 1]?.avg_price_per_m2 -
  //       data[data?.length - 2]?.avg_price_per_m2) /
  //       data[data?.length - 2]?.avg_price_per_m2) *
  //     100
  //   ).toFixed(2);
  // };
  // const boostOffer = (offerId) => {
  //   dispatch(loadingBoostButtonAction(true));
  //   let premiumPrice = options[language].packets[typeOfReklam].find(
  //     (option) => option.dayOrTime === selectedTarif
  //   )?.price;
  //   dispatch(
  //     makeBoostOfferApi(
  //       premiumPrice,
  //       offerId,
  //       localStorage.getItem("id"),
  //       selectedTarif,
  //       window.location.pathname.split("/")[2],
  //       typeOfReklam
  //     )
  //   );
  // };
  const addInFavorite = () => {
    addOrRemoveObjectFromLocalStorage(offerDetail);
  };
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MyFavourites");
    if (existingData) {
      const dataArray = JSON.parse(existingData);
      const existingIndex = dataArray.findIndex((item) => item.id === obj.id);
      if (existingIndex !== -1) {
        dataArray.splice(existingIndex, 1);
      } else {
        dataArray.push(obj);
      }
      localStorage.setItem("MyFavourites", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MyFavourites", JSON.stringify(newDataArray));
    }
    setIsFavorite(!isFavorite);
  }
  const handleImageClick = async () => {
    dispatch(setShowModal(false));
    if (window.location.href.includes("secilmis-elanlar")) {
      navigate("/secilmis-elanlar");
    } else if (lastSearchUrl !== "/" && lastSearchUrl !== undefined) {
      await navigate("/");
      navigate(`/${lastSearchUrl}`);
    } else {
      navigate("/");
      window.location.reload();
    }
  };
  const offers = useSelector((state) => state.defaultSimilarOffers);
  const defaultOffers = Object.values(offers).map((offer, index) =>
    !offer?.type?.includes("auto") ? (
      <OfferCard key={index} offer={offer} />
    ) : (
      <OfferCardAuto key={index} offer={offer} />
    )
  );
  return (
    <div
      className="offer"
      style={{
        position: "relative",
        overflow: showPaymentModal || showPremiumModal ? "hidden" : "auto",
        borderRadius: showPaymentModal || showPremiumModal ? "0" : "20px",
      }}
    >
      {offerDetail.id ? (
        <>
          {!showFullScreen ? (
            !isMobile ? (
              <div
                style={{
                  position: "sticky",
                  top: "0",
                  width: "100%",
                  backgroundColor: "white",
                  zIndex: "2",
                  padding: "0 3%",
                }}
              >
                <img
                  onClick={() => handleImageClick()}
                  src={XIconBlack}
                  alt="close icon"
                  style={{
                    height: "40px",
                    position: "absolute",
                    right: "0px",
                    top: "8px",
                    cursor: "pointer",
                    zIndex: "1",
                  }}
                />
                <div className="road" style={{ position: "relative" }}>
                  {window.location.pathname.split("/")[2]?.includes("sale")
                    ? options[language].sale
                    : options[language].rent}
                  {" / " + options[language].cars}
                  {offerDetail?.city
                    ? " / " +
                      options[language].cities[offerDetail?.city - 1]?.label
                    : ""}
                </div>
                <hr className="roadHr" />
              </div>
            ) : (
              <>
                <div
                  style={{
                    position: "sticky",
                    top: "0",
                    width: "100%",
                    backgroundColor: "white",
                    zIndex: "2",
                    padding: "5px 3%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      width: "55px",
                      height: "20px",
                    }}
                  >
                    <img
                      src={mobileHeaderBack}
                      alt="back to offers"
                      onClick={() => handleImageClick()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      height: "36px",
                    }}
                  >
                    <img
                      src={mobileHeaderLogo}
                      alt="logo"
                      onClick={() => handleImageClick()}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignContent: "center",
                      width: "55px",
                      height: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={mobileHeaderShare}
                      alt="share"
                      onClick={handleClickToCopy}
                    />
                    <img
                      src={
                        localStorage.getItem("MyFavourites")
                          ? JSON.parse(
                              localStorage.getItem("MyFavourites")
                            )?.findIndex(
                              (item) => item.id === offerDetail.id
                            ) !== -1
                            ? mobileHeaderHeartRed
                            : mobileHeaderHeart
                          : mobileHeaderHeart
                      }
                      alt="heart"
                      onClick={() => addInFavorite()}
                    />
                  </div>
                </div>
                <div
                  className="road"
                  style={{
                    fontSize: "14px",
                    width: "95%",
                    marginLeft: "2.5%",
                    padding: "5px 0",
                  }}
                >
                  {window.location.pathname.split("/")[2]?.includes("sale")
                    ? options[language].sale
                    : options[language].rent}
                  {" / " + options[language].cars}
                  {offerDetail?.city
                    ? " / " + options[language].cities[offerDetail?.city - 1]?.label
                    : ""}
                </div>
              </>
            )
          ) : (
            <></>
          )}
          <div className="offer-content">
            {!isMobile && (
              <div className="offer-content-top">
                <div className="offer-content-title">
                  {offerDetail?.brand !== null
                    ? options['az'].auto_type_options.find(
                        (el) => el.value === offerDetail.brand.toString()
                      )?.label
                    : ""}
                  {offerDetail?.model !== null && offerDetail?.brand === null
                    ? "Auto"
                    : ""}
                  {offerDetail?.model !== null
                    ? ", " +
                      options['az'].auto_model_options.find(
                        (el) => el.value === offerDetail.model.toString()
                      )?.label
                    : ""}
                </div>
                <div className="offer-content-helper">
                  {/* <div className="offer-content-helper-heart">
                    <div
                      className="boostOfferButton"
                      onClick={() => makeBoostOfferAction()}
                    >
                      <img src={BoostIcon} alt="boost" />
                      {options[language].boostNameSmall}
                    </div>
                    <div
                      className="premiumOfferButton"
                      onClick={() => makePremiumOfferAction()}
                    >
                      <img src={PremiumIcon} alt="boost" />
                      {options[language].makePremiumNameSmall}
                    </div>
                  </div> */}
                  <div
                    className="offer-content-helper-block heartblockwidth"
                    style={{ cursor: "pointer", opacity: "1" }}
                    onClick={() => addInFavorite()}
                  >
                    {localStorage.getItem("MyFavourites") ? (
                      JSON.parse(
                        localStorage.getItem("MyFavourites")
                      )?.findIndex((item) => item.id === offerDetail.id) !==
                      -1 ? (
                        <div>
                          <FavoriteIcon size={20} sx={{ color: "#ff2b00" }} />{" "}
                          {options[language].deleteFromFavouritesNameSmall}
                        </div>
                      ) : (
                        <div>
                          <FavoriteBorderIcon size={20} />{" "}
                          {options[language].addToFavouritesNameSmall}
                        </div>
                      )
                    ) : (
                      <div>
                        <FavoriteBorderIcon size={20} />{" "}
                        {options[language].addToFavouritesNameSmall}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
            {filteredArray.length ? (
              !isMobile ? (
                <div className="offer-content-average-left-images">
                  <div className="images-left">
                    <div
                      className={`images-left-div ${
                        offerDetail?.status === 1 ? "" : "deleted"
                      }`}
                      style={{
                        position: "relative",
                      }}
                      onClick={() =>
                        offerDetail?.status === 1 && setShowFullScreen(true)
                      }
                    >
                      <img
                        src={filteredArray[0].original}
                        alt={offerDetail.title ? offerDetail.title : "elan"}
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = whiteFon;
                        }}
                      />
                      {offerDetail?.status !== 1 && (
                        <div className="isofferDeletedDiv">
                          {
                            options[language][
                              window.location.pathname
                                .split("/")[2]
                                ?.includes("sale")
                                ? "saleDeleted"
                                : "rentDeleted"
                            ]
                          }
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="images-right">
                    {filteredArray.map((image, index) => {
                      if (index <= 3 && index < filteredArray.length - 1) {
                        return (
                          <div
                            className={`images-right-div ${
                              offerDetail?.status === 1 ? "" : "deleted"
                            }`}
                            onClick={() =>
                              offerDetail?.status === 1 &&
                              setShowFullScreen(true)
                            }
                            key={index + 1}
                          >
                            <img
                              src={filteredArray[index + 1]?.original}
                              alt={
                                offerDetail.title ? offerDetail.title : "elan"
                              }
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = whiteFon;
                              }}
                            />

                            {offerDetail?.status !== 1 && (
                              <div className="isofferDeletedDiv"></div>
                            )}
                            {index === 3 && (
                              <div className="images-right-div-more">
                                <span>
                                  {window.innerWidth >= 640 &&
                                    options[language].moreSmall + " "}
                                  +{filteredArray.length - 4}
                                </span>
                              </div>
                            )}
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              ) : (
                <div className="offer-content-average-left-images mobile-images">
                  <ImageGallery
                    items={filteredArray}
                    showNav={isMobile ? false : true}
                    showThumbnails={true}
                    showPlayButton={false}
                    showFullscreenButton={false}
                    disableThumbnailSwipe={false}
                    disableSwipe={false}
                    lazyLoad={true}
                    slideDuration={isMobile ? 300 : 0}
                    disableThumbnailScroll={false}
                    onClick={(e) => setShowFullScreen(true)}
                    onImageError={(e) => {
                      e.target.onerror = null;
                      e.target.src = whiteFon;
                    }}
                    onThumbnailError={(e) => {
                      e.target.onerror = null;
                      e.target.src = whiteFon;
                    }}
                  />
                </div>
              )
            ) : (
              <></>
            )}
            <div
              className="offer-content-average"
              style={{ marginTop: isMobile && "30px" }}
            >
              <div className="offer-content-average-left">
                {offerDetail?.video !== null && (
                  <div
                    className="offer-content-watch-video"
                    style={{
                      marginBottom: isMobile && "20px",
                    }}
                    onClick={(e) => showFullscreenVideoF()}
                  >
                    {options[language].watchVideo}
                  </div>
                )}
                <hr
                  style={{ margin: isMobile ? "-17px 0 17px 0" : "20px 0" }}
                />
                {!isMobile ? (
                  <div className="offer-content-average-left-tags">
                    <div className="offer-content-average-left-tags-l">
                      <div className="offer-content-average-left-tags-l-tags">
                        <span>
                          {options[language].newOffer_offerType.replace(
                            ", ",
                            ""
                          )}
                        </span>
                        {offerDetail?.brand !== null && (
                          <span>{options[language].autoTypeName}</span>
                        )}
                        {offerDetail?.model !== null && (
                          <span>{options[language].autoModelName}</span>
                        )}
                        {offerDetail?.year !== null && (
                          <span>
                            {options[language].yearName.replace(":", "")}
                          </span>
                        )}
                        {offerDetail?.enginef !== null && (
                          <span>{options[language].autoFuelType}</span>
                        )}
                        {offerDetail?.mileage !== null && (
                          <span>{options[language].mileageName}</span>
                        )}
                        {offerDetail?.city !== null && (
                          <span>{options[language].cityName}</span>
                        )}
                        {/* <span>Binanın tipi</span> */}
                      </div>
                      <div className="offer-content-average-left-tags-l-desc">
                        <span>
                          {window.location.pathname
                            .split("/")[2]
                            ?.includes("sale")
                            ? options[language].sale
                            : options[language].rent}
                        </span>
                        {offerDetail?.brand !== null && (
                          <span>
                            {
                              options['az'].auto_type_options.find(
                                (el) =>
                                  el.value === offerDetail.brand.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail?.model !== null && (
                          <span>
                            {
                              options['az'].auto_model_options.find(
                                (el) =>
                                  el.value === offerDetail.model.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail?.year !== null && (
                          <span>{offerDetail?.year}</span>
                        )}
                        {offerDetail?.enginef !== null && (
                          <span>
                            {
                              options[language].auto_fuel_type_options.find(
                                (el) =>
                                  el.value === offerDetail.enginef.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail?.mileage !== null && (
                          <span>
                            {offerDetail?.mileage.toLocaleString("ru")}{" "}
                            {options[language].km}
                          </span>
                        )}
                        {offerDetail?.city !== null && (
                          <span>
                            {
                              options[language].cities[offerDetail?.city - 1]
                                ?.label
                            }
                          </span>
                        )}
                        {/* <span>Yeni tikili</span> */}
                      </div>
                    </div>
                    <div className="offer-content-average-left-tags-r">
                      <div className="offer-content-average-left-tags-r-tags">
                        {offerDetail?.transmission !== null && (
                          <span>{options[language].autoTransmissionType}</span>
                        )}
                        {offerDetail?.enginef && (
                          <span>{options[language].autoFuelType}</span>
                        )}
                        {offerDetail?.body_type !== null && (
                          <span>{options[language].autoBodyType}</span>
                        )}
                        {offerDetail.enginel && (
                          <span>
                            {options[language].autoEngineVolume}&sup3;{")"}
                          </span>
                        )}
                        {offerDetail.color && (
                          <span>{options[language].autoColorName}</span>
                        )}
                        {offerDetail.drive && (
                          <span>{options[language].autoDriveType}</span>
                        )}
                      </div>
                      <div className="offer-content-average-left-tags-r-desc">
                        {offerDetail?.transmission !== null && (
                          <span>
                            {
                              options[
                                language
                              ].auto_transmission_type_options.find(
                                (el) =>
                                  el.value ===
                                  offerDetail.transmission.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail?.enginef && (
                          <span>
                            {
                              options[language].auto_fuel_type_options.find(
                                (el) =>
                                  el.value === offerDetail.enginef.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail?.body_type !== null && (
                          <span>
                            {
                              options[language].auto_body_type_options.find(
                                (el) =>
                                  el.value === offerDetail.body_type.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail.enginel !== null && (
                          <span>
                            {offerDetail.enginel?.toString()}{" "}
                            {options[language].l}
                          </span>
                        )}
                        {offerDetail.color && (
                          <span>
                            {
                              options[language].auto_color_type_options.find(
                                (el) =>
                                  el.value === offerDetail.color.toString()
                              )?.label
                            }
                          </span>
                        )}
                        {offerDetail.drive && (
                          <span>
                            {
                              options[language].auto_drive_type_options.find(
                                (el) =>
                                  el.value === offerDetail.drive.toString()
                              )?.label
                            }
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="offer-content-average-left-tags">
                    <div
                      className="offer-content-average-left-tags-l"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0px",
                        marginTop: "-10px",
                      }}
                    >
                      <span style={{ fontSize: "24px", fontWeight: "600" }}>
                        {offerDetail.price
                          ? offerDetail.price.toLocaleString("ru") + " AZN"
                          : options[language].priceByAgreement}
                      </span>
                    </div>
                    <div
                      className="offer-content-average-left-tags-l"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        gap: "0",
                      }}
                    >
                      {(offerDetail?.model !== null ||
                        offerDetail?.brand !== null ||
                        offerDetail?.year !== null) && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {offerDetail?.brand !== null
                              ? options['az'].auto_type_options.find(
                                  (el) =>
                                    el.value === offerDetail.brand.toString()
                                )?.label
                              : offerDetail?.model !== null
                              ? options['az'].auto_model_options.find(
                                  (el) =>
                                    el.value === offerDetail.model.toString()
                                )?.label
                              : "Auto"}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {offerDetail?.brand !== null &&
                            offerDetail?.model !== null
                              ? options['az'].auto_model_options.find(
                                  (el) =>
                                    el.value === offerDetail.model.toString()
                                )?.label
                              : ""}{" "}
                            {offerDetail?.year !== null && offerDetail?.year}
                          </span>
                        </div>
                      )}
                      {offerDetail?.enginef && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            minWidth: "62px",
                          }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {options[language].auto_fuel_type_options.find(
                                (el) =>
                                  el.value === offerDetail.enginef.toString()
                              )?.label}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {options[language].autoFuelType?.toLowerCase()}
                          </span>
                        </div>
                      )}
                      {offerDetail?.mileage !== null && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                            {offerDetail?.mileage.toLocaleString("ru")}{" "}
                            {options[language].km}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {options[language].mileageName.replace(":",'')
                              .toLowerCase()}
                          </span>
                        </div>
                      )}
                      {(offerDetail.enginel) && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span style={{ fontSize: "18px", fontWeight: "500" }}>
                          {offerDetail.enginel?.toString()}{" "}
                          {options[language].l}
                          </span>
                          <span style={{ fontSize: "14px", marginTop: "-5px" }}>
                            {options[language].autoEngineVolume}&sup3;{")"}
                          </span>
                        </div>
                      )}
                    </div>
                    {/* <hr
                      style={{ margin: isMobile ? "20px 0 10px 0" : "20px 0" }}
                    />
                    <div
                      className="offer-content-helper-heart"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="boostOfferButton"
                        onClick={() => makeBoostOfferAction()}
                      >
                        <img src={BoostIcon} alt="boost" />
                        {options[language].boostNameSmall}
                      </div>
                      <div
                        className="premiumOfferButton"
                        onClick={() => makePremiumOfferAction()}
                      >
                        <img src={PremiumIcon} alt="boost" />
                        {options[language].makePremiumNameSmall}
                      </div>
                    </div> */}
                    <hr
                      style={{ margin: isMobile ? "10px 0 10px 0" : "20px 0" }}
                    />
                    <div
                      className="offer-content-average-left-description"
                      style={{ margin: "0 0 10px 0" }}
                    >
                      {offerDetail?.title ? (
                        <span style={{ fontSize: "18px" }}>
                          {offerDetail?.title}
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
                {!isMobile && <hr style={{ margin: "20px 0" }} />}
                <div
                  className="offer-content-average-left-description"
                  style={{ fontSize: isMobile ? "14px" : "17px" }}
                >
                  {offerDetail?.details ? (
                    <span>{offerDetail?.details}</span>
                  ) : (
                    <></>
                  )}
                </div>
                <hr />
                <div className="offer-content-average-left-statistica">
                  <span>
                    {changed ? (
                      <a
                        href={"https://tap.az" + offerDetail?.url}
                        target="_blank"
                        rel="nofollow"
                        className="tap ataglength"
                        style={{ textDecoration: "underline" }}
                      >
                        {url}
                      </a>
                    ) : offerDetail?.url !== null ? (
                      <a
                        href={offerDetail?.url}
                        rel="nofollow"
                        className={`${
                          offerDetail.url?.split("/")[2].split(".")[0]
                        } ataglength`}
                        style={{ textDecoration: "underline" }}
                        target="_blank"
                      >
                        {url}
                      </a>
                    ) : (
                      <></>
                    )}
                  </span>
                  <span>
                    {offerDetail?.url === null && (
                      <a
                        href={window.location.href}
                        target="_blank"
                        className="evbu ataglength"
                        style={{ textDecoration: "underline" }}
                      >
                        ebaz.az
                      </a>
                    )}
                  </span>
                  <span>
                    {new Date(offerDetail?.post_date).toLocaleString("ru-Ru", {
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: false,
                    })}
                  </span>
                  {/* <span> - </span>
                <span>Baxışların sayı: 47</span> */}
                </div>
                <hr />
                {offerDetail &&
                  offerDetail.location &&
                  offerDetail.location.length >= 2 && (
                    <>
                      <div className="offer-content-average-left-map">
                        <GoogleMapsOnePoint
                          latLng={[
                            offerDetail.location[0],
                            offerDetail.location[1],
                          ]}
                        />
                      </div>
                      <hr />
                    </>
                  )}
                {/* PREDICT GRAPH */}
                {/* {predictData?.length >= 2 && (
                  <>
                    <div className='offer-content-average-left-map'>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between'
                        }}
                      >
                        <span style={{ fontSize: '18px', fontWeight: '500' }}>
                        {options[language].analytics}
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end'
                          }}
                        >
                          <span style={{
                            fontSize: '20px', fontWeight: '500'
                          }}>
                            <FaDownLong style = {{transform: calculatePercantage(predictData) >= 0 ? 'rotate(180deg)' : 'rotate(0deg)', marginBottom: calculatePercantage(predictData) >= 0 ? '5px' : '0' }} color={calculatePercantage(predictData) >= 0 ? 'green' : 'red'}/>{calculatePercantage(predictData).replace('-', '')}%
                          </span>
                          <span style={{
                            fontSize: '16px', fontWeight: '500'
                          }}>
                            {options[language].predicted}{' '}
                            {calculatePercantage(predictData) >= 0
                              ? options[language].predictedHigh
                              : options[language].predictedLow}
                          </span>
                        </div>
                      </div>
                      <Predict data={predictData} />
                    </div>
                    <hr />
                  </>
                )} */}
              </div>
              <div className="offer-content-average-right">
                <div className="offer-content-average-right-top">
                  {offerDetail?.status === 1 ? (
                    <>
                      <div className="offer-content-average-right-price">
                        {offerDetail.price
                          ? offerDetail.price.toLocaleString("ru") + " AZN"
                          : options[language].priceByAgreement}
                      </div>
                      <hr />
                      <div className="offer-content-average-right-user">
                        <div>{offerDetail.user}</div>
                        <AccountCircleIcon
                          color="primary"
                          sx={{ fontSize: 40 }}
                        />
                      </div>
                      <hr />
                      {offerDetail.phone ? (
                        <div
                          className="offer-content-average-right-phone-number"
                          onClick={() => {
                            dispatch(
                              clickOnPhoneNum(
                                window.location.pathname.split("/")[2],
                                window.location.pathname.split("/")[3]
                              )
                            );
                            setPhoneNumClicked(true);
                          }}
                        >
                          <div>{options[language].phoneNumberName}</div>
                          {offerDetail.phone.split(",").map((number) => (
                            <a href={`tel:${number}`} key={number}>
                              <div style={{ display: "flex", gap: "5px" }}>
                                <CallIcon />
                                {/* {offerDetail.phone && `+994(${offerDetail.phone?.replace(/\D/g, '').slice(3, 5)})${offerDetail.phone?.replace(/\D/g, '').slice(5, 8)}-${offerDetail.phone?.replace(/\D/g, '').slice(8, 10)}-${offerDetail.phone?.replace(/\D/g, '').slice(10, 12)}`} */}
                                {phoneNumClicked
                                  ? number
                                  : number.replace(/.{0,4}$/, "****")}
                              </div>
                            </a>
                          ))}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <>
                      {offerDetail.phone ? (
                        <div className="offer-content-average-right-phone-number">
                          <div>
                            {
                              options[language][
                                window.location.pathname
                                  .split("/")[2]
                                  ?.includes("sale")
                                  ? "saleDeleted"
                                  : "rentDeleted"
                              ]
                            }
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
                <div className="offer-content-average-right-share-part">
                  <IconButton onClick={handleClickToCopy} color="inherit">
                    <ShareIcon />
                    {/* <CopyAll/> */}
                  </IconButton>
                  <Snackbar
                    sx={{ paddingTop: "55px" }}
                    message={options[language].linkCopied}
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    autoHideDuration={1500}
                    onClose={() => setOpen(false)}
                    open={open}
                  />
                  <WhatsappShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <WhatsappIcon size={32} round={true}></WhatsappIcon>
                  </WhatsappShareButton>
                  <TelegramShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <TelegramIcon size={32} round={true}></TelegramIcon>
                  </TelegramShareButton>
                  <FacebookShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <FacebookIcon size={32} round={true}></FacebookIcon>
                  </FacebookShareButton>
                  <EmailShareButton
                    title={
                      offerDetail?.title
                        ? offerDetail?.title
                        : options[language].offersFound
                    }
                    description="https://ebaz.az"
                    url={window.location
                      .toString()
                      .replace("secilmis-elanlar", "elan")}
                  >
                    <EmailIcon size={32} round={true}></EmailIcon>
                  </EmailShareButton>
                </div>
              </div>
            </div>
          </div>
          {defaultOffers.length !== 0 ? (
            <div
              className="offer-content-bottom"
              style={{
                backgroundColor: "#f5f5f5",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                className="offer-content-average-left-similar"
                style={{
                  backgroundColor: "#f5f5f5",
                  width: "95%",
                  maxWidth: "1300px",
                }}
              >
                <span>{options[language].similarOffers}</span>
              </div>
            </div>
          ) : (
            <></>
          )}
          <div
            className="offers-content-cards"
            style={{ backgroundColor: "#f5f5f5", maxWidth: "none" }}
          >
            {defaultOffers}
          </div>
        </>
      ) : (
        <Loading />
      )}
      {showFullScreen && offerDetail.id && (
        <div className="fullscreenImage">
          <div className="fullscreenImage-header" style={{ zIndex: "5" }}>
            {window.innerWidth < 700 && (
              <div className="fullscreenImage-header-left">
                <span style={{ fontSize: "16px" }}>
                  {offerDetail?.brand !== null
                    ? options['az'].auto_type_options.find(
                        (el) => el.value === offerDetail.brand.toString()
                      )?.label
                    : ""}
                  {offerDetail?.model !== null && offerDetail?.brand === null
                    ? "Auto"
                    : ""}
                  {offerDetail?.model !== null
                    ? ", " +
                      options['az'].auto_model_options.find(
                        (el) => el.value === offerDetail.model.toString()
                      )?.label
                    : ""}
                </span>
                <hr />
                <span style={{ fontSize: "18px", marginTop: "5px" }}>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            {window.innerWidth >= 700 && (
              <div className="fullscreenImage-header-left">
                <span>
                  {offerDetail?.brand !== null
                    ? options['az'].auto_type_options.find(
                        (el) => el.value === offerDetail.brand.toString()
                      )?.label
                    : ""}
                  {offerDetail?.model !== null && offerDetail?.brand === null
                    ? "Auto"
                    : ""}
                  {offerDetail?.model !== null
                    ? ", " +
                      options['az'].auto_model_options.find(
                        (el) => el.value === offerDetail.model.toString()
                      )?.label
                    : ""}
                </span>
                <hr />
                <span>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            <div className="fullscreenImage-header-right">
              {window.innerWidth >= 700 && (
                <>
                  {" "}
                  {offerDetail.phone && (
                    <div
                      onClick={() => {
                        dispatch(
                          clickOnPhoneNum(
                            window.location.pathname.split("/")[2],
                            window.location.pathname.split("/")[3]
                          )
                        );
                        setPhoneNumClicked(true);
                      }}
                      style={{
                        backgroundColor: "#0089CF",
                        padding: "8px",
                        borderRadius: "5px",
                      }}
                    >
                      {offerDetail.phone.split(",").map((number) => {
                        return (
                          <a href={`tel:${number}`} key={number}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <CallIcon />
                              {/* {offerDetail.phone && `+994(${offerDetail.phone?.replace(/\D/g, '').slice(3, 5)})${offerDetail.phone?.replace(/\D/g, '').slice(5, 8)}-${offerDetail.phone?.replace(/\D/g, '').slice(8, 10)}-${offerDetail.phone?.replace(/\D/g, '').slice(10, 12)}`} */}
                              {phoneNumClicked
                                ? number
                                : number.replace(/.{0,4}$/, "****")}
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  )}
                  <span
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="offer-content-helper-block heartblockwidth2"
                      style={{ cursor: "pointer" }}
                      onClick={() => addInFavorite()}
                    >
                      {localStorage.getItem("MyFavourites") ? (
                        JSON.parse(
                          localStorage.getItem("MyFavourites")
                        )?.findIndex((item) => item.id === offerDetail.id) !==
                        -1 ? (
                          <div>
                            <FavoriteIcon size={20} sx={{ color: "#ff2b00" }} />{" "}
                            {options[language].deleteFromFavouritesNameSmall}
                          </div>
                        ) : (
                          <div>
                            <FavoriteBorderIcon size={20} />{" "}
                            {options[language].addToFavouritesNameSmall}
                          </div>
                        )
                      ) : (
                        <div>
                          <FavoriteBorderIcon size={20} />{" "}
                          {options[language].addToFavouritesNameSmall}
                        </div>
                      )}
                    </div>
                  </span>
                </>
              )}
              <span
                style={{
                  cursor: "pointer",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  top: isMobile ? "-25px" : "0px",
                  right: isMobile ? "5px" : "20px",
                }}
                onClick={() => setShowFullScreen(false)}
              >
                <img src={XIcon} alt="x icon" style={{ height: "40px" }} />
              </span>
            </div>
          </div>
          {filteredArray.length ? (
            <ImageGallery
              items={filteredArray}
              showNav={isMobile ? false : true}
              showThumbnails={true}
              showPlayButton={false}
              showFullscreenButton={false}
              disableThumbnailSwipe={false}
              disableSwipe={false}
              lazyLoad={true}
              slideDuration={isMobile ? 300 : 0}
              disableThumbnailScroll={false}
              onClick={(e) => closeFullscreen(e)}
              onImageError={(e) => {
                e.target.onerror = null;
                e.target.src = whiteFon;
              }}
              onThumbnailError={(e) => {
                e.target.onerror = null;
                e.target.src = whiteFon;
              }}
            />
          ) : (
            <></>
          )}
        </div>
      )}
      {showFullScreenVideo && offerDetail.id && (
        <div className="fullscreenImage">
          <div className="fullscreenImage-header" style={{ zIndex: "5" }}>
            {window.innerWidth < 700 && (
              <div className="fullscreenImage-header-left">
                <span style={{ fontSize: "16px" }}>
                  {offerDetail?.brand !== null
                    ? options['az'].auto_type_options.find(
                        (el) => el.value === offerDetail.brand.toString()
                      )?.label
                    : ""}
                  {offerDetail?.model !== null && offerDetail?.brand === null
                    ? "Auto"
                    : ""}
                  {offerDetail?.model !== null
                    ? ", " +
                      options['az'].auto_model_options.find(
                        (el) => el.value === offerDetail.model.toString()
                      )?.label
                    : ""}
                </span>
                <hr />
                <span style={{ fontSize: "18px", marginTop: "5px" }}>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            {window.innerWidth >= 700 && (
              <div className="fullscreenImage-header-left">
                <span>
                  {offerDetail?.brand !== null
                    ? options['az'].auto_type_options.find(
                        (el) => el.value === offerDetail.brand.toString()
                      )?.label
                    : ""}
                  {offerDetail?.model !== null && offerDetail?.brand === null
                    ? "Auto"
                    : ""}
                  {offerDetail?.model !== null
                    ? ", " +
                      options['az'].auto_model_options.find(
                        (el) => el.value === offerDetail.model.toString()
                      )?.label
                    : ""}
                </span>
                <hr />
                <span>
                  {offerDetail.price
                    ? offerDetail.price.toLocaleString("ru") + " AZN"
                    : options[language].priceByAgreement}
                </span>
              </div>
            )}
            <div className="fullscreenImage-header-right">
              {window.innerWidth >= 700 && (
                <>
                  {" "}
                  {offerDetail.phone && (
                    <div
                      onClick={() => {
                        dispatch(
                          clickOnPhoneNum(
                            window.location.pathname.split("/")[2],
                            window.location.pathname.split("/")[3]
                          )
                        );
                        setPhoneNumClicked(true);
                      }}
                      style={{
                        backgroundColor: "#0089CF",
                        padding: "8px",
                        borderRadius: "5px",
                      }}
                    >
                      {offerDetail.phone.split(",").map((number) => {
                        return (
                          <a href={`tel:${number}`} key={number}>
                            <div style={{ display: "flex", gap: "5px" }}>
                              <CallIcon />
                              {/* {offerDetail.phone && `+994(${offerDetail.phone?.replace(/\D/g, '').slice(3, 5)})${offerDetail.phone?.replace(/\D/g, '').slice(5, 8)}-${offerDetail.phone?.replace(/\D/g, '').slice(8, 10)}-${offerDetail.phone?.replace(/\D/g, '').slice(10, 12)}`} */}
                              {phoneNumClicked
                                ? number
                                : number.replace(/.{0,4}$/, "****")}
                            </div>
                          </a>
                        );
                      })}
                    </div>
                  )}
                  <span
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="offer-content-helper-block heartblockwidth2"
                      style={{ cursor: "pointer" }}
                      onClick={() => addInFavorite()}
                    >
                      {localStorage.getItem("MyFavourites") ? (
                        JSON.parse(
                          localStorage.getItem("MyFavourites")
                        )?.findIndex((item) => item.id === offerDetail.id) !==
                        -1 ? (
                          <div>
                            <FavoriteIcon size={20} sx={{ color: "#ff2b00" }} />{" "}
                            {options[language].deleteFromFavouritesNameSmall}
                          </div>
                        ) : (
                          <div>
                            <FavoriteBorderIcon size={20} />{" "}
                            {options[language].addToFavouritesNameSmall}
                          </div>
                        )
                      ) : (
                        <div>
                          <FavoriteBorderIcon size={20} />{" "}
                          {options[language].addToFavouritesNameSmall}
                        </div>
                      )}
                    </div>
                  </span>
                </>
              )}
              <span
                style={{
                  cursor: "pointer",
                  height: "100%",
                  position: "absolute",
                  display: "flex",
                  alignItems: "center",
                  top: isMobile ? "-25px" : "0px",
                  right: isMobile ? "5px" : "20px",
                }}
                onClick={() => setShowFullScreenVideo(false)}
              >
                <img src={XIcon} alt="x icon" style={{ height: "40px" }} />
              </span>
            </div>
          </div>
          {offerDetail?.video !== null ? (
            <div
              className="offer-video-part"
              onClick={(e) =>
                e.target.className === "offer-video-part" &&
                setShowFullScreenVideo(false)
              }
            >
              <video className="offer-video" controls autoPlay>
                <source src={offerDetail?.video} type={"video/mp4"} />
                Your browser does not support the video tag.
              </video>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
export default OfferAuto;
