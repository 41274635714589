import React, { useLayoutEffect } from "react";
import "./FavouriteOffers.css";
import OfferCard from "../../Components/Offers/OfferCard";
import { useEffect } from "react";
import { useState } from "react";
import { options } from "../../constants";
import { Empty } from "antd";
import Header from "../../Components/Header/Header";
import { useNavigate } from "react-router-dom";
import Offer from "../Offer/Offer";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import MiniBanner from "../../Components/MiniBanner/MiniBanner";
import OfferCardAuto from "../../Components/Offers/OfferCardAuto";
import OfferAuto from "../Offer/OfferAuto";

const FavouriteOffers = () => {
  const navigate = useNavigate();
  const [offers, setOffers] = useState(localStorage.getItem("MyFavourites"));
  const language = useSelector((state) => state.language);
  const [defaultOffers, setDefaultOffers] = useState();
  const [count, setCount] = useState();
  const [showModal, setShowModal] = useState(false);
  useLayoutEffect(() => {
    document.title = options[language].allFavouriteOffersTitle;
  }, []);
  useEffect(() => {
    setOffers(localStorage.getItem("MyFavourites"));
    if (offers) {
      setCount(JSON.parse(offers).length);
      setDefaultOffers(
        Object.values(JSON.parse(offers)).map((offer, index) =>
          !offer?.type?.includes("auto") ? (
            <OfferCard key={index} offer={offer} />
          ) : (
            <OfferCardAuto key={index} offer={offer} />
          )
        )
      );
    } else {
      setCount(0);
    }
  }, [offers]);
  const closeModal = (e) => {
    if (e.target.classList[0] === "offerdetailsModal") {
      setShowModal(false);

      navigate("/secilmis-elanlar");
    }
  };
  useEffect(() => {
    if (window.location.pathname !== "/secilmis-elanlar") {
      setShowModal(true);
    } else {
      setShowModal(false);
    }
  }, [window.location.pathname]);
  return (
    <>
      <MiniBanner />
      <Header />
      <div className="favouriteOffers">
        <div className="favouriteOffers-content">
          <div className="favouriteOffers-content-first-row">
            {options[language].favouriteOffersCount} {count}
          </div>
          <div className="offers-content-cards">
            {count !== 0 ? defaultOffers : <></>}
          </div>
          {count !== 0 ? (
            <></>
          ) : (
            <div style={{ width: "100%", textAlign: "center" }}>
              <Empty description={options[language].notFavouritesOffers} />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  backgroundColor: "#0089CF",
                  width: "280px",
                }}
                onClick={() => navigate("/")}
              >
                {options[language].seeAllOffers}
              </Button>
            </div>
          )}
        </div>
        {showModal && (
          <div className="offerdetailsModal" onClick={(e) => closeModal(e)}>
            {window.location.href?.includes("autosales") ||
            window.location.href?.includes("autorents") ? (
              <OfferAuto />
            ) : (
              <Offer />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default FavouriteOffers;
