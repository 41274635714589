import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "./Filter.css";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import Checkbox from "@mui/material/Checkbox";
import { grey } from "@mui/material/colors";
import History from "../../images/history.png";
import CircularProgress from "@mui/material/CircularProgress";
import Detail from "../../images/detail.png";
import { options } from "../../constants";
import Down from "../../images/down.png";
import { useSearchParams } from "react-router-dom";
import {
  changeFilter,
  changeSearchRow,
  getPage,
  requestOffersWithLocations,
  searchOffer,
  setShouldRunSecondEffect,
  getInitial,
  getCountOfOffers,
  generateUrlAction,
  setAdsType,
} from "../../redux/action";
const styles = {
  // #0089CF !important
  valueContainer: (base) => ({
    ...base,
    maxHeight: 36,
    overflowY: "auto",
    borderRadius: "10px",
  }),
  control: (styles, state) => ({
    ...styles,
    maxHeight: 36,
    overflowY: "auto",
    borderRadius: state.isFocused ? "4px" : "10px",
    cursor: "pointer",
    boxShadow: "none",
    borderColor: state.isFocused ? "#0089CF" : "#CED4DA",
    "&:hover": {
      borderColor: state.isFocused ? "#0089CF" : "#CED4DA",
    },
  }),
};
const label = { inputProps: { "aria-label": "Checkbox demo" } };

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  if (isFocused) bg = "#eee";
  if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <input
        type="checkbox"
        className="checkBoxSelect"
        checked={isSelected}
        style={{ marginRight: "5px" }}
      />
      {children}
    </components.Option>
  );
};

const Filter = () => {
  const [clickedEnter, setClickedEnter] = useState(0);
  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter") {
        event.preventDefault();
        let count = clickedEnter + 1;
        setClickedEnter(count);
      }
    };
    document.addEventListener("keyup", keyDownHandler);
    return () => {
      document.removeEventListener("keyup", keyDownHandler);
    };
  });
  const myElementRef = useRef(null);
  const dispatch = useDispatch();
  const offerTypeAction = useSelector((state) => state.offerType);
  const loadingOfCount = useSelector((state) => state.loadingOfCount);
  const countOfOffers = useSelector((state) => state.countOfOffers);
  const filter = useSelector((state) => state.filter);
  const language = useSelector((state) => state.language);
  const fTypeReducer = useSelector((state) => state.flatType);
  const [flatType, setFlatType] = useState(fTypeReducer);
  const [offerType, setOfferType] = useState("s");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [minPricePerM2, setMinPricePerM2] = useState("");
  const [maxPricePerM2, setMaxPricePerM2] = useState("");
  const [withGoodsTrue, SetWithGoodsTrue] = useState(false);
  const [withGoodsFalse, SetWithGoodsFalse] = useState(false);
  const [withCredit, SetWithCredit] = useState(false);
  const [documentTrue, setDocumentTrue] = useState(false);
  const [documentFalse, setDocumentFalse] = useState(false);
  const [repairTrue, setRepairTrue] = useState(false);
  const [repairFalse, setRepairFalse] = useState(false);
  const [lastRequest, setLastRequest] = useState("");
  const [showComplexBtn, setShowComplexBtn] = useState(true);
  const [province, setProvince] = useState([]);
  const [province_opt, setProvince_opt] = useState(
    options[language].region_options
  );
  const [resident_opt, setResident_opt] = useState(
    options[language].resident_options
  );
  const [station_opt, setStation_opt] = useState(
    options[language].station_options
  );
  const [residents, setResidents] = useState([]);
  const [stations, setStations] = useState([]);
  const [target_opt, setTarget_opt] = useState(
    options[language].target_options
  );
  const [targets, setTargets] = useState([]);
  const [areaMin, setAreaMin] = useState("");
  const [areaMax, setAreaMax] = useState("");
  const [floorMin, setFloorMin] = useState("");
  const [floorMax, setFloorMax] = useState("");
  const countofPages = useSelector((state) => state.countOfPages);
  const page = useSelector((state) => state.page);
  const searchBool = useSelector((state) => state.search);
  const [showAditional, setShowAditional] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [selectedCountries, setSelectedCountries] = useState(null);
  const [selectedCities, setSelectedCities] = useState(null);
  const [city, setCity] = useState([]);
  const [sortedCities, setSortedCities] = useState(options[language].cities);
  const [sites, setSites] = useState([]);
  const [site_opt, setSiteOpt] = useState(options[language].site_options);
  const [rooms, setRooms] = useState([]);
  const [rotateIcon, setRotateIcon] = useState(false);
  const [rotateIcon2, setRotateIcon2] = useState(false);
  const [searchHistoryCheck, setSearchHistoryCheck] = useState(false);
  const showMap = useSelector((state) => state.showOrCloseMap);
  const [offers, setOffers] = useState(localStorage.getItem("MySearchs"));
  const [historySpan, setHistorySpan] = useState();
  const [count, setCount] = useState();
  const [isSearchByHistory, setIsSearchByHistory] = useState(false);
  const [isSearchByHistoryData, setIsSearchByHistoryData] = useState();
  const [queryParameters] = useSearchParams();
  const shouldRunSecondEffect = useSelector(
    (state) => state.shouldRunSecondEffect
  );
  useEffect(() => {
    if (fTypeReducer !== flatType) {
      setFlatType(fTypeReducer);
      searchButton(fTypeReducer);
    }
  }, [fTypeReducer]);
  const changeParametrs = () => {
    if (queryParameters.get("satis") !== null) {
      setOfferType("s");
    }
    if (queryParameters.get("gunluk") !== null) {
      setOfferType("g");
    }
    if (queryParameters.get("kiraye") !== null) {
      setOfferType("k");
    }
    if (queryParameters.get("elan") !== null) {
      queryParameters.get("elan") === "menzil"
        ? setFlatType(options[language].flat_type_options[0])
        : queryParameters.get("elan") === "heyet-evi-bag-villa"
        ? setFlatType(options[language].flat_type_options[1])
        : queryParameters.get("elan") === "ofis"
        ? setFlatType(options[language].flat_type_options[2])
        : queryParameters.get("elan") === "qaraj"
        ? setFlatType(options[language].flat_type_options[3])
        : queryParameters.get("elan") === "torpaq"
        ? setFlatType(options[language].flat_type_options[4])
        : queryParameters.get("elan") === "obyekt"
        ? setFlatType(options[language].flat_type_options[5])
        : setFlatType(options[language].flat_type_options[6]);
    }
    if (queryParameters.get("seher") !== null) {
      const citiesArray = queryParameters.get("seher").split("-");
      if (citiesArray.length <= 3) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].cities.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setCity(validResultArray);
      }
      if (citiesArray.length > 3) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].cities.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setCity(validResultArray);
      }
    }
    if (queryParameters.get("rayon") !== null) {
      const citiesArray = queryParameters.get("rayon").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].region_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setProvince(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].region_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setProvince(validResultArray);
      }
    }
    if (queryParameters.get("qesebe") !== null) {
      const citiesArray = queryParameters.get("qesebe").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].resident_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setResidents(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].resident_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setResidents(validResultArray);
      }
    }
    if (queryParameters.get("otaqlar") !== null) {
      const roomsArray = queryParameters.get("otaqlar").split("-");
      const resultArray = roomsArray.map((city, index) => {
        const matchingLabel = options[language].room_options.find(
          (label) => label.value === city
        );
        return matchingLabel ? { ...matchingLabel, order: index } : null;
      });
      const validResultArray = resultArray.filter((result) => result !== null);
      setRooms(validResultArray);
    }
    if (queryParameters.get("min-qiymet") !== null) {
      const mprice = queryParameters.get("min-qiymet");
      setMinPrice(mprice);
    }
    if (queryParameters.get("max-qiymet") !== null) {
      const mprice = queryParameters.get("max-qiymet");
      setMaxPrice(mprice);
    }
    if (queryParameters.get("min-qiymet-m2") !== null) {
      const mprice = queryParameters.get("min-qiymet-m2");
      setMinPricePerM2(mprice);
    }
    if (queryParameters.get("max-qiymet-m2") !== null) {
      const mprice = queryParameters.get("max-qiymet-m2");
      setMaxPricePerM2(mprice);
    }
    if (queryParameters.get("min-sahe") !== null) {
      const mprice = queryParameters.get("min-sahe");
      setAreaMin(mprice);
    }
    if (queryParameters.get("max-sahe") !== null) {
      const mprice = queryParameters.get("max-sahe");
      setAreaMax(mprice);
    }
    if (queryParameters.get("min-mertebe") !== null) {
      const mprice = queryParameters.get("min-mertebe");
      setFloorMin(mprice);
    }
    if (queryParameters.get("max-mertebe") !== null) {
      const mprice = queryParameters.get("max-mertebe");
      setFloorMax(mprice);
    }
    if (queryParameters.get("metro") !== null) {
      const citiesArray = queryParameters.get("metro").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].station_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setStations(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].station_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setStations(validResultArray);
      }
    }
    if (queryParameters.get("nisangah") !== null) {
      const citiesArray = queryParameters.get("nisangah").split("-");
      if (citiesArray.length <= 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].target_options.find(
            (label) => label.label === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setTargets(validResultArray);
      }
      if (citiesArray.length > 1) {
        const resultArray = citiesArray.map((city, index) => {
          const matchingLabel = options[language].target_options.find(
            (label) => label.value === city
          );
          return matchingLabel ? { ...matchingLabel, order: index } : null;
        });
        const validResultArray = resultArray.filter(
          (result) => result !== null
        );
        setTargets(validResultArray);
      }
    }
    if (queryParameters.get("esyali") !== null) {
      SetWithGoodsTrue(true);
    }
    if (queryParameters.get("esyasiz") !== null) {
      SetWithGoodsFalse(true);
    }
    if (queryParameters.get("temirli") !== null) {
      setRepairTrue(true);
    }
    if (queryParameters.get("temirsiz") !== null) {
      setRepairFalse(true);
    }
    if (queryParameters.get("senedli") !== null) {
      setDocumentTrue(true);
    }
    if (queryParameters.get("senedsiz") !== null) {
      setDocumentFalse(true);
    }
    if (queryParameters.get("sayt") !== null) {
      const siteArray = queryParameters.get("sayt").split("-");
      const resultArray = siteArray.map((city, index) => {
        const matchingLabel = options[language].site_options.find(
          (label) => label.value === city
        );
        return matchingLabel ? { ...matchingLabel, order: index } : null;
      });
      const validResultArray = resultArray.filter((result) => result !== null);
      setSites(validResultArray);
    }
    if (queryParameters.get("ipoteka") !== null) {
      SetWithCredit(true);
    }
    if (queryParameters.get("page") !== null) {
      dispatch(getPage(Number(queryParameters.get("page"))));
    }
    if (queryParameters.get("filter") !== null) {
      dispatch(
        changeFilter(
          queryParameters.get("filter") === "dn"
            ? options[language].filter_options[0]
            : queryParameters.get("filter") === "do"
            ? options[language].filter_options[1]
            : queryParameters.get("filter") === "pl"
            ? options[language].filter_options[2]
            : queryParameters.get("filter") === "ph"
            ? options[language].filter_options[3]
            : options[language].filter_options[0]
        )
      );
    }
  };
  const generateUrlAction = (
    flatType,
    offerType,
    page,
    filter,
    city,
    rooms,
    minPrice,
    maxPrice,
    areaMin,
    areaMax,
    province,
    residents,
    stations,
    targets,
    floorMin,
    floorMax,
    sites,
    withGoodsTrue,
    withGoodsFalse,
    withCredit,
    repairTrue,
    repairFalse,
    documentTrue,
    documentFalse,
    minPricePerM2,
    maxPricePerM2
  ) => {
    const url = new URL(window.location.href);

    let fType =
      flatType === 1 || flatType?.value === "1"
        ? "flat"
        : flatType?.value === "2"
        ? "country"
        : flatType?.value === "3"
        ? "office"
        : flatType?.value === "4"
        ? "garage"
        : flatType?.value === "5"
        ? "land"
        : flatType?.value === "6"
        ? "commercial"
        : "flat";

    let oType =
      offerType === "s" ? "sales" : offerType === "g" ? "rents" : "rents";

    const stationValues =
      stations?.map((station) => station.value - 1).join(",") ?? "";
    const roomValues = rooms?.map((room) => room.value).join(",") ?? "";
    const siteValues = sites?.map((site) => site?.value).join(",") ?? "";
    const residentValues =
      residents?.map((resident) => resident.value - 1).join(",") ?? "";
    const targetValues =
      targets?.map((target) => target.value - 1).join(",") ?? "";
    const regionValues =
      province
        ?.filter((value, index, array) => array.indexOf(value) === index)
        ?.map((region) => region.value)
        .join(",") ?? "";
    const cityValues = city?.map((city) => city.value).join(",") ?? "";

    if (!window.location.href.includes("/elan/")) {
      offerType
        ? url.searchParams.set(
            offerType === "s"
              ? "satis"
              : offerType === "g"
              ? "gunluk"
              : "kiraye",
            ""
          )
        : url.searchParams.delete("satis") &&
          url.searchParams.delete("kiraye") &&
          url.searchParams.delete("gunluk");

      flatType
        ? url.searchParams.set(
            "elan",
            flatType === 1 || flatType?.value === "1"
              ? "menzil"
              : flatType?.value === "2"
              ? "heyet-evi-bag-villa"
              : flatType?.value === "3"
              ? "ofis"
              : flatType?.value === "4"
              ? "qaraj"
              : flatType?.value === "5"
              ? "torpaq"
              : flatType?.value === "6"
              ? "obyekt"
              : "menzil"
          )
        : url.searchParams.delete("elan");

      if (cityValues.length !== 0) {
        url.searchParams.set(
          "seher",
          city.length > 3
            ? city?.map((city) => city.value).join("-")
            : city?.map((city) => city.label).join("-")
        );
      } else {
        url.searchParams.delete("seher");
      }

      if (cityValues?.includes("8") && regionValues?.length !== 0) {
        url.searchParams.set(
          "rayon",
          province?.length > 1
            ? province?.map((city) => city.value).join("-")
            : province?.map((city) => city.label).join("-")
        );
      } else {
        url.searchParams.delete("rayon");
      }

      if (cityValues?.includes("8") && residentValues?.length !== 0) {
        url.searchParams.set(
          "qesebe",
          residents.length > 1
            ? residents?.map((city) => city.value).join("-")
            : residents?.map((city) => city.label).join("-")
        );
      } else {
        url.searchParams.delete("qesebe");
      }
      roomValues?.length !== 0
        ? url.searchParams.set("otaqlar", roomValues?.replaceAll(",", "-"))
        : url.searchParams.delete("otaqlar");

      minPrice !== ""
        ? url.searchParams.set("min-qiymet", minPrice)
        : url.searchParams.delete("min-qiymet");

      maxPrice !== "" && maxPrice !== 99999999999
        ? url.searchParams.set("max-qiymet", maxPrice)
        : url.searchParams.delete("max-qiymet");

      minPricePerM2 !== ""
        ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
        : url.searchParams.delete("min-qiymet-m2");

      maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
        ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
        : url.searchParams.delete("max-qiymet-m2");

      areaMin !== ""
        ? url.searchParams.set("min-sahe", areaMin)
        : url.searchParams.delete("min-sahe");

      areaMax !== "" && areaMax !== 99999999999
        ? url.searchParams.set("max-sahe", areaMax)
        : url.searchParams.delete("max-sahe");

      floorMin !== ""
        ? url.searchParams.set("min-mertebe", floorMin)
        : url.searchParams.delete("min-mertebe");

      floorMax !== "" && floorMax !== 99999999999
        ? url.searchParams.set("max-mertebe", floorMax)
        : url.searchParams.delete("max-mertebe");

      if (cityValues.includes("8") && stationValues.length !== 0) {
        url.searchParams.set(
          "metro",
          stations.length > 1
            ? stations?.map((city) => city.value).join("-")
            : stations?.map((city) => city.label).join("-")
        );
      } else {
        url.searchParams.delete("metro");
      }

      if (cityValues.includes("8") && targetValues.length !== 0) {
        url.searchParams.set(
          "nisangah",
          targets.length > 1
            ? targets?.map((city) => city.value).join("-")
            : targets?.map((city) => city.label).join("-")
        );
      } else {
        url.searchParams.delete("nisangah");
      }

      withGoodsTrue
        ? url.searchParams.set("esyali", "")
        : url.searchParams.delete("esyali");

      withGoodsFalse
        ? url.searchParams.set("esyasiz", "")
        : url.searchParams.delete("esyasiz");

      repairTrue
        ? url.searchParams.set("temirli", "")
        : url.searchParams.delete("temirli");

      repairFalse
        ? url.searchParams.set("temirsiz", "")
        : url.searchParams.delete("temirsiz");

      documentTrue
        ? url.searchParams.set("senedli", "")
        : url.searchParams.delete("senedli");

      documentFalse
        ? url.searchParams.set("senedsiz", "")
        : url.searchParams.delete("senedsiz");

      siteValues.length !== 0
        ? url.searchParams.set(
            "sayt",
            sites.length > 1
              ? sites?.map((city) => city.value).join("-")
              : sites?.map((city) => city.label).join("-")
          )
        : url.searchParams.delete("sayt");

      withCredit
        ? url.searchParams.set("ipoteka", "")
        : url.searchParams.delete("ipoteka");

      filter && url.searchParams.set("filter", filter.value);
      page !== 0
        ? url.searchParams.set("page", page)
        : url.searchParams.delete("page");
    }

    return url.toString().replace("butun-premium-elanlar", "");
  };

  const generateUrl = (el) => {
    return generateUrlAction(
      el.ftType,
      el.offerType,
      el.page,
      el.filter,
      el.city,
      el.rooms,
      el.minPrice,
      el.maxPrice,
      el.areaMin,
      el.areaMax,
      el.province,
      el.residents,
      el.stations,
      el.targets,
      el.floorMin,
      el.floorMax,
      el.sites,
      el.withGoodsTrue,
      el.withGoodsFalse,
      el.withCredit,
      el.repairTrue,
      el.repairFalse,
      el.documentTrue,
      el.documentFalse,
      el?.minPricePerM2,
      el?.maxPricePerM2
    );
  };
  useEffect(() => {
    setOffers(localStorage.getItem("MySearchs"));
    if (offers) {
      setCount(JSON.parse(offers).length);
      setHistorySpan(
        Object.values(JSON.parse(offers))
          .reverse()
          .map((el, index) => (
            el.atType !== "masin" &&<a href={generateUrl(el)} target="_blank" key={index}>
              <span
                className="searchHistorySpan"
                // onClick={() => searchByHistory(el)}
                style={{ cursor: "pointer" }}
              >
                {el.offerType === "s"
                  ? " • Satılır"
                  : el.offerType === "g"
                  ? " • Günlük"
                  : " • Kirayə"}
                {el.ftType && el.ftType?.label ? " • " + el.ftType.label : ""}
                {el.city?.length
                  ? el.city.map((c) => {
                      return " • " + c.label;
                    })
                  : ""}
                {el.province?.length !== 0 &&
                el.city.some((c) => c.value.includes("8"))
                  ? el.province?.map((r) => {
                      return " • " + r.label;
                    })
                  : ""}
                {el.residents?.length &&
                el.city.some((c) => c.value.includes("8"))
                  ? el.residents.map((r) => {
                      return " • " + r.label;
                    })
                  : ""}
                {el.targets?.length &&
                el.city.some((c) => c.value.includes("8"))
                  ? el.targets.map((r) => {
                      return " • " + r.label;
                    })
                  : ""}
                {el.stations?.length &&
                el.city.some((c) => c.value.includes("8"))
                  ? el.stations.map((r) => {
                      return " • " + r.label;
                    })
                  : ""}
                {el.rooms?.length
                  ? el.rooms.map((r) => {
                      return " • " + r.label + "otaqlı";
                    })
                  : ""}
                {el.sites?.length
                  ? el.sites.map((r) => {
                      return " • " + r.label;
                    })
                  : ""}
                {el.areaMin !== "" ? " • Minimum sahə " + el.areaMin : ""}
                {el.areaMax !== "" ? " • Maksimum sahə " + el.areaMax : ""}
                {el.floorMin !== "" ? " • Minimum mərtəbə " + el.floorMin : ""}
                {el.floorMax !== "" ? " • Maksimum mərtəbə " + el.floorMax : ""}
                {el.minPrice !== 0 && el.minPrice !== ""
                  ? " • Minimum qiymət " + el.minPrice
                  : ""}
                {el.maxPrice !== 99999999999 && el.maxPrice !== ""
                  ? " • Maksimum qiymət " + el.maxPrice
                  : ""}
                {el.withGoodsTrue === true ? " • Əşyalı" : ""}
                {el.withGoodsFalse === true ? " • Əşyasız" : ""}
                {el.repairTrue === true ? " • Təmirli" : ""}
                {el.repairFalse === true ? " • Təmirsiz" : ""}
                {el.documentTrue === true ? " • Sənədli" : ""}
                {el.documentFalse === true ? " • Sənədsiz" : ""}
                {el.withCredit === true ? " • İpotekaya yararlı" : ""}
              </span>
            </a>
          ))
      );
    } else {
      setCount(0);
    }
  }, [searchHistoryCheck, filter, page, offerType, showMap]);
  useEffect(() => {
    const fetchData = async () => {
      //gulcan
      changeParametrs();
      if (
        queryParameters.get("elan") !== null ||
        queryParameters.get("satis") !== null ||
        queryParameters.get("page") !== null ||
        queryParameters.get("filter") !== null ||
        queryParameters.get("seher") !== null ||
        queryParameters.get("otaqlar") !== null ||
        queryParameters.get("min-qiymet") !== null ||
        queryParameters.get("max-qiymet") !== null ||
        queryParameters.get("min-qiymet-m2") !== null ||
        queryParameters.get("max-qiymet-m2") !== null ||
        queryParameters.get("min-sahe") !== null ||
        queryParameters.get("max-sahe") !== null ||
        queryParameters.get("rayon") !== null ||
        queryParameters.get("qesebe") !== null ||
        queryParameters.get("metro") !== null ||
        queryParameters.get("nisangah") !== null ||
        queryParameters.get("min-mertebe") !== null ||
        queryParameters.get("max-mertebe") !== null ||
        queryParameters.get("sayt") !== null ||
        queryParameters.get("esyali") !== null ||
        queryParameters.get("esyasiz") !== null ||
        queryParameters.get("ipoteka") !== null ||
        queryParameters.get("temirli") !== null ||
        queryParameters.get("temirsiz") !== null ||
        queryParameters.get("senedli") !== null ||
        queryParameters.get("senedsiz") !== null
      ) {
        dispatch(
          searchOffer(
            queryParameters.get("elan") === "menzil"
              ? options[language].flat_type_options[0]
              : queryParameters.get("elan") === "heyet-evi-bag-villa"
              ? options[language].flat_type_options[1]
              : queryParameters.get("elan") === "ofis"
              ? options[language].flat_type_options[2]
              : queryParameters.get("elan") === "qaraj"
              ? options[language].flat_type_options[3]
              : queryParameters.get("elan") === "torpaq"
              ? options[language].flat_type_options[4]
              : queryParameters.get("elan") === "obyekt"
              ? options[language].flat_type_options[5]
              : options[language].flat_type_options[0],
            queryParameters.get("satis") !== null
              ? "s"
              : queryParameters.get("kiraye") !== null
              ? "k"
              : queryParameters.get("gunluk") !== null
              ? "g"
              : "s",
            queryParameters.get("page") !== null
              ? Number(queryParameters.get("page"))
              : 1,
            queryParameters.get("filter") !== null
              ? queryParameters.get("filter") === "dn"
                ? options[language].filter_options[0]
                : queryParameters.get("filter") === "do"
                ? options[language].filter_options[1]
                : queryParameters.get("filter") === "pl"
                ? options[language].filter_options[2]
                : queryParameters.get("filter") === "ph"
                ? options[language].filter_options[3]
                : options[language].filter_options[4]
              : options[language].filter_options[0],
            queryParameters.get("seher") !== null
              ? ((citiesArray) =>
                  citiesArray.length <= 3
                    ? citiesArray
                        .map((city, index) => {
                          const matchingLabel = options[language].cities.find(
                            (label) => label.label === city
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : citiesArray
                        .map((city, index) => {
                          const matchingLabel = options[language].cities.find(
                            (label) => label.value === city
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("seher").split("-")
                )
              : [],
            queryParameters.get("otaqlar") !== null
              ? ((roomsArray) =>
                  roomsArray
                    .map((city, index) => {
                      const matchingLabel = options[language].room_options.find(
                        (label) => label.value === city
                      );
                      return matchingLabel
                        ? { ...matchingLabel, order: index }
                        : null;
                    })
                    .filter((result) => result !== null))(
                  queryParameters.get("otaqlar").split("-")
                )
              : [],
            queryParameters.get("min-qiymet") !== null
              ? queryParameters.get("min-qiymet")
              : "",
            queryParameters.get("max-qiymet") !== null
              ? queryParameters.get("max-qiymet")
              : "",
            queryParameters.get("min-sahe") !== null
              ? queryParameters.get("min-sahe")
              : "",
            queryParameters.get("max-sahe") !== null
              ? queryParameters.get("max-sahe")
              : "",
            queryParameters.get("rayon") !== null
              ? ((regionsArray) =>
                  regionsArray.length <= 1
                    ? regionsArray
                        .map((region, index) => {
                          const matchingLabel = options[
                            language
                          ].region_options.find(
                            (label) => label.label === region
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : regionsArray
                        .map((region, index) => {
                          const matchingLabel = options[
                            language
                          ].region_options.find(
                            (label) => label.value === region
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("rayon").split("-")
                )
              : [],
            queryParameters.get("qesebe") !== null
              ? ((residentsArray) =>
                  residentsArray.length <= 1
                    ? residentsArray
                        .map((resident, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.label === resident
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : residentsArray
                        .map((region, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.value === region
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("qesebe").split("-")
                )
              : [],
            queryParameters.get("metro") !== null
              ? ((metroArray) =>
                  metroArray.length <= 1
                    ? metroArray
                        .map((metro, index) => {
                          const matchingLabel = options[
                            language
                          ].station_options.find(
                            (label) => label.label === metro
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : metroArray
                        .map((metro, index) => {
                          const matchingLabel = options[
                            language
                          ].station_options.find(
                            (label) => label.value === metro
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("metro").split("-")
                )
              : [],
            queryParameters.get("nisangah") !== null
              ? ((residentsArray) =>
                  residentsArray.length <= 1
                    ? residentsArray
                        .map((resident, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.label === resident
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : residentsArray
                        .map((resident, index) => {
                          const matchingLabel = options[
                            language
                          ].resident_options.find(
                            (label) => label.value === resident
                          );
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("nisangah").split("-")
                )
              : [],
            queryParameters.get("min-mertebe") !== null
              ? queryParameters.get("min-mertebe")
              : "",
            queryParameters.get("max-mertebe") !== null
              ? queryParameters.get("max-mertebe")
              : "",
            queryParameters.get("sayt") !== null
              ? ((siteArray) =>
                  siteArray.length <= 1
                    ? siteArray
                        .map((site, index) => {
                          const matchingLabel = options[
                            language
                          ].site_options.find((label) => label.label === site);
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null)
                    : siteArray
                        .map((site, index) => {
                          const matchingLabel = options[
                            language
                          ].site_options.find((label) => label.value === site);
                          return matchingLabel
                            ? { ...matchingLabel, order: index }
                            : null;
                        })
                        .filter((result) => result !== null))(
                  queryParameters.get("sayt").split("-")
                )
              : [],
            queryParameters.get("esyali") !== null ? true : false,
            queryParameters.get("esyasiz") !== null ? true : false,
            queryParameters.get("ipoteka") !== null ? true : false,
            queryParameters.get("temirli") !== null ? true : false,
            queryParameters.get("temirsiz") !== null ? true : false,
            queryParameters.get("senedli") !== null ? true : false,
            queryParameters.get("senedsiz") !== null ? true : false,
            queryParameters.get("min-qiymet-m2") !== null
              ? queryParameters.get("min-qiymet-m2")
              : "",
            queryParameters.get("max-qiymet-m2") !== null
              ? queryParameters.get("max-qiymet-m2")
              : ""
          )
        );
      } else {
        dispatch(getInitial());
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    dispatch(
      changeSearchRow(
        `${
          offerType === "s"
            ? options[language].sale
            : offerType === "g"
            ? options[language].day
            : options[language].rent
        }${
          flatType
            ? " • " +
              (flatType.label
                ? flatType.label
                : options[language].flat_type_options[0].label)
            : ""
        }${
          city?.length
            ? " • " +
              city.map((c) => {
                return " " + c.label;
              })
            : ""
        }`
      )
    );
    if (!shouldRunSecondEffect) {
      dispatch(setShouldRunSecondEffect(true));
      if (!isSearchByHistory) {
        if (!showMap) {
          const stationValues = stations
            ?.map((station) => station.value - 1)
            .join(",");
          const roomValues = rooms?.map((room) => room.value).join(",");
          const siteValues = sites?.map((site) => site.value).join(",");
          const residentValues = residents
            ?.map((resident) => resident.value - 1)
            .join(",");
          const targetValues = targets
            ?.map((target) => target.value - 1)
            .join(",");
          const regionValues = province
            ?.map((region) => region.value)
            .join(",");
          const cityValues = city?.map((city) => city.value).join(",");
          const url = new URL(window.location.href);
          offerType
            ? url.searchParams.set(
                offerType === "s"
                  ? "satis"
                  : offerType === "g"
                  ? "gunluk"
                  : "kiraye",
                ""
              ) ||
              url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
              url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
              url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
              url.searchParams.delete(
                offerType === "k" ? "gunluk" : "kiraye"
              ) ||
              url.searchParams.delete(
                offerType === "g" ? "kiraye" : "gunluk"
              ) ||
              url.searchParams.delete(offerType === "g" ? "satis" : "gunluk")
            : url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
              url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
              url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
              url.searchParams.delete(
                offerType === "k" ? "gunluk" : "kiraye"
              ) ||
              url.searchParams.delete(
                offerType === "g" ? "kiraye" : "gunluk"
              ) ||
              url.searchParams.delete(offerType === "g" ? "satis" : "gunluk");
          flatType
            ? url.searchParams.set(
                "elan",
                flatType === 1 || flatType.value === "1"
                  ? "menzil"
                  : flatType.value === "2"
                  ? "heyet-evi-bag-villa"
                  : flatType.value === "3"
                  ? "ofis"
                  : flatType.value === "4"
                  ? "qaraj"
                  : flatType.value === "5"
                  ? "torpaq"
                  : flatType.value === "6"
                  ? "obyekt"
                  : "menzil"
              )
            : url.searchParams.delete("elan");
          cityValues.length !== 0
            ? url.searchParams.set(
                "seher",
                city.length > 3
                  ? city?.map((city) => city.value).join("-")
                  : city?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("seher");
          cityValues.includes("8") && regionValues.length !== 0
            ? url.searchParams.set(
                "rayon",
                province.length > 1
                  ? province?.map((city) => city.value).join("-")
                  : province?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("rayon");
          cityValues.includes("8") && residentValues.length !== 0
            ? url.searchParams.set(
                "qesebe",
                residents.length > 1
                  ? residents?.map((city) => city.value).join("-")
                  : residents?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("qesebe");
          roomValues.length !== 0
            ? url.searchParams.set("otaqlar", roomValues.replaceAll(",", "-"))
            : url.searchParams.delete("otaqlar");
          minPrice !== ""
            ? url.searchParams.set("min-qiymet", minPrice)
            : url.searchParams.delete("min-qiymet");
          maxPrice !== "" && maxPrice !== 99999999999
            ? url.searchParams.set("max-qiymet", maxPrice)
            : url.searchParams.delete("max-qiymet");
          areaMin !== ""
            ? url.searchParams.set("min-sahe", areaMin)
            : url.searchParams.delete("min-sahe");
          areaMax !== "" && areaMax !== 99999999999
            ? url.searchParams.set("max-sahe", areaMax)
            : url.searchParams.delete("max-sahe");
          floorMin !== ""
            ? url.searchParams.set("min-mertebe", floorMin)
            : url.searchParams.delete("min-mertebe");
          floorMax !== "" && floorMax !== 99999999999
            ? url.searchParams.set("max-mertebe", floorMax)
            : url.searchParams.delete("max-mertebe");
          cityValues.includes("8") && stationValues.length !== 0
            ? url.searchParams.set(
                "metro",
                stations.length > 1
                  ? stations?.map((city) => city.value).join("-")
                  : stations?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("metro");
          cityValues.includes("8") && targetValues.length !== 0
            ? url.searchParams.set(
                "nisangah",
                targets.length > 1
                  ? targets?.map((city) => city.value).join("-")
                  : targets?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("nisangah");
          withGoodsTrue
            ? url.searchParams.set("esyali", "")
            : url.searchParams.delete("esyali");
          withGoodsFalse
            ? url.searchParams.set("esyasiz", "")
            : url.searchParams.delete("esyasiz");
          repairTrue
            ? url.searchParams.set("temirli", "")
            : url.searchParams.delete("temirli");
          repairFalse
            ? url.searchParams.set("temirsiz", "")
            : url.searchParams.delete("temirsiz");
          documentTrue
            ? url.searchParams.set("senedli", "")
            : url.searchParams.delete("senedli");
          documentFalse
            ? url.searchParams.set("senedsiz", "")
            : url.searchParams.delete("senedsiz");
          siteValues.length !== 0
            ? url.searchParams.set(
                "sayt",
                sites.length > 1
                  ? sites?.map((city) => city.value).join("-")
                  : sites?.map((city) => city.label).join("-")
              )
            : url.searchParams.delete("sayt");
          withCredit
            ? url.searchParams.set("ipoteka", "")
            : url.searchParams.delete("ipoteka");
          filter && url.searchParams.set("filter", filter.value);
          page !== 0
            ? url.searchParams.set("page", page)
            : url.searchParams.delete("page");
          minPricePerM2 !== ""
            ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
            : url.searchParams.delete("min-qiymet-m2");
          maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
            ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
            : url.searchParams.delete("max-qiymet-m2");
          window.location.href = url
            .toString()
            .replace("butun-premium-elanlar", "");
        }
        return dispatch(
          requestOffersWithLocations(
            flatType,
            offerType,
            page,
            filter,
            city,
            rooms,
            minPrice,
            maxPrice,
            areaMin,
            areaMax,
            province,
            residents,
            stations,
            targets,
            floorMin,
            floorMax,
            sites,
            withGoodsTrue,
            withGoodsFalse,
            withCredit,
            repairTrue,
            repairFalse,
            documentTrue,
            documentFalse,
            minPricePerM2,
            maxPricePerM2
          )
        );
      } else {
        if (!showMap)
          return dispatch(
            searchOffer(
              isSearchByHistoryData.flatType,
              isSearchByHistoryData.offerType,
              page,
              isSearchByHistoryData.filter,
              isSearchByHistoryData.city,
              isSearchByHistoryData.rooms,
              isSearchByHistoryData.minPrice,
              isSearchByHistoryData.maxPrice,
              isSearchByHistoryData.areaMin,
              isSearchByHistoryData.areaMax,
              isSearchByHistoryData.province,
              isSearchByHistoryData.residents,
              isSearchByHistoryData.stations,
              isSearchByHistoryData.targets,
              isSearchByHistoryData.floorMin,
              isSearchByHistoryData.floorMax,
              isSearchByHistoryData.sites,
              isSearchByHistoryData.withGoodsTrue,
              isSearchByHistoryData.withGoodsFalse,
              isSearchByHistoryData.withCredit,
              isSearchByHistoryData.repairTrue,
              isSearchByHistoryData.repairFalse,
              isSearchByHistoryData.documentTrue,
              isSearchByHistoryData.documentFalse,
              isSearchByHistoryData.minPricePerM2,
              isSearchByHistoryData.maxPricePerM2
            )
          );
        return dispatch(
          requestOffersWithLocations(
            isSearchByHistoryData.flatType,
            isSearchByHistoryData.offerType,
            page,
            isSearchByHistoryData.filter,
            isSearchByHistoryData.city,
            isSearchByHistoryData.rooms,
            isSearchByHistoryData.minPrice,
            isSearchByHistoryData.maxPrice,
            isSearchByHistoryData.areaMin,
            isSearchByHistoryData.areaMax,
            isSearchByHistoryData.province,
            isSearchByHistoryData.residents,
            isSearchByHistoryData.stations,
            isSearchByHistoryData.targets,
            isSearchByHistoryData.floorMin,
            isSearchByHistoryData.floorMax,
            isSearchByHistoryData.sites,
            isSearchByHistoryData.withGoodsTrue,
            isSearchByHistoryData.withGoodsFalse,
            isSearchByHistoryData.withCredit,
            isSearchByHistoryData.repairTrue,
            isSearchByHistoryData.repairFalse,
            isSearchByHistoryData.documentTrue,
            isSearchByHistoryData.documentFalse,
            isSearchByHistoryData.minPricePerM2,
            isSearchByHistoryData.maxPricePerM2
          )
        );
      }
    }
  }, [shouldRunSecondEffect, filter, page, offerType, showMap]);
  const handleComplexSearchClick = () => {
    setShowAditional(!showAditional);
    setRotateIcon(!rotateIcon);
    try {
      if (showAditional) {
        myElementRef.current
          .querySelectorAll(".css-b62m3t-container")
          ?.forEach((element) => {
            element.style.width = "calc(100% / 6 - 15px)";
          });
      } else {
        myElementRef.current
          .querySelectorAll(".css-b62m3t-container")
          ?.forEach((element) => {
            element.style.width = "calc(100% / 5 - 10px)";
          });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleHistoryClick = () => {
    setShowHistory(!showHistory);
    setRotateIcon2(!rotateIcon2);
  };
  const rotationStyle = {
    transform: rotateIcon ? "rotate(180deg)" : "rotate(0deg)",
  };
  const rotationStyle2 = {
    transform: rotateIcon2 ? "rotate(180deg)" : "rotate(0deg)",
  };
  function clearComplexFilter() {
    setSites([]);
    setRooms([]);
    setResidents([]);
    setProvince([]);
    setTargets([]);
    setStations([]);
    setTargets([]);
    setCity([]);
    setAreaMax("");
    setAreaMin("");
    setFloorMax("");
    setFloorMin("");
    setFlatType(options[language].flat_type_options[0]);
    setOfferType("s");
    setMinPrice("");
    setMaxPrice("");
    setMinPricePerM2("");
    setMaxPricePerM2("");
    SetWithGoodsTrue(false);
    SetWithGoodsFalse(false);
    SetWithCredit(false);
    setDocumentTrue(false);
    setDocumentFalse(false);
    setRepairTrue(false);
    setRepairFalse(false);
  }
  function setResidentsF(e) {
    if (e.length !== 0) {
      if (city.length === 0) {
        setCity([options[language].cities[8]]);
      }
      if (!province.length) {
        setProvince([
          options[language].region_options[
            options[language].region_options.findIndex(
              (item) => item.label === e[e.length - 1]?.region
            )
          ],
        ]);
        const filtered_res = options[language].resident_options.filter(
          (item) => item.region === e[0]?.region
        );
        setResident_opt([...filtered_res]);
        const filtered_resT = options[language].target_options.filter(
          (item) => item.region === e[0]?.region
        );
        setTarget_opt([...filtered_resT]);
      }
    }
    setResidents(e);
    // else if (e.length === 0) {
    //     setResident_opt(resident_options)
    //     setResidents([])
    // }
  }
  function setTargetsF(e) {
    if (e.length !== 0) {
      if (city.length === 0) {
        setCity([options[language].cities[8]]);
      }
      if (!province.length) {
        setProvince([
          options[language].region_options[
            options[language].region_options.findIndex(
              (item) => item.label === e[e.length - 1]?.region
            )
          ],
        ]);
        const filtered_res = options[language].target_options.filter(
          (item) => item.region === e[0]?.region
        );
        setTarget_opt([...filtered_res]);
        const filtered_resR = options[language].resident_options.filter(
          (item) => item.region === e[0]?.region
        );
        setResident_opt([...filtered_resR]);
      }
    }
    setTargets(e);
    // else if (e.length === 0) {
    //     setTarget_opt(target_options)
    //     setTargets([])
    // }
  }
  function setRegionsF(e) {
    if (e.length === 0) {
      setProvince([]);
      setResident_opt(options[language].resident_options);
      setResidents([]);
      setTarget_opt(options[language].target_options);
      setTargets([]);
    } else {
      if (city.length === 0) {
        setCity([options[language].cities[8]]);
      }
      setProvince(e);
      const fR = [];
      const fT = [];
      e.forEach((region) => {
        const filtered_res = options[language].resident_options.filter(
          (item) =>
            item.region === options[language].region_options[region.value].label
        );
        fR.push(...filtered_res);
        const filtered_resT = options[language].target_options.filter(
          (item) =>
            item.region === options[language].region_options[region.value].label
        );
        fT.push(...filtered_resT);
      });
      setResident_opt(fR);
      // setResidents([])
      setTarget_opt(fT);
      // setTargets([])
    }
  }
  function setStationsF(e) {
    if (e.length !== 0) {
      setStations(e);
      if (city.length === 0) {
        setCity([options[language].cities[8]]);
      }
    } else if (e.length === 0) {
      setStation_opt(options[language].station_options);
      setStations([]);
    }
  }
  function addOrRemoveObjectFromLocalStorage(obj) {
    const existingData = localStorage.getItem("MySearchs");
    let dataArray = [];
    if (existingData) {
      dataArray = JSON.parse(existingData);
      if (dataArray.length >= 5) {
        dataArray = dataArray.slice(dataArray.length - 4);
      }
      const existingIndex = dataArray.findIndex(
        (item) => JSON.stringify(item) === JSON.stringify(obj)
      );
      if (existingIndex === -1) {
        dataArray.push(obj);
      }
      localStorage.setItem("MySearchs", JSON.stringify(dataArray));
    } else {
      const newDataArray = [obj];
      localStorage.setItem("MySearchs", JSON.stringify(newDataArray));
    }

    setSearchHistoryCheck(!searchHistoryCheck);
  }
  const searchByHistory = (el) => {
    setIsSearchByHistoryData(el);
    setIsSearchByHistory(true);
    dispatch(
      searchOffer(
        el.flatType,
        el.offerType,
        el.page,
        el.filter,
        el.city,
        el.rooms,
        el.minPrice,
        el.maxPrice,
        el.areaMin,
        el.areaMax,
        el.province,
        el.residents,
        el.stations,
        el.targets,
        el.floorMin,
        el.floorMax,
        el.sites,
        el.withGoodsTrue,
        el.withGoodsFalse,
        el.withCredit,
        el.repairTrue,
        el.repairFalse,
        el.documentTrue,
        el.documentFalse,
        el?.minPricePerM2,
        el?.maxPricePerM2
      )
    );
  };
  const searchButton = (fltType) => {
    let ftType = fltType ? fltType : flatType;
    setIsSearchByHistory(false);
    setIsSearchByHistoryData();
    dispatch(
      changeSearchRow(
        `${
          offerType === "s"
            ? options[language].sale
            : offerType === "g"
            ? options[language].day
            : options[language].rent
        }${
          flatType
            ? " • " +
              (flatType.label
                ? flatType.label
                : options[language].flat_type_options[0].label)
            : ""
        }${
          city?.length
            ? " • " +
              city.map((c) => {
                return " " + c.label;
              })
            : ""
        }`
      )
    );
    addOrRemoveObjectFromLocalStorage({
      ftType,
      offerType,
      page,
      filter,
      city,
      rooms,
      minPrice,
      maxPrice,
      areaMin,
      areaMax,
      province,
      residents,
      stations,
      targets,
      floorMin,
      floorMax,
      sites,
      withGoodsTrue,
      withGoodsFalse,
      withCredit,
      repairTrue,
      repairFalse,
      documentTrue,
      documentFalse,
      minPricePerM2,
      maxPricePerM2,
    });

    const unselectedOptionsC = options[language].cities.filter((opt) => {
      return !city.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsC = city
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsC);
    setSortedCities(sortedOptionsC);

    const unselectedOptionsR = options[language].region_options.filter(
      (opt) => {
        return !province.some((ct) => opt.label === ct.label);
      }
    );
    const sortedOptionsR = province
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsR);
    setProvince_opt(sortedOptionsR);

    const unselectedOptionsRe = resident_opt.filter((opt) => {
      return !residents.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsRe = residents
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsRe);
    setResident_opt(sortedOptionsRe);

    const unselectedOptionsT = target_opt.filter((opt) => {
      return !targets.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsT = targets
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsT);
    setTarget_opt(sortedOptionsT);

    const unselectedOptionsM = options[language].station_options.filter(
      (opt) => {
        return !stations.some((ct) => opt.label === ct.label);
      }
    );
    const sortedOptionsM = stations
      .sort((a, b) => parseInt(a.value) - parseInt(b.value))
      .concat(unselectedOptionsM);
    setStation_opt(sortedOptionsM);

    const unselectedOptionsS = options[language].site_options.filter((opt) => {
      return !sites.some((ct) => opt.label === ct.label);
    });
    const sortedOptionsS = sites.concat(unselectedOptionsS);
    setSiteOpt(sortedOptionsS);

    if (!showMap) {
      if (offerTypeAction !== "get") {
        return dispatch(
          searchOffer(
            ftType,
            offerType,
            page,
            filter,
            city,
            rooms,
            minPrice,
            maxPrice,
            areaMin,
            areaMax,
            province,
            residents,
            stations,
            targets,
            floorMin,
            floorMax,
            sites,
            withGoodsTrue,
            withGoodsFalse,
            withCredit,
            repairTrue,
            repairFalse,
            documentTrue,
            documentFalse,
            minPricePerM2,
            maxPricePerM2
          )
        );
      } else {
        const stationValues = stations
          ?.map((station) => station.value - 1)
          .join(",");
        const roomValues = rooms?.map((room) => room.value).join(",");
        const siteValues = sites?.map((site) => site.value).join(",");
        const residentValues = residents
          ?.map((resident) => resident.value - 1)
          .join(",");
        const targetValues = targets
          ?.map((target) => target.value - 1)
          .join(",");
        const regionValues = province?.map((region) => region.value).join(",");
        const cityValues = city?.map((city) => city.value).join(",");
        const url = new URL(window.location.href);
        offerType
          ? url.searchParams.set(
              offerType === "s"
                ? "satis"
                : offerType === "g"
                ? "gunluk"
                : "kiraye",
              ""
            ) ||
            url.searchParams.delete(offerType === "s" ? "kiraye" : "satis") ||
            url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
            url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
            url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
            url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
            url.searchParams.delete(offerType === "g" ? "satis" : "gunluk")
          : url.searchParams.delete(offerType === "s" ? "kiraye" : "satis");
        url.searchParams.delete(offerType === "s" ? "gunluk" : "satis") ||
          url.searchParams.delete(offerType === "k" ? "satis" : "kiraye") ||
          url.searchParams.delete(offerType === "k" ? "gunluk" : "kiraye") ||
          url.searchParams.delete(offerType === "g" ? "kiraye" : "gunluk") ||
          url.searchParams.delete(offerType === "g" ? "satis" : "gunluk");
        ftType
          ? url.searchParams.set(
              "elan",
              ftType === 1 || ftType.value === "1"
                ? "menzil"
                : ftType.value === "2"
                ? "heyet-evi-bag-villa"
                : ftType.value === "3"
                ? "ofis"
                : ftType.value === "4"
                ? "qaraj"
                : ftType.value === "5"
                ? "torpaq"
                : ftType.value === "6"
                ? "obyekt"
                : "menzil"
            )
          : url.searchParams.delete("elan");
        cityValues.length !== 0
          ? url.searchParams.set(
              "seher",
              city.length > 3
                ? city?.map((city) => city.value).join("-")
                : city?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("seher");
        cityValues.includes("8") && regionValues.length !== 0
          ? url.searchParams.set(
              "rayon",
              province.length > 1
                ? province?.map((city) => city.value).join("-")
                : province?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("rayon");
        cityValues.includes("8") && residentValues.length !== 0
          ? url.searchParams.set(
              "qesebe",
              residents.length > 1
                ? residents?.map((city) => city.value).join("-")
                : residents?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("qesebe");
        roomValues.length !== 0
          ? url.searchParams.set("otaqlar", roomValues.replaceAll(",", "-"))
          : url.searchParams.delete("otaqlar");
        minPrice !== ""
          ? url.searchParams.set("min-qiymet", minPrice)
          : url.searchParams.delete("min-qiymet");
        maxPrice !== "" && maxPrice !== 99999999999
          ? url.searchParams.set("max-qiymet", maxPrice)
          : url.searchParams.delete("max-qiymet");
        minPricePerM2 !== ""
          ? url.searchParams.set("min-qiymet-m2", minPricePerM2)
          : url.searchParams.delete("min-qiymet-m2");
        maxPricePerM2 !== "" && maxPricePerM2 !== 99999999999
          ? url.searchParams.set("max-qiymet-m2", maxPricePerM2)
          : url.searchParams.delete("max-qiymet-m2");
        areaMin !== ""
          ? url.searchParams.set("min-sahe", areaMin)
          : url.searchParams.delete("min-sahe");
        areaMax !== "" && areaMax !== 99999999999
          ? url.searchParams.set("max-sahe", areaMax)
          : url.searchParams.delete("max-sahe");
        floorMin !== ""
          ? url.searchParams.set("min-mertebe", floorMin)
          : url.searchParams.delete("min-mertebe");
        floorMax !== "" && floorMax !== 99999999999
          ? url.searchParams.set("max-mertebe", floorMax)
          : url.searchParams.delete("max-mertebe");
        cityValues.includes("8") && stationValues.length !== 0
          ? url.searchParams.set(
              "metro",
              stations.length > 1
                ? stations?.map((city) => city.value).join("-")
                : stations?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("metro");
        cityValues.includes("8") && targetValues.length !== 0
          ? url.searchParams.set(
              "nisangah",
              targets.length > 1
                ? targets?.map((city) => city.value).join("-")
                : targets?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("nisangah");
        withGoodsTrue
          ? url.searchParams.set("esyali", "")
          : url.searchParams.delete("esyali");
        withGoodsFalse
          ? url.searchParams.set("esyasiz", "")
          : url.searchParams.delete("esyasiz");
        repairTrue
          ? url.searchParams.set("temirli", "")
          : url.searchParams.delete("temirli");
        repairFalse
          ? url.searchParams.set("temirsiz", "")
          : url.searchParams.delete("temirsiz");
        documentTrue
          ? url.searchParams.set("senedli", "")
          : url.searchParams.delete("senedli");
        documentFalse
          ? url.searchParams.set("senedsiz", "")
          : url.searchParams.delete("senedsiz");
        siteValues.length !== 0
          ? url.searchParams.set(
              "sayt",
              sites.length > 1
                ? sites?.map((city) => city.value).join("-")
                : sites?.map((city) => city.label).join("-")
            )
          : url.searchParams.delete("sayt");
        withCredit
          ? url.searchParams.set("ipoteka", "")
          : url.searchParams.delete("ipoteka");
        filter && url.searchParams.set("filter", filter.value);
        page !== 0
          ? url.searchParams.set("page", page)
          : url.searchParams.delete("page");
        return (window.location.href = url
          .toString()
          .replace("butun-premium-elanlar", ""));
      }
    }
    return dispatch(
      requestOffersWithLocations(
        ftType,
        offerType,
        page,
        filter,
        city,
        rooms,
        minPrice,
        maxPrice,
        areaMin,
        areaMax,
        province,
        residents,
        stations,
        targets,
        floorMin,
        floorMax,
        sites,
        withGoodsTrue,
        withGoodsFalse,
        withCredit,
        repairTrue,
        repairFalse,
        documentTrue,
        documentFalse,
        minPricePerM2,
        maxPricePerM2
      )
    );
  };
  useMemo(() => {
    // if(!document.cookie.includes("countRequest")){
    //     let expirationDate = new Date();
    //     expirationDate.setTime(expirationDate.getTime() + (2000));
    //     let cookieString = "countRequest=1; expires=" + expirationDate.toUTCString() + "; path=/";
    //     document.cookie = cookieString;
    dispatch(
      getCountOfOffers(
        flatType,
        offerType,
        city,
        rooms,
        minPrice,
        maxPrice,
        areaMin,
        areaMax,
        province,
        residents,
        stations,
        targets,
        floorMin,
        floorMax,
        sites,
        withGoodsTrue,
        withGoodsFalse,
        withCredit,
        repairTrue,
        repairFalse,
        documentTrue,
        documentFalse,
        minPricePerM2,
        maxPricePerM2
      )
    );
    // }
  }, [
    flatType,
    minPrice,
    maxPrice,
    withGoodsTrue,
    withGoodsFalse,
    withCredit,
    documentTrue,
    documentFalse,
    repairTrue,
    repairFalse,
    province,
    residents,
    stations,
    targets,
    areaMin,
    areaMax,
    floorMin,
    floorMax,
    city,
    sites,
    rooms,
    minPricePerM2,
    maxPricePerM2,
  ]);
  return (
    <div className="filter">
      <div className="filter-content">
        <div className="filter-category">
          <div className="filter-category">
            <div
              className={
                offerType === "s" && offerTypeAction !== "get"
                  ? "cover"
                  : "dontcover"
              }
              onClick={() => {
                dispatch(setShouldRunSecondEffect(false));
                setOfferType("s");
              }}
            >
              {options[language].sale}
            </div>
            <div
              className={offerType === "k" ? "cover" : "dontcover"}
              onClick={() => {
                dispatch(setShouldRunSecondEffect(false));
                setOfferType("k");
              }}
            >
              {options[language].rent}
            </div>
            <div
              className={offerType === "g" ? "cover" : "dontcover"}
              onClick={() => {
                dispatch(setShouldRunSecondEffect(false));
                setOfferType("g");
              }}
            >
              {options[language].day}
            </div>
            <div
              className={"cars-name"}
              onClick={() => {
                const url = new URL(window.location.href);
                url.searchParams.set("elan", "masin");
                url.searchParams.delete("rayon")
                url.searchParams.delete("qesebe")
                url.searchParams.delete("otaqlar")
                url.searchParams.delete("min-qiymet-m2")
                url.searchParams.delete("max-qiymet-m2")
                url.searchParams.delete("min-sahe")
                url.searchParams.delete("max-sahe")
                url.searchParams.delete("min-mertebe")
                url.searchParams.delete("max-mertebe")
                url.searchParams.delete("metro")
                url.searchParams.delete("nisangah")
                url.searchParams.delete("esyali")
                url.searchParams.delete("esyasiz")
                url.searchParams.delete("temirli")
                url.searchParams.delete("temirsiz")
                url.searchParams.delete("senedli")
                url.searchParams.delete("senedsiz")
                url.searchParams.delete("ipoteka")
                window.location.href = url
                  .toString()
                  .replace("butun-premium-elanlar", "");
                // dispatch(setAdsType("cars"))
                localStorage.setItem("adsType", "cars");
              }}
            >
              {options[language].cars}
            </div>
          </div>
          <div className="underline" onClick={() => clearComplexFilter()}>
            {options[language].clean}
          </div>
          {/* <div className='vl'></div>
                    <div className='underline'>Günlük</div> */}
        </div>
        <div className="filters-first-row" ref={myElementRef}>
          <Select
            styles={styles}
            closeMenuOnSelect={false}
            isMulti={true}
            options={sortedCities}
            value={city}
            placeholder={options[language].cityName}
            hideSelectedOptions={false}
            onChange={(options) => {
              if (Array.isArray(options)) {
                setCity(options.map((opt) => opt));
              }
            }}
            components={{
              Option: InputOption,
            }}
          />
          <Select
            styles={styles}
            closeMenuOnSelect={true}
            isMulti={false}
            options={options[language].flat_type_options}
            onChange={(e) => setFlatType(e)}
            value={
              flatType?.value
                ? flatType
                : options[language].flat_type_options[0]
            }
            placeholder={options[language].flatTypeName}
          />
          <Select
            closeMenuOnSelect={false}
            isMulti={true}
            styles={styles}
            options={options[language].room_options}
            value={rooms.sort((a, b) => parseInt(a.value) - parseInt(b.value))}
            placeholder={options[language].roomsCountName}
            hideSelectedOptions={false}
            onChange={(options) => {
              if (Array.isArray(options)) {
                setRooms(options.map((opt) => opt));
              }
            }}
            components={{
              Option: InputOption,
            }}
          />
          <div className="css-b62m3t-container minmaxInput">
            <span className="min-width-span">
              {options[language].priceName}
            </span>
            <h1
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={minPrice}
                placeholder={options[language].min}
                min={0}
                type="number"
                onChange={(e) => setMinPrice(e.target.value)}
              />
              <input
                value={maxPrice}
                placeholder={options[language].max}
                min={0}
                type="number"
                onChange={(e) => setMaxPrice(e.target.value)}
              />
            </h1>
          </div>
          <div className="css-b62m3t-container minmaxInput">
            <span className="min-width-span">{`${options[language].areaName}${
              flatType?.value === "5"
                ? ", " + options[language].sot
                : ", " + options[language].m2
            }:`}</span>
            <h1
              style={{ width: "100%", display: "flex", flexDirection: "row" }}
            >
              <input
                value={areaMin}
                placeholder={options[language].min}
                min={0}
                type="number"
                onChange={(e) => setAreaMin(e.target.value)}
              />
              <input
                value={areaMax}
                placeholder={options[language].max}
                min={0}
                type="number"
                onChange={(e) => setAreaMax(e.target.value)}
              />
            </h1>
          </div>
          {!showAditional && (
            <div className="css-b62m3t-container css-b62m3t-container-buttonM">
              <button
                className="css-b62m3t-container-button"
                onClick={() => searchButton()}
              >
                {options[language].searchName}
              </button>
            </div>
          )}
        </div>
        <hr />
        {/* {showAditional && <> */}
        <div className={`ann ${showAditional ? "showAditional" : ""}`}>
          <div className="filters-second-row">
            <Select
              closeMenuOnSelect={false}
              isMulti={true}
              styles={styles}
              options={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? province_opt
                  : []
              }
              value={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? province
                  : []
              }
              placeholder={options[language].regionName}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setRegionsF(options.map((opt) => opt));
                }
              }}
              components={{
                Option: InputOption,
              }}
            />
            <Select
              styles={styles}
              closeMenuOnSelect={false}
              isMulti={true}
              options={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? resident_opt
                  : []
              }
              value={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? residents
                  : []
              }
              placeholder={options[language].residentName}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setResidentsF(options.map((opt) => opt));
                }
              }}
              components={{
                Option: InputOption,
              }}
            />
            <div className="css-b62m3t-container minmaxInput">
              <span className="min-width-span">
                {options[language].floorName}
              </span>
              <h1
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <input
                  value={floorMin}
                  placeholder={options[language].min}
                  min={0}
                  type="number"
                  onChange={(e) => setFloorMin(e.target.value)}
                />
                <input
                  value={floorMax}
                  placeholder={options[language].max}
                  min={0}
                  type="number"
                  onChange={(e) => setFloorMax(e.target.value)}
                />
              </h1>
            </div>
            <div className="css-b62m3t-container minmaxInput">
              <span className="min-width-span">
                {flatType?.value === "5"
                  ? options[language].pricePerSotName
                  : options[language].pricePerM2Name}
              </span>
              <h1
                style={{ width: "100%", display: "flex", flexDirection: "row" }}
              >
                <input
                  value={minPricePerM2}
                  placeholder={options[language].min}
                  min={0}
                  type="number"
                  onChange={(e) => setMinPricePerM2(e.target.value)}
                />
                <input
                  value={maxPricePerM2}
                  placeholder={options[language].max}
                  min={0}
                  type="number"
                  onChange={(e) => setMaxPricePerM2(e.target.value)}
                />
              </h1>
            </div>
            <Select
              closeMenuOnSelect={false}
              isMulti={true}
              styles={styles}
              options={site_opt}
              value={sites}
              placeholder={options[language].siteName}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setSites(options.map((opt) => opt));
                }
              }}
              components={{
                Option: InputOption,
              }}
            />
          </div>
          <hr />
          <div className="filters-third-row">
            <Select
              closeMenuOnSelect={false}
              isMulti={true}
              styles={styles}
              options={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? station_opt
                  : []
              }
              value={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? stations
                  : []
              }
              placeholder={options[language].stationName}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setStationsF(options.map((opt) => opt));
                }
              }}
              components={{
                Option: InputOption,
              }}
            />
            <Select
              styles={styles}
              closeMenuOnSelect={false}
              isMulti={true}
              options={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? target_opt
                  : []
              }
              value={
                city
                  .map((city) => city.value)
                  .join(",")
                  .includes("8") || city.length === 0
                  ? targets
                  : []
              }
              placeholder={options[language].targetName}
              hideSelectedOptions={false}
              onChange={(options) => {
                if (Array.isArray(options)) {
                  setTargetsF(options.map((opt) => opt));
                }
              }}
              components={{
                Option: InputOption,
              }}
            />
            <div className="checkboxes-div">
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={withGoodsTrue}
                  value={withGoodsTrue}
                  className="checkBoxC"
                  onChange={(e) => SetWithGoodsTrue(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].withGoodsName}
              </label>
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={withGoodsFalse}
                  value={withGoodsFalse}
                  className="checkBoxC checkBoxC2"
                  onChange={(e) => SetWithGoodsFalse(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].withoutGoodsName}
              </label>
            </div>
            <div className="checkboxes-div">
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={repairTrue}
                  value={repairTrue}
                  className="checkBoxC"
                  onChange={(e) => setRepairTrue(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].repairName}
              </label>
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={repairFalse}
                  value={repairFalse}
                  className="checkBoxC checkBoxC2"
                  onChange={(e) => setRepairFalse(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].withoutRepairName}
              </label>
            </div>
            <div className="checkboxes-div">
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={documentTrue}
                  value={documentTrue}
                  className="checkBoxC"
                  onChange={(e) => setDocumentTrue(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].withDocumentsName}
              </label>
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={documentFalse}
                  value={documentFalse}
                  className="checkBoxC checkBoxC2"
                  onChange={(e) => setDocumentFalse(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].withoutDocumentsName}
              </label>
            </div>
            <div className="checkboxes-div">
              <label
                className="css-b62m3t-container smallFontSize"
                style={{ zIndex: "0" }}
              >
                <Checkbox
                  checked={withCredit}
                  value={withCredit}
                  className="checkBoxC"
                  onChange={(e) => SetWithCredit(e.target.checked)}
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 28 },
                    color: grey[50],
                    "&.Mui-checked": {
                      color: grey[50],
                    },
                  }}
                />
                {options[language].mortgageName}
              </label>
            </div>
            <div className="css-b62m3t-container css-b62m3t-container-buttonM">
              <button
                className="css-b62m3t-container-button"
                onClick={() => searchButton()}
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                }}
              >
                {loadingOfCount ? (
                  <CircularProgress
                    size={20}
                    fontSize="small"
                    color="inherit"
                  />
                ) : (
                  countOfOffers + " "
                )}
                {options[language].showOffersName}
              </button>
            </div>
          </div>
          <hr />
        </div>
        {/* </>} */}
      </div>
      <div className="filters-additional-row">
        <div className="filters-additional-row-content">
          <div className="history" onClick={(e) => handleHistoryClick()}>
            <img className="firstImg" src={History} alt="history icon" />
            {options[language].historyName}
            <img
              className="secondImg secondImg1"
              src={Down}
              alt="down icon"
              style={rotationStyle2}
            />
          </div>
          <div
            className="complex-search"
            onClick={(e) => handleComplexSearchClick()}
          >
            <img className="firstImg" src={Detail} alt="history icon" />
            {options[language].additionalsearchName}
            <img
              className="secondImg secondImg2"
              src={Down}
              alt="down icon"
              style={rotationStyle}
            />
          </div>
        </div>
      </div>
      <div
        className={`filters-history-row ${showHistory ? "showHistory" : ""}`}
      >
        <div className="filters-history-row-content">
          <span className="history-span-title">
            {options[language].lastFiveSearchName}
          </span>
          {historySpan}
        </div>
      </div>
    </div>
  );
};

export default Filter;
